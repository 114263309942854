import React, { useState } from "react";
import format from 'date-fns/format';
import FormDialog from '../../_presentationals.v2/FormDialog';
import DateField from './../../_presentationals.v2/Form/DateField';

const date = format(new Date(), 'yyyy/MM/dd');

const defaultValues = {
  start: date,
  finish: date
};

const DateDialogCheckIn14DialogComponent = props => {

  const [ values, setValues ] = useState(defaultValues);
  
  const handleClick = () => {
    props.onClick(values.start.toString().replace(/\//g, '-'), values.finish.toString().replace(/\//g, '-'));
  }

  const handleDateChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd')
    setValues(values => ({ ...values, [name]: tmpValue }));
  }

  return(
    <FormDialog
      title='Selecciona el rango de fechas'
      isOpen={props.isOpen}
      width='sm'
      hideClose
      actionLabel="Continuar"
      handleAction={handleClick}
    >
      <DateField 
        name='start'
        label='Fecha Inicial'
        value={values.start}
        onChange={handleDateChange}
      />
      <DateField 
        name='finish'
        label='Fecha Final'
        value={values.finish}
        onChange={handleDateChange}
      />
    </FormDialog>
  );
}

export default DateDialogCheckIn14DialogComponent;