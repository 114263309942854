import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'column',
    // // padding: theme.spacing(2, 4, 3, 4),
    // [theme.breakpoints.down('xs')]: {
    //   // padding: theme.spacing(0, 1, 0, 1),
    //   // marginBottom: theme.spacing(1),
    // },
  },
}));

export default useStyles;
