import React from 'react';

import dateFormat from 'date-fns/format';


import FormDialog from './../../_presentationals.v2/FormDialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { newDate } from '../../_helpers.v2';

const Component = props => {
	return (
		<FormDialog
      title='Historial'
      isOpen={props.isOpen || false}
      handleClose={props.handleClose}
      width='md'
      hideAction
    >
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Fecha</TableCell>
            <TableCell align='center'>Promedio</TableCell>
            <TableCell>Apreciación</TableCell>
            <TableCell>Colaborador</TableCell>
            <TableCell>Cliente</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            props.values.data.map(el => (
              <TableRow key={el.id}>
                <TableCell>{ dateFormat(newDate(el.createdAt), 'dd/MM/yyyy') }</TableCell>
                <TableCell align='center'>{ parseFloat(el.avg).toFixed(2) }</TableCell>
                <TableCell>{ `${el.appreciationScore} ${el.appreciationDescription}` }</TableCell>
                <TableCell>{ `${el.collaboratorScore} ${el.collaboratorDescription}` }</TableCell>
                <TableCell>{ `${el.customerScore} ${el.customerDescription}` }</TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </FormDialog>
   )
}

export default Component;