import React, { Fragment } from "react";

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import CalendarIcon from '@material-ui/icons/CalendarToday';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';

import { useStyles } from './style';

const Component = ({ handleOpenFilter, handleAction }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <Grid container alignContent="center" alignItems="center" className={ classes.header } spacing={0}>
        <Grid item md={6} xs={12}>
          <Typography variant="subtitle2" className={ classes.headerTitle }>
           REPORTE MENSUAL DE INDICADORES DE PRODUCTIVIDAD
          </Typography>
        </Grid>
        <Grid item className={ classes.headerButtons } md={6} xs={false}>
          <Button onClick={ handleOpenFilter } color="secondary" data-id={1}>
            <CalendarIcon fontSize="small" /> Filtrar
          </Button>
          <Button onClick={ handleAction } color="secondary" data-id={1}>
            <SettingsIcon fontSize="small" /> Guardar
          </Button>
        </Grid>
      </Grid>
      
      <Divider />
    </Fragment>
  )
}

export default Component;