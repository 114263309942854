const api = ['name', 'phone', 'years', 'preference', 'Days', 'schedule', 'addressLine2'];
const tmp = {};

const users = (method, values) => {
  switch (method) {
    case 'POST':
      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      api.forEach(key =>{
        if(!Object.keys(tmp).includes(key)) tmp[key] = "";
      });
      return tmp;
    case 'PUT':
      if(values.generalBenefits)
      return {
        healthInsurance: values.healthInsurance,
        additionalBenefits: values.additionalBenefits,
        taxBenefits: values.taxBenefits,
        infonavit: values.infonavit,
      };
      if (values.updateBenefits)
        return {
          healthInsurance: values.healthInsurance,
          uber: values.uber,
          additionalBenefits: values.additionalBenefits,
          taxBenefits: values.taxBenefits,
          infonavit: values.infonavit,
          status: values.status,
          startBenefit: values.startBenefit,
          finishBenefit: values.finishBenefit,
          movementType: values.movementType,
        };
      if(values.isInterview){
        const { description } = values;
        const isAllowed = (description === "") ? true : false;
        return { isAllowed, description }; 
      }

      api.push('addressLine1', 'zipCode', 'city', 'civilStatus', 'experience', 'estimatedSalary', 'filing', 'isAllowed', 'description', 'creditCardBank', 'creditCardId', 'creditCardOwner', 'paymentPlan');
      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      api.forEach(key =>{
        if(!Object.keys(tmp).includes(key)) tmp[key] = "";
      });
      
      tmp['Reference'] = {
        name: values.referenceName,
        phone: values.referencePhone
      }
      tmp['Documents'] = {
        'ine': values.ine,
        'proofOfAddress': values.proofOfAddress,
        'birthCertificate': values.birthCertificate
      }
      return tmp;
    default:
      return tmp;
  }
}

export default users;