import React, { useState, useEffect } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCellMUI from '@material-ui/core/TableCell';

import Dialog from './dialog';
import { recordsServices } from '../../_services';

const defaultValues = { total: "" };

export default function BalanceDialog(props) {

	const [ values, setValues ] = useState(defaultValues);

  useEffect(() => {
    if(parseInt(props.customerId) > 0){
      recordsServices.getAll({ table: 'filipinas', _id: props.customerId }).then(data => {
        const total = (data.Quotation && data.Quotation.Products) ? parseFloat(data.Quotation.Products.reduce((a, b) => a + parseFloat(b.total), 0)).toFixed(2) : 0;
        const advancePayment = data.advancePayment ? data.advancePayment : 0;
        setValues({ advancePayment, total });
      });
    }
  }, [ props.customerId ]);

  const handleAccept = () => { handleClose(); }
  const handleClose = () => { props.handleClose('balance', { resetForm: true }); }

	return (
		<Dialog
			isOpen={ props.isOpen }
			handleClose={ handleClose }
			handleAccept={ handleAccept }
			title="Resumen de la venta"
			contentText="Revisa un resumen de la venta emitida, el total es el resultado de la suma de los conceptos cotizados."
		>
			<Table>
	    	<TableBody>
	    		<TableRow>
	    			<TableCellMUI>Total de Cotización</TableCellMUI>
	    			<TableCellMUI align="right">{ parseFloat(values.total).toFixed(2) }</TableCellMUI>
	    		</TableRow>
	    		<TableRow>
	    			<TableCellMUI>Anticipo</TableCellMUI>
	    			<TableCellMUI align="right">$ { parseFloat(values.advancePayment).toFixed(2) }</TableCellMUI>
	    		</TableRow>
	    		<TableRow>
	    			<TableCellMUI>Saldo Pendiente</TableCellMUI>
	    			<TableCellMUI align="right">$ { (parseFloat(values.total) - parseFloat(values.advancePayment)).toFixed(2) }</TableCellMUI>
	    		</TableRow>
	    	</TableBody>
    	</Table>

		</Dialog>
	);
}