import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import RoomIcon from '@material-ui/icons/Room';
import {FormDialog} from '../../_presentationals/FormDialog';
import { TextField, SelectField } from './../../_presentationals/Form';

import { recordsActions } from '../../_actions';

const EditAddress = ({ getRecords, states, ...props }) => {

  const [mexicanStates, setMexicanStates] = useState({});

  useEffect(() => {
    const _session = JSON.parse(localStorage.getItem('session'));
    const { countryId } = _session.data;
    getRecords({ table: 'states', countryId  });
  }, [getRecords]);

  useEffect(() => {
    const { count, rows } = states;
    const tmp = count > 0 ? rows.map(({id, name}) => ({ key: id, value: name })) : {};
    setMexicanStates(tmp);
  }, [states]);

  return (
    <FormDialog
      name="Address"
      singular="Address"
      filter={false}
      title="Editar domicilio"
    >
      <TextField
        icon={<RoomIcon />}
        fields={[
          {
            id: "addressLine1",
            label: "Dirección 1",
            multiline: true
          },
          {
            id: "addressLine2",
            label: "Dirección 2",
            multiline: true,
            required: false
          }
        ]}
      />
      <TextField
        fields={[
          {
            id: "city",
            label: "Ciudad"
          },
          {
            id: "zipCode",
            label: "Código Postal"
          }
        ]}
      />
      <SelectField
        fields={[{
          id: 'stateId',
          label: 'Estado',
          options: mexicanStates,
        }]}
      />
      <TextField
        fields={[{
          id: "reference",
          label: "Referencia",
          multiline: true,
          required: false
        }]}
      />
    </FormDialog>
  );

}

const mapStateToProps = state => ({
  states: state.records.states || {count: 0, rows: {}}
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value))
});

export default connect(mapStateToProps,mapDispatchToProps)(EditAddress);
