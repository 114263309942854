import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import TodayIcon from '@material-ui/icons/TodayOutlined';
import EventIcon from '@material-ui/icons/EventOutlined';

import { recordsServices } from './../../_services.v2';
import { getDataFromServer } from './../../_actions.v2';
import moneyFormat from './../../_helpers.v2/moneyFormat';

import Module from './../../_presentationals.v2/Module';

const Rows = [
	{ label: 'Mucama', key: 'name', sort: 'text', filter: true }, 
	{ label: 'InfSemana', key: 'infonavit', format: 'currency', sort: 'number', align: 'right' },
	{ label: 'SegSemana', key: 'healthInsurance', format: 'currency', sort: 'number', align: 'right' },
	{ label: 'Infonavit', key: 'totalInfonavit', format: 'currency', sort: 'number', align: 'right' },
	{ label: 'Seguro', key: 'totalHealthInsurance', format: 'currency', sort: 'number', align: 'right' },
	{ label: 'Total', key: 'benefits', format: 'currency', sort: 'number', align: 'right' },
]; 

const Component = ({ getData, ...props }) => {

	const [ values, setValues ] = useState([]);
	const [ query, setQuery ] = useState('');
	const [ total, setTotal ] = useState([0, 0, 0]);

	useEffect(() => {
		getData && recordsServices.get(`maids/benefits${query}`)
			.then(data => {
				const tmpTotal = data.reduce((total, el) => 
					[
						total[0] + parseFloat(el.totalInfonavit),
						total[1] + parseFloat(el.totalHealthInsurance),
						total[2] + parseFloat(el.benefits),
					]
				, [0, 0, 0]);
				setTotal(tmpTotal);
				setValues(data);
        props.getDataFromServer(false);
			})
			.catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleFilter = ({ start, finish }) => {
		if(start && finish) {
			setQuery(`?start=${start}&finish=${finish}`);
			props.getDataFromServer(true);
		}
	}

	return (
		<Module 
			route=''
      title='Reporte de Prestaciones'
      subTitle={`I: ${moneyFormat(total[0])}, S: ${moneyFormat(total[1])}, T: ${moneyFormat(total[2])}`}
      Rows={Rows}
      noEdit
      noDelete
      noCreate
      filter={[handleFilter, [{
      	field: 'date',
      	icon: <TodayIcon />,
      	name: 'start',
      	label: 'Fecha Inicial'
      },{
      	field: 'date',
      	icon: <EventIcon />,
      	name: 'finish',
      	label: 'Fecha Final'
      }]]}
      records={values}
    />
	);
};

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: value => dispatch(getDataFromServer(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component); 