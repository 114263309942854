import React from "react";

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const ConfirmDialogModule = ({_id, ...props}) => {

  const handleClose = () => {
    props.handleCancel && props.handleCancel();
  }

  const handleAction = () => {
    props.handleAction(_id);
  }

  return (
    <Dialog
      open={parseInt(_id) > 0 ? true : false}
      fullWidth={true}
      maxWidth="xs"
      aria-labelledby="Confirmar"
      aria-describedby="Dialogo de confirmación para validar un registro."
    >
      <DialogTitle disableTypography={true}>¿Estás seguro que deseas continuar?</DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleAction} color="primary" autoFocus>
          Si, estoy seguro.
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const ConfirmDialog = ConfirmDialogModule;