const customers = ({method, ...props}) => {
  switch (method) {
    case 'PUT':
    	if(props.typeRate) return `customers/${props._id}/rates`;
      if(props.changeQuotationStatus) return `customers/${props._id}/quotations`;
      if(props.isCommenting) return `customers/${props._id}/status`;
    	if(props.isHiring) return `customers/${props._id}/contracts`;
      if(props.updateBalance) return `customers/${props._id}/balances`;
    	return `customers/${props._id}`;
    case 'DELETE':
      return `customers/${props._id}`;
    default:
      return 'customers';
  }
}

export default customers;
