import { format } from "date-fns";
import localeES from 'date-fns/locale/es';
import { newDate } from "../../_helpers.v2";

const checkIns = ({method, ...props}) => {
  switch (method) {
  	case 'POST':
  		return `maids/${props.id}/checkIns`;
  	case 'PUT':
      if(props.customerId && props.updateReceivable){ return `customers/${props.customerId}/payments`}
      if(props.payrollId && props.status){ return `payrolls`}
      if(props.isBonus || props.isLoan){ return `payrolls/${props.id}`}
      return `checkIns/${props.id}/status/${props.status}`;
    case 'GET':
      if(props.isChecksOfMaid) return `checkIns/maids/${props.id}?date=${props.date}`;
      if(props.balance){ 
        const startFormat = props.start ? newDate(props.start) : new Date();
        const finishFormat = props.finish ? newDate(props.finish) : new Date();

        const start = format(startFormat, 'yyyy/MM/dd', {locale: localeES});
        const finish = format(finishFormat, 'yyyy/MM/dd', {locale: localeES});
        return `checkIns/results?start=${start}&finish=${finish}` 
      }
      if(props.receivable) return `checkIns/receivables/${props.start}/${props.finish}`;
      return `checkIns/${props.start}/${props.finish}`
    case 'DELETE':
      return `checkIns?date=${props.date}`
    default:
      return 'checkIns';
  }
}

export default checkIns;