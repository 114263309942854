import React from 'react';

import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';

const Component = props => {

	const handleClick = () => { props.onClick(props.id); }

	return (
		<TableSortLabel active direction={props.direction || 'asc'} onClick={handleClick}>
			<Typography variant="subtitle2" noWrap={true}>
				{ props.label }
			</Typography>
		</TableSortLabel>
	)
}

export default Component;