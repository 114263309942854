import React, { useState } from 'react';

import format from 'date-fns/format';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import TextField from './../../_presentationals.v2/Form/TextField';
import DateField from './../../_presentationals.v2/Form/DateField';
import { newDate } from '../../_helpers.v2';

const currentDate = format(new Date(), 'yyyy-MM-dd');

const defaultValues = {
  start: currentDate,
  finish: currentDate
}

const SearchDialog = props => {
	
	const [ value, setValue ] = useState('');
  const [ dates, setDates ] = useState(defaultValues);
  
	const handleClose = () => {
		props.handleClose && props.handleClose();
	};

	const handleClick = () => {
		props.handleClick && props.showDate 
      ? props.handleClick({ search: value, start: dates.start, finish: dates.finish })
      : props.handleClick(value);
    handleClose();
	}

	const handleChange = e => {
		const value = e.target.value;
		setValue(value);
	}

  const handleDateChange = (name, value) => {
    const tmpValue = format(value, 'yyyy-MM-dd')
    setDates(values => ({ ...values, [name]: tmpValue }));
  }

	return (
		<Dialog
      open={props.isOpen}
      onClose={handleClose}
      maxWidth='xs'
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
    	<DialogContent>
    		<br/>
        {
          props.showDate &&
            <Grid container spacing={1}>

              <Grid item xs={12} md={6}>
                <DateField 
                  name='start'
                  label='Fecha Inicial'
                  value={newDate(dates.start)}
                  onChange={handleDateChange}
                  noFormat
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DateField 
                  name='finish'
                  label='Fecha Final'
                  value={newDate(dates.finish)}
                  onChange={handleDateChange}
                  noFormat
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <br/>
              </Grid>
              
            </Grid>
        }
    		<TextField
    			label='Buscador'
    			name='search'
    			value={value}
    			onChange={handleChange}
    			variant='outlined'
    			size='small'
    			noFormat
    			autoFocus
    		/>
    		<br/>
        
    	</DialogContent>
    	<DialogActions>
    		<Button onClick={handleClose} color="inherit">
          Cancelar
        </Button>
        <Button onClick={handleClick} color="primary">
          Buscar
        </Button>
    	</DialogActions>
    </Dialog>
	);
};

export default SearchDialog