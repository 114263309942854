import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Icon from '@material-ui/core/Icon';
import { FixedSizeList } from 'react-window';
import PropTypes from 'prop-types';

import { editValueAction } from '../../../_actions';

import "./index.css";

function renderRow(props) {
  const { data, index, style } = props;

  return React.cloneElement(data[index], {
    style: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
      ...style,
    },
  });
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = smUp ? 36 : 48;

  const outerElementType = React.useMemo(() => {
    return React.forwardRef((props2, ref2) => <div ref={ref2} {...props2} {...other} />);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div ref={ref}>
      <FixedSizeList
        style={{ padding: 0, height: Math.min(8, itemCount) * itemSize, maxHeight: 'auto' }}
        itemData={children}
        height={250}
        width="100%"
        outerElementType={outerElementType}
        innerElementType="ul"
        itemSize={itemSize}
        overscanCount={5}
        itemCount={itemCount}
      >
        {renderRow}
      </FixedSizeList>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const useStyles = makeStyles({
  listbox: {
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

function AutocompleteField(props) {

	const [ value, setValue ] = useState(null);
  const classes = useStyles();

  useEffect(() => {
  	if(props.fieldValue[props._id]){
  		const data = props.options.find(el => el.value === props.fieldValue[props._id]);
  		data && setValue(data);
  	}
  }, [props.fieldValue, props.options, props._id]);

  const handleChange = (event, newValue) => { 
  	if(newValue){
  		props.editValueAction({ 
  			[props._id]: newValue.value,
  			[`${props._id}Label`]: newValue.label
  		});
	    Object.keys(newValue).map(key => !['label', 'value'].includes(key) && editValueAction({ [key]: newValue[key] }));
  	}
  }

	return (
		<Grid container alignItems={ props.dialog ? "flex-end" : "stretch"}>
			{ props.dialog && 
        <Grid item xs={1}><Icon className="material-icons-outlined">{props.icon}</Icon></Grid> 
      }
      <Grid item xs={props.dialog ? 9 : 12}>
				<Autocomplete
				  id={props._id}
				  disableListWrap
				  classes={classes}
				  value={value}
	        onChange={handleChange}
				  ListboxComponent={ListboxComponent}
				  options={props.options}
				  getOptionLabel={ option => option.label }
				  renderInput={params => (
				    <TextField {...params} autoComplete="off" variant={props.variant} label={props.label} fullWidth />
				  )}
				/>
			</Grid>
		</Grid>
	);
};

AutocompleteField.defaultProps = {
  dialog: true,
  variant: 'standard'
};

const mapStateToProps = state => ({ 
  fieldValue: state.field.value
});

const mapDispatchToProps = dispatch => ({ 
  editValueAction: data => dispatch(editValueAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AutocompleteField);