import React, { PureComponent } from "react";
import { connect } from 'react-redux';

import PhoneAndroidcon from '@material-ui/icons/PhoneAndroid'

import { FormDialog } from './../../_presentationals/FormDialog';
import { TextField } from './../../_presentationals/Form';

class EditPhone2 extends PureComponent {

  render() {
  	return (
      <FormDialog
        title="Editar teléfono movíl"
        name="EditPhone2"
        singular="phone2"
      >
        <TextField
          icon={<PhoneAndroidcon />}
          fields={[
            {
              id: "phone2",
              label: "Teléfono Móvil",
              required: false
            }
          ]}
        />
      </FormDialog>
  	);
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(EditPhone2);
