import React, { Fragment } from "react";

import Header from './../Table/Header';

import Table from './../Table';
import DeleteDialog from './../DeleteDialog';
import ActionForm from './../ActionForm';
import FilterForm from './../FilterForm';
import Snackbar from './../Snackbar';

const Component = props => {
  return (
    <Fragment>
    
      <Header 
        title={props.title}
        subTitle={props.subTitle}
        qty={props.records.length}
        filter={props.filter && props.filter[0]}
        redirect={props.redirect}
        extraParams={props.extraParams}
        noCreate={props.noCreate}
      />

      <Table 
        title={props.title}
        Rows={props.Rows}
        records={props.records}
        route={props.route}
        actions={props.actions}
        extraParams={props.extraParams}
        noEdit={props.noEdit}
        noDelete={props.noDelete}
      />

      <FilterForm 
        title={props.title}
        action={props.filter && props.filter[0]} 
        fields={props.filter && props.filter[1]}
      />

      { props.fields && 
        <ActionForm route={props.route} singular={props.singular} fields={props.fields}/> 
      }
      
      <DeleteDialog route={props.route} />
      <Snackbar />
    </Fragment>
  )
}

export default Component;