const branches = ({method, ...props}) => {
  switch (method) {
    case 'DELETE':
    case 'PUT':
    	if(props.balance) return `branches/balance`;
    	if (props.beginningBalance && !props.phone1) return `branches/${props._id}/beginningBalance/`
  		return (props.unjustifiedDiscount && !props.phone1) ? `branches/${props._id}/discounts/` : `branches/${props._id}`;
    default:
    	if(props.id){ return `branches/${props.id}`}
      return 'branches';
  }
}

export default branches;