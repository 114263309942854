import { deleteDialogConstants } from '../_constants.v2';

const defaultValues = [ false, null ];

export const deleteDialog = (state = defaultValues, action) => {
  switch (action.type) {
    case deleteDialogConstants.OPEN_FORM_DIALOG:
      return [ true, action.payload ];
    case deleteDialogConstants.CLOSE_FORM_DIALOG:
      return defaultValues;
    default:
      return state;
  }
};