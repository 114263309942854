import React, { useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import DateRangeIcon from '@material-ui/icons/DateRangeOutlined';

import IconButton from '../../../_presentationals.v3/FormComponents/IconButton';
import Button from '../../../_presentationals.v3/FormComponents/Button';
import TrackingForm from '../TrackingForm';
import S1Form from '../S1Form';
import S2Form from '../S2Form';
import S3Form from '../S3Form';

const useStyles = makeStyles(theme => ({
	container: {
    display: 'flex',
    flex: 1,
	},
  button: {
    marginLeft: theme.spacing(1)
  },
}));

const ActionButtonComponent = props => {
  const classes = useStyles();

  const [isTrackingFormOpen, setIsTrackingFormOpen] = useState(false);
  const [isS1FormOpen, setIsS1FormOpen] = useState(false);
  const [isS2FormOpen, setIsS2FormOpen] = useState(false);
  const [isS3FormOpen, setIsS3FormOpen] = useState(false);

  const handleClick = (e, params) => {
    e.preventDefault();
    switch(parseInt(params)) {
      case 2:
        setIsS1FormOpen(true);
        break;
      case 3:
        setIsS2FormOpen(true);
        break;
      case 4:
        setIsS3FormOpen(true);
        break;
      default: break;
    }
  };

  const handleS1FormClose = () => {
    setIsS1FormOpen(false);
  };
  const handleS2FormClose = () => {
    setIsS2FormOpen(false);
  };
  const handleS3FormClose = () => {
    setIsS3FormOpen(false);
  };

  const handleTrackingDateClick = () => {
    setIsTrackingFormOpen(true);
  };
  const handleTrackingDateClose = () => {
    setIsTrackingFormOpen(false);
  };
  return (
    <div className={classes.container}>
      <IconButton label='Seguimiento' icon={<DateRangeIcon />} size='small' onClick={handleTrackingDateClick} />
      <Button params={2} onClick={handleClick} label='PROS' size='small' color='secondary' variant={props.status === 2 ? 'contained' : 'outlined'} disabled={props.status > 1 || props.trackingDate === '-'} className={classes.button} />
      <Button params={3} onClick={handleClick} label='COT' size='small' color='secondary' variant={props.status === 3 ? 'contained' : 'outlined'} disabled={[1, 4].includes(props.status) || props.trackingDate === '-'} className={classes.button} />
      <Button params={4} onClick={handleClick} label='CON' size='small' color='secondary' variant={props.status === 4 ? 'contained' : 'outlined'} disabled={[1, 2, 4].includes(props.status) || props.trackingDate === '-'} className={classes.button} />
      <TrackingForm id={props.id} isOpen={isTrackingFormOpen} handleClose={handleTrackingDateClose} />
      <S1Form id={props.id} isOpen={isS1FormOpen} handleClose={handleS1FormClose} />
      <S2Form id={props.id} isOpen={isS2FormOpen} handleClose={handleS2FormClose} />
      <S3Form id={props.id} isOpen={isS3FormOpen} handleClose={handleS3FormClose} />
    </div>
  );
};

export default ActionButtonComponent;
