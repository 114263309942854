import React from "react";

import TextField  from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

const Component = ({ noFormat, error, icon, options, ...props }) => 
  !noFormat ?
    <Grid container spacing={0} alignItems="flex-end">
      <Grid item xs={1}>
        { icon || "" }
      </Grid>
      <Grid item xs={10}>
      	<TextField 
          select
          name={props.name}
          label={props.label}
          variant={ props.variant || "standard"}
          fullWidth={props.fullWidth || true}
          autoFocus={props.autoFocus || false}
          onChange={props.onChange}
          value={props.value}
          error={typeof error === 'string' ? true : false}
          helperText={error && error}
          autoComplete="off"
          size="small"
          { ...props }
        >
          { 
            options.map(el => 
              <MenuItem key={el.value} value={el.value}>
                {el.label}
              </MenuItem>
            ) 
          }
        </TextField>
      </Grid>
    </Grid>
  :
    <TextField 
      select
      name={props.name}
      label={props.label}
      variant={ props.variant || "standard"}
      fullWidth={props.fullWidth || true}
      autoFocus={props.autoFocus || false}
      onChange={props.onChange}
      value={props.value}
      error={typeof error === 'string' ? true : false}
      helperText={error && error}
      autoComplete='off'
      { ...props }
    >
      { 
        options.map(el => 
          <MenuItem key={el.value} value={el.value}>
            {el.label}
          </MenuItem>
        ) 
      }
    </TextField>

export default Component;