import React from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import { recordsActions, closeFormDialog } from '../../_actions';

import { DateField, SelectField } from '../../_presentationals/Form';
import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';

const Dialog = props => {

  const handdleFilter = e => {
    const { start, finish, type, status, customerId, maidId } = props;
    props.getRecords({ table: 'checkInsReport', start, finish, status, type, customerId, maidId });
    props.closeFormDialog('CheckInsReportFilter');
  };

	return (
	  <FormDialog  
      singular="CheckInsReportFilter" 
      title="Filtro"
      primaryButtonLabel="Continuar"
      handleCustomizedClick={handdleFilter}
    >
      <DateField
        id="start"
        label="Fecha Inicial"
        icon="today"
      />
      <DateField
        id="finish"
        label="Fecha Final"
      />
      <SelectField
        fields={[{
          id: 'type', 
          label: 'Tipo de Cliente',
          required: false,
          options: [
            { key: 0, value: 'Todos' },
            { key: 1, value: 'Casa' },
            { key: 2, value: 'Oficina' }
          ]
        }]}
      />
      <SelectField
        fields={[{
          id: 'status', 
          label: 'Estatus',
          required: false,
          options: [
            { key: 0, value: 'Todos' },
            { key: 1, value: 'Asistencia' },
            { key: 3, value: 'Falta Justificada' },
            { key: 2, value: 'Falta Injustificada' },
            { key: 4, value: 'Suplencia' }
          ]
        }]}
      />
      <Autocomplete 
        label="Cliente" 
        _id="customerId" 
        options={ props.customers }
      />
      <Autocomplete 
        label="Mucama" 
        _id="maidId" 
        options={ props.maids }
      />
	  </FormDialog>
	)
};

const mapStateToProps = state => ({ 
  start: state.field.value.start || new Date(),
  finish: state.field.value.finish || new Date(),
  customerId: state.field.value.customerId || 0,
  maidId: state.field.value.maidId || 0,
  type: state.field.value.type || 0,
  status: state.field.value.status || 0
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export const FilterDialog = connect(mapStateToProps, mapDispatchToProps)(Dialog);