import React from 'react';

import Container from '../../_presentationals.v3/Container';

import FormComponent from './Form';
import TableComponent from './Table';
import FilterComponent from './Filter';
import ReportByDateComponent from './ReportByDate';
import ReportByDateButton from './reportByDate.button';

const CrmComponent = () => {
  return (
    <>
      <Container title='CRM' moreButtons={[<ReportByDateButton key={1} />]}>
        <TableComponent />
      </Container>
      <FormComponent />
      <FilterComponent />
      <ReportByDateComponent />
    </>
  );
}

export default CrmComponent;
