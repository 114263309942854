import React from "react";

import LinkMUI from '@material-ui/core/Link';

const Link = ({ onClick, variant, label, ...props }) => {
	const handleClick = e => {
		e.preventDefault()
		onClick(props);
	}
	return (
		<LinkMUI component="button" variant={ variant || "body1"} onClick={handleClick}>
			{label}
		</LinkMUI>
	);
}


export {
	Link
}