import React from "react";
import { connect } from 'react-redux';

import { FormDialog } from './../../_presentationals/FormDialog';
import { DateField } from './../../_presentationals/Form';
import { recordsActions, closeFormDialog } from '../../_actions';

const FilterDialog = ({ getRecords, closeFormDialog, ...props }) => {

  const handleGet = () => {
    getRecords({ table: 'checkIns', balance: true, start: props.start, finish: props.finish });
    closeFormDialog('filterDialog');
  }

  return (
    <FormDialog
      title="Filtro por Fechas"
      singular="filterDialog"
      keepDataAfterSuccess={true}
      clearDataAfterClose={false}
      handleCustomizedClick={handleGet}
      primaryButtonLabel="Buscar"
    >
      <DateField
        id="start"
        label="Fecha Inicial"
        icon="today"
      />
      <DateField
        id="finish"
        label="Fecha Final"
      />
    </FormDialog>
  )
}



const mapStateToProps = state => ({
  start: state.field.value.start || null,
  finish: state.field.value.finish || null
});

const mapDispatchToProps = dispatch => ({
  closeFormDialog: data => dispatch(closeFormDialog(data)),
  getRecords: value => dispatch(recordsActions.getAll(value))
});


export default connect(mapStateToProps, mapDispatchToProps)(FilterDialog);