const api = ['name', 'ivaTax', 'addressLine1', 'addressLine2', 'city', 'stateId','countryId', 'zipCode', 'reference', 'description', 'price1', 'price2', 'price3', 'price4'];

const branches = (method, values) => {
  const tmp = {};
  switch (method) {
    case 'POST':
      api.push('phone1', 'phone2');
      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      api.forEach(key =>{
        if(!Object.keys(tmp).includes(key)) tmp[key] = "";
      });
      return tmp;
    case 'PUT':
      if(values.balance) return { fixedExpense: values.fixedExpense };
      if(values.beginningBalance && !values.phone1){
        return { beginningBalance: values.beginningBalance };
      }

      if(values.unjustifiedDiscount && !values.phone1){
        return { unjustifiedDiscount: values.unjustifiedDiscount };
      }
      api.push('Phone');
      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      tmp['Phone'] = [];
      ['phone1', 'phone2'].forEach((key) => {
        typeof values[key] !== 'undefined' && tmp['Phone'].push({ phoneId: values[key+'Id'], phone: values[key] })
      });
      return tmp;
    default:
      return tmp;
  }
}

export default branches;