const expenses = ({method, ...props}) => {
  switch (method) {
    case 'POST':
    case 'GET':
    	return 'expenses';
    case 'DELETE':
    case 'PUT':
    	return `expenses/${props._id}`
    default:
      return 'expenses';
  }
}

export default expenses;
