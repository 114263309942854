import React from "react";

import { styled, makeStyles } from '@material-ui/core/styles';
import TableMUI from '@material-ui/core/Table';
import TableContainerMUI from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

const defaultHeader = { fontSize: "15px", color: "#141414", minWidth: 150 };

const HeaderTableCell = styled(TableCell)(defaultHeader);
const HeaderCell = props => <HeaderTableCell padding={ props.padding || 'normal' } align={props.align || 'left'}>{props.children}</HeaderTableCell>

const CustomerTableCell = styled(TableCell)({ ...defaultHeader, minWidth: 270 });
const CustomerCell = props => <CustomerTableCell align={props.align || 'left'}>{props.children}</CustomerTableCell>

const useStyles = makeStyles(theme => {
	return {
	  tooltip: { marginTop: theme.spacing(0.5) },
	  actionButton: { padding: theme.spacing(1) }
	}
});

const Button = ({ icon, title, onClick, isDisabled, ...props }) => {
	const classes = useStyles();
	const handleClick = e => {
		e.preventDefault();
		onClick(props);
	}
	return (
		<Tooltip title={ title } classes={{ tooltip: classes.tooltip }}>
      <IconButton disabled={isDisabled || false} onClick={ handleClick } className={ classes.actionButton } aria-label={title}>
        { icon }
      </IconButton> 
    </Tooltip>
  );
}

const TableContainer = styled(TableContainerMUI)({ 
	maxHeight: document.documentElement.clientHeight - ((56 * 1) + 62)
});
const Table = props => 
<TableContainer>
	<TableMUI size='small' stickyHeader>
		{props.children}
	</TableMUI>
</TableContainer>

export { HeaderCell, CustomerCell, Button, Table };