import React, { Fragment } from "react";
import { connect } from 'react-redux';

import { openFormDialog } from '../../_actions';

import FilterDialog from './filterDialog';
import SettingsDialog from './settingsDialog';
import { Header } from './_header';
import DataTable from './_table';

const Balance = ({ openFormDialog, ...props}) => {

  const handleFilter = () => {
    openFormDialog('filterDialog');
  }

  const handleSettings = () => {
    openFormDialog('settignsDialog');
  }

  return (
    <Fragment>
      <Header handleFilter={handleFilter} handleSettings={handleSettings} fixedExpense={props.checkIns.fixedExpense} />
      <DataTable checkIns={props.checkIns} />
      <FilterDialog />
      <SettingsDialog />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
    checkIns: state.records.checkIns || defaultTableValue,
    start: state.field.value.start || null,
    finish: state.field.value.finish || null
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: data => dispatch(openFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Balance);