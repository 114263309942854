import makeStyles from '@material-ui/core/styles/makeStyles';
const screenWidth = window.innerWidth;
const useStyles = makeStyles(theme => ({
  tableContainer: {
    width: screenWidth,
    overflowX: 'auto',
    display: 'flex',
    flex: 1,
  },
}));

export default useStyles;
