import React from "react";

import { FormDialog } from './../../_presentationals/FormDialog';
import { TextField, SelectField, DateField } from './../../_presentationals/Form';
import { connect } from "react-redux";

const statusOptions = [
  { value: 'Estrategia', key: true },
  { value: 'Liquidación', key: false }
];
const movementTypeOptions = [
  { value: 'Alta', key: '1' },
  { value: 'Baja', key: '2' },
  { value: 'Alta fija', key: '3' },
];

const HealthInsuranceDialog = (props) => {
  return <FormDialog
    table="maids"
    title="Configurar cuota de seguro e infonavit"
    singular="healthInsurance"
  >
    <TextField
      fields={[{
        id: "healthInsurance", 
        label: "Cuota (Seguro)",
        required: false
      }]}
    />
    <TextField
      fields={[{
        id: "additionalBenefits", 
        label: "Prestaciones con altas",
        required: false
      }]}
    />
    <TextField
      fields={[{
        id: "taxBenefits", 
        label: "Prestaciones con bajas",
        required: false
      }]}
    />
    <TextField
      fields={[{
        id: "infonavit", 
        label: "Cuota (Infonavit)",
        required: false
      }]}
    />
    <SelectField
      fields={[{
        id: "movementType", 
        label: "Tipo de movimiento",
        options: movementTypeOptions,
      }]}
    />
    {props.fieldValue.movementType === "2" && <SelectField
      fields={[{
        id: "status", 
        label: "Tipo de baja",
        options: statusOptions,
      }]}
    />}
    <DateField
      id="startBenefit"
      label="Inicio de Prestaciones"
    />

    <DateField 
      id= "finishBenefit"
      label= "Fin de Prestaciones"
    />
    
  </FormDialog>
}

const mapStateToProps = state => ({
  fieldValue: state.field.value,
});

export default connect(mapStateToProps)(HealthInsuranceDialog);