import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    // backgroundColor: '#00ff00',
    display: 'flex',
    padding: theme.spacing(1, 2, 1, 2),
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #d5d5d5',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  subTitle: {
    fontSize: 12,
  }
}));

export default useStyles;
