const api = ['date', 'customerId', 'status'];

const checkIns = (method, values) => {
  const tmp = {};
  switch (method) {
    case 'POST':
      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      api.forEach(key =>{
        if(!Object.keys(tmp).includes(key)) tmp[key] = "";
      });
      return tmp;
    case 'PUT':
      if(values.updateReceivable && values.customerId){
        tmp['CheckIns'] = values.checkIns;
        tmp['action'] = values.action ? values.action : 0;
        tmp['amount'] = values.amount ? values.amount : 0;
        tmp['password'] = values.password ? values.password : 0;
        return tmp;
      }
      if(values.isBonus){
        tmp['bonus'] = values.bonus;
        tmp['password'] = values.password ? values.password : '';
        return tmp;
      } 
      if(values.isLoan){
        tmp['loan'] = values.loan ? values.loan : 0;
        tmp['loanDiscount'] = values.loanDiscount ? values.loanDiscount : 0;
        return tmp;
      }  
      return {};
    default:
      return tmp;
  }
}

export default checkIns;
