import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';

import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import WorkIcon from '@material-ui/icons/WorkOutlined';
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import RoomIcon from '@material-ui/icons/RoomOutlined';
import LayersIcon from '@material-ui/icons/LayersOutlined';
import ThumbUpIcon from '@material-ui/icons/ThumbUpOutlined';
import GavelIcon from '@material-ui/icons/GavelOutlined';
import CreditCardIcon from '@material-ui/icons/CreditCardOutlined';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooksOutlined';
import MessageIcon from '@material-ui/icons/MessageOutlined';
import LocalHospitalIcon from '@material-ui/icons/LocalHospitalOutlined';

import { recordsServices } from './../../_services.v2';
import { getDataFromServer } from './../../_actions.v2';

import Module from './../../_presentationals.v2/Module';

import DescriptionForm from './description.form';
import HealthInsuranceDialog from '../Assignment/healthInsuranceDialog';
import { editValueAction, openFormDialog } from '../../_actions';

const Rows = [
	{ label: 'Nombre', key: 'name', sort: 'text', filter: true }, 
	{ label: 'Banco', key: 'creditCardBank'}, 
	{ label: 'NoTarjeta', key: 'creditCardId' }, 
	{ label: 'Propietario', key: 'creditCardOwner' }, 
	{ label: 'Teléfono', key: 'phone', sort: 'text' }, 
  { label: 'Plan', key: 'paymentPlan', sort: 'text' }, 
	{ label: 'Preferencia', key: 'preference', align: 'center', sort: 'text' },
	{ label: 'Colonia', key: 'addressLine2', sort: 'text' },
	{ label: 'Prospección', key: 'prospecting', sort: 'text' },
	{ label: 'Entrevista', key: 'interview', sort: 'text' },
	{ label: 'Espera', key: 'waiting', sort: 'text' },
	{ label: 'Trabajando', key: 'working', sort: 'text' },
	{ label: 'Comentario', key: 'commentary', wrap: true }
];  

const civilStatusOptions = [
  { value: 1, label: "Casada/o"}, 
  { value: 2, label: "Soltera/o"}, 
  { value: 3, label: "Otro"}
];

const preferenceOptions = [
  { value: 1, label: "Casa"}, 
  { value: 2, label: "Oficina"}, 
  { value: 3, label: "Casa y Oficina"},
  { value: 4, label: "Sin Experiencia"}
];

const experienceOptions = [
  { value: 1, label: "1 año"}, 
  { value: 2, label: "2 años"}, 
  { value: 3, label: "3 ó más años"}
];

const scheduleOptions = [
  { value: 1, label: "Matutino"}, 
  { value: 2, label: "Vespertino"}, 
  { value: 3, label: "Ambos"}
];

const filingOptions = [
  { value: 1, label: "Aceptable"}, 
  { value: 2, label: "No aceptable"}
];

const yesNoOptions = [
  { value: true, label: "Si"}, 
  { value: false, label: "No"}
];

const bankOptions = [
  { value: 1, label: "Coppel"}, 
  { value: 2, label: "Saldazo"},
  { value: 3, label: "Banamex"},
  { value: 4, label: "Banorte"},
  { value: 5, label: "Bancomer"},
  { value: 6, label: "HSBC"},
  { value: 7, label: "Otro"}
];

const companyOptions = [
  { value: 1, label: 'EDER FERNANDO JIMENEZ GARCÍA'},
  { value: 2, label: 'JOSE CRUZ ROSALES FELIX'},
  { value: 3, label: 'CYNTHIA NALLELY ROSALES VEGA'},
  { value: 4, label: 'FERNANDO...'},
  { value: 6, label: 'MARIA DEL ROSARIO VEGA ESEBERRE'},
  { value: 7, label: 'FERNANDO JIMENEZ RAMIREZ'},
];

const paymentPlanOptions = [
  { value: 1, label: "Semanal"}, 
  { value: 3, label: "Decenal"}, 
  { value: 2, label: "Quincenal"},
  { value: 4, label: "Mensual"},
  { value: 5, label: "Cartorcenal"},
];

const defaultCommentaryValue = [ false, { id: null, description: '' } ];

const Component = ({ getData, ...props }) => {

	const [ values, setValues ] = useState([]);
  const [ openDescriptionForm, setOpenDescriptionForm ] = useState(defaultCommentaryValue);

	useEffect(() => {
		getData && recordsServices.get('v2/maids')
			.then(data => {
				setValues(data);
        props.getDataFromServer(false);
			})
			.catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleContract = (e, { id }) => {
    recordsServices.get(`maids/${id}`)
      .then(data => {
        const nombre = data.name;
        const edad = data.years;
        const domicilio = `${data.addressLine1}, ${data.addressLine2}, CP ${data.zipCode}, ${data.city}, SINALOA, MÉXICO`;
        const sueldo = parseFloat(data.salary).toFixed(2);
        const companyIdentifier = getCompanyIdentifier(parseInt(data.chooseCompany));
        const companyName = getCompanyName(parseInt(data.chooseCompany));

        const url = 'https://app.fotoymax.com/api/api.php?accion=contrato_mucaama_trabajo&tipo_accion=modelo&auth=false&';
        window.open(`${url}nombre_emisor=${companyName}&rfc_emisor=${companyIdentifier}&nombre=${nombre}&edad=${edad}&domicilio=${domicilio}&sueldo=${sueldo}`, '_blank');
      })
      .catch(e => console.log(e.message));
  };

  const handleCommentary = (e, values) => { 
    recordsServices.get(`maids/${values.id}/commentary`)
      .then(({ commentary }) => {
        setOpenDescriptionForm([ true, { id: values.id, description: commentary } ]); 
      })
      .catch(e => console.log(e.message));
  };
  const closeCommentary = () => { setOpenDescriptionForm(defaultCommentaryValue); };

  const handleAction = (e, {id}) => {
    
    recordsServices.get(`maids/${id}`)
      .then(data => {
        props.editValueAction({
          id,
          healthInsurance: data.healthInsurance,
          infonavit: data.infonavit,
          taxBenefits: data.taxBenefits,
          additionalBenefits: data.additionalBenefits,
          startBenefit: data.startBenefit,
          finishBenefit: data.finishBenefit,
          status: data.status,
          updateBenefits: true
        });
        props.openFormDialog('healthInsurance');
      })
      .catch(e => console.log(e.message));
  }


	return (
    <Fragment>
		<Module 
			route='maids'
      title='Mucamas'
      singular='Mucama'
      Rows={Rows}
      records={values}
      actions={[{
        label: 'Comentario',
        icon: <MessageIcon fontSize='small' />,
        onClick: handleCommentary
      },{
        label: 'Contrato',
        icon: <LibraryBooksIcon fontSize="small" />,
        onClick: handleContract
      },{
        icon: <LocalHospitalIcon fontSize="small" />,
        label: "Seguro e Infonavit",
        onClick: handleAction
      }]}
      fields={[
      	{
      		icon: <AccountCircleIcon />,
      		name: 'name',
      		label: 'Nombre'
      	},
        {
      		name: 'years',
      		label: 'Edad',
      		type: 'number'
      	},{
      		name: 'civilStatus',
      		label: 'Estado Civil',
      		options: civilStatusOptions,
      		field: 'select',
          show: 'id'
      	},{
      		name: 'preference',
      		label: 'Experiencia en...',
      		options: preferenceOptions,
      		field: 'select',
      		icon: <WorkIcon />
      	},{
      		name: 'experience',
      		label: 'Años de Experiencia',
      		options: experienceOptions,
      		field: 'select',
          show: 'id'
      	},{
      		name: 'schedule',
      		label: 'Horario Deseado',
      		options: scheduleOptions,
      		field: 'select'
      	},{
          name: 'Days',
          label: 'Días deseados',
          field: 'checkBox',
          controls: [{
            name: 'monday',
            label: 'Lunes'
          },{
            name: 'tuesday',
            label: 'Martes'
          },{
            name: 'wednesday',
            label: 'Miércoles'
          },{
            name: 'thursday',
            label: 'Jueves'
          },{
            name: 'friday',
            label: 'Viernes'
          },{
            name: 'saturday',
            label: 'Sábado'
          },{
            name: 'sunday',
            label: 'Domingo'
          }]
        },{
      		name: 'estimatedSalary',
      		label: 'Sueldo semanal deseado',
      		type: 'number',
          show: 'id'
      	},{
      		name: 'filing',
      		label: 'Presentación',
      		options: filingOptions,
      		field: 'select',
          show: 'id'
      	},{
      		name: 'phone',
      		label: 'Teléfono',
      		type: 'tel',
      		icon: <PhoneIcon />
      	},{
      		name: 'addressLine1',
      		label: 'Calle y Número',
      		icon: <RoomIcon />,
          show: 'id'
      	},{
      		name: 'addressLine2',
      		label: 'Colonia',
      	},{
      		name: 'zipCode',
      		label: 'Código Postal',
          show: 'id'
      	},{
      		name: 'city',
      		label: 'Ciudad',
          show: 'id'
      	},{
      		name: 'ine',
      		label: 'Identificación Oficial',
      		options: yesNoOptions,
      		field: 'select',
      		icon: <LayersIcon />,
          show: 'id'
      	},{
      		name: 'proofOfAddress',
      		label: 'Comprobante de Domicilio',
      		options: yesNoOptions,
      		field: 'select',
          show: 'id'
      	},{
      		name: 'birthCertificate',
      		label: 'Carta de No Antecedentes Penales',
      		options: yesNoOptions,
      		field: 'select',
          show: 'id'
      	},{
      		name: 'referenceName',
      		label: 'Nombre de Referencia',
      		icon: <ThumbUpIcon />,
          show: 'id'
      	},{
      		name: 'referencePhone',
      		label: 'Teléfono de Referencia',
      		type: 'tel',
          show: 'id'
      	},{
      		name: 'isAllowed',
      		label: 'Es apta para laborar',
      		field: 'select',
      		options: yesNoOptions,
      		icon: <GavelIcon />,
          show: 'id'
      	},{
      		name: 'creditCardBank',
      		label: 'Banco',
      		field: 'select',
      		options: bankOptions,
      		icon: <CreditCardIcon />,
          show: 'id'
      	},{
      		name: 'creditCardId',
      		label: 'No. de Tarjeta',
          show: 'id'
      	},{
      		name: 'creditCardOwner',
      		label: 'Nombre de Propietario',
          show: 'id'
      	},{
          name: 'salary',
          label: 'Sueldo semanal (CONTRATO)',
          type: 'number',
          show: 'id'
        },{
      		name: 'chooseCompany',
      		label: 'Emisor de Contrato',
      		field: 'select',
      		options: companyOptions,
          show: 'id'
      	},{
          name: 'paymentPlan',
          label: 'Plan de Pago',
          field: 'select',
          options: paymentPlanOptions,
          show: 'id'
        }
      ]}
    />
    <DescriptionForm 
      isOpen={openDescriptionForm[0]} 
      values={openDescriptionForm[1]} 
      handleClose={closeCommentary}
    />
    <HealthInsuranceDialog />
    </Fragment>
	);
};

function getCompanyIdentifier(value){
  switch(value){
    case 1:
      return "JIGE830606G90";
    case 2:
      return "ROFC560503919";
    case 3:
      return "ROVC870905FH2";
    case 6:
      return "VEER5705161U0";
    case 7:
      return "JIRF540914N61";
    default:
      return "Indefinido";
  }
}

function getCompanyName(value){
  switch(value){
    case 1:
      return "EDER FERNANDO JIMENEZ GARCÍA";
    case 2:
      return "JOSE CRUZ ROSALES FELIX";
    case 3:
      return "CYNTHIA NALLELY ROSALES VEGA";
    case 6:
      return "MARIA DEL ROSARIO VEGA ESEBERRE";
    case 7:
      return "FERNANDO JIMENEZ RAMIREZ";
    default:
      return "Indefinido";
  }
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: data => dispatch(openFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data)),
  getDataFromServer: value => dispatch(getDataFromServer(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component); 