import React, { Fragment } from "react";
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { editValueAction, validateFieldAction } from '../../_actions';

import InputText from '@material-ui/core/TextField';

import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';

import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import ErrorIcon from '@material-ui/icons/ErrorOutline';

const TextFieldUI = ({editValueAction, fields, fieldValue, fieldType, validateFieldAction, fieldValidation, icon, plus, dialog, ...props}) => {
  
  const handleChange = e => {
    // const { name, value, required } = e.target;
    const { name, value } = e.target;
    editValueAction({ [name]: value });
    // const validation = Validator({type: fieldType[name] || "text", value, required});
    // validateFieldAction({ [name]: { ...validation } })
  }

  return(
    <Fragment>
      <Grid container alignItems={ dialog ? "flex-end" : "stretch"}>
      { dialog && 
        <Grid item xs={1}><Icon className="material-icons-outlined">{icon}</Icon></Grid> 
      }
        <Grid item xs={dialog ? 9 : 12}>
          <Grid container spacing={2}>
            {fields.map(el => {
              const { id, label, type, fullWidth, grid, margin, ...props } = el;
              const { required } = el;
              return (
                <Grid key={id} item sm={grid || ( dialog ? (12 / parseInt(fields.length)) : 12)} xs={12}>
                  <InputText
                    id={id}
                    name={id}
                    label={label}
                    value={fieldValue[id] || ""}
                    required={required || true}
                    onChange={handleChange}
                    fullWidth={fullWidth || true}
                    margin={ margin || "none"}
                    type={type}
                    autoComplete="off"
                    error={fieldValidation[id] && fieldValidation[id].isError}
                    FormHelperTextProps={{ component: "div" }}
                    helperText={
                      (fieldValidation[id] && fieldValidation[id].isError) && (
                        <Grid container item spacing={0} alignItems="center" >
                          <ErrorIcon fontSize="small" /> {fieldValidation[id].message} 
                        </Grid> 
                      )
                    }
                    { ...props }
                  />
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      { dialog && (
        <Grid item xs={2}>
          { plus &&
            <AddCircleIcon fontSize="small" color="primary" />
          }
        </Grid>
      )}
      </Grid>
    </Fragment>
  )

}

TextFieldUI.propTypes = {
  fields: PropTypes.array.isRequired,
  dialog: PropTypes.bool.isRequired,
};

TextFieldUI.defaultProps = {
  fields: [],
  dialog: true
};

const mapStateToProps = state => ({ 
  fieldValue: state.field.value,
  fieldType: state.field.type,
  fieldValidation: state.field.validation,
});

const mapDispatchToProps = dispatch => ({ 
  editValueAction: data => dispatch(editValueAction(data)),
  validateFieldAction: data => dispatch(validateFieldAction(data)),
});

export const TextField = connect(mapStateToProps, mapDispatchToProps)(TextFieldUI);