import React, { useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';

import Dialog from './dialog';
import { Button } from './helpers';
import { useStyles } from './style';
import { recordsServices } from '../../_services';

const defaultValues = {
	name: "",
  contactName: "",
  phone: "",
  email: "",
  addressLine1: "",
  addressLine2: "",
  zipCode: "",
  city: "",
  state: ""
};

export default function CustomerDialog(props) {

  const classes = useStyles();

	const [ values, setValues ] = useState(defaultValues);

  useEffect(() => {
    if(parseInt(props.customerId) > 0){
      recordsServices.getAll({ table: 'filipinas', _id: props.customerId }).then(data => {
        const { name, contactName, phone, addressLine1, addressLine2, zipCode, city, state, email } = data;
        setValues({ name, contactName, phone, addressLine1, addressLine2, zipCode, city, state, email });
      });
    }
  }, [ props.customerId ]);

	const handleChange = e => {
  	const { name, value } = e.target;
  	setValues(values => ({ ...values, [name]: value }));
  }

  const handleAccept = async () => {
  	try{
      const method = props.customerId ? "update" : "create";
  		await recordsServices[method]({ table: 'filipinas', id: props.customerId, ...values });
  		await handleClose();
  		await setValues(defaultValues);	
  	}catch (e){
  		console.log(e.message);
  	}
  }

  const handleDetele = async () => {
    try{
      await recordsServices._delete({ table: 'filipinas', _id: props.customerId });
      await handleClose();
      await setValues(defaultValues);
    }catch (e){
      console.log(e.message);
    }
  }

  const handleClose = () => {
  	props.handleClose('customer', { resetForm: true });
  }

	return (
		<Dialog
			isOpen={ props.isOpen }
			handleClose={ handleClose }
			handleAccept={ handleAccept }
			title="Clientes"
      acceptLabel={ props.customerId ? "Actualizar" : "Almacenar" }
      contentText="Este es el primer paso para dar seguimiento a un cliente, 
                   el nombre y el teléfono son obligatorios, los datos pueden 
                   ser modificados en cualquier momento."
		>
			<TextField name="name" label="Nombre" onChange={handleChange} value={values.name} fullWidth />
      <TextField name="contactName" label="Contacto" onChange={handleChange} value={values.contactName} fullWidth />
      <TextField name="phone" label="Teléfono" onChange={handleChange} value={values.phone} fullWidth />
      <TextField name="email" label="Correo Electrónico" onChange={handleChange} value={values.email} fullWidth />
      <TextField name="addressLine1" label="Calle y Número" onChange={handleChange} value={values.addressLine1} fullWidth />
      <TextField name="addressLine2" label="Colonia" onChange={handleChange} value={values.addressLine2} fullWidth />
      <TextField name="zipCode" label="CP" onChange={handleChange} value={values.zipCode} fullWidth />
      <TextField name="city" label="Ciudad" onChange={handleChange} value={values.city} fullWidth />
      <TextField name="state" label="Estado" onChange={handleChange} value={values.state} fullWidth />
      { props.customerId && 
        <Button handleClick={ handleDetele } className={classes.marginTop} label="Dar de Baja" color="secondary" /> 
      }
		</Dialog>
	);
}