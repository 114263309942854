import { authServices, recordsServices } from '../_services';
import { validateFieldAction, editValueAction } from './';

const login = () => (dispatch, getState) => {
  authServices.login(getState().field.value)
  .then(() => recordsServices.getAll({ table: 'countries' }).then(async data => { 
    await Promise.all(data.rows.map(async ({ id }, index) => {
      data.rows[index]['states'] = await recordsServices.getAll({ table: 'states', countryId: id });
    }));
    await localStorage.setItem('countries', JSON.stringify(data)); 
    await window.location.reload(true);
  })).catch(({ message }) => JSON.parse(message).forEach(({ name, message }) => 
    dispatch(validateFieldAction({ [name]: { isError: true, message } }))
  ));
}
// const login = () => (dispatch, getState) => {
//   authServices.login(getState().field.value)
//   .then(() => window.location.reload(true))
//   .catch((data) => console.log(data))
//   // .catch(({ message }) => JSON.parse(message).forEach(({ name, message }) => 
//   //   dispatch(validateFieldAction({ [name]: { isError: true, message } }))
//   // ));
// }

const passwordRecovery = ({method, ...props}) => (dispatch, getState) => {
  if(method === "phone"){
    const { tokenId } = props;
    dispatch(editValueAction({ tokenId }));
  }

  authServices.passwordRecovery({ method, ...props })
  .then(({ isValid }) => {
    if(!isValid){
      dispatch(validateFieldAction({ verificationCode: { isError: true, message: "El código no puede ser verificado en este momento." } }));
    }
    dispatch(validateFieldAction({ readyToRecover: true }));
  }).catch(error => console.log(error));
}


const logout = () => {
  authServices.logout();
  window.location.reload(true);
}

export const authActions = {
    login, logout, passwordRecovery
};