import React, { PureComponent } from "react";
import { connect } from 'react-redux';

import PollIcon from '@material-ui/icons/Poll';

import { FormDialog } from './../../_presentationals/FormDialog';
import { SelectField } from './../../_presentationals/Form';

class EditIva extends PureComponent {

  render() {
  	return (
      <FormDialog
        title="Editar tasa IVA"
        name="EditIva"
        singular="IVA"
      >
        <SelectField
          icon={<PollIcon />}
          fields={[{
            id: "ivaTax",
            label: "Tasa IVA",
            options: [
              {key: 8, value: '8%'},
              {key: 16, value: '16%'}
            ],
          }]}
        />
      </FormDialog>
  	);
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(EditIva);
