import { config } from '../_helpers';
import { handleFetchResponseNoAuth } from './';

const login = ({ username, password }) => {
	const myRequest = new Request(
		`${ config.apiUrl }/auth/login`,
		{
			method: 'POST',
			headers: { 'Content-Type' : 'application/json' },
			body: JSON.stringify({ username, password })
		}
	);

	return fetch(myRequest).then(handleFetchResponseNoAuth)
	.then(response => {
		if(response.errorCode){
			throw new Error(response.errorMessage);
		}
		const { isAuth, isVerified } = response;

		if(isAuth && !isVerified){
			localStorage.setItem('session', JSON.stringify(response));
			return response;
		}
		
		const sessionData = {
			isAuth, isVerified,
      userId: response.data.id,
      username: response.data.username,
      firstname: response.data.firstName,
      lastname: response.data.lastName,
      token: response.accessToken,
      countryId: response.data.countryId,
      branchName: response.data.branchName,
      branchId: response.data.branchId,
      refresh: response.refreshToken
    };

		localStorage.setItem('session', JSON.stringify(sessionData));
		return sessionData;

	});
};

const passwordRecovery = ({ method, ...props }) => {

	let body = { method };

	switch(method) {
		case "phone":
			const { tokenId } = props;
			body = { ...body, tokenId };
			break;
		case "email":
			body = {};
			break;
		default:
			body = { ...body };
			break;
	}

	const myRequest = new Request(
		`${ config.apiUrl }/auth/password/recovery`,
		{
			method: 'POST',
			headers: { 'Content-Type' : 'application/json' },
			body: JSON.stringify(body)
		}
	);
	return fetch(myRequest).then(handleFetchResponseNoAuth)
};

const logout = () => {
	localStorage.removeItem('session');
};


export const authServices = { login, logout, passwordRecovery };
