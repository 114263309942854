import React, { useState } from 'react';

import { connect } from 'react-redux';

import { startOfDay, endOfDay } from 'date-fns';

import CloseIcon from '@material-ui/icons/Close';

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

import { closeFilterDialog } from '../../_actions.v2';

import Button from '../FormComponents/Button';
import DateField from '../FormComponents/DateField';

import useStyles from './style';

const defaultValues = {
  startDate: startOfDay(new Date()),
  endDate: endOfDay(new Date()),
  date: endOfDay(new Date()),
  useDates: false,
};

const FilterDrawer = props => {

  const classes = useStyles();

  const [values, setValues] = useState(defaultValues);

  const handleClose = () => {
    props.handleClose();
  };

  const handleClick = e => {
    props.handleAction && props.handleAction(e, {
      ...values,
      startDate: (props.optionalDates && values.useDates)
        ? values.startDate
        : props.showDates
          ? values.startDate
          : null,
      endDate: (props.optionalDates && values.useDates)
        ? values.endDate
        : props.showDates
          ? values.endDate
          : null,
      date: (props.optionalDates && values.useDates)
        ? values.date
        : props.showOneDate
          ? values.date
          : null,
    });
    handleClose();
  };

  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleSwitchChange = e => {
    const { name } = e.target;
    setValues(values => ({ ...values, [name]: e.target.checked }));
  };

  return (
    <Drawer anchor='right' open={props.isOpen[0]} onClose={handleClose} classes={{ paper: classes.container }}>
      <div className={classes.title}>
        <Typography variant='h6'>
          Filtrar Información
        </Typography>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          className={classes.closeButton}
          size='medium'
        >
          <CloseIcon fontSize='inherit' />
        </IconButton>
      </div>
      <Divider />
      {
        (props.showDates || props.showOneDate || props.optionalDates) &&
          <div>
            <div className={classes.dateContent}>
              {
                props.optionalDates &&
                  <>
                      <Typography variant='subtitle1'>
                        Usar Fechas
                      </Typography>
                      <Switch
                        checked={values.useDates}
                        onChange={handleSwitchChange}
                        name='useDates'
                      />
                     </>
              }
              {
                (props.showDates || values.useDates) &&
                  <div>
                      <Typography variant='subtitle1'>
                        Rango de fechas:
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                          <DateField
                            variant='outlined'
                            name='startDate'
                            size='medium'
                            value={values.startDate}
                            onChange={handleDateChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <DateField
                            variant='outlined'
                            name='endDate'
                            size='medium'
                            value={values.endDate}
                            onChange={handleDateChange}
                          />
                        </Grid>
                      </Grid>
                     </div>

              }
              {
                (props.showOneDate || values.useDates) &&
                  <div>
                      <Typography variant='subtitle1'>
                        Fecha:
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                          <DateField
                            variant='outlined'
                            name='date'
                            size='medium'
                            value={values.date}
                            onChange={handleDateChange}
                          />
                        </Grid>
                      </Grid>
                     </div>

              }
            </div>
            <Divider />
             </div>
      }
      <div className={classes.content}>
        {props.children}
      </div>
      <Divider />
      <div className={classes.footer}>
        <Button
          label='Aplicar Filtro'
          size='large'
          color='secondary'
          variant='outlined'
          onClick={handleClick}
          className={classes.actionButton}
        />
      </div>
    </Drawer>
  );
};

const mapStateToProps = state => ({
  isOpen: state.filterDialog
});

const mapDispatchToProps = dispatch => ({
  handleClose: () => dispatch(closeFilterDialog()),
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterDrawer);
