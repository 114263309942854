import React from 'react';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import useStyles from './style';

const Component = props => {
	const classes = useStyles();
	return (
		<TableHead>
			<TableRow>
				{ props.Rows.map(el => (
					<TableCell className={classes.tableCell} key={el.key} align={el.align || 'left'}>
						<Typography variant='body2' noWrap={true}>
							<strong>{el.label}</strong>
						</Typography>
     </TableCell>
        ))}
        <TableCell className={classes.tableCell} />
			</TableRow>
		</TableHead>
	);
};

export default Component;
