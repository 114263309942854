import React from "react";
import { connect } from 'react-redux';

import { FormDialog } from './../../_presentationals/FormDialog';
import { TextField } from './../../_presentationals/Form';
import { recordsActions, editValueAction } from '../../_actions';

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import VpnKey from '@material-ui/icons/VpnKey';

const ConfigPayment = ({ editValueAction, update, fields, ...props }) => {

    const handlePayment = () => {
      const { customerId } = fields;
      editValueAction({ id: customerId, customerId, updateBalance: true });
      update('customers');
    }

  	return (
      <FormDialog
        title="Cobro"
        name="payment"
        singular="ConfigPayment"
        handleCustomizedClick={handlePayment}
      >
        <TextField
          icon={<Check />}
          fields={[{
            id: "payment",
            label: "Cobro",
            required: false 
          }]}
        />
        <TextField
          icon={<ArrowUpward />}
          fields={[{
            id: "increase",
            label: "Incremento de Saldo",
            required: false 
          }]}
        />
        <TextField
          icon={<ArrowDownward />}
          fields={[{
            id: "decrease",
            label: "Decremento de Saldo",
            required: false 
          }]}
        />
        <TextField
          icon={<VpnKey />}
          fields={[{
            id: "password",
            type: "password",
            label: "Contraseña Administrativa",
            required: false 
          }]}
        />
      </FormDialog>
  	);
}

const mapStateToProps = state => ({
  fields: state.field.value,
});

const mapDispatchToProps = dispatch => ({
  editValueAction: data => dispatch(editValueAction(data)),
  update: table => dispatch(recordsActions.update(table))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigPayment);
