import jsPDF from 'jspdf';
import format from 'date-fns/format';

import { authHeader, config } from '../../_helpers';
import { handleFetchResponse } from './../../_services';

const session = JSON.parse(localStorage.getItem('session'));
const BRANCH_NAME = session && session.data.branchName;

const currentDate = format(new Date(), 'dd/MM/yyyy HH:mm:ss');

const getDataFromServer = (start,finish,paymentPlan) => {
	const method = 'GET';
	const url = `${config.apiUrl}/v2/payrolls/${start}/${finish}?paymentPlan=${paymentPlan}`;
  const myRequest = new Request(
    url, { method, headers: { ...authHeader() } }
  );
  return fetch(myRequest)
  	.then(handleFetchResponse({ url, method, headers: {} }))
  	.then(data => data);
}


const PDF = (start,finish,paymentPlan) => { getDataFromServer(start,finish,paymentPlan).then(data => {
	var doc = new jsPDF('L');
	
	header(doc);

	let breakLine = 40;
	let tmpBreakLine;
	//DATOS TABLA
	data.forEach( el => {
		const creditCardBank = el.creditCardBank ? el.creditCardBank : '-';
		const creditCardId = el.creditCardId ? el.creditCardId : '-';
		const creditCardOwner = el.creditCardOwner ? el.creditCardOwner : '-';
		// const payroll = el.payment ? parseFloat(el.payment) + parseFloat(el.loan) - parseFloat(el.loanDiscount) : '0.00';

		tmpBreakLine = breakLine;

		// const employeeJustify = doc.splitTextToSize(`${el.Maid.name}`, 85);
		// const bankJustify = doc.splitTextToSize(`${creditCardBank}`, 40);
		// const headlineJustify = doc.splitTextToSize(`${creditCardOwner}`, 60);
		// const accountJustify = doc.splitTextToSize(`${creditCardId}`, 100);
		// const payrollJustify = doc.splitTextToSize(`${(parseFloat(payroll)).toFixed(2)}MXN`, 120);
		const total = parseFloat(el.total);

		if(parseFloat(total) === 0) {
			return;
		}

		const employeeJustify = doc.splitTextToSize(`${el.name}`, 85);
		const bankJustify = doc.splitTextToSize(`${creditCardBank}`, 40);
		const headlineJustify = doc.splitTextToSize(`${creditCardOwner}`, 60);
		const accountJustify = doc.splitTextToSize(`${creditCardId}`, 100);
		const payrollJustify = doc.splitTextToSize(`${parseFloat(total).toFixed(2)}MXN`, 120);

		doc.text(5, breakLine, employeeJustify, 'left');
		doc.text(85, breakLine, bankJustify, 'left');
		doc.text(130, breakLine, headlineJustify, 'left');
		doc.text(215, breakLine, accountJustify, 'left');
		doc.text(260, breakLine, payrollJustify, 'left');
		tmpBreakLine += 5;

		doc.line(5, tmpBreakLine, 290, tmpBreakLine);

		breakLine = tmpBreakLine + 7;

		if(breakLine >= 200){
			breakLine = 40;
			doc.addPage('L');
			header(doc);
		}
	});

	doc.save(`nomina_${currentDate}.pdf`);
})}

const header = (doc) => {
	//CABECERA
	doc.setFontSize(12);
	doc.setFontStyle("bold");
	doc.text("Reporte de Nómina", 5, 15, 'left');
	doc.text(`${BRANCH_NAME}`, 5, 20, 'left');
	doc.text("Fecha y Hora", 290, 15, 'right');
	doc.text(`${currentDate}`, 290, 20, 'right');

	//TABLA
	doc.line(5, 25, 290, 25);
	doc.text("Empleado", 5, 30, 'left');
	doc.text("Banco", 85, 30, 'left');
	doc.text("Titular", 130, 30, 'left');
	doc.text("Cuenta", 215, 30, 'left');
	doc.text("Nomina", 260, 30, 'left');
	doc.line(5, 33, 290, 33);

	doc.setFontSize(8);
	doc.setFontStyle("normal");
}

export default PDF;