import React, { PureComponent } from "react";
import { connect } from 'react-redux';

import LocalPhoneIcon from '@material-ui/icons/LocalPhone';

import { FormDialog } from './../../_presentationals/FormDialog';
import { TextField } from './../../_presentationals/Form';

class EditPhone1 extends PureComponent {

  render() {
  	return (
      <FormDialog
        title="Editar teléfono fijo"
        name="EditPhone1"
        singular="phone1"
      >
        <TextField
          icon={<LocalPhoneIcon />}
          fields={[
            {
              id: "phone1",
              label: "Teléfono Fijo",
              required: false
            }
          ]}
        />
      </FormDialog>
  	);
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(EditPhone1);
