import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';

import dateFormat from 'date-fns/format';


import InfiniteScroll from 'react-infinite-scroller';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';

import IconButton from './../../_presentationals.v2/Form/IconButton';

import { recordsServices } from './../../_services.v2';
import { getDataFromServer } from './../../_actions.v2';

import TableSortLabel from './../../_presentationals.v2/Table/helpers/TableSortLabel';
import moneyFormat from './../../_helpers.v2/moneyFormat';
import { newDate } from "../../_helpers.v2";

const defaultCell = { 
  display: 'table-cell', 
  background: 'transparent',
  borderBottom: '1px solid #e0e0e0'
};

const useStyles = makeStyles((theme) => ({
  actionButton: { padding: theme.spacing(0.4) },
  table: { 
    flexGrow: 1,
    overflow:'auto',
    height: document.documentElement.clientHeight - ((theme.mixins.toolbar.minHeight * 1) + 18)
  },
  tableHead: { display: 'table-header-group' },
  tableBody: { display: 'table-row-group' },
  tableRow: { 
    display: 'table-row',
    '&:hover': {
      background: '#ededed'
    }
  },
  tableCell: { 
    ...defaultCell, 
    padding: theme.spacing(.5, 2, .5, 2),
    verticalAlign: 'middle'
  },
  tableTitle: { 
    ...defaultCell,
    position: 'sticky', 
    top: 0,
    fontWeight: '500',
    padding: theme.spacing(1, 2, 1, 2),
    background: '#fff'
  },
  loader: { 
    padding: theme.spacing(1, 2, 1, 2), 
    background: '#fff' 
  }
}));

const Rows = [
  { label: 'Nombre', key: 'name', sort: 'text', filter: true },
  { label: 'Teléfono', key: 'phone', sort: 'text' }, 
  { label: 'Preferencia', key: 'preference', sort: 'text' },
  { label: 'Colonia', key: 'addressLine2', sort: 'text' },
  { label: 'Prospección', key: 'prospecting', sort: 'text' },
  { label: 'Entrevista', key: 'interview', sort: 'text' },
  { label: 'Espera', key: 'waiting', sort: 'text' },
  { label: 'Trabajando', key: 'working', sort: 'text' },
  { label: 'Descripción', key: 'description', wrap: true }
];

const Component = ({ getData, ...props }) => {

  const classes = useStyles();

  const [ hasMore, setHasMore ] = useState(true);
  const [ server, setServer ] = useState([]);
  const [ values, setValues ] = useState([]);

  useEffect(() => {
    getData && recordsServices.get('v2/maids')
      .then(data => {
        setServer(data);
        props.getDataFromServer(false);
      })
      .catch(e => console.log(e.message));
  }, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadFunc = page => {
    if(server.length === 0) return false;
    const newArray = server.map((el, i) => 
      parseInt(i) < (page * 100) ? el : false
    ).filter(el => el);
    setValues(newArray);
    server.length === values.length && setHasMore(false);
  };

  return (
    <div className={ classes.table }>
      <div className={ classes.tableHead }>
        <div className={ classes.tableRow }>
          { Rows.map(el => 
            <div key={el.key} align={el.align || 'left'} className={ classes.tableTitle }>
              {el.sort 
                ? <TableSortLabel 
                    id={el.key} 
                    label={el.label} 
                    onClick={() => {}}
                  />
                : el.label
              }
            </div>
          )}
          <div className={ classes.tableTitle } />
        </div>
      </div>
      {server.length > 0 && 
      <InfiniteScroll
        pageStart={0}
        className={ classes.tableBody }
        loadMore={loadFunc}
        hasMore={hasMore}
        loader={<div className={classes.loader} key={0}>Cargando...</div>}
        useWindow={false}
      >
        {values.map(data => 
          <div key={data.id} className={ classes.tableRow }>
            {Rows.map(el => 
              <div key={el.key} align={el.align} className={ classes.tableCell }>
                <Typography variant="body2" noWrap={!el.wrap}>
                  { format(data[el.key], el.format) }
                </Typography>
              </div>
            )}
            <div align='right' className={ classes.tableCell }>
              <Typography variant="body2" noWrap={true}>
                <IconButton 
                  label="Editar" 
                  action={ () => {} } 
                  params={ {} }
                  className={ classes.actionButton }
                  icon={<EditIcon fontSize="small" />}
                />        
                <IconButton 
                  label="Eliminar" 
                  action={ () => {} } 
                  params={ {  } }
                  className={ classes.actionButton }
                  icon={<DeleteIcon fontSize="small" />}
                />
              </Typography>
            </div>
          </div>
        )}
      </InfiniteScroll>
      }
    </div>
  );
}

const format = (value, type) => {
  switch(type){
    case 'date':
      return dateFormat(newDate(value), 'dd/MM/yyyy');
    case 'dateTime':
      return (value !== '-') ? dateFormat(newDate(value), 'dd/MM/yyyy HH:mm:ss') : value;
    case 'time':
      return dateFormat(newDate(value), 'HH:mm:ss');
    case 'currency':
      return moneyFormat(value);
    case 'balance':
      return (parseFloat(value) === 0) ? '-' : moneyFormat(value);
    default: return value;
  }
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: value => dispatch(getDataFromServer(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);