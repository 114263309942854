import React from "react";

import { FormDialog } from './../../_presentationals/FormDialog';
import { TextField } from './../../_presentationals/Form';

const BalanceDialog = () => 
  <FormDialog
    table="branches"
    title="Configuración de sueldo inicial"
    singular="balanceDialog"
  >
    <TextField
      fields={[{
        id: "beginningBalance", 
        label: "Cantidad de saldo inicial"
      }]}
    />
  </FormDialog>


export default BalanceDialog;