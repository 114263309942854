import React, { Fragment, useMemo } from "react";
import { connect } from 'react-redux';

import FolderSharedIcon from '@material-ui/icons/FolderSharedOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

import Button from '@material-ui/core/Button';

import { FormDialog } from '../../_presentationals/FormDialog';
import './style';
import { AutocompleteField, TextField, SelectField } from '../../_presentationals/Form';
import { recordsActions, closeFormDialog, editValueAction } from '../../_actions';

const serviceTime = ["01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24"].map(el => ({ key: `${el}:00:00`, value: `${el}:00` }));

const ConfigDialog = ({ closeFormDialog, day, fields, _delete, editValueAction, ...props }) => {
  
  // useEffect(() => {
  //   if(!fields.update) {
  //     const iva = parseFloat(fields.payment) * 0.16;
  //     !fields.typeRate && editValueAction({ iva });
  //   }
  // }, [fields.payment, fields.id, editValueAction, fields.typeRate, fields.update]);
  // }, [fields.payment, fields.id, editValueAction]);

  const customers = useMemo(() => {
    return (props.customers.count > 0 ? props.customers.rows.map(el => {
      const label = el.name;
      return {
        value: el.id, 
        label,
        payment: (el.typeRate && parseInt(el.typeRate) === 2) ? el.dailyDayRate : 0,
        supplies: (el.typeRate && parseInt(el.typeRate) === 2) ? el.dailySuppliesRate : 0,
        typeRate: el.typeRate
      }
    }) : [])
  }, [props.customers]);
  
  const handleDelete = e => {
    e.preventDefault();
    _delete({ _id: fields.maidId, table: 'assignments' });
    editValueAction({ day: fields.day, customerId: fields.customerId, reason: fields.reason, descriptionDelete: fields.descriptionDelete ? fields.descriptionDelete : '' });
  }

  return(
    <FormDialog 
      primaryButtonLabel={fields.update ? "Actualizar" : "Guardar"}
      singular="ConfigDialog" 
      table="assignments"
      title={`Configuración de la Jornada | ${day}`}
    >
      <TextField
        icon={<FolderSharedIcon />}
        fields={[{
          id: 'houseMaidLabel',
          label: 'Mucama',
          disabled: true
        }]}
      />
      <AutocompleteField 
        icon={"person_outline"}
        label="Cliente" 
        _id="customerId"
        isDisabled={ fields.update }
        options={customers}
      />
      <SelectField
        icon={<SettingsIcon />}
        fields={[{
          id: 'start',
          label: 'Inicio de Servicio',
          options: serviceTime
        },{
          id: 'finish',
          label: 'Fin de Servicio',
          options: serviceTime
        }]}
      />
      <TextField
        fields={[{
          id: 'salary',
          label: 'Sueldo a Mucama'
        }]}
      />
      <TextField
        fields={[{
          id: 'payment',
          label: 'Cobro a Cliente (Servicio)'
        }]}
      />
      <TextField
        fields={[{
          id: 'supplies',
          label: 'Cobro a Cliente (Insumos)'
        }]}
      />
      {/*<TextField
        fields={[{
          id: 'iva',
          label: 'Cobro a Cliente (IVA de Servicio)'
        }]}
      />*/}
      {
        fields.update &&
          <Fragment>
            <SelectField
              icon={<ArrowDownward/>}
              fields={[{
                id: 'reason',
                label: 'Razon de baja',
                options: [{
                  key: 1,
                  value: 'Cliente'
                },{
                  key: 2,
                  value: 'Mucama'
                },{
                  key: 3,
                  value: 'Suplencia'
                }]
              }]}
            />
            <TextField
              fields={[{
                id: 'descriptionDelete',
                label: 'Descripción de baja',
                required: false
              }]}
            />
            <Button variant="contained" color="secondary" onClick={handleDelete} style={{ marginTop: 10, marginLeft: 50 }}>
              Dar de Baja
            </Button>
          </Fragment>
      }
    </FormDialog>
  );
}

const mapStateToProps = state => ({
  fields: state.field.value,
});

const mapDispatchToProps = dispatch => ({
  closeFormDialog: data => dispatch(closeFormDialog(data)),
  _delete: data => dispatch(recordsActions._delete(data)),
  editValueAction: data => dispatch(editValueAction(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(ConfigDialog);
