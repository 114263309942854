import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabelMui from '@material-ui/core/FormControlLabel';
import CheckboxMui from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  marginVertical: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  form: {
    width: '100%',
  },
}));

const FormControlLabel = props => {
  const handleChange = e => {
    props.handleChange(e.target.name, e.target.checked, props.params);
  };
  return (
    <FormControlLabelMui
      control={
        <CheckboxMui
          checked={props.checked}
          onChange={handleChange}
          name={props.name}
          style={{ color: props.color }}
        />
      }
      label={props.label}
    />
  );
};

const CheckboxComponent = ({ error, label, ...props }) => {
  const classes = useStyles();
  const handleChange = (name, checked, params) => {
    props.onChange(props.name, name, checked, params);
  };
  return (
    <div className={classes.marginVertical}>
      {label && <Typography variant='subtitle1'>{label}</Typography>}
      <FormControl required component='fieldset' className={classes.form}>
        <FormGroup row={props.row || false}>
          {props.values &&
            props.values.map(el => (
              <FormControlLabel
                key={`${el.name}-${el.label}`}
                checked={el.checked}
                name={el.name}
                label={el.label}
                params={el.params}
                handleChange={handleChange}
                color={el.color}
              />
            ))}
        </FormGroup>
      </FormControl>
      {error && (
        <Typography variant='caption' color='error'>
          {error}
        </Typography>
      )}
    </div>
  );
};

export default CheckboxComponent;
