import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import Hidden from '@material-ui/core/Hidden';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import LanguageIcon from '@material-ui/icons/LanguageOutlined';
import FolderSharedIcon from '@material-ui/icons/FolderSharedOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';
import AlarmIcon from '@material-ui/icons/AlarmOutlined';
import TrendingUpIcon from '@material-ui/icons/TrendingUpOutlined';
import CreditCardIcon from '@material-ui/icons/CreditCardOutlined';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import BarChartIcon from '@material-ui/icons/BarChartOutlined';
import SyncProblemIcon from '@material-ui/icons/SyncProblemOutlined';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcardOutlined';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import ImportExportIcon from '@material-ui/icons/ImportExportOutlined';
import DeviceHubIcon from '@material-ui/icons/DeviceHubOutlined';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';

import StoreIcon from '@material-ui/icons/StoreOutlined';
import FingerprintIcon from '@material-ui/icons/FingerprintOutlined';

import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { styles } from './styles';

import { getDataFromServer } from './../../_actions.v2';
import { history } from './../../_helpers/history';

const { id: userId } = localStorage.getItem('session') 
  ? JSON.parse(localStorage.getItem('session')).data
  : { id: null };

const reservedPrivileges = [9];

class MenuItems extends PureComponent{

  state = {
    open: false,
    openReports: false,
    fullName: ""
  }

  handleOpenReports = e => {
    e.preventDefault();
    this.setState(state => ({ ...state, openReports: !state.openReports }));
  }

  componentDidMount = () => {
    const session = JSON.parse(localStorage.getItem('session'));
    const { firstName, lastName } = session.data || {};

    this.setState({ fullName: `${firstName} ${lastName}`});
  }

  handleClickV2 = (e, url) => { 
    history.push(url);
    this.props.getDataFromServer(true); 
  }

  handleClick = e => {
    e.preventDefault();
    this.setState(state => ({ ...state, open: !state.open }));
  }

  render(){

    const { classes, toggleDrawer, profile, handleLogout } = this.props;
    const { open, fullName } = this.state;
    return (
      <Fragment>
        {/* <Hidden xsDown implementation="css">
          <div className={ classes.toolbar } />
        </Hidden> */}
        <div>
          <List className={ classes.listItems } component="nav">
            <Hidden smUp implementation="css">
              <Grid
                container
                spacing={0}
                className={ classes.accountMenu }
              >
                <Grid
                  item
                  xs={6}
                >
                  <Avatar
                    className={ classes.avatarIcon }
                    alt="Avatar Temporal"
                    src={ profile }
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ paddingTop: 60 }}
                >
                  <IconButton style={{ color: '#fff' }}>
                    <Badge badgeContent={4} color="secondary">
                      <MailIcon />
                    </Badge>
                  </IconButton>
                  <IconButton style={{ color: '#fff' }}>
                    <Badge badgeContent={17} color="secondary">
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                </Grid>
              </Grid>
              <ListItem
                button
                onClick={ this.handleClick }
                className={ classes.accountButton }
              >
                <ListItemText
                  classes={{
                    primary: classes.userOptions
                  }}

                  primary={ fullName }
                />
                { open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={ open } timeout="auto" unmountOnExit>
                <List component="nav" disablePadding>
                  <ListItem button component={Link} to="/MyAccount">
                    <ListItemIcon>
                      <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Mi cuenta" />
                  </ListItem>
                  <ListItem button component={Link} to="/Preferences">
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Preferencias" />
                  </ListItem>
                  <Divider />
                  <ListItem button onClick={handleLogout}>
                    <ListItemIcon>
                      <PowerSettingsNewIcon />
                    </ListItemIcon>
                    <ListItemText primary="Cerrar Sesión" />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />
            </Hidden>
            { !open && (
              <div
                tabIndex={0}
                role="button"
                onClick={toggleDrawer('left', false)}
              >
                <List component="nav" disablePadding>
                  {
                    !reservedPrivileges.includes(userId) &&
                      <Divider />
                  }
                  {
                    !reservedPrivileges.includes(userId) &&
                      <LinkButtonV2
                        handleClick={this.handleClickV2} 
                        icon={<DeviceHubIcon />} 
                        url='/crm' 
                        label='CRM' 
                      />
                  }
                  {
                    !reservedPrivileges.includes(userId) &&
                      <Divider />
                  }
                  {
                    !reservedPrivileges.includes(userId) &&
                      <LinkButtonV2
                        handleClick={this.handleClickV2} 
                        icon={<FolderSharedIcon />} 
                        url='/v2/houseMaid' 
                        label='Mucamas' 
                      />
                  }
                  {/* {
                    !reservedPrivileges.includes(userId) &&<>
                      <ListItem button component={Link} to="/CheckIn">
                        <ListItemIcon>
                          <AlarmIcon />
                        </ListItemIcon>
                        <ListItemText primary="Asistencia semanal" />
                      </ListItem>
                      <ListItem button component={Link} to="/CheckIn/15">
                      <ListItemIcon>
                        <AlarmIcon />
                      </ListItemIcon>
                      <ListItemText primary="Asistencia quincenal" />
                    </ListItem>
                    </>
                  } */}
                    {
                    !reservedPrivileges.includes(userId) &&
                      <ListItem button component={Link} to="/CheckIn/14">
                        <ListItemIcon>
                          <AlarmIcon />
                        </ListItemIcon>
                        <ListItemText primary="Asistencia" />
                      </ListItem>
                  }
                  {
                    !reservedPrivileges.includes(userId) &&
                      <LinkButtonV2
                        handleClick={this.handleClickV2} 
                        icon={<CardGiftcardIcon />} 
                        url='/bonus' 
                        label='Bono' 
                      />
                  }
                  {
                    !reservedPrivileges.includes(userId) &&
                      <LinkButtonV2
                        handleClick={this.handleClickV2} 
                        icon={<SyncProblemIcon />} 
                        url='/substitution' 
                        label='Suplencia' 
                      />
                  }
                  {
                    !reservedPrivileges.includes(userId) &&
                      <Divider />
                  }
                  

                  <LinkButtonV2
                    handleClick={this.handleClickV2} 
                    icon={<PersonIcon />} 
                    url='/v2/customers' 
                    label='Clientes' 
                  />


                  {
                    !reservedPrivileges.includes(userId) &&
                      <Divider />
                  }
                  {
                    !reservedPrivileges.includes(userId) &&
                      <ListItem button component={Link} to="/Assignment">
                        <ListItemIcon>
                          <ExtensionIcon />
                        </ListItemIcon>
                        <ListItemText primary="Colocación" />
                      </ListItem>
                  }
                  {
                    !reservedPrivileges.includes(userId) &&
                      <LinkButtonV2
                        handleClick={this.handleClickV2} 
                        icon={<TrendingUpIcon />} 
                        url='/v2/expenses' 
                        label='Flujo de Efectivo' 
                      />
                  }
                  {
                    !reservedPrivileges.includes(userId) &&
                      <LinkButtonV2
                        handleClick={this.handleClickV2} 
                        icon={<CreditCardIcon />} 
                        url='/v2/receivable' 
                        label='Cobranza' 
                      />
                  }
                  {
                    !reservedPrivileges.includes(userId) &&
                      <LinkButtonV2
                        handleClick={this.handleClickV2} 
                        icon={<ImportExportIcon />} 
                        url='/reckoning' 
                        label='Ajuste de Saldos' 
                      />
                  }
                  {
                    !reservedPrivileges.includes(userId) &&
                      <Divider /> 
                  }
                  {
                    // !reservedPrivileges.includes(userId) &&
                    //   <ListItem button onClick={this.handleOpenReports}>
                    //     <ListItemIcon>
                    //       <AssignmentIcon />
                    //     </ListItemIcon>
                    //     <ListItemText primary="Reportes" />
                    //     {openReports ? <ExpandLess /> : <ExpandMore />}
                    //   </ListItem>
                  }
                  {
                    !reservedPrivileges.includes(userId) &&
                      // <Collapse in={openReports} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItem button component={Link} to="/CheckIn/Report">
                            <ListItemText primary="Repo. Asistencias" />
                          </ListItem>
                          <LinkButtonV2
                            handleClick={this.handleClickV2} 
                            url='/checkIn/utility' 
                            label='Repo. Utilidad' 
                          />
                          <LinkButtonV2
                            handleClick={this.handleClickV2} 
                            url='/customers/charge/report' 
                            label='Repo. Cobranza' 
                          />
                          <LinkButtonV2
                            handleClick={this.handleClickV2} 
                            url='/houseMaid/salary/report' 
                            label='Repo. Pagos' 
                          />
                          <LinkButtonV2
                            handleClick={this.handleClickV2} 
                            url='/houseMaid/benefit/report' 
                            label='Repo. Prestaciones' 
                          />
                        </List>
                      // </Collapse>                      
                  }
                  <Divider />
                  {
                    !reservedPrivileges.includes(userId) &&
                      <ListItem button component={Link} to="/Balance">
                        <ListItemIcon>
                          <AccountBalanceWalletIcon />
                        </ListItemIcon>
                        <ListItemText primary="Estado de Resultados" />
                      </ListItem>
                  }
                  
                  <LinkButtonV2
                    handleClick={this.handleClickV2} 
                    icon={<SupervisedUserCircleIcon />} 
                    url='/Supervision' 
                    label='Supervisión' 
                  />


                  {
                    !reservedPrivileges.includes(userId) &&
                      <LinkButtonV2
                        handleClick={this.handleClickV2} 
                        icon={<BarChartIcon />} 
                        url='/Productivity/Month' 
                        label='Indicadores' 
                      />
                  }
                  
                  {
                    !reservedPrivileges.includes(userId) &&
                      <Divider />
                  }
                  {
                    !reservedPrivileges.includes(userId) &&
                      <ListItem button component={Link} to="/User">
                        <ListItemIcon>
                          <FingerprintIcon />
                        </ListItemIcon>
                        <ListItemText primary="Usuarios" />
                      </ListItem>
                  }
                  {
                    !reservedPrivileges.includes(userId) &&
                      <ListItem button component={Link} to="/Branch">
                        <ListItemIcon>
                          <StoreIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sucursales" />
                      </ListItem>
                  }
                  {
                    !reservedPrivileges.includes(userId) &&
                      <ListItem button component={Link} to="/Country">
                        <ListItemIcon>
                          <LanguageIcon />
                        </ListItemIcon>
                        <ListItemText primary="Paises y Estados" />
                      </ListItem>
                  }
                  {
                    !reservedPrivileges.includes(userId) &&
                      <Divider />   
                  }
                  {
                    !reservedPrivileges.includes(userId) &&
                      <ListItem button component={Link} to="/Filipina/Customer">
                        <ListItemIcon>
                          <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary="Filipinas | Clientes" />
                      </ListItem>
                  }
                </List>
              </div>
            )}
          </List>
        </div>
      </Fragment>
    );
  }

};

const LinkButtonV2 = props => {
  const handleClick = e => { props.handleClick(e, props.url); }
  return (
    <ListItem button onClick={handleClick}>
      { props.icon && <ListItemIcon>{props.icon}</ListItemIcon> }
      <ListItemText primary={props.label} />
    </ListItem>
  );
};

const mapStateToProps = state => ({
  profile: state.profile.image
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: value => dispatch(getDataFromServer(value))
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MenuItems));
