import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

import format from 'date-fns/format';

import FormDialog from './../FormDialog';
import TextField from './../Form/TextField';
import SelectField from './../Form/SelectField';
import AutocompleteField from './../Form/AutocompleteField';
import DateField from './../Form/DateField';
import CheckBoxField from './../Form/CheckBoxField';

import { closeActionDialog, getDataFromServer, alertActions } from './../../_actions.v2';
import { recordsServices } from './../../_services.v2';
import { errorFormat } from './../../_helpers.v2';

import routes from './../Module/routes';

const ERROR_MESSAGE = "Ha ocurrido un error, por favor inténtalo de nuevo más tarde.";

const Component = props => { 

	const [ values, setValues ] = useState({});
  const [ errors, setErrors ] = useState({});

  useEffect(() => {
    setValues(props.openForm[1]);
  }, [props.openForm]);
  
	const handleChange = e => {
		const name = e.target.name;
		const value = e.target.value;
		setValues(values => ({ ...values, [name]: value }));
	}

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleDateChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd')
    setValues(values => ({ ...values, [name]: tmpValue }));
  }

  const handleCheckBox = (el, name) => {
    const tmpValue = values[name] ? { ...values[name], [el]: !values[name][el] } : { [el]: true };
    setValues(values => ({ ...values, [name]: tmpValue }));
  }

  const handleCloseForm = () => {
    props.handleCloseForm();
    setValues({});
    setErrors({});
  }

  const handleAction = () => {
    const requestMethod = !values.id ? "create" : "update";
    const url = routes(props.route, requestMethod.toUpperCase(), { id: values.id });
    const body = Object.keys(values).reduce((result, el) => {
      const { field } = props.fields.find(ch => ch.name === el) || 'text';
      const tmpValue = (field === 'autocomplete') ? values[el].value : values[el];
      return { ...result, [el]: tmpValue };
    }, {});

    recordsServices[requestMethod](url, body)
      .then(data => {
        if(data.errorCode) { throw new Error(data.errorMessage); }
        props.showAlert({ variant: 'success' })
        props.getDataFromServer(true);
        handleCloseForm();
      }) 
      .catch(e => { 
        const tmpErrors = errorFormat(e.message);
        setErrors(tmpErrors);
        props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
      });
  }
  
	return (
		<FormDialog
      title={`${!values.id ? 'Crear' : 'Actualizar'} ${props.singular}`}
      actionLabel={values.id && "Actualizar"}
      isOpen={props.openForm[0]}
      handleClose={handleCloseForm}
      handleAction={handleAction}
    >
    	{ 
    		props.fields && props.fields.map(el => {
          
          if(Object.keys(values).find(key => key === el.noShow)) {
            return false;
          }

          if(!el.show || Object.keys(values).find(key => key === el.show)){
      			switch(el.field){
      				case 'select': return (
      					<SelectField 
      						key={el.name}
  								name={el.name}
                  icon={el.icon}
  								label={el.label}
  								value={values[el.name] || ''}
  								onChange={handleChange}
  								options={el.options}
                  error={errors[el.name]}
  							/>
      				)
              case 'autocomplete': return (
                <AutocompleteField 
                  key={el.name}
                  name={el.name}
                  icon={el.icon}
                  label={el.label}
                  value={values[el.name] || { value: '', label: '' }}
                  onChange={handleAutocompleteChange}
                  options={el.options}
                  error={errors[el.name]}
                />
              )
              case 'date': return (
                <DateField 
                  key={el.name}
                  name={el.name}
                  icon={el.icon}
                  label={el.label}
                  value={values[el.name] || null}
                  onChange={handleDateChange}
                  error={errors[el.name]}
                />
              )
              case 'checkBox': return (
                <CheckBoxField 
                  key={el.name}
                  name={el.name}
                  icon={el.icon}
                  label={el.label}
                  action={handleCheckBox}
                  controls={el.controls.map(chEl => ({
                    name: chEl.name,
                    label: chEl.label,
                    value: values[el.name] ? values[el.name][chEl.name] : false
                  }))}
                />
              )
      				default: return (
    						<TextField 
    							type={el.type || 'text'}
    							key={el.name}
  								icon={el.icon}
  								name={el.name}
  								label={el.label}
  								value={values[el.name] || ''}
  								onChange={handleChange}
                  error={errors[el.name]}
  							/>
    					)
      			}
          }

          return false;

    		})
    	}
    </FormDialog>
	)
}

const mapStateToProps = states => ({
  openForm: states.actionDialog
});

const mapDispatchToProps = dispatch => ({
  handleCloseForm: () => dispatch(closeActionDialog()),
  getDataFromServer: value => dispatch(getDataFromServer(value)),
  showAlert: data => dispatch(alertActions.show(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);