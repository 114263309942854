import React, { useEffect } from "react";
import { connect } from 'react-redux';

import { recordsActions, editValueAction } from '../../_actions';
import { FormDialog } from './../../_presentationals/FormDialog';
import { TextField } from './../../_presentationals/Form';
// import { closeFormDialog } from '../../_actions';

const SettingsDialog = ({ getRecords, editValueAction, update, closeFormDialog, ...props }) => {
  
  const handleSettings = () => {
    editValueAction({ id: 1, fixedExpense: props.fixedExpense, balance: true });
    update('branches');
  }

  useEffect(() => {
    if(props.alert.type === 'success') getRecords({ table: 'checkIns', balance: true, start: props.start, finish: props.finish });
  },[props.alert]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormDialog
      title="Configaración"
      handleCustomizedClick={handleSettings}
      singular="settignsDialog"
      keepDataAfterSuccess={true}
      clearDataAfterClose={false}
      primaryButtonLabel="Actualizar"
    >
      <TextField
        fields={[{
          id: "fixedExpense", 
          label: "Gastos Fijos",
          autoFocus: true
        }]}
      />
    </FormDialog>
  )
}

const mapStateToProps = state => ({
  fixedExpense: state.field.value.fixedExpense || 0, 
  alert: state.alert
});

const mapDispatchToProps = dispatch => ({
  editValueAction: data => dispatch(editValueAction(data)),
  update: table => dispatch(recordsActions.update(table)),
  getRecords: value => dispatch(recordsActions.getAll(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsDialog);