import XLSX from 'xlsx';
import { format } from 'date-fns';
import locale from 'date-fns/locale/es';

import { authHeader, config } from '../../_helpers';
import { handleFetchResponse } from './../../_services';

const getDataFromServer = (start,finish,paymentPlan) => {
	const method = 'GET';
	const url = `${config.apiUrl}/v2/payrolls/${start}/${finish}?paymentPlan=${paymentPlan}`;
  const myRequest = new Request(
    url, { method, headers: { ...authHeader() } }
  );
  return fetch(myRequest)
  	.then(handleFetchResponse({ url, method, headers: {} }))
  	.then(data => data);
}

const XLS = (start,finish,paymentPlan) => { getDataFromServer(start,finish,paymentPlan).then(data => {
	const filename = `nomina_${format(new Date(), "dd-MMM-yyyy_HH-mm", {locale})}.xlsx`;
	const wb = XLSX.utils.book_new();

	const header = ["Empleado","Banco","Titular","Cuenta","Nomina"];
	
	let ws = XLSX.utils.json_to_sheet([], { header, skipHeader: true });

	const tmp = data.reduce((newArray = [],el) => {

		const creditCardBank = el.creditCardBank ? el.creditCardBank : '-';
		const creditCardId = el.creditCardId ? el.creditCardId : '-';
		const creditCardOwner = el.creditCardOwner ? el.creditCardOwner : '-';
		const total = parseFloat(el.total);

		if(parseFloat(total) === 0) {
			return newArray;
		}
		
		return [ ...newArray, { "Empleado": el.name, "Banco": creditCardBank, "Titular": creditCardOwner, "Cuenta": creditCardId, "Nomina": total }];

	},[]);

	XLSX.utils.sheet_add_json(ws, tmp, { skipHeader: false, origin: "A1"});

	const ws_name = 'Nomina';

	XLSX.utils.book_append_sheet(wb, ws, ws_name);
	XLSX.writeFile(wb, filename);
})}

export default XLS;