import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0, 0, 0),
  },
}));

export default useStyles;
