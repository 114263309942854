import React, { Fragment, useMemo, useState, useEffect  } from "react";
import { connect } from 'react-redux';

import Module from '../../_presentationals/Module';
import { openFormDialog } from '../../_actions';

import { format } from 'date-fns';
import localeES from 'date-fns/locale/es';

import { moneyFormat } from '../../_helpers/moneyFormat';
import { recordsServices } from '../../_services';

import { FilterDialog }  from './filter';
import { newDate } from "../../_helpers.v2";

const CheckInReport = props => {
  const [ additionalText, setAdditionalText ] = useState("");
  const [ customers, setCustomers ] = useState([]);
  const [ maids, setMaids ] = useState([]);

  useEffect(() => {
    recordsServices.getAll({ table: 'customers' }).then(data => {
      const customersData = data.count > 0 ? data.rows.map(el => ({ value: el.id, label: el.name })) : [];
      setCustomers(customersData);
    });
    recordsServices.getAll({ table: 'maids' }).then(data => {
      const maidsData = data.count > 0 ? data.rows.map(el => ({ value: el.id, label: el.name })) : [];
      setMaids(maidsData);
    });
  }, []);

  const filter = () => {
    props.openFormDialog('CheckInsReportFilter');
  };

  const checkIns = useMemo(() => {
    let totalSalary = 0;
    let totalPayment = 0;
    let totalSupplies = 0;
    let totalTaxes = 0;
    let total = 0;

    const tmp = props.checkIns.count > 0 ? props.checkIns.rows.map(el => {
      totalSalary = parseFloat(totalSalary) + parseFloat(el.salary);

      // if([1,4].includes(parseInt(el.status))) {
      totalPayment = parseFloat(totalPayment) + parseFloat(el.payment);
      totalSupplies = parseFloat(totalSupplies) + parseFloat(el.supplies);
      totalTaxes = parseFloat(totalTaxes) + parseFloat(el.iva);
      // }
      total = totalPayment + totalSupplies + totalTaxes;
      return {
        ID: { 
          show: false,
          value: el.id,
          data: el
        },
        Creación: { 
          value: format(new Date(el.createdAt), "dd/MMM/yyyy HH:mm:ss", {locale: localeES}),
          alt: new Date(el.createdAt),
          _sort: 'alt'
        },
        Asistencia: { 
          value: format(newDate(el.date), "dd/MMM/yyyy", {locale: localeES}),
          alt: newDate(el.date),
          _sort: 'alt'
        },
        Estatus: { 
          value: el.statusLabel.label,
          alt: el.statusLabel.label,
          _sort: 'alt'
        },
        Mucama: {
          value: el.Maid.name,
          filter: true,
          _sort: 'text'
        },
        Cliente: {
          value: el.Customer ? el.Customer.name : '-',
          filter: true,
          _sort: 'text'
        },
        Tipo: {
          value: el.Customer ? (parseInt(el.Customer.type) === 1 ? 'Casa' : 'Oficina') : '-',
          filter: true,
          _sort: 'text'
        },
        Sueldo: {
          value: moneyFormat(el.salary),
          alt: parseFloat(el.salary),
          _sort: 'text'
        },
        Cobro: {
          value: moneyFormat(el.payment),
          alt: parseFloat(el.payment),
        },
        Insumos: {
          value: moneyFormat(el.supplies),
          alt: parseFloat(el.supplies),
        },
        IVA: {
          value: moneyFormat(el.iva),
          alt: parseFloat(el.iva),
        }
      }
    }) : [];
    const healthInsurance = props.checkIns.infonavitAndhealth ? props.checkIns.infonavitAndhealth.totalBenefits : 0; 
    const infonavit = props.checkIns.infonavitAndhealth ? props.checkIns.infonavitAndhealth.totalInfonavit : 0;

    setAdditionalText(`Sue ${moneyFormat(totalSalary)} | COB ${moneyFormat(total)} | Seg ${moneyFormat(healthInsurance)} | Inf ${moneyFormat(infonavit)}`);
    return tmp;
  }, [props.checkIns]);

  return (
    <Fragment>
      <Module
        table="checkInsReport"
        name="Reporte de Asistencias"
        records={checkIns}
        singularName="reporte"
        filter={filter}
        edit={false}
        create={false}
        download={true}
        additionalText={additionalText}
      ></Module>
      <FilterDialog customers={customers} maids={maids} />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  checkIns: state.records.checkInsReport || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: data => dispatch(openFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckInReport);