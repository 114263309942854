const sortByType = (rows, key, type, direction) => {
  switch (type) {
    case 'text':
      rows.sort((a, b) => {
        if (a[key] > b[key]) {
          return 1;
        }
        if (a[key] < b[key]) {
          return -1;
        }
        return 0;
      });
      break;
    case 'date':
      rows.sort((a, b) => {
        return new Date(b[key]) - new Date(a[key]);
      });
      break;
    case 'number':
      rows.sort((a, b) => {
        return b[key] - a[key];
      });
      break;
    default:
      break;
  }
  if (direction === 'desc') rows.reverse();
  return rows;
};

export default sortByType;
