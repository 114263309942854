import makeStyles from '@material-ui/core/styles/makeStyles';

// const drawerWidth = 240; 
// const drawerWidth = "22%"; 

const useStyles = makeStyles((theme) => ({
	header: {
		padding: theme.spacing(0.5, 1.5, 1, 1.5),
	  borderBottom: '1px solid #e0e0e0', 
	  // position: 'absolute',
	  zIndex: 1000,
	  backgroundColor: "#fff",
	},
	marginRight: { marginRight: theme.spacing(1) },
	paddingX: {
		paddingLeft: theme.spacing(1.6),
		paddingRight: theme.spacing(1.6),
	},
	toolbar: theme.mixins.toolbar,
}));

export default useStyles;