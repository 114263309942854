import React, { useState } from 'react';
import { connect } from 'react-redux';

import FormDialog from './../../_presentationals.v2/FormDialog';
import TextField from './../../_presentationals.v2/Form/TextField';

import { getDataFromServer, alertActions } from './../../_actions.v2';

import { recordsServices } from './../../_services.v2';

const ERROR_MESSAGE = 'Ha ocurrido un error, por favor inténtalo de nuevo más tarde.';

const Component = props => {

  const [ value, setValue ] = useState('');

	const handleChange = e => {
		const value = e.target.value;
		setValue(value);
	}

	const handleAction = () => {
		const body = { amount: value };
    recordsServices.update(`expenses/${props.expenseId}/amount`, body)
      .then(data => {   
      	if(data.errorCode) { throw new Error(data.errorMessage); }
      	props.showAlert({ variant: 'success' });
        props.getDataFromServer(true);
        props.handleClose();
        setValue('');   
      })
      .catch(e => { 
      	console.log(e.message);
      	props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
      });
	}

	return (
		<FormDialog
      title='Actualizar Importe'
      isOpen={props.isOpen || false}
      handleClose={props.handleClose}
      handleAction={handleAction}
    >
    	<TextField
				name='amount'
				label='Importe'
				value={value}
				onChange={handleChange}
				required
			/>
    </FormDialog>
   )
}

const mapDispatchToProps = dispatch => ({
  getDataFromServer: value => dispatch(getDataFromServer(value)),
  showAlert: data => dispatch(alertActions.show(data))
});

export default connect(null, mapDispatchToProps)(Component);