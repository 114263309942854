import React from "react";

import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { styles } from './style';


const DataTable = ({ classes, checkIns }) =>
	<Table className={classes.table} size="small">
	  <TableHead>
	    <TableRow>
	      <TableCell></TableCell>
	      <TableCell align="right">Total</TableCell>
	      <TableCell align="right">Porc.</TableCell>
	    </TableRow>
	  </TableHead>
	  <TableBody>

	    <TableRow>
	      <TableCell className={classes.tableCell}>Venta (Casas)</TableCell>
	      <TableCell className={classes.tableCell} align="right">$ { parseFloat(checkIns.totalHouse).toFixed(2) } MXN</TableCell>
	      <TableCell className={classes.tableCell} align="right">{ parseFloat(checkIns.totalHousePercent).toFixed(2) } %</TableCell>
	    </TableRow>

	    <TableRow>
	      <TableCell className={classes.tableCell}>Venta (Oficinas)</TableCell>
	      <TableCell className={classes.tableCell} align="right">$ { parseFloat(checkIns.totalOffice).toFixed(2) } MXN</TableCell>
	      <TableCell className={classes.tableCell} align="right">{ parseFloat(checkIns.totalOfficePercent).toFixed(2) } %</TableCell>
	    </TableRow>

	    <TableRow>
	      <TableCell className={classes.tableCell}>Ajuste de Saldo</TableCell>
	      <TableCell className={classes.tableCell} align="right">$ { parseFloat(checkIns.totalReckoning).toFixed(2) } MXN</TableCell>
	      <TableCell className={classes.tableCell} align="right">{ parseFloat(checkIns.totalReckoningPercent).toFixed(2) } %</TableCell>
	    </TableRow>

	    <TableRow>
	      <TableCell className={classes.tableCell}>Insumos</TableCell>
	      <TableCell className={classes.tableCell} align="right">$ { parseFloat(checkIns.totalSupplies).toFixed(2) } MXN</TableCell>
	      <TableCell className={classes.tableCell} align="right">{ parseFloat(checkIns.totalSuppliesPercent).toFixed(2) } %</TableCell>
	    </TableRow>

	    <TableRow>
	      <TableCell className={classes.tableCell}>Impuestos a Favor</TableCell>
	      <TableCell className={classes.tableCell} align="right">$ { parseFloat(checkIns.totalTaxes).toFixed(2) } MXN</TableCell>
	      <TableCell className={classes.tableCell} align="right">{ parseFloat(checkIns.totalTaxesPercent).toFixed(2) } %</TableCell>
	    </TableRow>

	    <TableRow >
	      <TableCell className={classes.tableCellTitle} >Ventas Brutas</TableCell>
	      <TableCell className={classes.tableCellTitle} align="right">$ { parseFloat(checkIns.totalSale).toFixed(2) } MXN</TableCell>
	      <TableCell className={classes.tableCellTitle} align="right">{ parseFloat(checkIns.totalSalePercent).toFixed(2) } %</TableCell>
	    </TableRow>

	    <TableRow>
	      <TableCell className={classes.tableCell}>Nomina</TableCell>
	      <TableCell className={classes.tableCell} align="right">$ { parseFloat(checkIns.totalPayroll).toFixed(2) } MXN</TableCell>
	      <TableCell className={classes.tableCell} align="right">{ parseFloat(checkIns.totalPayrollPercent).toFixed(2) } %</TableCell>
	    </TableRow>

	    <TableRow>
	      <TableCell className={classes.tableCell}>Infonavit</TableCell>
	      <TableCell className={classes.tableCell} align="right">$ { parseFloat(checkIns.totalInfonavit).toFixed(2) } MXN</TableCell>
	      <TableCell className={classes.tableCell} align="right">{ parseFloat(checkIns.totalInfonavitPercent).toFixed(2) } %</TableCell>
	    </TableRow>

	    <TableRow>
	      <TableCell className={classes.tableCell}>Prestaciones</TableCell>
	      <TableCell className={classes.tableCell} align="right">$ { parseFloat(checkIns.totalBenefits).toFixed(2) } MXN</TableCell>
	      <TableCell className={classes.tableCell} align="right">{ parseFloat(checkIns.totalBenefitsPercent).toFixed(2) } %</TableCell>
	    </TableRow>
 
	    <TableRow>
	      <TableCell className={classes.tableCellTitle}>Costo de Ventas</TableCell>
	      <TableCell className={classes.tableCellTitle} align="right">$ { parseFloat(checkIns.totalCostSale).toFixed(2) } MXN</TableCell>
	      <TableCell className={classes.tableCellTitle} align="right">{ parseFloat(checkIns.totalCostSalePercent).toFixed(2) } %</TableCell>
	    </TableRow>

	    <TableRow>
	      <TableCell className={classes.tableCellTitle}>Utilidad Bruta</TableCell>
	      <TableCell className={classes.tableCellTitle} align="right">$ { parseFloat(checkIns.totalUtility).toFixed(2) } MXN</TableCell>
	      <TableCell className={classes.tableCellTitle} align="right">{ parseFloat(checkIns.totalUtilityPercent).toFixed(2) } %</TableCell>
	    </TableRow>

	    <TableRow>
	      <TableCell className={classes.tableCell}>Gastos Fijos</TableCell>
	      <TableCell className={classes.tableCell} align="right">$ { parseFloat(checkIns.totalFixedExpense).toFixed(2) } MXN</TableCell>
	      <TableCell className={classes.tableCell} align="right">{ parseFloat(checkIns.totalExpensesPercent).toFixed(2) } %</TableCell>
	    </TableRow>

	    <TableRow>
	      <TableCell className={classes.tableCellTitle}>Utilidad Operativa</TableCell>
	      <TableCell className={classes.tableCellTitle} align="right">$ { parseFloat(checkIns.totalOperativeUtility).toFixed(2) } MXN</TableCell>
	      <TableCell className={classes.tableCellTitle} align="right">{ parseFloat(checkIns.totalOperativeUtilityPercent).toFixed(2) } %</TableCell>
	    </TableRow>

	  {/*  <TableRow>
	      <TableCell className={classes.tableCellTitle}>Utilidad Neta</TableCell>
	      <TableCell className={classes.tableCellTitle} align="right">$ { parseFloat(checkIns.totalNetUtility).toFixed(2) } MXN</TableCell>
	      <TableCell className={classes.tableCellTitle} align="right">{ parseFloat(checkIns.totalNetUtilityPercent).toFixed(2) } %</TableCell>
	    </TableRow>*/}

	  </TableBody>
  </Table>

export default withStyles(styles)(DataTable);