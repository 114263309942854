import { format } from "date-fns";
import localeES from 'date-fns/locale/es';
import { newDate } from "../../_helpers.v2";

const Receivable = ({method, ...props}) => {
  switch (method) {
    default:
    	const startFormat = props.start ? newDate(props.start) : new Date();
      const finishFormat = props.finish ? newDate(props.finish) : new Date();

      const start = format(startFormat, 'yyyy-MM-dd', {locale: localeES});
      const finish = format(finishFormat, 'yyyy-MM-dd', {locale: localeES});

      return `checkIns/receivables/reports?start=${start}&finish=${finish}`;
  }
}

export default Receivable;