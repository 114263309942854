import React, { Fragment, useMemo, useState  } from "react";
import { connect } from 'react-redux';

import Module from '../../_presentationals/Module';
import { openFormDialog } from '../../_actions';

// import { format } from 'date-fns';
// import localeES from 'date-fns/locale/es';

// import { moneyFormat } from '../../_helpers/moneyFormat';

import { FilterDialog }  from './filter';

const ReceivableReport = props => {
  const [ additionalText ] = useState("");

  const filter = () => {
    props.openFormDialog('ReceivableReportFilter');
  };

  const checkIns = useMemo(() => {
    // let totalSalary = 0;
    // let totalPayment = 0;
    // let totalSupplies = 0;
    // let totalTaxes = 0;

    const tmp = props.checkIns.count > 0 ? props.checkIns.rows.map(el => {
      // totalSalary = parseFloat(totalSalary) + parseFloat(el.salary);

      // if(parseInt(el.status) === 1) {
      //   totalPayment = parseFloat(totalPayment) + parseFloat(el.Day.payment);
      //   totalSupplies = parseFloat(totalSupplies) + parseFloat(el.Day.supplies);
      //   totalTaxes = parseFloat(totalTaxes) + parseFloat(el.Day.iva);
      // }

      return {
        ID: { 
          show: false,
          value: el.id,
          data: el
        },
        Cliente: {
          value: el.name,
          filter: true,
          _sort: 'text'
        }
      }
    }) : [];

    // setAdditionalText(`Sueldo: ${moneyFormat(totalSalary)} | Cobro: ${moneyFormat(totalPayment)} | Insumos: ${moneyFormat(totalSupplies)} | IVA: ${moneyFormat(totalTaxes)}`);
    return tmp;
  }, [props.checkIns]);

  return (
    <Fragment>
      <Module
        table="ReceivableReport"
        name="Reporte de Nómina"
        records={checkIns}
        singularName="reporte"
        filter={filter}
        edit={false}
        create={false}
        download={true}
        _delete={false}
        additionalText={additionalText}
      ></Module>

      <FilterDialog />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  checkIns: state.records.ReceivableReport || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: data => dispatch(openFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReceivableReport);