import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// import Table from './helpers/Table';
// import TableHead from './TableHead';
// import TableBody from './TableBody';
import dateFormat from 'date-fns/format';

import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';

import InfiniteScroll from 'react-infinite-scroller'; // NEW LINE
import makeStyles from '@material-ui/core/styles/makeStyles'; // NEW LINE
import Typography from '@material-ui/core/Typography'; // NEW LINE

import TableSortLabel from './helpers/TableSortLabel'; // NEW LINE
import moneyFormat from './../../_helpers.v2/moneyFormat'; // NEW LINE
import IconButton from './../Form/IconButton'; //NEW LINE

import { recordsServices } from './../../_services.v2'; //NEW LINE
import routes from './../Module/routes'; //NEW LINE

import { sortByType, filterRows } from './helpers/General';
import { createXLS, createPDF, openActionDialog, openDeleteDialog } from './../../_actions.v2';
import { JsonToXls } from './../XLS';
import { JsonToPdf } from './../PDF';

const defaultCell = { display: 'table-cell', borderBottom: '1px solid #e0e0e0' };
const innerWidth = window.innerWidth;
const useStyles = makeStyles((theme) => ({
  actionButton: { padding: theme.spacing(0.4) },
  table: { 
    width: innerWidth,
    // flex: 1,
    overflow:'auto',
    height: document.documentElement.clientHeight - ((theme.mixins.toolbar.minHeight * 1) + 18)
  },
  tableHead: { display: 'table-header-group' },
  tableBody: { display: 'table-row-group' },
  tableRow: { 
    display: 'table-row',
    '&:hover': {
      background: '#ededed'
    },
  },
  tableRowDager: { 
    display: 'table-row',
    '&:hover': {
      background: '#ededed'
    },
    backgroundColor: '#ffebeb'
  },
  tableCell: { 
    ...defaultCell, 
    padding: theme.spacing(.5, 1.5, .5, 1.5),
    verticalAlign: 'middle'
  },
  tableTitle: { 
    ...defaultCell,
    position: 'sticky', 
    top: 0,
    fontWeight: '500',
    padding: theme.spacing(1, 1.5, 1, 1.5),
    background: '#fff',
  },
  loader: { 
    padding: theme.spacing(1, 1.5, 1, 1.5), 
    background: '#fff' 
  }
}));

const Component = props => {

	const classes = useStyles();

  const [ firstRequest, setFirstRequest ] = useState(true);
	const [ hasMore, setHasMore ] = useState(true);
	const [ values, setValues ] = useState([]);
  const [ currentPage, setCurrentPage ] = useState(1);

	const [ direction, setDirection ] = useState({});
	const [ rows, setRows ] = useState([]);

	useEffect(() => {
    setRows(props.records);
    setFirstRequest(false);
	}, [props.records]);

  useEffect(() => {
    if(!firstRequest){ loadFunc(currentPage); }
  }, [firstRequest, rows]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if(rows.length > 0){
      const keys = props.Rows.map(el => el.filter && el.key).filter(el => el);
      const tmp = filterRows(props.searchField, keys, props.records);
      setRows(tmp);
      loadFunc(currentPage);
    }
	}, [props.searchField]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if(props.xlsDoc){
			JsonToXls(props.title, props.Rows, rows);
			props.createXLS();
		}
	}, [props.xlsDoc]); // eslint-disable-line react-hooks/exhaustive-deps
	
	useEffect(() => {
		if(props.pdfDoc){
			JsonToPdf(props.title, props.Rows, rows);
			props.createPDF();
		}
	}, [props.pdfDoc]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleSort = key => {
		const { sort } = props.Rows.find(el => el.key === key);
		const tmpDirection = direction[key] || 'asc';
    setDirection({ [key]: tmpDirection === 'asc' ? 'desc' : 'asc' })
		const data = sortByType(rows, key, sort, tmpDirection);
    setRows(data);
    loadFunc(currentPage);
	}

	const loadFunc = page => {
    
    if(props.records.length === 0) return false;
    
    const newArray = rows.map((el, i) => 
      parseInt(i) < (page * 100) ? el : false
    ).filter(el => el);
    
    setValues(newArray);
    setCurrentPage(page);

    (newArray.length < 100 || newArray.length === rows.length)
      ? setHasMore(false) 
      : setHasMore(true);

  };

  const handleOpenEdit = (e, params) => {
    recordsServices.get(routes(props.route, 'GET_BY_PK', params))
      .then(data => props.openForm(data))
      .catch(e => console.log(e.message));
  }

  const handleOpenDelete = (e, id) => { props.openDelete(id); }

	return (
		<div className={ classes.table }>
      <div className={ classes.tableHead }>
        <div className={ classes.tableRow }>
          { props.Rows.map(el => 
            <div key={el.key} align={el.align || 'left'} className={ classes.tableTitle }>
              {el.sort 
                ? <TableSortLabel 
                    id={el.key} 
                    label={el.label} 
                    direction={direction[el.key]}
										onClick={handleSort}
                  />
                : el.label
              }
            </div>
          )}
          <div className={ classes.tableTitle } />
        </div>
      </div>
      {rows.length > 0 && 
      <InfiniteScroll
        pageStart={0}
        className={ classes.tableBody }
        loadMore={loadFunc}
        hasMore={hasMore}
        loader={<div className={classes.loader} key={0}>Cargando...</div>}
        useWindow={false}
      >
        {values.map(data => 
          <div key={data.id} className={ data.background ? classes.tableRowDager : classes.tableRow }>
            {props.Rows.map(el => 
              <div key={el.key} align={el.align} className={ classes.tableCell }>
                <Typography variant="body2" noWrap={!el.wrap}>
                  { format(data[el.key], el.format) }
                </Typography>
              </div>
            )}
            <div align='right' className={ classes.tableCell }>
              <Typography variant="body2" noWrap={true}>
              {
                props.actions && props.actions.map(el => 
                  <IconButton 
                    key={ el.label }
                    label={ el.label }
                    action={ el.onClick } 
                    params={ { id: data.id, ...el.params } }
                    className={ classes.actionButton }
                    icon={ el.icon }
                  />
                )
              }
              { !props.noEdit && (
                <IconButton 
                  label="Editar" 
                  action={ handleOpenEdit } 
                  params={ { id: data.id, ...props.extraParams } }
                  className={ classes.actionButton }
                  icon={<EditIcon fontSize="small" />}
                />
              ) }
              { !props.noDelete && (
                <IconButton 
                  label="Eliminar" 
                  action={ handleOpenDelete } 
                  params={ { id: data.id, ...data } }
                  className={ classes.actionButton }
                  icon={<DeleteIcon fontSize="small" />}
                />
              ) }
              </Typography>
            </div>
          </div>
        )}
      </InfiniteScroll>
      }
    </div>
	);
}

const format = (value, type) => {
  switch(type){
    case 'date':
      return value !== '-' ? dateFormat(new Date(value), 'dd/MM/yyyy') : '-';
    case 'dateTime':
      return value !== '-' ? dateFormat(new Date(value), 'dd/MM/yyyy HH:mm:ss') : value;
    case 'time':
      return dateFormat(new Date(value), 'HH:mm:ss');
    case 'currency':
      return moneyFormat(value);
    case 'balance':
      return (parseFloat(value) === 0) ? '-' : moneyFormat(value);
    default: return value;
  }
}

const mapStateToProps = state => ({
	searchField: state.searchField,
	xlsDoc: state.xlsDoc,
	pdfDoc: state.pdfDoc
});

const mapDispatchToProps = dispatch => ({
	createXLS: () => dispatch(createXLS(false)),
  createPDF: () => dispatch(createPDF(false)),
  openForm: data => dispatch(openActionDialog({ ...data })),
  openDelete: id => dispatch(openDeleteDialog(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);