import React, { Fragment, useState, useEffect } from "react";

import AlarmOnIcon from '@material-ui/icons/AlarmOnOutlined';
import AlarmOffIcon from '@material-ui/icons/AlarmOffOutlined';
import GavelIcon from '@material-ui/icons/GavelOutlined';
import format from 'date-fns/format';
import { recordsServices } from '../../../_services'; 
import { Button } from './../_components';
import { newDate } from "../../../_helpers.v2";

const CheckinButton = props => {

	const [ isDisabled, setIsDisabled ] = useState(false);

	useEffect(() => {
		const tmpIsDisabled = [4,5].includes(parseInt(props.status.id)) ? true : false;
		setIsDisabled(tmpIsDisabled);
	}, [props.status.id]);

	const handleCheckIn = async e => {
		try{
			setIsDisabled(true);

			const action = (parseInt(props.status.id) === 6) ? 'create': 'update';
			const customerId = props.Customer ? props.Customer.id : 0;

			let data = { status: e.status, id: props.id };
			if(action === 'create'){ data = { ...data, date: format(newDate(props.day), 'yyyy-MM-dd'), customerId, id: props.maidId }; }
			
			const response = await recordsServices[action]({ table: 'checkIns', ...data }).then(data => {
				setIsDisabled(false);	
				(props.handleClick && data) && props.handleClick({ ...data.data, id: data.checkInId, day: props.date, date: props.day, i: props.i, position: props.position });
				return data;
			});
			
			if(response.errorCode){ throw new Error(response.errorMessage); }
		} catch(e){
			console.log(e)
			alert(e.message);
		}
	}

	return (
		<Fragment>
			{
				![4,5].includes(parseInt(props.status.id)) &&
					<Fragment>
						<Button 
							title="Asistencia" 
							onClick={handleCheckIn}  
							icon={<AlarmOnIcon />} 
							isDisabled={isDisabled}
							status={1}
						/>
				 		<Button 
							title="Falta" 
							onClick={handleCheckIn} 
							icon={<AlarmOffIcon />} 
							isDisabled={isDisabled}
							status={3}
						/>
				 		<Button 
							title="Falta Injustificada" 
							onClick={handleCheckIn} 
							icon={<GavelIcon />} 
							isDisabled={isDisabled}
							status={2}
						/>
					</Fragment>
			}
			
			<div>{props.Customer ? props.Customer.name : ''}</div>
			<div>{`${props.status.label} (${parseFloat(props.salary).toFixed(2)}MXN)`}</div>
			<div>{`Cobro (${parseFloat(props.payment).toFixed(2)}MXN)`}</div>
			<div>{`Insumos (${parseFloat(props.supplies).toFixed(2)}MXN)`}</div>
			<div>{`IVA (${parseFloat(props.iva).toFixed(2)}MXN)`}</div>
			
			<hr/>
		</Fragment>
	)
}

export default CheckinButton;