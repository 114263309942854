import React, { Fragment, useState, useEffect } from "react";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { TextField, SelectField } from './../../_presentationals/Form';

const serviceTime = ["01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24"].map(el => ({ key: `${el}:00:00`, value: `${el}:00` }));

const Form = ({ customerId, type, supplies, Days, editValueAction, handleQuotation }) => {
	
	const [state, setState] = useState({
    monday: Days ? Days.monday : false,
    tuesday: Days ? Days.tuesday : false,
    wednesday: Days ? Days.wednesday : false,
    thursday: Days ? Days.thursday : false,
    friday: Days ? Days.friday : false,
    saturday: Days ? Days.saturday : false,
    sunday: Days ? Days.sunday : false,
  });

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

  useEffect(() => {
    editValueAction({Days: state})
  },[state, editValueAction]);

	return(
		<Fragment>
			<SelectField
			icon="assignment_ind"
		    fields={[{
		      id: "type", 
		      label: "Tipo de Cliente",
		      options: [
		        { key: 1, value: "Casa"}, 
		        { key: 2, value: "Empresa"}
		      ],
		      autoFocus: true
		    }]}
		  />
		  { parseInt(type) === 2 &&
		    <TextField
		      fields={[{
		        id: "branchName", 
		        label: "Nombre de Empresa",
		        required: false
		      }]}
		    />
		  }
			<TextField
		    icon="account_circle"
		    fields={[{
		      id: "name", 
		      label: "Nombre"
		    }]}
		  />
		  <TextField
		    icon="phone"
		    fields={[{
		      id: "phone", 
		      label: "Teléfono"
		    }]}
		  />
		  <TextField
		    icon="alternate_email"
		    fields={[{
		      id: "email", 
		      label: "Correo Electrónico (para cotización)",
		      required: false
		    }]}
		  />
		  <TextField
		    icon="room"
		    fields={[{
		      id: "addressLine1", 
		      label: "Calle y Número"
		    }]}
		  />
		  <TextField
		    fields={[{
		      id: "addressLine2", 
		      label: "Colonia"
		    }]}
		  />
		  <TextField
		    fields={[{
		      id: "zipCode", 
		      label: "Código Postal"
		    },{
		      id: "city", 
		      label: "Ciudad"
		    }]}
		  /> 
		  <TextField
		    icon="settings"
		    fields={[{
		      id: "quantity", 
		      label: "Número de Mucamas"
		    }]}
		  />
		  <Grid container>
        <Grid item xs={1}>
        </Grid>
        <Grid item xs={9}>
          Días
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox checked={state.monday} onChange={handleChange('monday')} value="monday" />
              }
              label="Lunes"
            />
            <FormControlLabel
              control={
                <Checkbox checked={state.tuesday} onChange={handleChange('tuesday')} value="tuesday" />
              }
              label="Martes"
            />
            <FormControlLabel
              control={
                <Checkbox checked={state.wednesday} onChange={handleChange('wednesday')} value="wednesday" />
              }
              label="Miércoles"
            />
            <FormControlLabel
              control={
                <Checkbox checked={state.thursday} onChange={handleChange('thursday')} value="thursday" />
              }
              label="Jueves"
            />
            <FormControlLabel
              control={
                <Checkbox checked={state.friday} onChange={handleChange('friday')} value="friday" />
              }
              label="Viernes"
            />
            <FormControlLabel
              control={
                <Checkbox checked={state.saturday} onChange={handleChange('saturday')} value="saturday" />
              }
              label="Sábado"
            />
            <FormControlLabel
              control={
                <Checkbox checked={state.sunday} onChange={handleChange('sunday')} value="sunday" />
              }
              label="Domingo"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={2}>
        </Grid>
      </Grid>
		  <SelectField
		    fields={[{
		      id: "startServiceTime", 
		      label: "Inicio de Servicio",
		      options: serviceTime
		    },{
		      id: "finishServiceTime", 
		      label: "Fin de Servicio",
		      options: serviceTime
		    }]}
		  />
		  <SelectField
		    fields={[{
		      id: "supplies", 
		      label: "Insumos",
		      options: [
		        { key: true, value: "Si"}, 
		        { key: false, value: "No"}
		      ]
		    }]}
		  />
		  { supplies &&
		  	<div >
		  		<br />
			  	<TextField
			   	 	fields={[{
			        id: "suppliesCommentary", 
			        label: "Comentario",
			        multiline: true,
			        rows: 2,
			        variant: "outlined",
			        required: false
			      }]}
			    />
		  	</div> 
		  }
		  <SelectField
		    fields={[{
		      id: "typePrice",
		      label: "Modalidad de Precio", 
		      options: [
		        { key: 1, value: "Día"}, 
		        { key: 2, value: "Semana"},
		        { key: 3, value: "Mes"}
		      ]
		    }]}
		  />
		  <TextField
		    fields={[{
		      id: "price", 
		      label: "Precio"
		    }]}
		  /> 
			{ customerId &&
			  <Button variant="contained" color="secondary" onClick={handleQuotation} style={{ marginTop: 10, marginLeft: 50, marginBottom: 20 }}>
			    Descargar Cotización
			  </Button>
			}
	  </Fragment>
  )
}

export default Form;