import React, { useEffect, useMemo } from "react";
import { connect } from 'react-redux';

import Module from '../../_presentationals/Module';
import { TextField, SelectField } from './../../_presentationals/Form';

import { recordsActions } from '../../_actions';

const roleOptions = [
  { key: 1, value: 'Administrador' },
  { key: 2, value: 'Vendedor' },
];

const User = ({getRecords, clearRecords, ...props}) => {

  useEffect(() => { getRecords({ table: 'branches' }); return () => clearRecords('branches'); }, [getRecords, clearRecords]);

  const branches = useMemo(() => {
    return props.branches.count > 0 ? props.branches.rows.map(({id, name}) => ({ key: id, value: name })) : [];
  }, [props.branches]);

  const users = useMemo(() => 
    props.users.count > 0 ? props.users.rows.map(el => ({
      ID: { 
        value: el.id,
        show: false,
        data: {
          branchId: el.Branch.id,
          ...el
        }
      }, 
      Nombre: {
       value: el.firstName+' '+el.lastName,
       filter: true
      },
      Usuario:{
        value: el.username
      },
      Sucursal: {
        value: el.Branch.name 
      }
    })) : []
  , [props.users]);

  return (
    <Module
      table="users"
      name="usuarios"
      records={users}
      singularName="usuario"
      filter={false}
      title="Crear Usuario"
    >
      <TextField
        icon="account_circle"
        fields={[{
          id: "firstName", 
          label: "Nombre",
          autoFocus: true
        },
        {
          id: "lastName", 
          label: "Apellido(s)"
        }]}
      />
      <TextField
        icon="fingerprint"
        fields={[{
          id: "username", 
          label: "Usuario (Correo Corporativo)"
        }]}
      />
      {
        !Object.keys(props.field).includes('id') &&
          <TextField
            fields={[{
              id: "password", 
              label: "Contraseña",
              type: "password"
            }]}
          />
      }
      <SelectField
        icon="store"
        fields={[{
          id: 'branchId', 
          label: 'Sucursal',
          options: branches,
        }]}
      />
      <SelectField
        fields={[{
          id: 'role', 
          label: 'Privilegios',
          options: roleOptions,
        }]}
      />

    </Module>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  field: state.field.value,
  branches: state.records.branches || defaultTableValue,
  users: state.records.users || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  clearRecords: value => dispatch(recordsActions.clearRecords(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(User);