import React, { PureComponent, Fragment } from "react";
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

import Link from '@material-ui/core/Link';
import Button  from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { authActions } from './../../_actions';

import { history } from './../../_helpers';
import { TextField } from './../../_presentationals/Form';
import Background from './../../_presentationals/Background';

class SingIn extends PureComponent {

  handleLogin = () => {
    const { login } = this.props;
    login();
  }

  handleKeyPress = e => {
    if(e.keyCode !== 13){ return; }
    const { login } = this.props;
    login();
  }

  handleForgotPassword = () => {
    history.push('/public/forgotPassword/email');
  }

  render() {
    const { classes } = this.props;

    return (
      <Background
        footer={
          <Fragment> 
            ¿Olvidaste tu contraseña? <Link href="#" onClick={ this.handleForgotPassword } className={ classes.bodyLink } underline="always">Recuperar Ahora</Link>
          </Fragment>
        }
      >
        <Typography variant="h4" align="center" className={ classes.typographyTitle1 }>
          Iniciar Sesión
        </Typography>
        <Typography variant="subtitle1" align="center" className={ classes.typographySubtitle1 }>
          Una herramienta inteligente, veloz y facil de usar.
        </Typography>
        <br/>
        <TextField
          dialog={false}
          fields={[{
            id: "username", 
            label: "Usuario",
            variant: "outlined",
            onKeyDown: this.handleKeyPress,
            autoFocus: true
          },{
            type: "password",
            id: "password", 
            label: "Contraseña",
            variant: "outlined",
            onKeyDown: this.handleKeyPress
          }]}
        />
        <Button variant="contained" onClick={this.handleLogin} color="primary" className={classes.registerButton} size="large" fullWidth>
          Iniciar Sesión
        </Button>
      </Background>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  login: () => dispatch(authActions.login())
});

export default withStyles(styles)(connect(null, mapDispatchToProps)(SingIn));