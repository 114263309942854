import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';

import Dialog from './dialog';
import { recordsServices } from '../../_services';

const defaultValues = {
	advancePayment: ""
};

export default function PulledApartDialog(props) {

	const [ values, setValues ] = useState(defaultValues);

	const handleChange = e => {
  	const { name, value } = e.target;
  	setValues(values => ({ ...values, [name]: value }));
  }

  const handleAccept = async () => {
  	try{
  		const { advancePayment } = values;
  		await recordsServices.update({ table: 'filipinasStatus', id: props.customerId, pulledApart: true });
  		await recordsServices.update({ table: "filipinasConfig", id: props.customerId, advancePayment });
  		await handleClose();
  		await setValues(defaultValues);	
  	}catch (e){
  		console.log(e.message);
  	}
  }

  const handleClose = () => {
  	props.handleClose('pulledApart', { resetForm: true });
  }

	return (
		<Dialog
			isOpen={ props.isOpen }
			handleClose={ handleClose }
			handleAccept={ handleAccept }
			title="Módulo de Anticipo"
			contentText="En un futuro, podrás emitir un comprobante de pago, 
									 de momento, solo podrás capturar el anticipo recibido 
									 por el cliente."
		>
			<TextField name="advancePayment" label="Anticipo" onChange={handleChange} value={values.advancePayment} fullWidth />
		</Dialog>
	);
}