const defaultValues = {
  subtitle: '',
}

export const header = (state = defaultValues, action) => {
  switch (action.type) {
    case 'SET_SUBTITLE':
      return { ...state, subtitle: action.subtitle };
    default:
      return state;
  }
};