import { isMobile } from "react-device-detect";
export const styles = theme => ({
  actionButton: {
    padding: theme.spacing(1)
  },
  bodyCell: {
    border: 'none',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    height: '50px',
    '&:hover': {
      background: "transparent"
    }
  },
  filterCell: {
    width: isMobile ? '110px' : '200px',
    minWidth: isMobile ? '110px' : '200px',
    paddingBottom: theme.spacing(0.5)
  },
  headerTitle: {
    fontSize: "15px",
    color: "#141414"
  },
  link: {
    margin: theme.spacing(1),
  },
  mobileInput: {
    height: 40
  },
  mobileInputProps: {
    fontSize: '15px',
    paddingTop: theme.spacing(1.20)
  },
  table: {
    flexGrow: 1,
    paddingBottom: isMobile ? theme.spacing(8) : 0,
    maxHeight: document.documentElement.clientHeight - ((theme.mixins.toolbar.minHeight * 1) + 18)
  },
  tooltip: {
    marginTop: theme.spacing(0.5)
  },
  danger: {
    background: '#ffebeb',
  }
});