import React, { Fragment } from "react";

import dateFormat from 'date-fns/format';
import localeES from 'date-fns/locale/es';

import withStyles from '@material-ui/core/styles/withStyles';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

import { styles } from './style';
import { newDate } from "../../../_helpers.v2";

// const session = JSON.parse(localStorage.getItem('session'));
// const privileges = session && JSON.parse(session.data.privileges);

const Body = ({classes, ...props}) => 
	<TableBody>
		{ props.rows.map((el, index) => 
			<TableRow
  			key={index}
  			tabIndex={ -1 }
        hover={ true }
        data-id={index}
        className={el.background && classes[el.background.value]}
  		>
  			{ Object.keys(el).map(keys => 
  					el[keys].show !== false &&
  					<TableCell key={keys} align={el[keys].align || 'left'} className={ classes.bodyCell }> 
    	 				{
    	 					el[keys].format ? format(el[keys].value, el[keys].format) : el[keys].value
    	 				}
    	 			</TableCell>
  			) }
  			<TableCell align="right" className={ classes.bodyCell }>
  			  <Fragment>
			  		{
			  			props.actions && props.actions.map(({ icon, label, handle }) =>
			  				<Tooltip key={label} title={label} classes={{ tooltip: classes.tooltip }}>
		              <IconButton data-row={JSON.stringify(el.ID.data)} className={ classes.actionButton } onClick={ handle } aria-label={ label }>
		                { icon }
		              </IconButton>
	              </Tooltip>
			  			)
			  		}
    				{ 
    					props.edit  && (
              <Tooltip title="Editar" classes={{ tooltip: classes.tooltip }}>
	              <IconButton data-row={JSON.stringify(el.ID.data)} className={ classes.actionButton } onClick={ props.handleEdit } aria-label="Editar">
	                <EditIcon fontSize="small" />
	              </IconButton>
              </Tooltip>
            )}
            { 
            	props._delete && (
              <Tooltip title="Desactivar" classes={{ tooltip: classes.tooltip }}>
                <IconButton data-id={el.ID.data.id} className={ classes.actionButton } onClick={ props.handleDelete } aria-label="Desactivar">
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
           </Fragment>
  			</TableCell>
  		</TableRow>
		) }
	</TableBody>


const format = (value, format) => {
	switch(format){
		case 'percent':
			return `${parseFloat(value)}%`;
		case 'city':
			return `${value}, `
		case 'addressLine2':
			return value ? ` ${value}, ` : ', ';
		case 'State':
			return ` ${value}`;
		case 'zipCode':
			return `CP ${value}, `;
		case 'price':
			return `${parseFloat(value).toFixed(2)}MXN`;
		case 'date':
			return dateFormat(newDate(value), 'd MMMM HH:mm', {locale: localeES}) ;
		case 'invoice':
			return value.map(el => 
				<div key={el.folio}><a  href={el.uri} target="_blank" rel="noopener noreferrer">{el.folio}</a><br /></div>
			);
		default:
			return value;
	}
}

export default withStyles(styles)(Body);