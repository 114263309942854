import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';

import { styles } from './styles';
import Logo from '../../_helpers/files/logo.png';

import LinearProgress from '@material-ui/core/LinearProgress';

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#5477e0',
  },
  barColorPrimary: {
    backgroundColor: '#1a3791',
  }
})(LinearProgress);

const RenderAppBar = props => {

	const { classes, anchorEl, handleClick, handleAvatar, toggleDrawer, handleClose, handleLogout, profile, isLoading} = props;
	const open = Boolean(anchorEl);

	const [fullName, setFullName] = useState("Usuario Genérico");
	const [completed, setCompleted] = React.useState(0);

	useEffect(() => {
    const session = JSON.parse(localStorage.getItem('session'));
    const { firstName, lastName } = session.data || {};
    setFullName(`${firstName} ${lastName}`);
  }, []);

  useEffect(() => {
    function progress() {
      setCompleted(oldCompleted => {
        if (oldCompleted === 100) {
        	setCompleted(100);
          return clearInterval(timer);
        }
        const diff = Math.random() * 10;
        return Math.min(oldCompleted + diff, 100);
      });
    }
    const timer = isLoading && setInterval(progress, 500);
    return () => {
      clearInterval(timer);
    };
  }, [isLoading]);

	return(
		<Fragment>
			{ isLoading &&
				<div className={classes.progressBar}>
					<ColorLinearProgress
						variant="determinate"
						value={completed}
					/>
				</div>
			}
			<AppBar position="fixed" className={ classes.appBar }>
			  <Toolbar>
				  <IconButton
			    	color="inherit"
			    	aria-label="Open drawer"
			    	onClick={ toggleDrawer('left', true) }
			    	className={ classes.menuButton }
			    >
				    <MenuIcon />
				  </IconButton>
				  <img src={ Logo } alt="Kool Tech S.A. de C.V." className={classes.logo} />
				  <Typography variant="h5" color="inherit" noWrap className={classes.companyName}>
				  </Typography>
					<div className={classes.userSection}>
						<IconButton
							aria-owns={open ? 'material-appbar' : undefined}
							aria-haspopup="true"
							onClick={ handleClick }
							color="inherit"
							className={ classes.userButton }
						>
							<Avatar
								alt="Profile"
								src={ profile }
							/>
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={ anchorEl }
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={open}
							onClose={ handleClose }
							className={ classes.appBarMenu }
						>
							<List className={ classes.userInfo }>
								<ListItem alignItems="flex-start">
									<div onClick={ handleAvatar } className={ classes.imgContainer } >
										<Avatar
											alt="Profile"
											src={ profile }
											className={ classes.image }
											style={{ width: "100%"}}
										>
										</Avatar>
										<div className={ classes.middle }>
											Cambiar
										</div>
									</div>
									<ListItemText
										primary={
											<Typography
												component="span"
												color="textPrimary"
											>
												<strong>{ fullName }</strong>
											</Typography>
										}
										secondary={
											<Fragment>
												<Typography
													component="span"
													color="textPrimary"
												>
												</Typography>
												<Link href="#">
													Políticas de Privacidad
												</Link>
												<Button
                          							component={RouterLink} to="/MyAccount"
													size="small"
													color="primary"
													className={ classes.fabBlue }
													fullWidth
												>
													Mi Cuenta
												</Button>
											</Fragment>
										}
									/>
								</ListItem>
								<Divider />
								<ListItem alignItems="flex-start">
									<Grid container spacing={2}>
										<Grid item xs={6} >
											<Button 
												component={RouterLink} to="/Preferences"
												size="small"
												className={ classes.fabGreen }
												fullWidth
											>
												Preferencias
											</Button>
										</Grid>
										<Grid item xs={6} >
											<Button
												size="small"
												color="secondary"
												className={ classes.fabPink }
												fullWidth
												onClick={ handleLogout}
											>
												Cerrar Sesión
											</Button>
										</Grid>
									</Grid>
								</ListItem>
							</List>
						</Menu>

					</div>
			  </Toolbar>
			</AppBar>
		</Fragment>
	)
}

const mapStateToProps = state => ({
	profile: state.profile.image,
	isLoading: state.progress.isLoading
});

export default withStyles(styles)(connect(mapStateToProps)(RenderAppBar));
