import React, { Fragment, useEffect, useMemo } from "react";
import { connect } from 'react-redux';

import AlarmOffIcon from '@material-ui/icons/AlarmOffOutlined';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import Module from '../../_presentationals/Module';
import { TextField, SelectField } from './../../_presentationals/Form';

import { recordsActions, openFormDialog, editValueAction } from '../../_actions';
import { addressFormat } from '../../_helpers';
import UnjustifiedDialog from './unjustifiedDialog';
import BalanceDialog from './balanceDialog';

const Branch = ({ getRecords, clearRecords, openFormDialog, editValueAction, ...props }) => {

  const { countryId } = props.fields || {};

  useEffect(() => { getRecords({ table: 'countries' }); return () => clearRecords('countries'); }, [getRecords, clearRecords]);

  const countries = useMemo(() => {
    return props.countries.count > 0 ? props.countries.rows.map(({id, name}) => ({ key: id, value: name })) : [];
  }, [props.countries]);

  useEffect(() => { getRecords({ table: 'states', countryId }); return () => clearRecords('states'); }, [getRecords, countryId, clearRecords]);

  const states = useMemo(() => {
    return props.states.count > 0 ? props.states.rows.map(({id, name}) => ({ key: id, value: name })) : [];
  }, [props.states]);

  const branches = useMemo(() => 
    props.branches.count > 0 ? props.branches.rows.map(el => ({
      ID: { 
        value: el.id,
        show: false,
        data: {
          phone1Id: el.BranchPhones[0].id,
          phone1: el.BranchPhones[0].phone,
          addressLine1: el.BranchAddress.addressLine1,
          addressLine2: el.BranchAddress.addressLine2,
          city: el.BranchAddress.city,
          zipCode: el.BranchAddress.zipCode,
          reference: el.BranchAddress.zipCode,
          countryId: el.BranchAddress.countryId,
          stateId: el.BranchAddress.stateId,
          phone2Id: (el.BranchPhones && el.BranchPhones[1]) && el.BranchPhones[1].id,
          phone2: (el.BranchPhones && el.BranchPhones[1]) && el.BranchPhones[1].phone,
          ...el
        }
      }, 
      Nombre: {
       value: el.name 
      },
      Domicilio: { 
        value: addressFormat(el.BranchAddress)
      },
      Teléfono:{
        value: el.BranchPhones[0].phone
      }
    })) : []
  , [props.branches]);

  const handleAction = e => {
    const data = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id: data.id, unjustifiedDiscount: data.unjustifiedDiscount });
    openFormDialog("unjustifiedDialog");
  }

  const handleActionBalance = e => {
    const data = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id: data.id, beginningBalance: data.beginningBalance });
    openFormDialog("balanceDialog");
  }

  return (
    <Fragment>
    <Module
      table="branches"
      name="sucursales"
      singularName="sucursal"
      filter={false}
      title="Crear Sucursal"
      records={branches}
      actions={[{
        icon: <AlarmOffIcon fontSize="small" />,
        label: "Falta Injustificada",
        handle: handleAction
      },{
        icon: <AccountBalanceWalletIcon fontSize="small" />,
        label: "Saldo Inicial",
        handle: handleActionBalance
      }]}
    >
      <TextField
        icon="domain"
        fields={[{
          id: "name", 
          label: "Nombre",
          autoFocus: true
        }]}
      />
      <TextField
        icon="phone"
        fields={[{
          id: "phone1", 
          label: "Teléfono Fijo"
        }]}
      />
      <TextField
        fields={[{
          id: "phone2", 
          label: "Teléfono Móvil",
          required: false
        }]}
      />
      <TextField
        icon="room"
        fields={[
          {
            id: "addressLine1", 
            label: "Dirección 1",
            multiline: true 
          },
          {
            id: "addressLine2", 
            label: "Dirección 2",
            multiline: true,
            required: false
          }
        ]}
      />
      <TextField
        fields={[
          {
            id: "city", 
            label: "Ciudad"
          },
          {
            id: "zipCode", 
            label: "Código Postal"
          }
        ]}
      />
      <SelectField
        fields={[{
          id: 'countryId', 
          label: 'País',
          options: countries
        }]}
      />
      <SelectField
        fields={[{
          id: 'stateId', 
          label: 'Estado',
          options: states,
        }]}
      />
      <TextField
        fields={[{
          id: "reference", 
          label: "Referencia",
          multiline: true,
          required: false
        }]}
      /> 
      <TextField
        icon="message"
        fields={[{
          id: "description", 
          label: "Comentarios",
          multiline: true,
          required: false
        }]}
      /> 
      <TextField
        fields={[{
          id: "price1", 
          label: "Precio 2 horas",
          required: false
        }]}
      /> 
      <TextField
        fields={[{
          id: "price2", 
          label: "Precio 4 horas",
          required: false
        }]}
      /> 
      <TextField
        fields={[{
          id: "price3", 
          label: "Precio 6 horas",
          required: false
        }]}
      /> 
      <TextField
        fields={[{
          id: "price4", 
          label: "Precio 8 horas",
          required: false
        }]}
      /> 
    </Module>
    <UnjustifiedDialog />
    <BalanceDialog />
    </Fragment>
  );

}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  records: state.records,
  fields: state.field.value,
  states: state.records.states || defaultTableValue,
  branches: state.records.branches || defaultTableValue,
  countries: state.records.countries || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  openFormDialog: data => dispatch(openFormDialog(data)),
  clearRecords: value => dispatch(recordsActions.clearRecords(value)),
  editValueAction: data => dispatch(editValueAction(data))
});

export default connect(mapStateToProps,mapDispatchToProps)(Branch);