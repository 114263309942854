import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import { recordsServices } from '../../../_services.v2';
import { getDataFromServer, setSubtitle } from '../../../_actions.v2';
import format from '../../../_presentationals.v2/Table/helpers/Format';

import Header from '../../../_presentationals.v3/Table/Head';

import useStyles from './style';
import ActionButtonComponent from './action.button';
import { newDate } from '../../../_helpers.v2';

const Rows = [
  { key: 'createdAt', label: 'Registro' },
  { key: 'statusLabel', label: 'Estatus' },
  { key: 'receiptLabel', label: 'Just' },
  { key: 'branchName', label: 'Empresa' },
  { key: 'addressLine2', label: 'Colonia' },
  { key: 'trackingDate', label: 'Seguimiento' },
  { key: 'trackingCount', label: 'Cont.' },
];

const TableComponent = props => {

  const classes = useStyles();
  const getData = props.getData;
  const getDataFromServer = props.getDataFromServer;
  const setSubtitle = props.setSubtitle;

  const [data, setData] = useState([]);

  useEffect(() => {
    return () => {
      setSubtitle('');
    }
  }, [setSubtitle]);

  useEffect(() => {
    if (getData) {
      const query = Object.keys(props.filterData).reduce((string, key) => {
        return `${string}&${key}=${props.filterData[key]}`;
      }, '');
      recordsServices.get(`v2/customers/crm?${query}`)
        .then(data => {
          let op = 0;
          let c1 = 0;
          let c2 = 0;
          let c3 = 0;
          let ds = 0;
          const tmpData = data.map(el => {
            switch(parseInt(el.status)) {
              case 1: op = op + 1; break;
              case 2:
                op = op + 1;
                c1 = c1 + 1;
                break;
              case 3:
                op = op + 1;
                c1 = c1 + 1;
                c2 = c2 + 1;
                break;
              case 4:
                op = op + 1;
                c1 = c1 + 1;
                c2 = c2 + 1;
                c3 = c3 + 1;
                break;
              default: break;
            }
            ds = ds + parseFloat(el.ds);
            return {
              ...el,
              createdAt: format(el.createdAt, 'dateTime'),
              trackingDate: format(el.trackingDate, 'date'),
              type: format(el.status, 'customerType'),
              statusLabel: format(el.status, 'customerStatus'),
              background: newDate(el.trackingDate) <= new Date(),
              receiptLabel: format(el.receipt, 'customerReceipt'),
            }
          });
          setData(tmpData);
          getDataFromServer(false);
          setSubtitle(`Op (${op}) | Pr (${c1}) | Cot (${c2}) | Con (${c3}) | Ds (${ds})`);
        })
        .catch(e => console.log(e.message));
    }
  }, [props.filterData, getData, getDataFromServer, setSubtitle]);
  
  return (
    <div className={classes.container}>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader size="small">
          <Header Rows={Rows} />
          <TableBody>
          {
            data.map(el => {
              return (
                <TableRow key={el.id}>
                  {
                    Rows.map(elCh => <TableCell className={el.background ? classes.redBackground : classes.whiteBackground} key={elCh.key}>{el[elCh.key]}</TableCell>)
                  }
                  <TableCell className={el.background ? classes.redBackground : classes.whiteBackground}>
                    <ActionButtonComponent trackingDate={el.trackingDate} id={el.id} status={el.status} />
                  </TableCell>
                </TableRow>);
            })
          }
          </TableBody>
        </Table>
      </TableContainer>
      
    </div>
  );
};

const mapStateToProps = state => ({
  filterData: state.filterDialog[1],
  getData: state.getData,
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: value => dispatch(getDataFromServer(value)),
  setSubtitle: value => dispatch(setSubtitle(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableComponent);
