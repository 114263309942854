import React from 'react';

import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import EventIcon from '@material-ui/icons/EventNoteOutlined';

import IconButton from '../../_presentationals.v2/Form/IconButton';

import { openAlternativeDialog } from '../../_actions.v2';

const useStyles = makeStyles(theme => ({
  paddingX: {
		paddingLeft: theme.spacing(1.6),
		paddingRight: theme.spacing(1.6),
	},
}));

const FilterButtonComponent = (props) => {

  const classes = useStyles();
  
  const handleOpenReport = () => {
    props.openReport();
  };

  return (
    <IconButton 
      label="Reporte por fechas" 
      action={ handleOpenReport } 
      className={ classes.paddingX }
      icon={<EventIcon />}
    />
  )
};

const mapDispatchToProps = dispatch => ({
  openReport: () => dispatch(openAlternativeDialog({})),
});

export default connect(null, mapDispatchToProps)(FilterButtonComponent);
