import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AddCircleIcon from '@material-ui/icons/AddOutlined';
import EventIcon from '@material-ui/icons/EventOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVertOutlined';

import TextField from './../../Form/TextField';
import IconButton from './../../Form/IconButton';

import { openActionDialog, openFilterDialog, editSearchValue } from './../../../_actions.v2';

import Menu from './menu';

import useStyles from './style';

const Component = props => {

	const classes = useStyles();

	const [ openMenu, setOpenMenu ] = useState(null);

	const handleOpenMenu = e => { setOpenMenu(e.currentTarget); }
	const handleCloseMenu = () => { setOpenMenu(null); }
  const handleOpenCreate = () => { props.openForm(); }
  const handleOpenFilter = () => { props.openFilter(); }
  const handleSearch = e => { props.editSearchValue(e.target.value); }

	return (
		<Fragment>
		<Grid container alignItems="center" className={ classes.header } spacing={0}>
			<Grid item md={7} xs={12}>
        <Typography variant="button" display="block">
          { props.title && props.title.toUpperCase() }  ({ props.qty || 0 })
        </Typography>
        { props.subTitle &&
        <Typography variant="caption" display="block">
          { props.subTitle.toUpperCase() }
        </Typography>
        }
      </Grid>
      <Grid container item justifyContent="flex-end" md={5} >
      	<TextField 
          label="Búsqueda"
          variant="outlined"
          margin="dense"
          size="small"
          color="secondary"
          fullWidth={ false }
          onChange={handleSearch}
          className={ classes.marginRight }
          noFormat
        />
        { !props.noCreate &&
        	<IconButton 
        		color="secondary" 
        		label="Crear" 
        		action={ handleOpenCreate } 
        		className={ classes.paddingX }
        		icon={<AddCircleIcon />}
        	/>
        }
      	{ props.filter &&
          <IconButton 
        		label="Filtrar" 
        		action={ handleOpenFilter } 
        		className={ classes.paddingX }
        		icon={<EventIcon />}
        	/>
        }
      	<IconButton 
      		label="Más Opciones" 
      		action={ handleOpenMenu } 
      		className={ classes.paddingX }
      		aria-controls="module-menu" 
	    		aria-haspopup="true" 
      		icon={<MoreVertIcon />}
      	/>
	      <Menu anchorEl={ openMenu } redirect={props.redirect} onClose={ handleCloseMenu } />
      </Grid>
		</Grid>
		</Fragment>
	)
}

const mapDispatchToProps = dispatch => ({
  openForm: () => dispatch(openActionDialog({})),
  openFilter: () => dispatch(openFilterDialog({})),
  editSearchValue: value => dispatch(editSearchValue(value))
})

export default connect(null, mapDispatchToProps)(Component);