// import { isMobile } from "react-device-detect";
export const styles = theme => ({
  bottomNavigation: {
    width: "100%",
    position: 'fixed',
    bottom: 0,
    borderTop: "1px solid #d8d8d8",
    zIndex: theme.zIndex.drawer + 1
  },
  header: {
    // paddingTop: isMobile ? theme.spacing(2) : theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderBottom: '1px solid #e0e0e0',
    zIndex: 1000,
    width: window.innerWidth,
    background: "#fff"
  },
  headerTitle: {
    fontSize: "15px",
    color: "#141414",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  headerButtons: {
    textAlign: "right"
  },
  menuOptions: {
    marginTop: theme.spacing(8)
  },
  spacing: {
    marginRight: theme.spacing(0.5)
  }, 
  toolbar: theme.mixins.toolbar, 
  alternativeToolbar: { 
    ...theme.mixins.toolbar, 
    minHeight: 100 ,
    height: 85
  },
  moreOptions: {
    padding: 7,
  }
});