import React, { useState, useMemo, useEffect } from 'react';

import { connect } from 'react-redux';

import FormDialog from '../../../_presentationals.v2/FormDialog';
import TextField from '../../../_presentationals.v3/FormComponents/TextField';
import SelectField from '../../../_presentationals.v3/FormComponents/SelectField';
import DateField from '../../../_presentationals.v3/FormComponents/DateField';
import Button from '@material-ui/core/Button';

import { recordsServices } from '../../../_services.v2';

import { alertActions, getDataFromServer } from '../../../_actions.v2';

import { countriesSelectField, newDate, statesSelectField } from '../../../_helpers.v2';

import Contract from '../../Customer.v2/contract.helper';

const chooseCompanyOptions = [
  { value: 1, label: 'EDER FERNANDO JIMENEZ GARCÍA'},
  { value: 2, label: 'JOSE CRUZ ROSALES FELIX'},
  { value: 3, label: 'CYNTHIA NALLELY ROSALES VEGA'},
  { value: 6, label: 'MARIA DEL ROSARIO VEGA ESEBERRE'},
  { value: 7, label: 'FERNANDO JIMENEZ RAMIREZ'},
];
const chooseContractTimeOptions = [
  { value: 'TIEMPO INDETERMINADO', label: 'TIEMPO INDETERMINADO'},
  { value: '1 MES', label: '1 MES'},
  { value: '2 MESES', label: '2 MESES'},
  { value: '3 MESES', label: '3 MESES'},
  { value: '4 MESES', label: '4 MESES'},
  { value: '5 MESES', label: '5 MESES'},
  { value: '6 MESES', label: '6 MESES'},
  { value: '7 MESES', label: '7 MESES'},
  { value: '8 MESES', label: '8 MESES'},
  { value: '9 MESES', label: '9 MESES'},
  { value: '10 MESES', label: '10 MESES'},
  { value: '11 MESES', label: '11 MESES'},
  { value: '12 MESES', label: '12 MESES'}
]

const defaultValues = {
  identifier: '',
  businessName: '',
  legalRepresentative: '',
  addressLine1: '',
  addressLine2: '',
  zipCode: '', 
  city: '',
  stateId: 1,
  countryId: 1,
  daysToText: '',
  chooseCompany: 1,
  quantity: '1',
  chooseContractTime: 'TIEMPO INDETERMINADO',
  contractDate: new Date(),
  initialDate: new Date(),
  endDate: new Date(),
};

const FormComponent = props => {
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (props.id && props.isOpen) {
      recordsServices.get(`v2/customers/${props.id}/contract`)
        .then(({ customerId, id, ...data}) => {
          let tmp = {};
          Object.keys(data).forEach(el => {
            if(data[el]){ tmp = { ...tmp, [el]: data[el] }; }
          });
          const startTime = data.Schedule ? data.Schedule.start : '';
          const finishTime = data.Schedule ? data.Schedule.finish : '';
          const daysObject = data && data.Days ? data.Days : {};
          const tmpDays = Object.keys(daysObject)
            .map(key => data.Days[key] && translateDayToSpanish(key))
            .filter(el => el);
          const days = data.Days ? tmpDays.join(', ') : '';
          const daysToText = `LOS DÍAS ${days} DE LAS ${startTime} HORAS A LAS ${finishTime} HORAS.`;
          setValues(values => ({
            ...values,
            contractId: id,
            contractDate: values.contractDate ? newDate(values.contractDate) : new Date(),
            initialDate: values.initialDate ? newDate(values.initialDate) : new Date(),
            endDate: values.endDate ? newDate(values.endDate) : new Date(),
            ...tmp,
            daysToText
          }));
        })
        .catch(e => console.log(e.message));
    }
  }, [props.isOpen, props.id]);

  const states = useMemo(() => statesSelectField(values.countryId), [values.countryId]);

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }
  const handleCreate = async (e, doc) => {
    try {
      setErrors({});
      let error;
      if (values.identifier === '') {
        error = new Error('Proporciona el RFC.')
        error.name = 'identifier';
        throw error;
      }
      if (values.businessName === '') {
        error = new Error('Proporciona una razon social.')
        error.name = 'businessName';
        throw error;
      }
      if (values.legalRepresentative === '') {
        error = new Error('Proporciona el representante legal.')
        error.name = 'legalRepresentative';
        throw error;
      }
      if (values.addressLine1 === '') {
        error = new Error('Proporciona la calle y número.')
        error.name = 'addressLine1';
        throw error;
      }
      if (values.addressLine2 === '') {
        error = new Error('Proporciona la colonia.')
        error.name = 'addressLine2';
        throw error;
      }
      if (values.zipCode === '') {
        error = new Error('Proporciona el código postal.');
        error.name = 'zipCode';
        throw error;
      }
      if (values.city === '') {
        error = new Error('Proporciona la ciudad.');
        error.name = 'city';
        throw error;
      }
      if (values.daysToText === '') {
        error = new Error('Proporciona los días laborales.');
        error.name = 'daysToText';
        throw error;
      }
      if (values.quantity === '') {
        error = new Error('Proporciona el número de mucamas.');
        error.name = 'quantity';
        throw error;
      }
      const url = `customers/${props.id}/contracts`;
      const requestMethod = values.contractId ? 'update' : 'create';
      await recordsServices[requestMethod](url, values);

      if (doc) {
        Contract(values);
        return;
      }
      await recordsServices.update(`crm/3/customer/${props.id}`, {});
      props.getDataFromServer(true);
      props.showAlert({ variant: 'success' });
      props.handleClose();
    } catch(e) {
      console.log(e.message);
      if (['identifier', 'businessName', 'legalRepresentative', 'addressLine1', 'addressLine2', 'zipCode', 'city', 'daysToText', 'quantity'].includes(e.name)){
        setErrors({[e.name]: e.message});
      }
    }
  }
  const handleContract = () => {
    handleCreate(null, true);
  };
  return (
    <FormDialog
      title='Contrato | Paso 3'
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      handleAction={handleCreate}
      actionLabel='Aceptar contrato y finalizar proceso'
    >
      <TextField
				name='identifier'
				label='RFC'
				value={values.identifier}
				onChange={handleChange}
        error={errors.identifier}
			/>
      <TextField
        name='businessName'
        label='Razón Social'
        value={values.businessName}
        onChange={handleChange}
        error={errors.businessName}
      />
      <TextField
        name='legalRepresentative'
        label='Representante Legal'
        value={values.legalRepresentative}
        onChange={handleChange}
        error={errors.legalRepresentative}
      />
      <TextField
        name='addressLine1'
        label='Calle y Número'
        value={values.addressLine1}
        onChange={handleChange}
        error={errors.addressLine1}
      />
      <TextField
        name='addressLine2'
        label='Colonia'
        value={values.addressLine2}
        onChange={handleChange}
        error={errors.addressLine2}
      />
      <TextField
        name='zipCode'
        label='Código Postal'
        value={values.zipCode}
        onChange={handleChange}
        error={errors.zipCode}
      />
      <TextField
        name='city'
        label='Ciudad'
        value={values.city}
        onChange={handleChange}
        error={errors.city}
      />
      <SelectField 
        name='countryId'
        label='País'
        value={values.countryId}
        onChange={handleChange}
        options={countriesSelectField}
        error={errors.countryId}
      />
      <SelectField 
        name='stateId'
        label='Estado'
        value={values.stateId}
        onChange={handleChange}
        options={states}
        error={errors.stateId}
      />
      <TextField
        name='daysToText'
        label='Configuración de Horario'
        value={values.daysToText}
        onChange={handleChange}
        error={errors.daysToText}
      />
      <SelectField 
        name='chooseCompany'
        label='Emisor del Contrato'
        value={values.chooseCompany}
        onChange={handleChange}
        options={chooseCompanyOptions}
        error={errors.chooseCompany}
      />
      <SelectField 
        name='chooseContractTime'
        label='Duración del Contrato'
        value={values.chooseContractTime}
        onChange={handleChange}
        options={chooseContractTimeOptions}
        error={errors.chooseContractTime}
      />
      <DateField 
        name='contractDate'
        label='Fecha de Contrato'
        value={values.contractDate}
        onChange={handleChange}
        error={errors.contractDate}
      />
      <DateField 
        name='initialDate'
        label='Fecha de Inicial'
        value={values.initialDate}
        onChange={handleChange}
        error={errors.initialDate}
      />
      <DateField 
        name='endDate'
        label='Fecha de Final'
        value={values.endDate}
        onChange={handleChange}
        error={errors.endDate}
      />
      <TextField
        name='quantity'
        label='Número de mucamas'
        value={values.quantity}
        onChange={handleChange}
        error={errors.quantity}
      />
      <Button 
        variant="contained" 
        color="secondary" 
        onClick={handleContract} 
        style={{ marginTop: 10, marginBottom: 20 }}
      >
        Descargar Contrato
      </Button>
    </FormDialog>
  );
};

function translateDayToSpanish(day) {
  switch(day) {
    case 'monday':
      return 'LUNES';
    case 'tuesday':
      return 'MARTES';
    case 'wednesday':
      return 'MIÉRCOLES';
    case 'thursday':
      return 'JUEVES';
    case 'friday':
      return 'VIERNES';
    case 'saturday':
      return 'SÁBADO';
    case 'sunday':
      return 'DOMINGO';
    default:
      return '-';
  }
}

const mapDispatchToProps = dispatch => ({
  showAlert: data => dispatch(alertActions.show(data)),
  getDataFromServer: value => dispatch(getDataFromServer(value)),
})

export default connect(null, mapDispatchToProps)(FormComponent);
