import React from "react";

import { Route, Switch } from "react-router-dom";
import { Authenticated } from "./_presentationals/Routes";
import { Unauthenticated } from "./_presentationals/Routes";

import HomeRoute from "./_containers/Home";
import signInRoute from "./_containers/Login";
import forgotPasswordByEmail from "./_containers/Login/forgotPassword";
import MyAccount from "./_containers/MyAccount";
import Preferences from "./_containers/Preferences";
import NotFound from "./_containers/NotFound";

import Branch from "./_containers/Branch";
import User from "./_containers/User";
import Country from "./_containers/Country";
import State from "./_containers/State";

import HouseMaid from "./_containers/HouseMaid";
import Customer from "./_containers/Customer";
import Assignment from "./_containers/Assignment";
import CheckIn from "./_containers/CheckIn";
import Expenses from "./_containers/Expenses";
import Receivable from "./_containers/Receivable";
import Balance from "./_containers/Balance";
import FilipinasCustomer from "./_containers/filipinasCustomer";
import CheckInReport from "./_containers/CheckInReport";
import ReceivableReport from "./_containers/ReceivableReport";
import MonthProductivity from "./_containers/MonthProductivity";
import CustomerV2 from "./_containers/Customer.v2";
import CheckInV2 from "./_containers/CheckIn.v2";
import CheckIn15Component from "./_containers/CheckIn15";
import CheckIn14Component from "./_containers/CheckIn14";
import Substitution from "./_containers/Substitution";
import Bonus from "./_containers/Bonus";
import UtilityReport from "./_containers/UtilityReport";
import ReceivableV2 from "./_containers/Receivable.v2";
import CustomerChargeReport from "./_containers/CustomerChargeReport";
import ExpensesV2 from "./_containers/Expenses.v2";
import HouseMaidV2 from "./_containers/HouseMaid.v2";
import Supervision from "./_containers/Supervision";
import Reckoning from "./_containers/Reckoning";
import SalaryReport from "./_containers/SalaryReport";
import BenefitReport from "./_containers/BenefitReport";
import CrmComponent from "./_containers/Crm";

const RoutesComponent = () =>
  <Switch>
    <Authenticated path="/" exact component={HomeRoute} />
    <Unauthenticated path="/public/signIn" component={signInRoute} />
    <Unauthenticated path="/public/forgotPassword/email" component={forgotPasswordByEmail} />
    <Authenticated path="/Branch" component={Branch} />
    <Authenticated path="/User" component={User} />
    <Authenticated path="/MyAccount" component={MyAccount} />
    <Authenticated path="/Preferences" component={Preferences} />
    <Authenticated path="/Country" exact component={Country} />
    <Authenticated path="/Country/State" component={State} />
    <Authenticated path="/HouseMaid" exact component={HouseMaid} />
    <Authenticated path="/Customer" component={Customer} />
    <Authenticated path="/Assignment" component={Assignment} />
    <Authenticated path="/CheckIn/report" component={CheckInReport} />
    <Authenticated path="/CheckIn" exact component={CheckIn} />
    <Authenticated path="/CheckIn/15" exact component={CheckIn15Component} />
    <Authenticated path="/CheckIn/14" exact component={CheckIn14Component} />
    <Authenticated path="/Expenses" component={Expenses} />
    <Authenticated path="/Receivable/report" component={ReceivableReport} />
    <Authenticated path="/Receivable" component={Receivable} />
    <Authenticated path="/Balance" component={Balance} />
    <Authenticated path="/Filipina/Customer" component={FilipinasCustomer} />
    
    <Authenticated path="/v2/customers" component={CustomerV2} />
    
    <Authenticated path="/v2/checkIn" component={CheckInV2} />
    
    <Authenticated path="/substitution" component={Substitution} />
    <Authenticated path="/bonus" component={Bonus} />
    <Authenticated path="/Productivity/Month" component={MonthProductivity} />
    <Authenticated path="/checkIn/utility" component={UtilityReport} />
    <Authenticated path="/v2/receivable" component={ReceivableV2} />
    <Authenticated path="/customers/charge/report" component={CustomerChargeReport} />
    <Authenticated path="/v2/expenses" component={ExpensesV2} />
    <Authenticated path="/v2/houseMaid" component={HouseMaidV2} />
    <Authenticated path="/supervision" component={Supervision} />
    <Authenticated path="/reckoning" component={Reckoning} />
    <Authenticated path="/houseMaid/salary/report" component={SalaryReport} />
    <Authenticated path="/houseMaid/benefit/report" component={BenefitReport} />
    <Authenticated path="/crm" component={CrmComponent} />
    
    <Route component={NotFound} />
  </Switch>;

  export default RoutesComponent