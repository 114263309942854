import React, { useEffect, useState, Fragment } from "react";
import { connect } from 'react-redux';

import GetAppIcon from '@material-ui/icons/GetAppOutlined';
import SearchIcon from '@material-ui/icons/SearchOutlined';

import { withStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';

import LocalHospitalIcon from '@material-ui/icons/LocalHospitalOutlined';

import { styles } from './style';
import ConfigDialog from './configDialog';
import HealthInsuranceDialog from './healthInsuranceDialog';
import { openFormDialog, editValueAction, resetFormAction } from '../../_actions';
import { JsonToXls } from '../../_presentationals.v2/XLS';
import TablePagination from '../../_presentationals.v2/Table/TablePagination';

import { currencyFormat } from '../../_helpers';
import { nameOfTheDay } from '../CheckIn/helpers.js';
import { Table, CustomerCell, HeaderCell, Button } from '../CheckIn/_components.js';
import { recordsServices } from '../../_services.v2/records.services';  
import { Link } from './_components.js';

import SearchDialog from './search.dialog';
import GeneralBenefitsDialogComponent from "./GeneralBenefitsDialog/index.js";

const Assignment = ({ classes, openFormDialog, editValueAction, resetFormAction, alert }) => {

	const [day, setDay] = useState("");
	const [assignments, setAssignments] = useState({count: 0, rows: []});
	const [customers, setCustomers] = useState({count: 0, rows: []});
  const [ limit, setLimit ] = useState(25);
  const [ offset, setOffset ] = useState(0);
  const [ search, setSearch ] = useState('');
  const [ isOpenSearch, setIsOpenSearch ] = useState(false);

	useEffect(() => {
    recordsServices.get(`customers`).then(data => setCustomers(data));
	}, []);

  useEffect(() => {
    recordsServices.get(`assignments/?limit=${limit}&offset=${offset}&search=${search}`).then(data => setAssignments(data));
  }, [limit, offset, search]);
	
	useEffect(() => {
    if(alert === 'success'){
      recordsServices.get(`assignments/?limit=${limit}&offset=${offset}&search=${search}`).then(data => {
        setAssignments(data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert]); 

	const handleNewAssignment = e => {
		setDay(nameOfTheDay[e.day]);
		resetFormAction();
		editValueAction({ houseMaidLabel: e.houseMaid, day: e.day, maidId: e.houseMaidId });
		openFormDialog('ConfigDialog');
	}

	const handleClick = e => {
		
		setDay(nameOfTheDay[e.day]);
		editValueAction({ 
			id: e.houseMaidId,
			houseMaidLabel: e.houseMaid, 
			customerId: e.customerId, 
			payment: e.payment, 
			iva: e.iva, 
			supplies: e.supplies, 
			salary: e.salary, 
			start: e.start, 
			finish: e.finish, 
			update: true, 
			maidId: e.houseMaidId, 
			day: e.day
		});
		openFormDialog('ConfigDialog');
	}

	const handleAction = ({ id, healthInsurance, taxBenefits, additionalBenefits, infonavit, status, startBenefit, finishBenefit, movementType }) => {
    editValueAction({ id, healthInsurance, taxBenefits, additionalBenefits, startBenefit, finishBenefit, infonavit, status, updateBenefits: true, movementType: movementType || '1' });
    openFormDialog('healthInsurance');
  }
  
  const handleDownload = async e => {
    try {
      const assignments = await recordsServices.get(`assignments/?limit=1000&offset=0&search=`);
      
      const keys = [
        { key: 'maid', label: 'Mucama' },
        { key: '1', label: 'Lunes' },
        { key: '2', label: 'Martes' },
        { key: '3', label: 'Miercoles' },
        { key: '4', label: 'Jueves' },
        { key: '5', label: 'Viernes' },
        { key: '6', label: 'Sábado' },
        { key: '7', label: 'Domingo' },
      ];
      const data = assignments.rows.map(el => {
        const monday = el.Days.find(elCh => elCh.day === "1");
        const tuesday = el.Days.find(elCh => elCh.day === "2");
        const wednesday = el.Days.find(elCh => elCh.day === "3");
        const thursday = el.Days.find(elCh => elCh.day === "4");
        const friday = el.Days.find(elCh => elCh.day === "5");
        const saturday = el.Days.find(elCh => elCh.day === "6");
        const sunday = el.Days.find(elCh => elCh.day === "7");
        return {
          '7': (sunday && sunday.Customer) ? sunday.Customer.name : '-',
          '6': (saturday && saturday.Customer) ? saturday.Customer.name : '-',
          '5': (friday && friday.Customer) ? friday.Customer.name : '-',
          '4': (thursday && thursday.Customer) ? thursday.Customer.name : '-',
          '3': (wednesday && wednesday.Customer) ? wednesday.Customer.name : '-',
          '2': (tuesday && tuesday.Customer) ? tuesday.Customer.name : '-',
          '1': (monday && monday.Customer) ? monday.Customer.name : '-',
          maid: el.name,
        }
      });
      JsonToXls('Colocaciones', keys, data);
    }catch(e) {
      console.log(e.message)
    }
    
  }

  const handleRows = (offset, limit) => {
    setLimit(limit);
    setOffset(offset);
  }

  const handleOpenSearch = () => {
    setIsOpenSearch(isOpenSearch => !isOpenSearch);
  }

  const handleSearch = value => {
    setSearch(value);
  };

  const handleOpenGeneralBenefits = () => {
	editValueAction({ generalBenefits: true, id: -1 });
	openFormDialog('generalBenefits');
  }

  console.log(assignments.rows[0])
	return (
		<Fragment>
		<Table>
    	<TableHead>
    		<TableRow>
    			<CustomerCell>
            Mucama
            <IconButton onClick={handleOpenSearch} aria-label="Abrir buscador">
              <SearchIcon size='small' />
            </IconButton>
          </CustomerCell>
    			<HeaderCell align="center">Lunes</HeaderCell>
    			<HeaderCell align="center">Martes</HeaderCell>
    			<HeaderCell align="center">Miercoles</HeaderCell>
    			<HeaderCell align="center">Jueves</HeaderCell>
    			<HeaderCell align="center">Viernes</HeaderCell>
    			<HeaderCell align="center">Sábado</HeaderCell>
    			<HeaderCell align="center">Domingo</HeaderCell>
    			<TableCell style={{display: 'flex', flexDirection: 'row'}}>
    				<IconButton onClick={handleDownload} aria-label="Descargar XLS">
    					<GetAppIcon size='small' />
    				</IconButton>
    				<IconButton onClick={handleOpenGeneralBenefits} aria-label="Seguro e infonavit">
    					<LocalHospitalIcon size='small' />
    				</IconButton>
    			</TableCell>
    		</TableRow>
    	</TableHead>
    	<TableBody>
    		{ Object.keys(assignments.rows).map(key => 
  				<TableRow key={assignments.rows[key].id}>
  					<TableCell>
					<div>{ assignments.rows[key].name }</div>
					<div style={{fontSize: 10}}>Cuota (Seguro): ${ assignments.rows[key].healthInsurance }</div>
					<div style={{fontSize: 10}}>Prestaciones con altas: ${ assignments.rows[key].additionalBenefits }</div>
					<div style={{fontSize: 10}}>Prestaciones con bajas: ${ assignments.rows[key].taxBenefits }</div>
					<div style={{fontSize: 10, color: !assignments.rows[key].status && assignments.rows[key].movementType === '2' && '#8b0000'}}>Infonavit: ${ assignments.rows[key].infonavit }</div>
					<div style={{fontSize: 10, color: !assignments.rows[key].status && assignments.rows[key].movementType === '2' && '#8b0000'}}>Movimiento: { assignments.rows[key].movementType === '1' ? 'Alta': assignments.rows[key].movementType === '2' ? 'Baja' : 'Alta fija' }</div>
					{assignments.rows[key].movementType === '2' &&<div style={{fontSize: 10, color: !assignments.rows[key].status && assignments.rows[key].movementType === '2' && '#8b0000'}}>{ assignments.rows[key].status ? 'Estrategia' : 'Liquidación' }</div>}
					{!assignments.rows[key].status && assignments.rows[key].movementType === '2' && <div style={{fontSize: 10, color: assignments.rows[key].movementType === '2' && '#8b0000'}}>Fecha de liquidación: { assignments.rows[key].finishBenefit }</div>}
					</TableCell>
  					{ Object.keys(nameOfTheDay).map(day => {
							const selectedDays = assignments.rows[key].Days.filter(el => parseInt(day) === parseInt(el.day) && el);
							return (
								<TableCell key={day} align="center">
  								<Link
    								variant="body2"
    								day={day} 
  									houseMaid={assignments.rows[key].name}
  									houseMaidId={assignments.rows[key].id}
    								onClick={handleNewAssignment}
    								label="+ Agregar"
    							/>
    							<br/>
    							{selectedDays.map(el =>
	    							<Fragment key={el.customerId}>
	    								<Link
	      								day={day} 
	    									houseMaid={assignments.rows[key].name}
	    									houseMaidId={assignments.rows[key].id}
	    									customerId={el.customerId}
	    									payment={el.payment}
	    									iva={el.iva}
	    									supplies={el.supplies}
	    									salary={el.salary}
	    									start={el.start}
	    									finish={el.finish}
  											onClick={handleClick}
  											label={el.Customer.name}
	      							/>
	      							<br />
	    								(C){currencyFormat(parseFloat(el.payment) + parseFloat(el.supplies) + parseFloat(el.iva))}MXN
	    								<br/>
	    								(P){currencyFormat(el.salary)}MXN
	    								<br/>
	    							</Fragment>
		    					)}
	    					</TableCell> 
							)
  					})}
  					<TableCell align="right">
  						<Button 
  							title="Seguro e Infonavit"  
  							icon={<LocalHospitalIcon />}
  							onClick={ handleAction }
  							id={ assignments.rows[key].id }
  							status={ assignments.rows[key].status }
  							healthInsurance={ assignments.rows[key].healthInsurance }
  							taxBenefits={ assignments.rows[key].taxBenefits }
  							additionalBenefits={ assignments.rows[key].additionalBenefits }
  							infonavit={ assignments.rows[key].infonavit }
  							startBenefit={ assignments.rows[key].startBenefit }
  							finishBenefit={ assignments.rows[key].finishBenefit }
  							movementType={ assignments.rows[key].movementType }
  						/>
  					</TableCell>
  				</TableRow>
    		)}
    	</TableBody>
    </Table>
    <TablePagination count={assignments.count} handleRows={handleRows} />
    <ConfigDialog customers={customers} day={day} />
    <HealthInsuranceDialog />
    <GeneralBenefitsDialogComponent />
    <SearchDialog isOpen={isOpenSearch} handleClose={handleOpenSearch} handleClick={handleSearch} />
    </Fragment>
	);
}

const mapStateToProps = state => ({
  alert: state.alert.type
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: data => dispatch(openFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data)),
  resetFormAction: () => dispatch(resetFormAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Assignment));