import branches from './branches';
import states from './states';
import countries from './countries';
import users from './users';
import maids from './maids';
import customers from './customers';
import assignments from './assignments';
import checkIns from './checkIns';
import expenses from './expenses';
import filipinas from './filipinas';
import filipinasStatus from './filipinas.status';
import filipinasConfig from './filipinas.config';
import checkInsReport from './checkInsReport';
import ReceivableReport from './ReceivableReport';

export const chooseUrl = {
	states, 
	countries, 
	users, 
	branches,
	maids,
	customers,
	assignments,
	checkIns,
	expenses,
	filipinas,
	filipinasStatus,
	filipinasConfig,
	checkInsReport,
	ReceivableReport
}
