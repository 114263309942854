import { actionDialogConstants } from '../_constants.v2';

const openActionDialog = data => ({ type: actionDialogConstants.OPEN_FORM_DIALOG, payload: data });
const closeActionDialog = () => ({ type: actionDialogConstants.CLOSE_FORM_DIALOG });

const openFilterDialog = data => ({ type: actionDialogConstants.OPEN_FILTER_DIALOG, payload: data });
const closeFilterDialog = () => ({ type: actionDialogConstants.CLOSE_FILTER_DIALOG });

const setFilterData = (data) => ({ type: actionDialogConstants.SET_FILTER_DATA, payload: data });

const openAlternativeDialog = data => ({ type: 'OPEN_ALTERNATIVE_DIALOG', payload: data });
const closeAlternativeDialog = () => ({ type: 'CLOSE_ALTERNATIVE_DIALOG' });
	
export { openActionDialog, closeActionDialog, openFilterDialog, closeFilterDialog, setFilterData, openAlternativeDialog, closeAlternativeDialog }