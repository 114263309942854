import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';

import { recordsServices } from './../../_services.v2';
import { getDataFromServer } from './../../_actions.v2';

import Module from './../../_presentationals.v2/Module';

import ActionForm from './action.form';
import { newDate } from '../../_helpers.v2';

const Rows = [
	{ label: 'Asistencia', key: 'CheckIn', sort: 'date', format: 'date' }, 
	{ label: 'Mucama', key: 'Maid', sort: 'text', filter: true }, 
	{ label: 'Cliente', key: 'Customer', sort: 'text', filter: true }, 
	{ label: 'Sueldo', key: 'salary', format: 'currency', align: 'right' }, 
	{ label: 'Cobro', key: 'payment', format: 'currency', align: 'right' }, 
	{ label: 'Insumos', key: 'supplies', format: 'currency', align: 'right' }, 
	{ label: 'IVA', key: 'iva', format: 'currency', align: 'right' }, 
]; 

const Component = ({ getData, ...props }) => {

	const [ values, setValues ] = useState([]);

	useEffect(() => {
		getData && recordsServices.get('substitutions')
			.then(data => {
				const tmpData = data.map(el => {
					return {
					...el,
					CheckIn: el.CheckIn ? newDate(el.CheckIn.date) : '1990-01-01T00:00:00',
					Maid: el.Maid ? el.Maid.name : '-',
					Customer: el.Customer ? el.Customer.name : '-'
				}});
				setValues(tmpData);
        props.getDataFromServer(false);
			})
			.catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
    <Fragment>
  		<Module 
  			route='substitutions'
        title='Suplencias'
        singular='Suplencia'
        Rows={Rows}
        noEdit
        records={values}
      />
      <ActionForm />
    </Fragment>
	);
};

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: value => dispatch(getDataFromServer(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component); 