import React from 'react';

import { connect } from 'react-redux';

import useStyles from './style';
import CreateButtonComponent from './create.button';
import FilterButtonComponent from './filter.button';

const HeaderComponent = props => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <div className={classes.title}>{props.title}</div>
        <div className={classes.subTitle}>{props.headerSubtitle}</div>
      </div>
      <div>
        <CreateButtonComponent />
        <FilterButtonComponent />
        { props.moreButtons && props.moreButtons.map(el => el) }
      </div>
    </div>)
};

const mapStateToProps = state => ({
  headerSubtitle: state.header.subtitle,
})

export default connect(mapStateToProps)(HeaderComponent);
