import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';

import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import GradeIcon from '@material-ui/icons/GradeOutlined';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import StorefrontIcon from '@material-ui/icons/Storefront';
import TimelineIcon from '@material-ui/icons/Timeline';
import SettingsIcon from '@material-ui/icons/Settings';

import { recordsServices } from './../../_services.v2';
import { getDataFromServer } from './../../_actions.v2';

import Module from './../../_presentationals.v2/Module';

import HistoryForm from './history.form';
import ConfigForm from './config.form';

const Rows = [
	{ label: 'Cliente', key: 'name', sort: 'text', filter: true }, 
  { label: 'Apreciación', key: 'appreciationScore', sort: 'number', format: 'balance' },
  { label: 'Colaborador', key: 'collaboratorScore', sort: 'number', format: 'balance' },
  { label: 'Cliente', key: 'customerScore', sort: 'number', format: 'balance' },
  { label: 'Promedio', key: 'avg', sort: 'number', format: 'balance' },
  { label: 'Fecha', key: 'createdAt', sort: 'date', format: 'dateTime' },
]; 

const scores = [
  { label: 1, value: '1' },
  { label: 2, value: '2' },
  { label: 3, value: '3' },
  { label: 4, value: '4' },
  { label: 5, value: '5' },
  { label: 6, value: '6' },
  { label: 7, value: '7' },
  { label: 8, value: '8' },
  { label: 9, value: '9' },
  { label: 10, value: '10' },
];

const defaultHistoryValue = [ false, { id: null, data: [] } ];
const defaultConfigValue = [ false, { id: null, supervisionDay: 1 } ];

const Component = ({ getData, ...props }) => {

	const [ values, setValues ] = useState([]);
	const [ customers, setCustomers ] = useState([]);
  const [ openHistoryForm, setOpenHistoryForm ] = useState(defaultHistoryValue);
  const [ openConfigForm, setOpenConfigForm ] = useState(defaultConfigValue);

	// useEffect(() => {
	// 	recordsServices.get('customers')
	// 		.then(data => {
	// 			const tmp = data.rows.map(el => ({ value: el.id, label: el.name }));
	// 			setCustomers(tmp);
	// 		})
	// 		.catch(e => console.log(e.message));
	// }, []);

	useEffect(() => {
		getData && recordsServices.get('supervisions')
			.then(data => {
        const tmpData = data.map(el => ({ ...el, name: `${el.name} - ${el.branchName}` }));
				setValues(tmpData);
        const tmp = data.map(el => ({ value: el.id, label: `${el.name} - ${el.branchName}` }));
        setCustomers(tmp);
        props.getDataFromServer(false);
			})
			.catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleHistory = (e, values) => {
    recordsServices.get(`supervisions/customers/${values.id}`)
      .then(data => {
        setOpenHistoryForm([ true, { id: values.id, data } ]); 
      })
      .catch(e => console.log(e.message));
  }

  const closeHistoryForm = () => { setOpenHistoryForm(defaultHistoryValue); };

  const handleConfig = (e, values) => {
    recordsServices.get(`customers/${values.id}`)
      .then(data => {
        setOpenConfigForm([ true, { id: values.id, supervisionDay: !isNaN(parseInt(data.supervisionDay)) ? data.supervisionDay : 1 } ]); 
      })
      .catch(e => console.log(e.message));
  }

  const closeConfigForm = () => { setOpenConfigForm(defaultConfigValue); };

	return (
    <Fragment>
  		<Module 
  			route='supervisions'
        title='Supervisión'
        singular='supervisión'
        Rows={Rows}
        noEdit
        noDelete
        records={values}
        actions={[{
          label: 'Historial',
          icon: <TimelineIcon fontSize='small' />,
          onClick: handleHistory
        },{
          label: 'Configuración',
          icon: <SettingsIcon fontSize="small" />,
          onClick: handleConfig
        }]}
        fields={[
        	{
        		name: 'customerId',
        		icon: <PersonOutlineIcon />,
        		label: 'Cliente',
        		options: customers,
        		field: 'autocomplete'
        	},{
            icon: <GradeIcon />,
        		name: 'appreciationScore',
        		label: 'Apreciación',
        		options: scores,
            field: 'select'
        	},{
        		name: 'appreciationDescription',
        		label: 'Comentarios para Apreciación'
        	},{
            icon: <WorkOutlineIcon />,
            name: 'collaboratorScore',
            label: 'Satisfacción de Colaborador',
            options: scores,
            field: 'select'
          },{
            name: 'collaboratorDescription',
            label: 'Comentarios para Colaborador'
          },{
            icon: <StorefrontIcon />,
            name: 'customerScore',
            label: 'Satisfacción de Cliente',
            options: scores,
            field: 'select'
          },{
            name: 'customerDescription',
            label: 'Comentarios para Cliente'
          }
        ]}
      />

      <HistoryForm 
        isOpen={openHistoryForm[0]} 
        values={openHistoryForm[1]} 
        handleClose={closeHistoryForm}
      />

      <ConfigForm 
        isOpen={openConfigForm[0]} 
        values={openConfigForm[1]} 
        handleClose={closeConfigForm}
      />

    </Fragment>

	);
};

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: value => dispatch(getDataFromServer(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component); 