import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { recordsServices } from './../../_services.v2';
import { getDataFromServer } from './../../_actions.v2';

import Module from './../../_presentationals.v2/Module';
import { newDate } from '../../_helpers.v2';

const Rows = [
	{ label: 'Fecha', key: 'CheckIn', sort: 'date', format: 'date' }, 
	{ label: 'Mucama', key: 'Maid', sort: 'text', filter: true }, 
	{ label: 'Bono', key: 'total', format: 'currency', align: 'right' }
]; 

const Component = ({ getData, ...props }) => {

	const [ values, setValues ] = useState([]);
	const [ maids, setMaids ] = useState([]);

	useEffect(() => {
		recordsServices.get('maids')
			.then(data => {
				const tmp = data.rows.map(el => ({ value: el.id, label: el.name }));
				setMaids(tmp);
			})
			.catch(e => console.log(e.message));
	}, []);

	useEffect(() => {
		getData && recordsServices.get('bonus')
			.then(data => {
				const tmpData = data.map(el => ({
					...el,
					CheckIn: newDate(el.CheckIn.date),
					Maid: el.Maid ? el.Maid.name : '-',
				}));
				setValues(tmpData);
        props.getDataFromServer(false);
			})
			.catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Module 
			route='bonus'
      title='Bonos'
      singular='Bono'
      Rows={Rows}
      noEdit
      records={values}
      fields={[
      	{
      		name: 'maidId',
      		label: 'Mucama',
      		options: maids,
      		field: 'autocomplete'
      	},
      	{
      		name: 'total',
      		label: 'Bono',
      		type: 'number'
      	},
      	{
      		name: 'date',
      		label: 'Fecha',
      		field: 'date'
      	}
      ]}
    />
	);
};

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: value => dispatch(getDataFromServer(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component); 