import React, { useEffect, useState } from 'react';

import format from 'date-fns/format';

import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';

import FilterDrawer from '../../../_presentationals.v3/FilterDrawer';
import Checkbox from '../../../_presentationals.v3/FormComponents/Checkbox';
import Autocomplete from '../../../_presentationals.v3/FormComponents/Autocomplete';
import SelectField from '../../../_presentationals.v3/FormComponents/SelectField';

import { setFilterData as setFilterDataAction, getDataFromServer as getDataFromServerAction } from '../../../_actions.v2';
import { recordsServices } from '../../../_services';

import { makeStyles } from '@material-ui/core/styles';

const quadrantOptions = [
  { value: 5, label: 'Todas' },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
];

const receiptOptions = [
  { value: 1, label: 'Personal interno' },
  { value: 2, label: 'En proceso' },
  { value: 3, label: 'Reagendar' },
  { value: 4, label: 'Precio alto' },
  { value: 5, label: 'Contrato vigente' },
];

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0, 1, 0),
  },
}));

const defaultValues = {
  status: ['1', '2', '3', '4'],
  isDefeated: false,
  user: null,
  quadrant: 5,
  receipt: '',
}

const FilterComponent = props => {
  const classes = useStyles();
  const { setFilterData } = props;
  const [values, setValues] = useState(defaultValues);
  const [userOptions, setUserOptions] = useState([]);
  useEffect(() => {
    return () => { setFilterData({}); };
  }, [setFilterData]);
  useEffect(() => {
    recordsServices.getAll({ table: 'users' }).then(data => {
      const usersData = data.count > 0 ? data.rows.map(el => ({ value: el.id, label: `${el.firstName} ${el.lastName}` })) : [];
      setUserOptions(usersData);
    });
  }, []);
  const handleAction = (e, data) => {
    const start = format(data.startDate, 'yyyy/MM/dd');
    const end = format(data.endDate, 'yyyy/MM/dd');
    const quadrant = values.quadrant === 5 ? {} : { quadrant: values.quadrant };
    const userId = values.user ? { userId: values.user.value } : {};
    const receipt = values.receipt ? { receipt: values.receipt } : {};
    props.setFilterData({ start, end, status: values.status, isDefeated: values.isDefeated, ...userId, ...quadrant, ...receipt });
    props.getDataFromServer(true);
  }
  const handleCheckBox = (name, option, value) => {
    const newValue = value ? [ ...values[name], option ] : values[name].filter(el => el !== option);
    setValues(values => ({ ...values, [name]: newValue }));
  };
  const handleSwitchChange = e => {
    const { name } = e.target;
    setValues(values => ({ ...values, [name]: e.target.checked }));
  };
  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }
  return (
    <FilterDrawer handleAction={handleAction} showDates>
      <Typography variant='subtitle1'>
        Estatus
      </Typography>
      <Checkbox
        onChange={handleCheckBox}
        name='status'
        values={[
          { name: '1', label: 'Oportunidad', checked: values.status.includes('1') },
          { name: '2', label: 'Prospecto', checked: values.status.includes('2') },
          { name: '3', label: 'Cotizado', checked: values.status.includes('3') },
          { name: '4', label: 'Contratado', checked: values.status.includes('4') },
        ]}
      />
      <Divider />
      <div className={classes.flex}>
        <Switch
          checked={values.isDefeated}
          onChange={handleSwitchChange}
          name='isDefeated'
        />
        <Typography variant='subtitle1'>
          Mostrar solo seguimientos vencidos
        </Typography>
      </div>
      <Divider />
      <br />
      <Typography variant='subtitle1'>
        Usuario
      </Typography>
      <Autocomplete name='user' size='medium' value={values.user} onChange={handleChange} options={userOptions} />
      <br />
      <Divider />
      <br />
      <Typography variant='subtitle1'>
        Cuadrante
      </Typography>
      <SelectField
        name='quadrant'
        onChange={handleChange}
        value={values.quadrant}
        options={quadrantOptions}
        size='medium'
      />
      <br />
      <Divider />
      <br />
      <Typography variant='subtitle1'>
        Justificante
      </Typography>
      <SelectField
        name='receipt'
        onChange={handleChange}
        value={values.receipt}
        options={receiptOptions}
        size='medium'
      />
    </FilterDrawer>
  )
};

const mapDispatchToProps = dispatch => ({
  setFilterData: data => dispatch(setFilterDataAction(data)),
  getDataFromServer: data => dispatch(getDataFromServerAction(data)),
})

export default connect(null, mapDispatchToProps)(FilterComponent);
