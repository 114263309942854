import branches from './branches';
import countries from './countries';
import states from './states';
import users from './users';
import maids from './maids';
import customers from './customers';
import assignments from './assignments';
import expenses from './expenses';
import checkIns from './checkIns';
import filipinas from './filipinas';
import filipinasStatus from './filipinas.status';
import filipinasConfig from './filipinas.config';
import checkInsReport from './checkInsReport';

export const chooseFields = {
	branches,
	countries,
	states,
	users,
	maids,
	customers,
	assignments,
	expenses,
	checkIns,
	filipinas,
	filipinasStatus,
	filipinasConfig,
	checkInsReport
}
