import React from 'react';

import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import AddCircleIcon from '@material-ui/icons/AddOutlined';

import IconButton from './../../../_presentationals.v2/Form/IconButton';

import { openActionDialog } from '../../../_actions.v2';

const useStyles = makeStyles(theme => ({
  paddingX: {
		paddingLeft: theme.spacing(1.6),
		paddingRight: theme.spacing(1.6),
	},
}));

const CreateButtonComponent = props => {

  const classes = useStyles();

  const handleOpenCreate = () => {
    props.openForm();
  };

  return (
    <IconButton 
      color="secondary" 
      label="Crear" 
      action={ handleOpenCreate } 
      className={ classes.paddingX }
      icon={<AddCircleIcon />}
    />
  )
};

const mapDispatchToProps = dispatch => ({
  openForm: () => dispatch(openActionDialog({})),
})

export default connect(null, mapDispatchToProps)(CreateButtonComponent);
