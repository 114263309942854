import indigo from '@material-ui/core/colors/indigo';
import green from '@material-ui/core/colors/green';
import pink from '@material-ui/core/colors/pink';

// import { isMobile } from "react-device-detect";

const drawerWidth = "22%";
const backgroundColor = "#2651d3";

const styles = theme => ({
	body: {
		background: '#fff !important'
	},
	accountButton: {
		color: '#fff',
    background: backgroundColor,
    '&:hover': {
    	background: backgroundColor
    },
    '&:focus': {
    	background: backgroundColor
    }
	},
	accountMenu: {
		background: "#4369dd",
    padding: 15,
    paddingTop: 50,
    paddingBottom: 10,
    borderTop: '5px solid',
    borderTopColor: "#1d3ea1",
    minWidth: 255
	},
	avatarIcon: {
		width: 100,
		height: 100
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		background: backgroundColor,
		boxShadow: "none"
	},
	appBarMenu: {
		marginTop: theme.spacing(5.5), 
		padding: 0
	},
	content: {
		flexGrow: 1,
		height: '100vh',
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		//Here is the problem
	},
	companyName: {
		marginLeft: theme.spacing(1),
		flexGrow: 1,
		fontWeight: '700',
		color: "#fff"
	},
	drawer: {
		// [theme.breakpoints.up('sm')]: {
		// 	width: drawerWidth,
		//  	flexShrink: 0,
		// },
		overflowY: 'auto'
	},
	drawerPaper: {
		width: drawerWidth,
	},
	fabBlue: {
    color: theme.palette.common.white,
    backgroundColor: indigo[500],
    '&:hover': {
      backgroundColor: indigo[600],
    },
  },
	fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
  fabPink: {
  	color: theme.palette.common.white,
  	backgroundColor: pink[500],
  	'&:hover': {
  		backgroundColor: pink[600],
  	}
  },
  listItems: {
  	paddingTop: 0
  },
  logo: {
  	width: theme.spacing(22)
  },
	menuButton: {
		marginRight: 10,
	},
	progressBar: {
		width: '100%',
		flexGrow: 1,
		flex: 1,
		top: 0,
		position: 'absolute',
		zIndex: theme.zIndex.drawer + 2
	},
	root: {
		display: 'flex'
	},
	toolbar: theme.mixins.toolbar,
	userButton: {
		padding: 3,
	},
	userIcon: {
		fontSize: 40,
	},
	userInfo: {
		width: 320, 
		padding: 0,
		'&:focus': {
			outline: 'none'
		}
	},
	userSection: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  userOptions: {
		color: '#fff'
	},
	imgContainer: {
	  position: 'relative',
	  width: '50%',
	  background: '#000',
	  borderRadius: 5,
	  '&:hover img':{
	  	opacity: 0.6,
	  },
	  '&:hover div':{
	  	opacity: 1,
	  },
	  marginRight: theme.spacing(2)
	},
	image: {
	  opacity: 1,
	  display: 'block',
	  width: 100,
	  height: 100,
	  borderRadius: 5,
	  transition: '.2s ease',
	  backfaceVisibility: 'hidden',
	  cursor: 'pointer'
	},
	middle: {
	  transition: '.5s ease',
	  opacity: 0,
	  position: 'absolute',
	  top: '50%',
	  left: '50%',
	  transform: 'translate(-50%, -50%)',
	  textAlign: 'center',
	  color: '#fff',
	  cursor: 'pointer'
	}
});

export { styles };