import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import MessageIcon from '@material-ui/icons/MessageOutlined';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import CreditCardIcon from '@material-ui/icons/CreditCardOutlined';

import { recordsServices } from './../../_services.v2';
import { getDataFromServer } from './../../_actions.v2';

import Module from './../../_presentationals.v2/Module';
import CommentaryDialog from './commentary.dialog';

const Rows = [
  { label: 'Cliente', key: 'name', sort: 'text', filter: true }, 
	{ label: 'Tipo', key: 'type', sort: 'text' }, 
	{ label: 'Saldo', key: 'balance', format: 'currency', align: 'right', sort: 'number' },
	{ label: 'Último Cobro', key: 'lastCharge' },
  { label: 'Último Comentario', key: 'commentary' },
  { label: 'AL corriente', key: 'aware' },
  { label: 'Promesa Pago', key: 'paymentDate' }
]; 

const accountOptions = [
  { value: 1, label: 'EDER FERNANDO JIMENEZ GARCÍA'},
  { value: 2, label: 'JOSE CRUZ ROSALES FELIX'},
  { value: 3, label: 'CYNTHIA NALLELY ROSALES VEGA'},
  { value: 4, label: 'FERNANDO...'},
  { value: 5, label: 'CAJA CHICA'},
  { value: 6, label: 'MARIA DEL ROSARIO VEGA ESEBERRE'},
  { value: 7, label: 'FERNANDO JIMENEZ RAMIREZ'},
];

const Component = ({ getData, ...props }) => {

	const [ values, setValues ] = useState([]);
	const [ customers, setCustomers ] = useState([]);
  const [ total, setTotal ] = useState(0);
  const [ openDialog, setOpenDialog ] = useState([false,null])

	useEffect(() => {
		recordsServices.get('customers')
			.then(data => {
				const tmp = data.rows.map(el => ({ value: el.id, label: el.name }));
				setCustomers(tmp);
			})
			.catch(e => console.log(e.message));
	}, []);

	useEffect(() => {
		getData && recordsServices.get('v2/customers/receivable')
			.then(data => {
        const tmpTotal = data.reduce((tmp, el) => {
			if(isNaN(el.balance)) console.log(el.id, el.balance)
			return tmp + parseFloat(el.balance || 0)
		}, 0);
		// console.log(tmpTotal)
        setTotal(parseFloat(tmpTotal).toFixed(2));
        const tmp = data.map(el => ({ 
          ...el, 
          background: (el.expired && parseInt(el.balance) <= -1) ? 'danger' : false  
        }))
				setValues(tmp);
        props.getDataFromServer(false);
			})
			.catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCommentary = (e,{id}) => setOpenDialog([true,id]);
  const handleCloseDialog = () => setOpenDialog([false,null]);

	return (
    <Fragment>
  		<Module 
  			route='receivable'
        title='Cobranza'
        subTitle={`Saldo: ${total}`}
        singular='cobranza'
        Rows={Rows}
        noEdit
        noDelete
        records={values}
        actions={[{
          label: 'Comentarios',
          icon: <MessageIcon fontSize='small' />,
          onClick: handleCommentary
        }]}
        fields={[
        	{
        		name: 'id',
        		icon: <PersonOutlineIcon />,
        		label: 'Cliente',
        		options: customers,
        		field: 'autocomplete'
        	},
        	{
        		name: 'payment',
        		icon: <CreditCardIcon />,
        		label: 'Cobro',
        		type: 'number'
        	},
        	{
        		name: 'account',
        		label: 'Cuenta Bancaria',
        		options: accountOptions,
        		field: 'select'
        	},
        	// {
        	// 	name: 'increase',
        	// 	icon: <CompareArrowsIcon />,
        	// 	label: 'Incremento de Saldo (-)',
        	// 	type: 'number'
        	// },
        	// {
        	// 	name: 'decrease',
        	// 	label: 'Decremento de Saldo (+)',
        	// 	type: 'number'
        	// },
        	{
        		name: 'password',
        		label: 'Contraseña',
        		type: 'password'
        	},
        ]}
      />
      <CommentaryDialog isOpen={openDialog[0]} handleClose={handleCloseDialog} customerId={openDialog[1]} />
    </Fragment>
	);
};

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: value => dispatch(getDataFromServer(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component); 