import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    marginVertical: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
    },
}));

const TextFieldComponent = ({ onChange, params, helperText, error, label, ...props }) => {
    const classes = useStyles();
    const handleChange = e => {
        onChange && onChange(e.target.name, e.target.value, params);
    };
    return (
        <div className={classes.marginVertical}>
            <Typography variant='subtitle1'>
                {label}
            </Typography>
            <TextField
                name={props.name}
                variant={props.variant || 'outlined'}
                fullWidth={props.fullWidth || true}
                autoFocus={props.autoFocus || false}
                onChange={handleChange}
                value={props.value}
                error={!!error}
                helperText={error || helperText}
                autoComplete='off'
                size={props.size || 'small'}
                {...props}
            />
        </div>
    );
};

export default TextFieldComponent;
