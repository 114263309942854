import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import TodayIcon from '@material-ui/icons/TodayOutlined';
import EventIcon from '@material-ui/icons/EventOutlined';

import { recordsServices } from './../../_services.v2';
import { getDataFromServer } from './../../_actions.v2';
import moneyFormat from './../../_helpers.v2/moneyFormat';

import Module from './../../_presentationals.v2/Module';
import { newDate } from '../../_helpers.v2';

const Rows = [
	{ label: 'Captura', key: 'createdAt', sort: 'date', format: 'date' }, 
	{ label: 'Mucama', key: 'maid', sort: 'text', filter: true }, 
	{ label: 'Plan', key: 'paymentPlan', sort: 'text' }, 
	{ label: 'Sueldo', key: 'preSalary', format: 'currency', sort: 'number', align: 'right' },
	{ label: 'Infonavit', key: 'infonavit', format: 'currency', sort: 'number', align: 'right' },
	{ label: 'Pago', key: 'salary', format: 'currency', sort: 'number', align: 'right' },
	{ label: 'FechaInicial', key: 'startDate', sort: 'date', format: 'date' }, 
	{ label: 'FechaFinal', key: 'finishDate', sort: 'date', format: 'date' }, 
	{ label: 'Usuario', key: 'user', sort: 'text' }, 
]; 

const Component = ({ getData, ...props }) => {

	const [ values, setValues ] = useState([]);
	const [ query, setQuery ] = useState('');
	const [ totalSalary, setTotalSalary ] = useState(0);

	useEffect(() => {
		getData && recordsServices.get(`v2/payrolls${query}`)
			.then(data => {
				const tmpTotalSalary = data.reduce((total, el) => total + parseFloat(el.salary), 0);
				setTotalSalary(moneyFormat(tmpTotalSalary));
				setValues(data.map(el => ({
					...el,
					createdAt: newDate(el.createdAt),
					startDate: newDate(el.startDate),
					finishDate: newDate(el.finishDate),
				})));
        props.getDataFromServer(false);
			})
			.catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleFilter = ({ start, finish }) => {
		if(start && finish) {
			setQuery(`?start=${start}&finish=${finish}`);
			props.getDataFromServer(true);
		}
	}

	return (
		<Module 
			route=''
      title='Reporte de Pagos'
      subTitle={`Total: ${totalSalary}`}
      Rows={Rows}
      noEdit
      noDelete
      noCreate
      filter={[handleFilter, [{
      	field: 'date',
      	icon: <TodayIcon />,
      	name: 'start',
      	label: 'Fecha Inicial'
      },{
      	field: 'date',
      	icon: <EventIcon />,
      	name: 'finish',
      	label: 'Fecha Final'
      }]]}
      records={values}
    />
	);
};

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: value => dispatch(getDataFromServer(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component); 