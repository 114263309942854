import format from 'date-fns/format';

import { newDate, statesSelectField } from './../../_helpers.v2';

const Contract = props => {
  
  const razonSocial = props.businessName;
  const rfc = props.identifier;
  const stateLabel = statesSelectField(props.countryId).find(el => el.value === props.stateId);
  const domicilio_empresa = `${props.addressLine1}, ${props.addressLine2}, ${props.city}, ${stateLabel.label} CP ${props.zipCode}`;
  const representante_legal = props.legalRepresentative;
  const total_pagar = parseFloat(props.price).toFixed(2);
  const numero_mucamas = (parseInt(props.quantity) === 1) ? '1' : `${props.quantity}`;
  const dias = props.daysToText;
  const chooseContractTime = props.chooseContractTime;
  const insumos = !props.supplies ? "NO" : "";
  
  const companyIdentifier = getCompanyIdentifier(parseInt(props.chooseCompany));
  const companyName = getCompanyName(parseInt(props.chooseCompany));

  const nombre_emisor = companyName
  const rfc_emisor = companyIdentifier;

  const dia_contrato = format(newDate(props.contractDate), 'd');
  const mes_contrato = format(newDate(props.contractDate), 'M');
  const anio_contrato = parseInt(format(newDate(props.contractDate), 'yyyy'));

  const dia_inicial = format(newDate(props.initialDate), 'd');
  const mes_inicial = format(newDate(props.initialDate), 'M');
  const anio_inicial = parseInt(format(newDate(props.initialDate), 'yyyy'));

  const dia_final = format(newDate(props.endDate), 'd');
  const mes_final = format(newDate(props.endDate), 'M');
  const anio_final = parseInt(format(newDate(props.endDate), 'yyyy'));
  
  const url = 'https://app.fotoymax.com/api/api.php?accion=contrato_mucaama&tipo_accion=modelo&auth=false&';
  const params = `dia_contrato=${dia_contrato}&mes_contrato=${mes_contrato}&anio_contrato=${anio_contrato}&razon_social=${razonSocial}&rfc=${rfc}&domicilio_empresa=${domicilio_empresa}&representante_legal=${representante_legal}&total_pagar=${total_pagar}&numero_mucamas=${numero_mucamas}&dias=${dias}&insumos=${insumos}&nombre_emisor=${nombre_emisor}&rfc_emisor=${rfc_emisor}&duracion_contrato=${chooseContractTime}&dia_inicial=${dia_inicial}&mes_inicial=${mes_inicial}&anio_inicial=${anio_inicial}&dia_final=${dia_final}&mes_final=${mes_final}&anio_final=${anio_final}`;

  window.open(`${url}${params}`, '_blank');
}

function getCompanyIdentifier(value){
  switch(value){
    case 1:
      return "JIGE830606G90";
    case 2:
      return "ROFC560503919";
    case 3:
      return "ROVC870905FH2";
    case 6:
      return "VEER5705161U0";
    case 7:
      return "JIRF540914N61";
    default:
      return "Indefinido";
  }
}

function getCompanyName(value){
  switch(value){
    case 1:
      return "EDER FERNANDO JIMENEZ GARCÍA";
    case 2:
      return "JOSE CRUZ ROSALES FELIX";
    case 3:
      return "CYNTHIA NALLELY ROSALES VEGA";
    case 6:
      return "MARIA DEL ROSARIO VEGA ESEBERRE";
    case 7:
      return "FERNANDO JIMENEZ RAMIREZ";
    default:
      return "Indefinido";
  }
}

export default Contract;