import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export default function FormDialog(props) {
  return (
    <Dialog 
      open={ props.isOpen || false } 
      fullWidth={ true } 
      maxWidth={ props.maxWidth || "sm" }
      onClose={ props.handleClose } 
      aria-labelledby={ props.title }
    >
      <DialogTitle id={ props.title }>{ props.title }</DialogTitle>
      <DialogContent>
        { props.contentText && <DialogContentText>{ props.contentText }</DialogContentText> }
        { props.children }
      </DialogContent>
      <DialogActions>
        <Button onClick={ props.handleClose } color="primary">
          { props.cancelLabel || "Cerrar" }
        </Button>
        <Button onClick={ props.handleAccept } color="primary">
          { props.acceptLabel || "Aceptar" }
        </Button>
      </DialogActions>
    </Dialog>
  );
}
