import React, { PureComponent, Fragment } from 'react';
import { styles } from './styles';
import { withStyles } from '@material-ui/core/styles';
import {
 Typography, ListItem, ListItemText, List, Grid, Avatar, Paper, ListItemAvatar, ListItemSecondaryAction, IconButton, Divider, Tooltip
}from '@material-ui/core';
import StoreIcon from '@material-ui/icons/StoreOutlined';
import HomeIcon from '@material-ui/icons/Home';
import DirectionIcon from '@material-ui/icons/Directions';
import StreetViewIcon from '@material-ui/icons/Streetview';
import MapIcon from '@material-ui/icons/Map';
import AttachMoney from '@material-ui/icons/AttachMoney';
import PublicIcon from '@material-ui/icons/Public';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone'
import PhoneAndroidcon from '@material-ui/icons/PhoneAndroid'
import Editcon from '@material-ui/icons/Edit'

class DataBranch extends PureComponent {
	render() {
    const { classes, handleOpenName, handleOpenIVA, handleOpenAddress, handleOpenPhone1, handleOpenPhone2 } = this.props;
		return (
			<Fragment>
        <main className={classes.main}>
          <Typography variant="h5" color="inherit" noWrap gutterBottom align="center">
            Información de sucursal
          </Typography>
          <Typography  component="p" align="center">
            Información básica que usas en los servicios de Kool Tech
          </Typography>
          <div>
            <Grid container item xs={12} spacing={2}>
              <Grid item md={12} xs={12}>
                <Paper className={classes.paper} elevation={0}>
                  <List className={classes.listItems}>
                    <ListItem>
                      <Typography variant="h5" component="p" align="left">
                        Sucursal
                      </Typography>
                    </ListItem>
                    <ListItem button>
                      <ListItemAvatar>
                         <Avatar>
                           <StoreIcon />
                         </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Nombre"  secondary="Kool Tech" />
                      <ListItemSecondaryAction>
                         <Tooltip title="Editar nombre de sucursal" >
                          <IconButton  onClick={ handleOpenName }>
                            <Editcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem button>
                      <ListItemAvatar>
                         <Avatar>
                           <AttachMoney />
                         </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Tasa IVA"  secondary="16%" />
                      <ListItemSecondaryAction>
                        <Tooltip title="Editar tasa IVA">
                          <IconButton onClick={ handleOpenIVA }>
                            <Editcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider/>
                    <ListItem button>
                      <Typography variant="h5" component="p" align="left">
                        Domicilio
                      </Typography>
                      <ListItemSecondaryAction>
                        <Tooltip title="Editar domicilio">
                          <IconButton onClick={ handleOpenAddress }>
                            <Editcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                         <Avatar>
                           <HomeIcon />
                         </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Dirección 1" secondary="Carretera a Imala 3132" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                         <Avatar>
                           <HomeIcon />
                         </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Dirección 2"  secondary="Fracc. Los Ángeles" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                         <Avatar>
                           <DirectionIcon />
                         </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Referencia" secondary="Carretera Imala" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                         <Avatar>
                           <MapIcon />
                         </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Estado"  secondary="Sinaloa" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                         <Avatar>
                           <MapIcon />
                         </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Ciudad"  secondary="Culiacán Rosales" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                         <Avatar>
                           <StreetViewIcon />
                         </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Código postal" secondary="80014" />
                    </ListItem>
                    <Divider/>
                    <ListItem>
                      <Typography variant="h5" component="p" align="left">
                        Telefonos
                      </Typography>
                    </ListItem>
                    <ListItem button>
                      <ListItemAvatar>
                         <Avatar>
                           <LocalPhoneIcon />
                         </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Teléfono fijo" secondary="667253442" />
                      <ListItemSecondaryAction>
                        <Tooltip title="Editar Teléfono fijo">
                          <IconButton onClick={ handleOpenPhone1 }>
                            <Editcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem button>
                      <ListItemAvatar>
                         <Avatar>
                           <PhoneAndroidcon />
                         </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Teléfono movíl" secondary="667253442" />
                      <ListItemSecondaryAction>
                        <Tooltip title="Editar Teléfono movíl">
                          <IconButton onClick={ handleOpenPhone2 }>
                            <Editcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider/>
                    <ListItem>
                      <Typography variant="h5" component="p" align="left">
                        Datos de facturación
                      </Typography>
                      <ListItemSecondaryAction>
                        <Tooltip title="Editar datos de facturación">
                          <IconButton aria-label="Delete">
                            <Editcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                         <Avatar>
                           <SupervisorAccount />
                         </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Razón social" secondary="Kool Tech, S.A" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                         <Avatar>
                           <PublicIcon />
                         </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="RFC" secondary="VECJ880326XXX " />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                         <Avatar>
                           <HomeIcon />
                         </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Dirección 1" secondary="Carretera a Imala 3132" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                         <Avatar>
                           <HomeIcon />
                         </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Dirección 2"  secondary="Fracc. Los Ángeles" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                         <Avatar>
                           <DirectionIcon />
                         </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Referencia" secondary="Carretera Imala" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                         <Avatar>
                           <MapIcon />
                         </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Estado"  secondary="Sinaloa" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                         <Avatar>
                           <MapIcon />
                         </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Ciudad"  secondary="Culiacán Rosales" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                         <Avatar>
                           <StreetViewIcon />
                         </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Código postal" secondary="80014" />
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </main>
			</Fragment>
		)
	}
}

export default withStyles(styles)(DataBranch);
