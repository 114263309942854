import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import FormDialog from './../../_presentationals.v2/FormDialog';
import TextField from './../../_presentationals.v2/Form/TextField';
import SelectField from './../../_presentationals.v2/Form/SelectField';
import CheckBoxField from './../../_presentationals.v2/Form/CheckBoxField';

import { alertActions } from './../../_actions.v2';

import { recordsServices } from './../../_services.v2';
import { errorFormat } from './../../_helpers.v2';

const ERROR_MESSAGE = 'Ha ocurrido un error, por favor inténtalo de nuevo más tarde.';
const typeRateOptions = [{ value: 1, label: 'Diaria' }, { value: 2, label: 'Mensual' }];

const defaultValues = {
  typeRate: 1,
  DaysOfRate: {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false, 
    friday: false,
    saturday: false,
    sunday: false
  },
  quantityMaid: '1',
  rate: '0',
  suppliesRate: '0',
  uber: '0'
};

const Component = props => {

	const [ values, setValues ] = useState(defaultValues);
  const [ errors, setErrors ] = useState({});

	useEffect(() => {
    if(props.values.id){ 
      let tmp = [];
      Object.keys(props.values).forEach(el => {
        if(props.values[el]){ tmp = { ...tmp, [el]: props.values[el] }; }
      });
		  setValues(values => ({ ...values, ...tmp }));
    }
	}, [props.values]);

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleDays = name => {
    setValues(values => ({ 
      ...values, DaysOfRate: { ...values.DaysOfRate, [name]: !values.DaysOfRate[name] } 
    }));
  };

  const handleAction = () => {
    recordsServices.update(`customers/${props.values.id}/rates`, values)
      .then(data => {
        if(data.errorCode) { throw new Error(data.errorMessage); }
        props.showAlert({ variant: 'success' });
        handleClose();
      })
      .catch(e => { 
        const tmpErrors = errorFormat(e.message);
        setErrors(tmpErrors);
        props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
      });
  }

  const handleClose = () => { 
    setValues(defaultValues); 
    setErrors({});
    props.handleClose();
  }
  
	return (
		<FormDialog
      title='Tarifas'
      isOpen={props.isOpen || false}
      handleClose={handleClose}
      handleAction={handleAction}
    >
      <SelectField 
        name='typeRate'
        label='Tarifa'
        value={values.typeRate}
        onChange={handleChange}
        options={typeRateOptions}
        error={errors.typeRate}
        required
      />
      { parseInt(values.typeRate) === 2 && 
        <Fragment>
        <CheckBoxField
          label='Días'
          action={handleDays}
          controls={[{
            name: 'monday',
            label: 'Lunes',
            value: values.DaysOfRate.monday
          },{
            name: 'tuesday',
            label: 'Martes',
            value: values.DaysOfRate.tuesday
          },{
            name: 'wednesday',
            label: 'Miércoles',
            value: values.DaysOfRate.wednesday
          },{
            name: 'thursday',
            label: 'Jueves',
            value: values.DaysOfRate.thursday
          },{
            name: 'friday',
            label: 'Viernes',
            value: values.DaysOfRate.friday
          },{
            name: 'saturday',
            label: 'Sábado',
            value: values.DaysOfRate.saturday
          },{
            name: 'sunday',
            label: 'Domingo',
            value: values.DaysOfRate.sunday
          }]}
        />
        <TextField
          name='quantityMaid'
          label='Cantidad de Mucamas'
          value={values.quantityMaid}
          onChange={handleChange}
          error={errors.quantityMaid}
          required
        />
        <TextField
          name='rate'
          label='Cobro a Cliente (Servicio)'
          value={values.rate}
          onChange={handleChange}
          error={errors.rate}
          required
        />
        <TextField
          name='suppliesRate'
          label='Cobro a Cliente (Insumos)'
          value={values.suppliesRate}
          onChange={handleChange}
          error={errors.suppliesRate}
          required
        />
        <TextField
          name='uber'
          label='Uber'
          value={values.uber}
          onChange={handleChange}
          error={errors.uber}
          required
        />
        </Fragment>
      }
      <p>
        <br/> AVISO IMPORTANTE: <br/>
        Para usar este módulo, se requiere al menos una colocación configurada. Si intentas almacenar 
        sin antes haber asignado una colocación, recibirás error.
      </p>
    </FormDialog>
   )
}

const mapDispatchToProps = dispatch => ({
  showAlert: data => dispatch(alertActions.show(data))
});

export default connect(null, mapDispatchToProps)(Component);