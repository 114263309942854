import { config, authHeader } from '../_helpers';
import { handleFetchResponse, handleFetchResponseNoAuth } from './';

const createNoAuth = ({ firstName, lastName, username, password, ReCAPTCHA }) => {
	const user = {
		firstName, lastName, username, password, ReCAPTCHA
	};

	const myRequest = new Request(
		`${config.apiUrl}/users`,
		{
			method: 'POST',
			headers: { 'Content-Type' : 'application/json', ...authHeader() },
			body: JSON.stringify(user)
		}
	);
	return fetch(myRequest).then(handleFetchResponseNoAuth).then(response => {
		if(response.errorCode){ return response; }
		localStorage.setItem('session', JSON.stringify(response));
		return response;
	});
};

const createAddress = ({ addressLine1, addressLine2, countryId, city, reference, stateId, zipCode }) => {
	const dataSession = JSON.parse(window.localStorage.getItem('session'));
  const id = dataSession.userId;

	const Address = {
		addressLine1, addressLine2, city, countryId, reference, stateId, zipCode
	};

	const myRequest = new Request(
		`${config.apiUrl}/users/${id}/addresses`,
		{
			method: 'POST',
			headers: { 'Content-Type' : 'application/json', ...authHeader() },
			body: JSON.stringify(Address)
		}
	);
	return fetch(myRequest).then(handleFetchResponse(myRequest));
};

const creteBillingInformation = ({ identifier, businessName, addressLine1, addressLine2, countryId, city, stateId, zipCode }) => {
	const dataSession = JSON.parse(window.localStorage.getItem('session'));
  const id = dataSession.userId;

	const billingInformation = {
		identifier, businessName, addressLine1, addressLine2, countryId, city, stateId, zipCode
	};

	const myRequest = new Request(
		`${config.apiUrl}/users/${id}/billinginformations`,
		{
			method: 'POST',
			headers: { 'Content-Type' : 'application/json', ...authHeader() },
			body: JSON.stringify(billingInformation)
		}
	);
	return fetch(myRequest).then(handleFetchResponse(myRequest));
};

const createPhone = ({ countryCode, phone, label }) => {
	const dataSession = JSON.parse(window.localStorage.getItem('session'));
  const id = dataSession.userId;

	const Phone = {
		countryCode, phone, label
	};

	const myRequest = new Request(
		`${config.apiUrl}/users/${id}/phones`,
		{
			method: 'POST',
			headers: { 'Content-Type' : 'application/json', ...authHeader() },
			body: JSON.stringify(Phone)
		}
	);
	return fetch(myRequest).then(handleFetchResponse(myRequest));
};

const verifyCode = ({ id, code }) => {

	const myRequest = new Request(
		`${config.apiUrl}/users/${id}/activate`,
		{
			method: 'POST',
			headers: { 'Content-Type' : 'application/json', ...authHeader() },
			body: JSON.stringify({code})
		}
	);

	return fetch(myRequest).then(handleFetchResponseNoAuth)
	.then(response => {
		if(response.errorCode){ return response; }
		const { isAuth, isVerified, accessToken, refreshToken } = response;
		const sessionData = {
			isAuth, isVerified,
      userId: response.data.id,
      username: response.data.username,
      firstname: response.data.firstName,
      lastname: response.data.lastName,
      token: accessToken,
      refresh: refreshToken
    };

		localStorage.setItem('session', JSON.stringify(sessionData));
		return sessionData;

	});
};

const verifyUsername = ({ username }) => {
  const myRequest = new Request(
    `${config.apiUrl}/users/username/${username || 'undefined' }`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
  );
  return fetch(myRequest).then(handleFetchResponse(myRequest));
};

const getById = () => {

  const session = JSON.parse(localStorage.getItem('session'));
  const { id } = session.data || {};

  const myRequest = new Request(
    `${config.apiUrl}/users/${id}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', ...authHeader() }
    }
  );
  return fetch(myRequest).then(handleFetchResponse(myRequest));
};

const editName = (data) => {
	const dataSession = JSON.parse(window.localStorage.getItem('session'));
  	const id = dataSession.userId;

	const myRequest = new Request(
		`${config.apiUrl}/users/${id}`,
		{
			method: 'PUT',
			headers: { 'Content-Type' : 'application/json', ...authHeader() },
			body: JSON.stringify(data)
		}
	);
	return fetch(myRequest).then(handleFetchResponse(myRequest));
};


const editProfile = (data) => {
	const dataSession = JSON.parse(window.localStorage.getItem('session'));
  	const id = dataSession.userId;

	const myRequest = new Request(
		`${config.apiUrl}/users/${id}/profile`,
		{
			method: 'PUT',
			headers: { 'Content-Type' : 'application/json', ...authHeader() },
			body: JSON.stringify(data)
		}
	);
	return fetch(myRequest).then(handleFetchResponse(myRequest));
};

const editPhone = (data) => {
	const dataSession = JSON.parse(window.localStorage.getItem('session'));
  	const id = dataSession.userId;

	const myRequest = new Request(
		`${config.apiUrl}/users/${id}/phones/${data.id}`,
		{
			method: 'PUT',
			headers: { 'Content-Type' : 'application/json', ...authHeader() },
			body: JSON.stringify(data)
		}
	);
	return fetch(myRequest).then(handleFetchResponse(myRequest));
};

const editAddress = (data) => {
	const dataSession = JSON.parse(window.localStorage.getItem('session'));
  	const id = dataSession.userId;
  	const myRequest = new Request(
		`${config.apiUrl}/users/${id}/addresses/${data.id}`,
		{
			method: 'PUT',
			headers: { 'Content-Type' : 'application/json', ...authHeader() },
			body: JSON.stringify(data)
		}
	);
	return fetch(myRequest).then(handleFetchResponse(myRequest));
};

const editBillingInformation = (data) => {
	const dataSession = JSON.parse(window.localStorage.getItem('session'));
  	const id = dataSession.userId;
  	const myRequest = new Request(
		`${config.apiUrl}/users/${id}/billinginformations`,
		{
			method: 'PUT',
			headers: { 'Content-Type' : 'application/json', ...authHeader() },
			body: JSON.stringify(data)
		}
	);
	return fetch(myRequest).then(handleFetchResponse(myRequest));
};

const forgotPasswordByPhone = ({ tokenId, newPassword, confirmationPassword }) => {

	const body = {
		confirmationPassword, newPassword
	};

	const myRequest = new Request(
		`${config.apiUrl}/users/password/recovery/?method=phone&hash=${tokenId}`,
		{
			method: 'PUT',
			headers: { 'Content-Type' : 'application/json' },
			body: JSON.stringify(body)
		}
	);
	return fetch(myRequest).then(handleFetchResponse(myRequest));
}

export const userServices = {
	createNoAuth,
	createAddress,
	createPhone,
	creteBillingInformation,
	verifyCode,
	getById,
	editName,
	editProfile,
	editPhone,
	editAddress,
	editBillingInformation,
	verifyUsername,
	forgotPasswordByPhone
};
