import { formConstants } from '../_constants';

const { EDIT_FIELD_VALUE, VALIDATE_FIELD, EDIT_TYPE_FIELD, RESET_FIELD_VALUE, RESET_FIELDS, EDIT_ID_FIELD } = formConstants;

const initialState = {
  value: {},
  data: {},
  type: {},
  id: {},
  validation: {}
}

export const field = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_FIELD_VALUE:
      return { ...state, value: { ...state.value, ...action.payload } };
    case EDIT_TYPE_FIELD:
      return { ...state, type: { ...state.type, ...action.payload } };
    case EDIT_ID_FIELD:
      return { ...state, id: { ...state.id, ...action.payload } };
    case VALIDATE_FIELD:
      return { ...state, validation: { ...state.validation, ...action.payload } };
    case RESET_FIELD_VALUE:
      let temp = {};    
      action.payload.map((item) => { return temp[item] = '' });
      return { ...state, ...temp };
    case RESET_FIELDS:
      return initialState;
    default:
      //En data se pueden transferir datos de uno a otro formulario
      // return { ...state, value: {}, type: {}, validation: {} };
      return state;
  }
};