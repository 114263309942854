const maids = ({method, ...props}) => {
  switch (method) {
    case 'PUT':
      if (props.up) return `maids/${props._id}`;
    	if(props.isInterview) return `maids/${props._id}/status`;
    	if(props.generalBenefits) return `maids/benefits`;
    	if(props.updateBenefits) return `maids/${props._id}/benefits`;
    	return `maids/${props._id}`;
    case 'DELETE':
      return `maids/${props._id}`;
    default:
      if(props.isAssigments) return 'maids/assignments';
      return 'maids';
  }
}

export default maids;
