import { config, authHeader } from '../_helpers';
import { handleFetchResponse } from './';

// const create = async ({id, profile}) => {

//   const formData  = await new FormData();

//   await formData.append("profile", profile);

// 	const myRequest = await new Request(
//     `${config.apiUrl}/users/${id}/profile/`,
//     {
//       method : 'POST',
//       headers: { ...authHeader() },
//       body: formData
//     }
//   );
//   return await fetch(myRequest).then(handleFetchResponse(myRequest));
// };
const createFile = async ({id, type, profile}) => {

  const formData  = await new FormData();

  await formData.append("contract", profile);

 const myRequest = await new Request(
    `${config.apiUrl}/${chooseType(type, id)}`,
    {
      method : 'PUT',
      headers: { ...authHeader() },
      body: formData
    }
  );
  return await fetch(myRequest).then(handleFetchResponse(myRequest));
};

const get = id => {

	const url = `${config.apiUrl}/users/${id}/profile/`;
	const method = 'GET';

  const myRequest = new Request(
   url,
    {
      method,
      headers: { ...authHeader() }
    }
  );

	return fetch(myRequest).then(handleFetchResponse({ url, method, headers: {} }));
}

const chooseType = (value, id) => {
  switch(value){
    case 'houseMaidContract':
      return `maids/${id}/contracts`;
    case 'customerContract':
      return `customers/${id}/contracts/file`;
    default:
      return "";
  }
}

export const profileServices = {
 get, createFile
};
