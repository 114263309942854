import React, { Fragment, useState, useEffect } from "react";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { TextField, SelectField } from './../../_presentationals/Form';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const Form = ({ _id, editValueAction, Days, ...props }) => {

  useEffect(() => {editValueAction({up:1})},[editValueAction]);

  const [state, setState] = useState({
    monday: Days ? Days.monday : false,
    tuesday: Days ? Days.tuesday : false,
    wednesday: Days ? Days.wednesday : false,
    thursday: Days ? Days.thursday : false,
    friday: Days ? Days.friday : false,
    saturday: Days ? Days.saturday : false,
    sunday: Days ? Days.sunday : false,
  });

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

  useEffect(() => {
    editValueAction({Days: state})
  },[state, editValueAction]);

  const handleContract = () => {
    const nombre = props.name;
    const edad = props.years;
    const domicilio = props.address;
    const sueldo = parseFloat(props.salary).toFixed(2);
    // console.log(props.chooseCompany, getCompanyIdentifier(parseInt(props.chooseCompany)));
    const companyIdentifier = getCompanyIdentifier(parseInt(props.chooseCompany));
    const companyName = getCompanyName(parseInt(props.chooseCompany));

    window.open(`https://api.cfdi.geecko.com.mx/v1/empresa/mucaama/contrato/mucama/pdf?nombre_emisor=${companyName}&rfc_emisor=${companyIdentifier}&nombre=${nombre}&edad=${edad}&domicilio=${domicilio}&sueldo=${sueldo}`, '_blank');
  }

  return(
  	<Fragment>
      { !_id && 
        <Grid container>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="subtitle1" >Al iniciar el cuestionario, primero pregunta...</Typography> 
            <Typography variant="h5" gutterBottom>¿Cómo te sientes el día de hoy?</Typography>
            <hr />
          </Grid>
          <Grid item xs={2}>
          </Grid>
        </Grid>
      }
  		<TextField
  	    icon="account_circle"
  	    fields={[{
  	      id: "name", 
  	      label: "Nombre",
  	      autoFocus: true
  	    }]}
  	  />
  	  <TextField
  	    fields={[{
  	      id: "years", 
  	      label: "Edad"
  	    }]}
  	  />
  	  { _id && 
        <SelectField
          fields={[{
            id: "civilStatus", 
            label: "Estado Civil",
            options: [
              { key: 1, value: "Casada/o"}, 
              { key: 2, value: "Soltera/o"}, 
              { key: 3, value: "Otro"}
            ]
          }]}
        />
  	  }
  	  <SelectField
  	    icon="work"
  	    fields={[{
  	      id: "preference", 
  	      label: "Experiencia en...",
  	      options: [
  	        { key: 1, value: "Casa"}, 
  	        { key: 2, value: "Oficina"}, 
  	        { key: 3, value: "Casa y Oficina"},
  	        { key: 4, value: "Sin Experiencia"}
  	      ]
  	    }]}
  	  />
  	  { _id && 
        <SelectField
          fields={[{
            id: "experience", 
            label: "Años de Experiencia",
            options: [
              { key: 1, value: "1 año"}, 
              { key: 2, value: "2 años"}, 
              { key: 3, value: "3 ó más años"}
            ]
          }]}
        />
  	  }
  	  <SelectField
  	    fields={[{
  	      id: "schedule", 
  	      label: "Horario deseado",
  	      options: [
  	        { key: 1, value: "Matutino"}, 
  	        { key: 2, value: "Vespertino"}, 
  	        { key: 3, value: "Ambos"}
  	      ]
  	    }]}
  	  />
      <Grid container>
        <Grid item xs={1}>
        </Grid>
        <Grid item xs={9}>
          Días deseados
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox checked={state.monday} onChange={handleChange('monday')} value="monday" />
              }
              label="Lunes"
            />
            <FormControlLabel
              control={
                <Checkbox checked={state.tuesday} onChange={handleChange('tuesday')} value="tuesday" />
              }
              label="Martes"
            />
            <FormControlLabel
              control={
                <Checkbox checked={state.wednesday} onChange={handleChange('wednesday')} value="wednesday" />
              }
              label="Miércoles"
            />
            <FormControlLabel
              control={
                <Checkbox checked={state.thursday} onChange={handleChange('thursday')} value="thursday" />
              }
              label="Jueves"
            />
            <FormControlLabel
              control={
                <Checkbox checked={state.friday} onChange={handleChange('friday')} value="friday" />
              }
              label="Viernes"
            />
            <FormControlLabel
              control={
                <Checkbox checked={state.saturday} onChange={handleChange('saturday')} value="saturday" />
              }
              label="Sábado"
            />
            <FormControlLabel
              control={
                <Checkbox checked={state.sunday} onChange={handleChange('sunday')} value="sunday" />
              }
              label="Domingo"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={2}>
        </Grid>
      </Grid>
  	  { _id && 
        <Fragment>
          <TextField
            fields={[{
              id: "estimatedSalary", 
              label: "Sueldo semanal deseado"
            }]}
          />
          <TextField
            fields={[{
              id: "salary", 
              label: "Sueldo semanal (CONTRATO)"
            }]}
          />
          <SelectField
            fields={[{
              id: "filing", 
              label: "Presentación",
              required: false,
              options: [
                { key: 1, value: "Aceptable"}, 
                { key: 2, value: "No aceptable"}
              ]
            }]}
          />
        </Fragment>
  	  }
  	  <TextField
  	    icon="phone"
  	    fields={[{
  	      id: "phone", 
  	      label: "Teléfono"
  	    }]}
  	  />
  	  { _id &&
        <TextField
          icon="room"
          fields={[{
            id: "addressLine1", 
            label: "Calle y Número"
          }]}
        />
  	  }
  	  <TextField
  	    icon={!_id && "room"}
  	    fields={[{
  	      id: "addressLine2", 
  	      label: "Colonia"
  	    }]}
  	  />
  	  { _id && 
        <Fragment>
          <TextField
            fields={[{
              id: "zipCode", 
              label: "Código Postal",
              required: false
            },{
              id: "city", 
              label: "Ciudad"
            }]}
          /> 
          <SelectField
            icon="layers"
            fields={[{
              id: "ine", 
              label: "Identificación Oficial",
              options: [
                { key: 1, value: "Si"}, 
                { key: 0, value: "No"}
              ]
            }]}
          />
          <SelectField
            fields={[{
              id: "proofOfAddress", 
              label: "Comprobante de Domicilio",
              options: [
                { key: 1, value: "Si"}, 
                { key: 0, value: "No"}
              ]
            }]}
          />
          <SelectField
            fields={[{
              id: "birthCertificate", 
              label: "Carta de No Antecedentes Penales",
              options: [
                { key: 1, value: "Si"}, 
                { key: 0, value: "No"}
              ]
            }]}
          />
          <TextField
            icon="thumb_up_alt"
            fields={[{
              id: "referenceName", 
              label: "Nombre | Referencia"
            }]}
          />
          <TextField
            fields={[{
              id: "referencePhone", 
              label: "Teléfono | Referencia"
            }]}
          />
          <SelectField
            icon="gavel"
            fields={[{
              id: "isAllowed", 
              label: "Es apta para laborar",
              options: [
                { key: true, value: "Si"}, 
                { key: false, value: "No"}
              ]
            }]}
          />
          <TextField
            fields={[{
              id: "description", 
              label: "Comentarios",
              required: false
            }]}
          />
          <SelectField
            icon="credit_card"
            fields={[{
              id: "creditCardBank", 
              label: "Banco",
              options: [
                { key: 1, value: "Coppel"}, 
                { key: 2, value: "Saldazo"},
                { key: 3, value: "Banamex"},
                { key: 4, value: "Banorte"},
                { key: 5, value: "Bancomer"},
                { key: 6, value: "HSBC"},
                { key: 7, value: "Otro"}
              ],
              required: false
            }]}
          />
          <TextField
            fields={[{
              id: "creditCardId", 
              label: "No. de Tarjeta",
              required: false
            }]}
          />
          <TextField
            fields={[{
              id: "creditCardOwner", 
              label: "Nombre de Propietario",
              required: false
            }]}
          />
          {
            _id &&
            <SelectField
              fields={[{
                id: 'chooseCompany', 
                label: 'Emisor de Contrato',
                options: [
                  { key: '1', value: 'EDER FERNANDO JIMENEZ GARCÍA'},
                  { key: '2', value: 'JOSE CRUZ ROSALES FELIX'},
                  { key: '3', value: 'CYNTHIA NALLELY ROSALES VEGA'},
                ]
              }]}
            />
          }
          {
            _id &&
            <Button variant="contained" color="secondary" onClick={handleContract} style={{ marginTop: 10, marginLeft: 50, marginBottom: 20 }}>
              Generar Contrato
            </Button>
          }
        </Fragment>
  	  }
  	</Fragment>
  )
}

function getCompanyIdentifier(value){
  switch(value){
    case 1:
      return "JIGE830606G90";
    case 2:
      return "ROFC560503919";
    case 3:
      return "RIVC870905FH2";
    default:
      return "Indefinido";
  }
}

function getCompanyName(value){
  switch(value){
    case 1:
      return "EDER FERNANDO JIMENEZ GARCÍA";
    case 2:
      return "JOSE CRUZ ROSALES FELIX";
    case 3:
      return "CYNTHIA NALLELY ROSALES VEGA";
    default:
      return "Indefinido";
  }
}


export default Form;