import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import TodayIcon from '@material-ui/icons/TodayOutlined';
import EventIcon from '@material-ui/icons/EventOutlined';

import { recordsServices } from './../../_services.v2';
import { getDataFromServer } from './../../_actions.v2';
import moneyFormat from './../../_helpers.v2/moneyFormat';

import Module from './../../_presentationals.v2/Module';

const Rows = [
	{ label: 'Fecha', key: 'createdAt', sort: 'date', format: 'dateTime' }, 
	{ label: 'Cliente', key: 'customer', sort: 'text', filter: true }, 
	{ label: 'Cobro', key: 'amount', format: 'currency', sort: 'number', align: 'right' },
	{ label: 'Saldo Anterior', key: 'balance', format: 'currency', sort: 'number', align: 'right' },
	{ label: 'Saldo Actual', key: 'currentBalance', format: 'currency', sort: 'number', align: 'right' },
	{ label: 'Cuenta Bancaria', key: 'account', sort: 'text' }, 
	{ label: 'Capturó', key: 'user', sort: 'text' }
]; 

const Component = ({ getData, ...props }) => {

	const [ values, setValues ] = useState([]);
	const [ query, setQuery ] = useState('');
	const [ totalCharges, setTotalCharges ] = useState(0);

	useEffect(() => {
		getData && recordsServices.get(`v2/customers/charges${query}`)
			.then(data => {
				const tmpTotalCharges = data.reduce((total, el) => total + parseFloat(el.amount), 0);
				setTotalCharges(moneyFormat(tmpTotalCharges));
				setValues(data);
        props.getDataFromServer(false);
			})
			.catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleFilter = ({ start, finish }) => {
		if(start && finish) {
			setQuery(`?start=${start}&finish=${finish}`);
			props.getDataFromServer(true);
		}
	}

	return (
		<Module 
			route=''
      title='Reporte de Cobranza'
      Rows={Rows}
      noEdit
      noDelete
      noCreate
      subTitle={`Cobro Total: ${totalCharges}`}
      filter={[handleFilter, [{
      	field: 'date',
      	icon: <TodayIcon />,
      	name: 'start',
      	label: 'Fecha Inicial'
      },{
      	field: 'date',
      	icon: <EventIcon />,
      	name: 'finish',
      	label: 'Fecha Final'
      }]]}
      records={values}
    />
	);
};

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: value => dispatch(getDataFromServer(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component); 