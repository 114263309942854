import format from 'date-fns/format';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';

import { unjustifiedDiscount, currencyFormat } from '../../_helpers/general';
import { newDate } from '../../_helpers.v2';
import { lastDayOfMonth } from 'date-fns';

const currentDate = new Date();
const start = () => {
	if(format(currentDate, 'd') < 16) return format(currentDate, 'yyyy-MM-01');
	else return format((currentDate), 'yyyy-MM-16');
}
const finish = () => {
	if(format(currentDate, 'd') < 16) return format((currentDate), 'yyyy-MM-15');
	else return format((lastDayOfMonth(currentDate)), 'yyyy-MM-dd');
}


const nameOfTheDay = { 1: "Lunes", 2: "Martes", 3: "Miercoles", 4: "Jueves", 5: "Viernes", 6: "Sábado", 7: "Domingo" }; 
const choosePrice = (status, salary) => {
	switch(status){
		case 4:
		case 1:
			return currencyFormat(salary);
		case 2:
			return '-' + currencyFormat(unjustifiedDiscount);
		default:
			return currencyFormat(0);
	}
}

const daysOfTheWeek = (startParam, endParam) => eachDayOfInterval({ start: newDate(startParam || start), end: newDate(endParam || finish) });
const headerDaysOfTheWeek = (startParam, endParam) => daysOfTheWeek(startParam, endParam).map(el => ({ 
	label: `${nameOfTheDay[format(el, 'i')]} ${format(el, 'd MMM')}`, 
	value: format(el, 'yyyy/MM/dd')
}) );

const headerDaysOfTheWeek1 = (startParam, endParam) => daysOfTheWeek(startParam, endParam).map(el => ({ 
	label: `${nameOfTheDay[format(el, 'i')]} ${format(el, 'd MMM')}`, 
	value: format(el, 'yyyy-MM-dd')
}) );
const headerLabelDaysOfTheWeek = (startParam, endParam) => daysOfTheWeek(startParam, endParam).map(el => ({ 
	label: `${nameOfTheDay[format(el, 'i')]}`, 
	value: format(el, 'yyyy/MM/dd')
}) );


const HANDLE_ERROR_MESSAGE = "Para hacer uso de esta opción necesitas tomar al menos una asistencia.";

const checkinFormat = data => {
	const tmp = data.map((el, i) => {
		const { CheckIns, ...data } = el;
		const datmpDays = CheckIns.reduce((array, elCh) => {
			return elCh && elCh.date 
				? { ...array, [elCh.date]: array[elCh.date] ? [ ...array[elCh.date], elCh ] : [elCh] }
				: array;
		}, {});
		return { ...data, ...datmpDays };
	});
	return tmp;
}

export { checkinFormat, headerDaysOfTheWeek1, headerLabelDaysOfTheWeek, nameOfTheDay, choosePrice, start, finish, daysOfTheWeek, currentDate, headerDaysOfTheWeek, HANDLE_ERROR_MESSAGE };