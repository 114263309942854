import React, { useEffect, Fragment, useState } from "react";
import { connect } from 'react-redux';

import isEqual from 'date-fns/isEqual';
import setHours from 'date-fns/setHours'


import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import CreditCardIcon from '@material-ui/icons/CreditCardOutlined';

import { openFormDialog, editValueAction } from '../../_actions';
import ConfigPayment from './payment';

import { recordsServices } from '../../_services';
import { currencyFormat } from '../../_helpers';

import { start, finish, headerDaysOfTheWeek, daysOfTheWeek } from '../CheckIn/helpers.js';
import { Table, CustomerCell, HeaderCell, Button } from '../CheckIn/_components';
import { newDate } from "../../_helpers.v2";

const CheckIn = ({ openFormDialog, editValueAction, ...props }) => {
	const [ checkIns, setCheckIns ] = useState({ count: 0, rows: [] });

	useEffect(() => {
		recordsServices.getAll({ table: 'checkIns', receivable: true, start, finish }).then(data => setCheckIns(data));
	}, []);

	const handleConfirmPayment = e => {
		editValueAction({ customerId: e.customerId });
		openFormDialog('ConfigPayment');
	}

	return (
		<Fragment>
			<Table>
				<TableHead>
					<TableRow>
						<CustomerCell>Cliente</CustomerCell>
						{ headerDaysOfTheWeek.map(el => <HeaderCell key={el} align="center">{el}</HeaderCell> )}
						<HeaderCell align="right">Saldo</HeaderCell>
						<TableCell />
					</TableRow>
				</TableHead>
				<TableBody>
					{ checkIns.rows.map(el => 
						<TableRow key={el.id}>
							<TableCell>{el.name}</TableCell>
							{ daysOfTheWeek.map(elDay => {
								const currentCheckIn = el.CheckIns.filter(elChecks => isEqual(elDay, setHours(newDate(elChecks.date), 0)));
								return (
									<TableCell align="center" key={elDay}>
										{ currentCheckIn.length > 0 ? 
												currentCheckIn.map(children => 
													<Fragment key={children.id}> {currencyFormat(children.total)}MXN <hr/> </Fragment>
												)
											: "X" 
										}
									</TableCell>
								)
							}) }
							<TableCell align="right">{ currencyFormat(el.balance) }MXN</TableCell>
							<TableCell align="right">
								<Button 
									title="Emitir Cobro"
									icon={<CreditCardIcon />}
									onClick={ handleConfirmPayment }
									customerId={ el.id }
								/>
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			<ConfigPayment />
		</Fragment>
	);

}

const mapDispatchToProps = dispatch => ({
  openFormDialog: data => dispatch(openFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data))
});

export default connect(null, mapDispatchToProps)(CheckIn);