const countries = localStorage.getItem('countries') ? JSON.parse(localStorage.getItem('countries')) : { count: 0, rows: [] };
const session = JSON.parse(localStorage.getItem('session'));

export const unjustifiedDiscount = session ? session.data.unjustifiedDiscount : 0;

export const countriesSelectField = countries && countries.rows.map(({id, name}) => ({ key: id, value: name }))
export const statesSelectField = countryId => {
	const states = countries.rows.map(({ id, states }) => 
		(parseInt(id) === parseInt(countryId)) && states.rows.map(el => ({ key: el.id, value: el.name }))
	).filter(el => el);
	return states[0] ? states[0] : [{ key: '', value: 'No se encontraron valores para mostrar.' }];
}

export const addressFormat = address => {
  
  const addressLine1 = address.addressLine1 ? `${address.addressLine1}, ` : "";
  const addressLine2 = address.addressLine2 ? `${address.addressLine2}, ` : "";
  const city = address.city ? `${address.city}, ` : "";
  const State = address.State ? `${address.State.name}, ` : "";
  const zipCode = address.zipCode ? `CP ${address.zipCode}` : "";

  return `${addressLine1}${addressLine2}${city}${State}${zipCode}`;

};

export const currencyFormat = num => (Number(num).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));