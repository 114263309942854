import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';

import Dialog from './dialog';
import { recordsServices } from '../../_services';

const defaultValues = {
	description: ""
};

export default function DescriptionDialog(props) {

	const [ values, setValues ] = useState(defaultValues);

	const handleChange = e => {
  	const { name, value } = e.target;
  	setValues(values => ({ ...values, [name]: value }));
  }

  const handleAccept = async () => {
  	try{
  		const { description } = values;
  		await recordsServices.update({ table: "filipinasConfig", id: props.customerId, description });
  		await handleClose();
  		await setValues(defaultValues);	
  	}catch (e){
  		console.log(e.message);
  	}
  }

  const handleClose = () => {
  	props.handleClose('description', { resetForm: true });
  }

	return (
		<Dialog
			isOpen={ props.isOpen }
			handleClose={ handleClose }
			handleAccept={ handleAccept }
			title="Obstrucción del Seguimiento"
			contentText="Utiliza este módulo es caso de no ser posible dar seguimiento al proceso del cliente. Trata de ser específico."
		>
			<TextField name="description" label="Describe el motivo" onChange={handleChange} value={values.description} fullWidth multiline />
		</Dialog>
	);
}