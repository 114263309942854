import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
    marginVertical: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
    },
}));

const SelectFieldComponent = ({ label, error, onChange, options, helperText, params, ...props }) => {
    const classes = useStyles();
    const handleChange = e => {
        onChange && onChange(e.target.name, e.target.value, params);
    };
    return (
        <div className={classes.marginVertical}>
            <Typography variant='subtitle1'>
                {label}
            </Typography>
            <TextField
                select
                name={props.name}
                variant={props.variant || 'outlined'}
                fullWidth={props.fullWidth || true}
                autoFocus={props.autoFocus || false}
                onChange={handleChange}
                value={props.value}
                error={typeof error === 'string'}
                helperText={error || helperText}
                autoComplete='off'
                size='small'
                {...props}
            >
                {
                    options.map(el => (
                        <MenuItem key={el.value} value={el.value}>{el.label}</MenuItem>
                    ))
                }
            </TextField>
        </div>
    );
};

export default SelectFieldComponent;
