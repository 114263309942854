import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';

import Dialog from './dialog';
import { recordsServices } from '../../_services';

const defaultValues = {
	tracing: ''
};

export default function TrancingDialog(props) {

	const [ values, setValues ] = useState(defaultValues);

	const handleChange = e => {
  	const { name, value } = e.target;
  	setValues(values => ({ ...values, [name]: value }));
  }

  const handleAccept = async () => {
  	try{
  		const { tracing } = values;
  		await recordsServices.update({ table: "filipinasConfig", id: props.customerId, tracing });
  		await handleClose();
  		await setValues(defaultValues);	
  	}catch (e){
  		console.log(e.message);
  	}
  }

  const handleClose = () => {
  	props.handleClose('tracing', { resetForm: true });
  }

	return (
		<Dialog
			isOpen={ props.isOpen }
			handleClose={ handleClose }
			handleAccept={ handleAccept }
			title="Módulo de Seguimiento"
			contentText="Proporciona las anotaciones para el seguimiento del cliente."
		>
			<TextField name="tracing" label="Anotaciones del seguimiento" onChange={handleChange} value={values.description} fullWidth multiline />
		</Dialog>
	);
}