import React, { useMemo } from "react";
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';

import { FormDialog } from './../../_presentationals/FormDialog';
import { TextField, SelectField, DateField } from './../../_presentationals/Form';

import { countriesSelectField, statesSelectField } from '../../_helpers';

const ContractDialog = props => {
  
  const states = useMemo(() => statesSelectField(props.countryId), [props.countryId]);

  const handleContract = () => {
    const razonSocial = props.businessName;
    const rfc = props.identifier;
    const stateLabel = statesSelectField(props.countryId).find(el => el.key === props.stateId);
    const domicilio_empresa = props.addressLine1 + ', ' + props.addressLine2 + ', ' + props.city + ', ' + stateLabel.value + ' CP ' + props.zipCode;
    const representante_legal = props.legalRepresentative;
    const total_pagar = parseFloat(props.price).toFixed(2);
    const numero_mucamas = (parseInt(props.quantity) === 1) ? '1 PERSONA' : props.quantity + ' PERSONAS';
    const dias = props.daysToText;
    const chooseContractTime = props.chooseContractTime;
    const insumos = !props.supplies ? "NO" : "";
    
    const companyIdentifier = getCompanyIdentifier(parseInt(props.chooseCompany));
    const companyName = getCompanyName(parseInt(props.chooseCompany));

    const nombre_emisor = companyName
    const rfc_emisor = companyIdentifier;
    
    window.open(`https://api.cfdi.geecko.com.mx/v1/empresa/mucaama/contrato/empresa/pdf?razon_social=${razonSocial}&rfc=${rfc}&domicilio_empresa=${domicilio_empresa}&representante_legal=${representante_legal}&total_pagar=${total_pagar}&numero_mucamas=${numero_mucamas}&dias=${dias}&insumos=${insumos}&nombre_emisor=${nombre_emisor}&rfc_emisor=${rfc_emisor}&duracion_contrato=${chooseContractTime}`, '_blank');
  }

  return (
    <FormDialog
      table="customers"
      title="Datos de Contrato"
      singular="contrato"
      keepDataAfterSuccess={false}
      clearDataAfterClose={true}
    >
      <TextField
        icon="account_balance"
        fields={[{
          id: "identifier", 
          label: "RFC",
        }]}
      />
      <TextField
        fields={[{
          id: "businessName", 
          label: "Razón Social",
        }]}
      />
      <TextField
        fields={[{
          id: "legalRepresentative", 
          label: "Representante Legal",
        }]}
      />
      <TextField
        icon="room"
        fields={[{
          id: "addressLine1", 
          label: "Calle y Número"
        }]}
      />
      <TextField
        fields={[{
          id: "addressLine2", 
          label: "Colonia"
        }]}
      />
      <TextField
        fields={[{
          id: "zipCode", 
          label: "Código Postal"
        },{
          id: "city", 
          label: "Ciudad"
        }]}
      />
      <SelectField
        fields={[{
          id: 'countryId', 
          label: 'País',
          options: countriesSelectField
        }]}
      />
      <SelectField
        fields={[{
          id: 'stateId', 
          label: 'Estado',
          options: states,
        }]}
      />
      <TextField
        icon="extension"
        fields={[{
          id: "daysToText", 
          label: "Configuración de Horário",
        }]}
      />
      <SelectField
        fields={[{
          id: 'chooseCompany', 
          label: 'Emisor de Contrato',
          options: [
            { key: 1, value: 'EDER FERNANDO JIMENEZ GARCÍA'},
            { key: 2, value: 'JOSE CRUZ ROSALES FELIX'},
            { key: 3, value: 'CYNTHIA NALLELY ROSALES VEGA'},
            { key: 6, value: 'MARIA DEL ROSARIO VEGA ESEBERRE'},
            { key: 7, value: 'FERNANDO JIMENEZ RAMIREZ'},
          ],
          required: true
        }]}
      />
      <SelectField
        fields={[{
          id: 'chooseContractTime', 
          label: 'Duración de Contrato',
          options: [
            { key: 'TIEMPO INDETERMINADO', value: 'TIEMPO INDETERMINADO'},
            { key: '1 MES', value: '1 MES'},
            { key: '2 MESES', value: '2 MESES'},
            { key: '3 MESES', value: '3 MESES'},
            { key: '4 MESES', value: '4 MESES'},
            { key: '5 MESES', value: '5 MESES'},
            { key: '6 MESES', value: '6 MESES'},
            { key: '7 MESES', value: '7 MESES'},
            { key: '8 MESES', value: '8 MESES'},
            { key: '9 MESES', value: '9 MESES'},
            { key: '10 MESES', value: '10 MESES'},
            { key: '11 MESES', value: '11 MESES'},
            { key: '12 MESES', value: '12 MESES'}
          ],
          required: true
        }]}
      />
      <DateField 
        id="contractDate"
        label="Fecha de Contrato"
      />
      <Button variant="contained" color="secondary" onClick={handleContract} style={{ marginTop: 10, marginLeft: 50, marginBottom: 20 }}>
        Generar Contrato
      </Button>
    </FormDialog>
  )
}

function getCompanyIdentifier(value){
  switch(value){
    case 1:
      return "JIGE830606G90";
    case 2:
      return "ROFC560503919";
    case 3:
      return "RIVC870905FH2";
    case 6:
      return "VEER5705161U0";
    case 7:
      return "JIRF540914N61";
    default:
      return "Indefinido";
  }
}

function getCompanyName(value){
  switch(value){
    case 1:
      return "EDER FERNANDO JIMENEZ GARCÍA";
    case 2:
      return "JOSE CRUZ ROSALES FELIX";
    case 3:
      return "CYNTHIA NALLELY ROSALES VEGA";
    case 6:
      return "MARIA DEL ROSARIO VEGA ESEBERRE";
    case 7:
      return "FERNANDO JIMENEZ RAMIREZ";
    default:
      return "Indefinido";
  }
}



const mapStateToProps = state => ({
  countryId: state.field.value.countryId || '',
  businessName: state.field.value.businessName || '',
  identifier: state.field.value.identifier || '',
  stateId: state.field.value.stateId || null,
  addressLine1: state.field.value.addressLine1 || '',
  addressLine2: state.field.value.addressLine2 || '',
  city: state.field.value.city || '',
  zipCode: state.field.value.zipCode || '',
  price: state.field.value.price || 0,
  quantity: state.field.value.quantity || 0,
  legalRepresentative: state.field.value.legalRepresentative || '',
  daysToText: state.field.value.daysToText || '',
  chooseContractTime: state.field.value.chooseContractTime || null,
  supplies: state.field.value.supplies || false,
  chooseCompany: state.field.value.chooseCompany || 1
});

export default connect(mapStateToProps)(ContractDialog);