import { recordsConstants } from '../_constants';
import { recordsServices } from '../_services';
import { progressActions, validateFieldAction, alertActions } from '../_actions';

const create = table => (dispatch, getState) => {
  
  if(!table) return;

  const fields = getState().field.value;
  
  dispatch(progressActions.openDialog());

  recordsServices.create({table, ...fields, redirectId: getState().redirect.id, redirectTable: getState().redirect.table})
  .then(data => {
    dispatch(progressActions.closeDialog());
    if(data.errorCode){
      const error = JSON.parse(data.errorMessage);
      error.forEach(({ name, message }) => {
        dispatch(validateFieldAction({[name]: { isError: true, message } }));
      });
      return;
    }
    dispatch(alertActions.clear())
    dispatch(alertActions.success(data.message || "Petición realizada con éxito."));
  });

}

const update = table => (dispatch, getState) => {
 
  if(!table) return;

  const fields = getState().field.value;
  const fieldsId = getState().field.id;
  
  if(!fields.id) return;

  dispatch(progressActions.openDialog());

  recordsServices.update({table, ...fields, fieldsId, redirectId: getState().redirect.id, redirectTable: getState().redirect.table})
  .then(data => {
    dispatch(progressActions.closeDialog());
    if(data.errorCode){
      const error = JSON.parse(data.errorMessage);
      error.forEach(({ name, message }) => {
        dispatch(validateFieldAction({[name]: { isError: true, message } }));
      });
      return;
    }
    dispatch(alertActions.clear());
    dispatch(alertActions.success(data.message || "Petición realizada con éxito."));
  });

}

const _delete = ({_id, table}) => (dispatch, getState) => {
  
  if(!table) return;
  const fields = getState().field.value;

  dispatch(progressActions.open());

  recordsServices._delete({table, ...fields, _id, redirectId: getState().redirect.id, redirectTable: getState().redirect.table})
  .then(data => {
    dispatch(progressActions.close());
    if(data.errorCode){
      const error = JSON.parse(data.errorMessage);
      error.forEach(({ name, message }) => {
        dispatch(validateFieldAction({[name]: { isError: true, message } }));
      });
      return;
    }
    dispatch(alertActions.clear());
    dispatch(alertActions.success(data.message || "Petición realizada con éxito."));
  });
  
}

const getAll = values => (dispatch, getState) => {
  const { table, alias } = values || {};
  if(!table) return;

  recordsServices.getAll({ ...values, redirectId: getState().redirect.id, redirectTable: getState().redirect.table })
  .then(data => {
    getState().progress.isLoading && dispatch(progressActions.close());
    dispatch({
      type: recordsConstants.GET_RECORDS,
      payload: { table: !alias ? values.table : alias, ...data }
    });
  });
  
}

const clearRecords = table => dispatch => dispatch({ type: recordsConstants.DELETE_RECORDS, payload: table })

export const recordsActions = {
	create, getAll, update, _delete, clearRecords
};