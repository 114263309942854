import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

import format from 'date-fns/format';

import FormDialog from '../../../_presentationals.v2/FormDialog';
import TextField from '../../../_presentationals.v3/FormComponents/TextField';
import SelectField from '../../../_presentationals.v3/FormComponents/SelectField';
import DateField from '../../../_presentationals.v3/FormComponents/DateField';

import { recordsServices } from '../../../_services.v2';

import { alertActions, getDataFromServer } from '../../../_actions.v2';
import { newDate } from '../../../_helpers.v2';

const cityOptions = [
  { value: 'Culiacán', label: 'Culiacán'},
  { value: 'Mazatlán', label: 'Mazatlán'},
  { value: 'Los Mochis', label: 'Los Mochis'},
  { value: 'Guasave', label: 'Guasave'},
];

const receiptOptions = [
  { value: 1, label: 'Personal interno' },
  { value: 2, label: 'En proceso' },
  { value: 3, label: 'Reagendar' },
  { value: 4, label: 'Precio alto' },
  { value: 5, label: 'Contrato vigente' },
];

const defaultValues = {
	type: 2, 
	branchName: '', 
	name: '', 
	phone: '', 
	email: '', 
	addressLine1: '', 
	addressLine2: '',
	zipCode: '',
	city: 'Culiacán',
  trackingDate: new Date(),
  trackingCommentary: '',
  receipt: '',
  businessActivity: '',
};

const FormComponent = props => {
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (props.id && props.isOpen) {
      recordsServices.get(`crm/1/customer/${props.id}`).then(data => {
        setValues(values => ({
          ...values,
          ...data,
        }))
      }).catch(e => console.log(e));
    }
  }, [props.id, props.isOpen]);
  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }
  const handleCreate = async () => {
    try {
      setErrors({});
      let error;
      if (values.type === 2 && values.branchName === '') {
        error = new Error('Proporciona el nombre de la empresa.')
        error.name = 'branchName';
        throw error;
      }
      if (values.name === '') {
        error = new Error('Proporciona el nombre del contacto.')
        error.name = 'name';
        throw error;
      }
      if (values.addressLine2 === '') {
        error = new Error('Proporciona la colonia de la empresa.')
        error.name = 'addressLine2';
        throw error;
      }
      if (values.receipt === '') {
        error = new Error('Selecciona una justificación.')
        error.name = 'receipt';
        throw error;
      }
      if (!values.trackingDate) {
        error = new Error('Proporciona una fecha para el seguimiento.')
        error.name = 'trackingDate';
        throw error;
      }
      if (values.trackingCommentary === '') {
        error = new Error('Proporciona un comentario para el seguimiento.')
        error.name = 'trackingCommentary';
        throw error;
      }
      if (values.receipt === '') {
        error = new Error('Proporciona una justificación.')
        error.name = 'receipt';
        throw error;
      }
      await recordsServices.update(`crm/1/customer/${props.id}`, {
        ...values,
        trackingDate: format(newDate(values.trackingDate), 'yyyy/MM/dd'),
      });
      props.getDataFromServer(true);
      props.showAlert({ variant: 'success' });
      props.handleClose();
      setValues(defaultValues);
    } catch(e) {
      if (['name', 'branchName', 'addressLine2', 'trackingDate', 'trackingCommentary', 'receipt'].includes(e.name)){
        setErrors({[e.name]: e.message});
      }
    }
  }
  return (
    <FormDialog
      title='Prospecto | Paso 1'
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      handleAction={handleCreate}
    >
      <TextField
        name='branchName'
        label='Nombre de la empresa'
        value={values.branchName}
        onChange={handleChange}
        error={errors.branchName}
      />
      <TextField
        name='businessActivity'
        label='Giro de la empresa'
        value={values.businessActivity}
        onChange={handleChange}
        error={errors.businessActivity}
      />
      <TextField
        name='name'
        label='Nombre del contacto'
        value={values.name}
        onChange={handleChange}
        error={errors.name}
      />
      <TextField
        name='phone'
        label='Teléfono'
        value={values.phone}
        onChange={handleChange}
        error={errors.phone}
      />
      <TextField
        name='email'
        label='Correo Electrónico'
        value={values.email}
        onChange={handleChange}
        error={errors.email}
      />
      <TextField
        name='addressLine2'
        label='Colonia'
        value={values.addressLine2}
        onChange={handleChange}
        error={errors.addressLine2}
      />
      <TextField
        name='addressLine1'
        label='Calle y número'
        value={values.addressLine1}
        onChange={handleChange}
        error={errors.addressLine1}
      />
      <SelectField
        name='city'
        label='Ciudad'
        value={values.city}
        options={cityOptions}
        onChange={handleChange}
        error={errors.city}
      />
      <SelectField
        name='receipt'
        label='Justificante'
        value={values.receipt}
        options={receiptOptions}
        onChange={handleChange}
        error={errors.receipt}
      />
      <DateField
        name='trackingDate'
        label='Fecha de seguimiento'
        value={values.trackingDate}
        onChange={handleChange}
        error={errors.trackingDate}
      />
      <TextField
        name='trackingCommentary'
        label='Comentario para el seguimiento'
        value={values.trackingCommentary}
        onChange={handleChange}
        error={errors.trackingCommentary}
      />
    </FormDialog>
  );
};

const mapDispatchToProps = dispatch => ({
  showAlert: data => dispatch(alertActions.show(data)),
  getDataFromServer: value => dispatch(getDataFromServer(value)),
})

export default connect(null, mapDispatchToProps)(FormComponent);
