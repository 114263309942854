import React from "react";

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import { DialogTitle, DialogContent, DialogActions } from './style';

const Component = props => {
  const Theme = useTheme();
  const isSmallScreen = useMediaQuery(Theme.breakpoints.down('sm'));
  return (
    <Dialog
    open={ props.isOpen }
    onClose={ props.handleClose }
    fullWidth={ true }
    fullScreen={props.fullScreen || isSmallScreen}
    maxWidth = { props.width || 'sm' }
    disableEscapeKeyDown={false}
    >
      { props.title && <DialogTitle children={props.title} /> }
      <DialogContent>{ props.children }</DialogContent>
      <DialogActions>
        { !props.hideClose && <Button onClick={ props.handleClose }>Cerrar</Button> }
        { !props.hideAction && (
          <Button onClick={ props.handleAction } color="primary">
            { props.actionLabel || "Guardar" }
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default Component;