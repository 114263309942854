import React, { Fragment, useMemo, useEffect, useState } from "react";
import { connect } from 'react-redux';

import MessageIcon from '@material-ui/icons/MessageOutlined';
import AttachFileIcon from '@material-ui/icons/AttachFileOutlined';

import LocalHospitalIcon from '@material-ui/icons/LocalHospitalOutlined';

import Module from '../../_presentationals/Module';
import { openFormDialog, editValueAction, profileActions } from './../../_actions';
import { config, authHeader } from '../../_helpers';
import { handleFetchResponse } from './../../_services';

import Form from './_form';
import DescriptionDialog from './descriptionDialog';
import FileDialog from './fileDialog';
import HealthInsuranceDialog from './healthInsuranceDialog';

const HouseMaid = ({ openFormDialog, editValueAction, ...props }) => {
  const [contractImage, setContractImage] = useState(null);
  useEffect(() => {
    editValueAction({ isAllowed: true, city: 'Culiacán' });
  }, [editValueAction]);

  const maids = useMemo(() => 
    props.maids.count > 0 ? props.maids.rows.map(el => ({
      ID: { 
        value: el.id,
        show: false,
        data: {
          ...el,
          referencePhone: el.Reference && el.Reference.phone,
          referenceName: el.Reference && el.Reference.name,
          birthCertificate: el.Documents && el.Documents.birthCertificate,
          proofOfAddress: el.Documents && el.Documents.proofOfAddress,
          ine: el.Documents && el.Documents.ine,
          filing: el.filing.id,
          schedule: el.schedule.id,
          preference: el.preference.id,
          civilStatus: el.civilStatus.id,
          creditCardBank: el.creditCardBank.id
        }
      }, 
      Nombre: { value: el.name, filter: true, _sort: 'text' },
      Banco: { value: el.creditCardBank, filter: true, _sort: 'text' },
      NoTarjeta: { value: el.creditCardId, filter: true, _sort: 'text' },
      Propietario: { value: el.creditCardOwner, filter: true, _sort: 'text' },
      Teléfono: { value: el.phone },
      Tipo: { value: preferenceToLabel(el.preference.id), _sort: 'text' },
      Colonia: { value: el.addressLine2, _sort: 'text' },
      Prospección:{ value: el.Status.prospecting },
      Entrevista:{ value: el.Status.interview },
      "En Espera":{ value: el.Status.waiting },
      Colocada:{ value: el.Status.working },
      Comentarios: { value: `${el.description} ${ el.descriptionDate ? el.descriptionDate : '' }`},
      background: {
        value: el.background,
        show: false
      }
    })) : []
  , [props.maids]);

  const handleMessage = e => {
    const data = JSON.parse(e.currentTarget.dataset.row);
    const { id, description } = data;
    editValueAction({ isInterview: true, id, description });
    openFormDialog('comentario');
  }

  const handleContract = async e => {
    const data = JSON.parse(e.currentTarget.dataset.row);
    const { id } = data;
    editValueAction({ id });
    const image = await getDataFromServer(id);
    if(image) setContractImage(image.image);
    openFormDialog('contrato');
  }

  const handleChange = files => {
    props.createFile({ id: props.id, type: 'houseMaidContract', profile: files[0] });
  }

  const handleAction = e => {
    const data = JSON.parse(e.currentTarget.dataset.row);
    //id, healthInsurance, taxBenefits, additionalBenefits, infonavit, status, startBenefit, finishBenefit
    const { id, healthInsurance, infonavit } = data;
    editValueAction({ id, healthInsurance, infonavit, updateBenefits: true });
    openFormDialog('healthInsurance');
  }

  return (
    <Fragment>
      <Module
        table="maids"
        name="Mucamas"
        singularName="mucama"
        filter={false}
        _delete={false}
        editText="Entrevista"
        title="Crear Mucama"
        actions={[{
          icon: <AttachFileIcon fontSize="small" />,
          label: "Adjuntar Contrato",
          handle: handleContract
        },{
          icon: <MessageIcon fontSize="small" />,
          label: "Comentario",
          handle: handleMessage
        },{
          icon: <LocalHospitalIcon fontSize="small" />,
          label: "Seguro e Infonavit",
          handle: handleAction
        }]}
        records={maids}
      >
        <Form 
          _id={ props.id } 
          name={ props.name } 
          years={ props.years } 
          type={ props.type } 
          supplies={ props.supplies } 
          salary={ props.salary } 
          address={ props.addressLine1 + ', ' + props.addressLine2 + ', ' + props.city + ', SINALOA ' + props.zipCode } 
          editValueAction={editValueAction} 
          Days={ props.Days }
          chooseCompany={ props.chooseCompany }
        />
      </Module>
      <DescriptionDialog />
      <FileDialog handleChange={handleChange} image={contractImage} />
      <HealthInsuranceDialog />
    </Fragment>
  );
}



const preferenceToLabel = value => {
  switch(value){
    case 1:
      return "Casa";
    case 2:
      return "Oficina";
    case 3:
      return "Ambos";
    case 4:
      return "Sin Experiencia";
    default: return "Indefinido"
  }
}

const getDataFromServer = id => {
  const method = 'GET';
  const url = `${config.apiUrl}/maids/${id}/contracts`;
  const myRequest = new Request(
    url, { method, headers: { ...authHeader() } }
  );
  return fetch(myRequest)
    .then(handleFetchResponse({ url, method, headers: {} }))
    .then(data => data);
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  id: state.field.value.id || null,
  name: state.field.value.name || '',
  years: state.field.value.years || '',
  type: state.field.value.type || null,
  supplies: state.field.value.supplies || null,
  salary: state.field.value.salary || null,
  addressLine1: state.field.value.addressLine1 || '',
  addressLine2: state.field.value.addressLine2 || '',
  city: state.field.value.city || '',
  zipCode: state.field.value.zipCode || '',
  Days: state.field.value.Days || {},
  chooseCompany: state.field.value.chooseCompany || null,
  maids: state.records.maids || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({ 
  openFormDialog: data => dispatch(openFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data)),
  createFile: data => dispatch(profileActions.createFile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HouseMaid);