import React, { PureComponent, Fragment } from "react";
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

// import { Validator } from './';

import Grid from '@material-ui/core/Grid';
import InputText from '@material-ui/core/TextField';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';

import { editValueAction, validateFieldAction } from '../../_actions';

const styles = theme => ({
  container: {
    marginTop: 4
  },
  smallFontSize: {
    fontSize: 16
  },
  largeFontSize: {
    fontSize: 20
  },
  visibilityIconButton: {
    '&:hover': {
      background: 'transparent'
    }
  }
});

export class PasswordFieldUI extends PureComponent {
  
  state = {
    showPassword: false,
    isHidden: false
  }

  componentDidMount = () => {
    const { editValueAction, fieldValue, id, confirmPassword } = this.props;
    editValueAction({ [id]: fieldValue[id] || "" });
    if(confirmPassword) {
      editValueAction({ [capitalize(id)]: fieldValue[capitalize(id)] || "" });
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    const { editValueAction, validateFieldAction, id, fieldValue } = this.props;
    editValueAction({ [name]: value });

    if(name === `confirm${capitalize(id)}`){
      const passwordValue = fieldValue[id];
      if(passwordValue !== value){
        validateFieldAction({ [name]: { 
          isError: true, 
          message: "Estas contraseñas no coinciden."
        } })
        return;
      }
    }

    // const validation = Validator({type: "password", value, required});
    // validateFieldAction({ [name]: { ...validation } })
    
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render(){
    const { fieldValue, dispatch, id, label, dialog, fullWidth, confirmPassword, fieldValidation, required, margin, icon, classes, editValueAction, validateFieldAction, ...props } = this.props;
    const { showPassword } = this.state;
    
    return (
      <Fragment>
        { dialog ? ( 
          "Prueba"
        ) : (
          <Grid container spacing={8} className={ classes.container }>
            <Grid item md={confirmPassword ? 6 : 12} xs={12}>
              <InputText 
                id={ id }
                name={ id }
                label={ label }
                type={ showPassword ? 'text' : 'password' }
                margin={ margin }
                InputProps={{ 
                  classes: {
                    input: showPassword ? classes.smallFontSize : classes.largeFontSize,
                  },
                  endAdornment: !confirmPassword && (
                    <InputAdornment position="end">
                      <IconButton className={ classes.visibilityIconButton } onClick={this.handleClickShowPassword} aria-label="Ver Contraseña">
                        { showPassword ? <Visibility /> : <VisibilityOff /> }
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                value={fieldValue[id] || ""}
                fullWidth={ fullWidth }
                onChange={this.handleChange}
                required= { required }
                error={fieldValidation[id] && fieldValidation[id].isError}
                FormHelperTextProps={{ component: "div" }}
                helperText={
                  (fieldValidation[id] && fieldValidation[id].isError) && (
                    <Grid container item spacing={0} alignItems="center" >
                      <ErrorIcon fontSize="small" />
                      {fieldValidation[id].message} 
                    </Grid> 
                  )}
                { ...props }
              />
            </Grid>
            {
              confirmPassword && (
                <Grid item md={6} xs={12}>
                  <InputText 
                    id={ `confirm${capitalize(id)}` }
                    name={ `confirm${capitalize(id)}` }
                    label="Confirmación"
                    value={fieldValue[`confirm${capitalize(id)}`] || ""}
                    type={ showPassword ? 'text' : 'password' }
                    margin={ margin }
                    onChange={this.handleChange}
                    InputProps={{ 
                      classes: {
                        input: showPassword ? classes.smallFontSize : classes.largeFontSize,
                      },
                      endAdornment: <InputAdornment position="end">
                        <IconButton className={ classes.visibilityIconButton } onClick={this.handleClickShowPassword} aria-label="Mostrar">                            
                          { showPassword ? <Visibility /> : <VisibilityOff /> }
                        </IconButton>
                      </InputAdornment>
                    }}
                    fullWidth={ fullWidth }
                    required= { required }
                    error={fieldValidation[`confirm${capitalize(id)}`] && fieldValidation[`confirm${capitalize(id)}`].isError}
                    FormHelperTextProps={{ component: "div" }}
                    helperText={
                      (fieldValidation[`confirm${capitalize(id)}`] && fieldValidation[`confirm${capitalize(id)}`].isError) && (
                        <Grid container item spacing={0} alignItems="center" >
                          <ErrorIcon fontSize="small" />
                          {fieldValidation[`confirm${capitalize(id)}`].message} 
                        </Grid> 
                      )}
                    { ...props }
                  />
                </Grid>
              )
            }
          </Grid>
        )}
      </Fragment>
    )
  }
}

const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

PasswordFieldUI.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  margin: PropTypes.string.isRequired,
  confirmPassword: PropTypes.bool,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool
};

PasswordFieldUI.defaultProps = {
  fullWidth: true,
  required: true,
  dialog: true,
  icon: false,
  confirmPassword: false,
  margin: "none"
};

const mapStateToProps = state => ({ 
  fieldValue: state.field.value,
  fieldValidation: state.field.validation,
});

const mapDispatchToProps = dispatch => ({
  editValueAction: data => dispatch(editValueAction(data)),
  validateFieldAction: data => dispatch(validateFieldAction(data))
});

const PasswordField = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PasswordFieldUI));

export { PasswordField };