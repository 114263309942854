import React from 'react';

import Typography from '@material-ui/core/Typography';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FixedSizeList } from 'react-window';
import PropTypes from 'prop-types';

import './style.css';

const useStyles = makeStyles(() => ({
    listbox: {
        '& ul': {
          padding: 0,
          margin: 0,
        },
    },
}));

const useStyles1 = makeStyles(theme => ({
  marginVertical: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
}));

function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
      style: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'block',
        ...style,
      },
    });
};

const ListboxComponent = React.forwardRef((props, ref) => {
    const { children, ...other } = props;
    const Theme = useTheme();
    const smUp = useMediaQuery(Theme.breakpoints.up('sm'));
    const itemCount = Array.isArray(children) ? children.length : 0;
    const itemSize = smUp ? 36 : 48;
    const outerElementType = React.useMemo(() => {
      return React.forwardRef((props2, ref2) => <div ref={ref2} {...props2} {...other} />);
    }, []); // eslint-disable-line
    return (
      <div ref={ref}>
        <FixedSizeList
          style={{ padding: 0, height: Math.min(8, itemCount) * itemSize, maxHeight: 'auto' }}
          itemData={children}
          height={250}
          width='100%'
          outerElementType={outerElementType}
          innerElementType='ul'
          itemSize={itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </FixedSizeList>
      </div>
    );
});
ListboxComponent.propTypes = { children: PropTypes.node };

const AutocompleteComponent = ({ onChange, helperText, error, label, onFocus, onBlur, ...props }) => {
    const classes = useStyles();
    const classes1 = useStyles1();
    const handleChange = (e, newValue) => {
        const tmp = newValue || (props.defaultValue
            ? props.defaultValue
            : null);
        onChange(props.name, tmp);
      };
    return (
        <div className={classes1.marginVertical}>
            <Typography variant='subtitle1'>
                {label}
            </Typography>
            <Autocomplete
                id={props.name}
                disableListWrap
                classes={classes}
                value={props.value}
                onChange={handleChange}
                onFocus={onFocus}
                onBlur={onBlur}
                ListboxComponent={ListboxComponent}
                options={props.options}
                getOptionLabel={option => option.label}
                noOptionsText='No encontramos resultados'
                renderInput={params => (
                    <TextField
                    {...params}
                    variant={props.variant || 'outlined'}
                    fullWidth
                    autoFocus={props.autoFocus}
                    error={!!error}
                    helperText={error || helperText}
                    size={props.size || 'small'}
                    />
                )}
            />
        </div>
    );
};

export default AutocompleteComponent;
