import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
	danger: {
		background: '#ffebeb'
	},
	regular: {
		background: 'transparent'
	},
	statusTableCell: {
		minWidth: '120px'
	},
	customerTableCell: {
		minWidth: '200px'
	},
	descriptionTableCell: {
		minWidth: '240px'
	}, 
	header: {
		borderBottom: '1px solid #e0e0e0',
		paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    position: 'fixed',
    zIndex: 1000,
    width: '78%',
    background: "#fff"
	},
	marginTop: {
		marginTop: theme.spacing(2)
	},
	textRight: {
		textAlign: "right"
	},
	title: {
		fontWeight: "bold"
	},
	toolbar: theme.mixins.toolbar,
	container: { maxHeight: 600 }
}));