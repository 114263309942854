import React, { useMemo } from "react";
import { connect } from 'react-redux';

import MapIcon from '@material-ui/icons/MapOutlined';

import Module from '../../_presentationals/Module';
import { TextField } from './../../_presentationals/Form';
import { history } from './../../_helpers';
import { redirectActions } from './../../_actions';

const Country = props => {

  const countries = useMemo(() => 
    props.countries.count > 0 ? props.countries.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      }, 
      País: { 
        value: el.name 
      },
       Código: {
       value: el.countryCode
      }
    })) : []
  , [props.countries]);
  
  const redirect = e => { 
    const { setRedirect } = props;
    const data = JSON.parse(e.currentTarget.dataset.row);
    setRedirect({id: data.id, table: 'states', returnTo: '/Country', returnToLabel: 'Paises'});
    history.push('/Country/State');
  };

  return (
    <Module
      table="countries"
      name="paises"
      singularName="país"
      filter={false}

      title="Crear País"
      actions={[{
        icon: <MapIcon fontSize="small" />,
        label: "Estados",
        handle: redirect
      }]}
      records={countries}
    >
      <TextField
        icon="language"
        fields={[{
          id: "name", 
          label: "Nombre",
          autoFocus: true
        }]}
      />
      <TextField
        fields={[{
          id: "countryCode",
          label: "Código"
        }]}
      />
    </Module>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  countries: state.records.countries || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  setRedirect: data => dispatch(redirectActions.setRedirect(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Country);