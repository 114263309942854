import { addMonths, eachDayOfInterval, endOfMonth, getMonth, startOfMonth, subMonths } from "date-fns";

const getDaysArrayInMonth = (month) => {
    const currentMonth = getMonth(new Date()) + 1;
    const diference =
      parseInt(month) >= parseInt(currentMonth)
        ? parseInt(month) - parseInt(currentMonth)
        : parseInt(currentMonth) - parseInt(month);
    const start =
      parseInt(month) >= parseInt(currentMonth)
        ? new Date(startOfMonth(new Date(addMonths(new Date(), parseInt(diference)))))
        : new Date(startOfMonth(new Date(subMonths(new Date(), parseInt(diference)))));
    const finish =
      parseInt(month) >= parseInt(currentMonth)
        ? new Date(endOfMonth(new Date(addMonths(new Date(), parseInt(diference)))))
        : new Date(endOfMonth(new Date(subMonths(new Date(), parseInt(diference)))));
  
    const days = eachDayOfInterval({ start, end: finish });
  
    return days;
  };

  export default getDaysArrayInMonth