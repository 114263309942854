import makeStyles from '@material-ui/core/styles/makeStyles';

const screenWidth = window.innerWidth;

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    overflowX: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: screenWidth,
    },
  },
  redBackground: {
    background: '#ffc3c3',
  }
}));

export default useStyles;
