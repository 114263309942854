import { config, authHeader } from '../_helpers';
import { handleFetchResponse } from './../_services';

const create = (request, values) => {
  const headers = { 'Content-Type': 'application/json', ...authHeader() };
  const method = 'POST';
  const url = `${config.apiUrl}/${request}`;
  const body = JSON.stringify(values);
  const myRequest = new Request(url, { method, headers, body });
  return fetch(myRequest).then(handleFetchResponse({ url, method, headers, body }));
}

const update = (request, values) => {
  const headers = { 'Content-Type': 'application/json', ...authHeader() };
  const method = 'PUT';
  const url = `${config.apiUrl}/${request}`;
  const body = JSON.stringify(values);
  const myRequest = new Request(url, { method, headers, body });
  return fetch(myRequest).then(handleFetchResponse({ url, method, headers, body }));
}

const _delete = request => {
  const method = 'DELETE';
  const url = `${config.apiUrl}/${request}`;
  const myRequest = new Request(url, { method, headers: { ...authHeader() } });
  return fetch(myRequest).then(handleFetchResponse({ url, method, headers: { ...authHeader() } }));
}

const get = request => {
  const method = 'GET';
	const url = `${config.apiUrl}/${request}`;
  const myRequest = new Request(url, { method, headers: { ...authHeader() } });
	return fetch(myRequest).then(handleFetchResponse({ url, method, headers: { ...authHeader() } }));
}

export const recordsServices = {
 create, get, update, _delete
};
