import React from 'react';
import ButtonMUI from '@material-ui/core/Button';
import TableCellMUI from '@material-ui/core/TableCell';

import { useStyles } from './style';

export const Button = props =>
	<ButtonMUI 
		onClick={ props.handleClick } 
		data-customerid={ props.customerId } 
		data-status={ props.status || "" } 
		size="small" 
		variant="outlined"
		color={ props.color || "default" }
		className={ props.className && props.className }
	>
		{ props.label }
	</ButtonMUI>


export const TableCell = props => {
	const classes = useStyles();
	return (
		<TableCellMUI className={classes.statusTableCell} align="center">
			{ props.date 
				? props.date 
				:  <Button 
							label={ props.label }
							status={ props.status } 
							handleClick={ props.handleClick } 
							customerId={ props.customerId } 
						/> 
			}
		</TableCellMUI>
	);
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}