import React, { Fragment, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { format, getMonth } from 'date-fns';

import { newDate, queryFormat } from './../../_helpers.v2/general';
import { recordsServices } from './../../_services.v2';
import { alertActions, getDataFromServer } from './../../_actions.v2';
import TextField from './../../_presentationals.v2/Form/TextField';

import FilterDialog from './filter';
import Header from './_header';
import { useStyles } from './style';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import getDaysArrayInMonth from "../../_helpers/getDaysArrayInMonth";
import sortArrayByDate from "../../_helpers/sortArrayByDate";

const defaultValues = {
  values: [],
  goalProspectingMaid: 0, goalInterview: 0, goalWaitingMaid: 0, goalWorkingMaid: 0,
  goalProspectingCustomer: 0, goalQuotationCustomer: 0, goalWaitingCustomer: 0, goalWorkingCustomer: 0,
  goalUnCheckIns: 0, goalReceivables: 0, goalUtility: 0, goalUtilityPercent: 0,
  goalVentaBruta: 0,
  goalCostoVenta: 0,
  goalUtilidadBruta: 0,
  goalUtilidadOperativa: 0,
  goalUtilidadNeta: 0,
  goalReceivableCommentaries: 0, receivableCommentaries: 0, trackingDate: 0, prospectedDate: 0, pendingDate: 0,
};

const indicators = {
  reclutamiento: { title: 'Indicador de Reclutamiento' },
  prospectingMaid: { label: 'Número de personas registradas en el día.', goal: 'goalProspectingMaid' },
  interview: { label: 'Número de entrevistas en el día.', goal: 'goalInterview' },
  waitingMaid: { label: 'Número de personas que pasaron a espera en el día.', goal: 'goalWaitingMaid' },
  workingMaid: { label: 'Número de nuevos ingresos en el día (colocaciones).', goal: 'goalWorkingMaid' },
  rotacion: { title: 'Indicador de Rotación' },
  unCheckIns: { label: 'Número de personas que faltaron en el día (Justificado o injustificado).', goal: 'goalUnCheckIns' },
  Supervisión: { title: 'Indicador de Supervisión' },
  waitingCustomer: { label: 'Número de empresas en estatus pendiente.', goal: 'goalWaitingCustomer' },
  ventas: { title: 'Indicador de Ventas' },
  trackingDate: { label: 'Fechas de seguimiento en rojo.', goal: 'trackingDate' },
  pendingDate: { label: 'Clientes por prospectar.', goal: 'pendingDate' },
  prospectingCustomer: { label: 'Número de empresas nuevas visitadas en el día.', goal: 'prospectedDate' },
  quotationCustomer: { label: 'Número de cotizaciones al día.', goal: 'goalQuotationCustomer' },
  workingCustomer: { label: 'Número de cierres al día.', goal: 'goalWorkingCustomer' },
  cobranza: { title: 'Indicadores Cobranza' },
  receivables: { label: 'Monto de cuentas por cobrar.', goal: 'goalReceivables' },
  receivableCommentaries: { label: 'Supervición de cuentas por cobrar.', goal: 'goalReceivableCommentaries' },
  utilidad: { title: 'Indicadores de Utilidad' },
  venta: { label: 'Ventas', goal: 'goalVenta' },
  nomina: { label: 'Nómina', goal: 'goalNomina' },
  porcentajeNomina: { label: '%Nómina', goal: 'goalPorcentajeNomina' },
  infonavit: { label: 'Infonavit', goal: 'goalInfonavit' },
  cuotaSeguro: { label: 'Cuota Seguro', goal: 'goalCuotaSeguro' },
  prestacionesAdicionales: { label: 'Prestaciones Adicionales', goal: 'goalPrestacionesAdicionales' },
  prestacionesFiscales: { label: 'Prestaciones Fiscales', goal: 'goalPrestacionesFiscales' },
  prestaciones: { label: 'Prestaciones', goal: 'goalPrestaciones' },
  porcentajePrestaciones: { label: '%Prestaciones', goal: 'goalPorcentajePrestaciones' },
  utilidadBruta: { label: 'Utilidad', goal: 'goalUtilidadBruta' },
  porcentajeUtilidad: { label: '%Utilidad', goal: 'goalPorcentajeUtilidad' }
}

const ERROR_MESSAGE = 'Ha ocurrido un error, por favor inténtalo de nuevo más tarde.';

const Component = ({ getData, ...props}) => {
  const [ values, setValues ] = useState(defaultValues);
  const [ openFilter, setOpenFilter ] = useState(false);
  const classes = useStyles();

  const handleFilter = ({ month = parseInt(getMonth(new Date())) + 1}) => {
    setValues(defaultValues);
    const daysInMonth = getDaysArrayInMonth(month);
    daysInMonth.map(async date => {
      recordsServices.get(`productivities/days?${queryFormat({start: date, end: date})}`)
        .then(data => {
          setValues(values => {
            const newArray = [...values.values, ...data.values].reduce((newArray, el) => {
              const isInArray = newArray.find(elCh => elCh.date === el.date);
              return !isInArray ? [...newArray, el] : newArray;
            }, []);
            const newObject = {...data, values: sortArrayByDate(newArray, 'date')};
            return newObject;
          });
          props.getDataFromServer(true);
        })
        .catch(e => console.log(e.message));
    })
  };

  useEffect(() => {
    if(getData) handleFilter({month: parseInt(getMonth(new Date())) + 1});
  }, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }



  const handleGoals = () => {
    const body = {
      goalProspectingMaid: values.goalProspectingMaid, goalInterview: values.goalInterview,
      goalWaitingMaid: values.goalWaitingMaid, goalWorkingMaid: values.goalWorkingMaid,
      goalProspectingCustomer: values.prospectedDate, goalQuotationCustomer: values.goalQuotationCustomer,
      goalWaitingCustomer: values.goalWaitingCustomer, goalWorkingCustomer: values.goalWorkingCustomer,
      goalUnCheckIns: values.goalUnCheckIns, goalReceivables: values.goalReceivables,
      receivableCommentaries: values.receivableCommentaries, goalReceivableCommentaries: values.goalReceivableCommentaries,
      trackingDate: values.trackingDate,
      pendingDate: values.pendingDate,
    };

    recordsServices.update(`productivities`, body)
      .then(data => {
        if(data.errorCode) { throw new Error(data.errorMessage); }
        props.getDataFromServer();
        props.showAlert({ variant: 'success' });
      })
      .catch(e => { 
        props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
      });
  }

  const handleOpenFilter = () => setOpenFilter(true);
  const closeFilter = () => setOpenFilter(false);

  return (
    <Fragment>
      <Header handleOpenFilter={handleOpenFilter} handleAction={handleGoals}/>
      <Table className={classes.table} size="small" style={{ tableLayout: 'auto' }}>
        <TableHead>
          <TableRow>
            <TableCell>Descripción</TableCell>
            {
              values.values.map(el => (
                <TableCell key={el.date} align="center">{format(newDate(el.date), 'dd/MM/yyyy')}</TableCell>
              ))
            }
            <TableCell align='center'>
              <Typography variant="body2" noWrap>
                Meta del Indicador
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
          Object.keys(indicators).map(key =>
            (indicators[key].title) ? (
              <TableRow key={key}>
                <TableCell className={classes.tableCellTitle}>
                  <Typography variant="body2" noWrap>
                    {indicators[key].title}
                  </Typography>
                </TableCell>
                {
                  values.values.map(el => (
                    <TableCell key={el.date} className={classes.tableCellTitle}/>
                  ))
                }
                <TableCell className={classes.tableCellTitle}/>
              </TableRow>
            )
            : 
              <TableRow key={key}>
                <TableCell className={classes.tableCell}>
                  <Typography variant="body2" noWrap>
                    {indicators[key].label}
                  </Typography>
                </TableCell>
                {
                  values.values.map(el => {
                    return (
                      (!['waitingCustomer','receivableCommentaries'].includes(key)) ?
                        (
                          <TableCell key={el.date} className={ (parseInt(el[key]) < parseInt(values[indicators[key].goal])) ? classes.tableCellDanger : classes.tableCell } align="center" >
                            { el[key] }
                          </TableCell>
                        )
                      :
                        (
                          <TableCell key={el.date} className={ (parseInt(el[key]) > parseInt(values[indicators[key].goal])) ? classes.tableCellDanger : classes.tableCell } align="center" >
                            { el[key] }
                          </TableCell>
                        )
                    )
                  })
                }
                <TableCell align='center' className={classes.tableCell}>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField noFormat name={indicators[key].goal} label='' value={values[indicators[key].goal] || 0} onChange={handleChange} fullWidth={true} />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
          )
        }
        </TableBody>  
      </Table>
      <FilterDialog 
        isOpen={openFilter} 
        handleClose={closeFilter}
        handleFilter={handleFilter}
      />  
    </Fragment>
  );
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  showAlert: data => dispatch(alertActions.show(data)),
  getDataFromServer: value => dispatch(getDataFromServer(value))
})
export default connect(mapStateToProps, mapDispatchToProps)(Component);