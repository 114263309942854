import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

import formatDFNS from 'date-fns/format';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import FormDialog from '../../../_presentationals.v2/FormDialog';
import format from '../../../_presentationals.v2/Table/helpers/Format';

import { recordsServices } from '../../../_services.v2';
import { closeAlternativeDialog } from '../../../_actions.v2';

import sortByType from '../../../_helpers/sortByType';
import { newDate } from '../../../_helpers.v2';

const ReportByDateComponent = props => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (props.isOpen) {
      const query = Object.keys(props.filterData).reduce((string, key) => {
        return `${string}&${key}=${props.filterData[key]}`;
      }, '');
      recordsServices.get(`v2/customers/crm?${query}`)
        .then(data => {
          const sortedData = sortByType(data, 'trackingDate', 'date', 'desc');
          const dates = sortedData.reduce((array, el) => {
            if (el.trackingDate && (array.length === 0 || array[array.length - 1] !== formatDFNS(newDate(el.trackingDate), 'dd/MM/yyyy'))) {
              return [...array, formatDFNS(newDate(el.trackingDate), 'dd/MM/yyyy')];
            }
            return array;
          }, []);
          const tmpData = dates.reduce((array, date) => {
            if (!date) return array;
            let tmpArray = [...array, { date }];
            sortedData.forEach(el => {
              if (formatDFNS(newDate(el.trackingDate), 'dd/MM/yyyy') === date) {
                tmpArray = [...tmpArray, {
                  ...el,
                  createdAt: format(el.createdAt, 'dateTime'),
                  trackingDate: format(el.trackingDate, 'date'),
                  type: format(el.status, 'customerType'),
                  statusLabel: format(el.status, 'customerStatus'),
                  background: newDate(el.trackingDate) <= new Date(),
                  receiptLabel: format(el.receipt, 'customerReceipt'),
                }];
              }
            })
            return tmpArray;
          }, []);
          setData(tmpData);
        })
        .catch(e => console.log(e.message));
    }
  }, [props.isOpen, props.filterData]);
  return (
  <FormDialog
    isOpen={props.isOpen}
    fullScreen
    hideAction
    handleClose={props.closeDialog}
  >
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell>Estatus</TableCell>
          <TableCell>Just</TableCell>
          <TableCell>Empresa</TableCell>
          <TableCell>Colonia</TableCell>
          <TableCell>Seguimiento</TableCell>
        </TableRow>
      </TableHead>
      {
        data.map((el, i) =>
          el.date
          ? <TableRow key={i}>
              <TableCell colSpan={5}><strong>Día {el.date}</strong></TableCell>
            </TableRow>
          : <TableRow key={i}>
              <TableCell>{el.statusLabel}</TableCell>
              <TableCell>{el.receiptLabel}</TableCell>
              <TableCell>{el.branchName}</TableCell>
              <TableCell>{el.addressLine2}</TableCell>
              <TableCell>{el.trackingDate}</TableCell>
            </TableRow>
        )
      }
    </Table>
  </FormDialog>
  )
};

const mapStateToProps = state => ({
  isOpen: state.alternativeDialog[0],
  filterData: state.filterDialog[1],
});

const mapDispatchToProps = dispatch => ({
  closeDialog: () => dispatch(closeAlternativeDialog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportByDateComponent);
