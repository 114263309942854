import React from 'react';

import ButtonMUI from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: '#282828',
  },
}));

const Button = ({ isLoading, onClick, ...props }) => {

	const classes = useStyles();

	const handleClick = e => {
		e.preventDefault();
		onClick(e, props.params);
	};

	return (
		<ButtonMUI
      variant={props.variant || 'contained'}
      color={props.color || 'primary'}
      onClick={handleClick}
      disabled={isLoading}
      size={props.size || 'medium'}
      {...props}
    >
      {
      	isLoading
	      	? <CircularProgress size={24} className={classes.buttonProgress} />
	      	: props.label
      }
  </ButtonMUI>
	);

};

export default Button;
