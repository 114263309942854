import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';

import EditIcon from '@material-ui/icons/EditOutlined';

import { recordsServices } from './../../_services.v2';
import { getDataFromServer } from './../../_actions.v2';

import Module from './../../_presentationals.v2/Module';

import AmountDialog from './amount.dialog';

const Rows = [
	{ label: 'Fecha', key: 'createdAt', sort: 'date', format: 'date' }, 
	{ label: 'Tipo', key: 'type', sort: 'text' }, 
	{ label: 'Descripción', key: 'description', sort: 'text', filter: true },
	{ label: 'Entrada', key: 'inflow', format: 'balance', align: 'right' },
	{ label: 'Salida', key: 'outflow', format: 'balance', align: 'right' },
	{ label: 'Saldo', key: 'balance', format: 'currency', align: 'right' },
	{ label: 'Cuenta Bancaria', key: 'account', sort: 'text' },
	{ label: 'Usuario', key: 'user', sort: 'text' },
]; 

const actionOptions = [
  { value: 1, label: "Salida" },
  { value: 2, label: "Entrada" }
];

const getDatesOptions = [
  { value: true, label: "Si" },
  { value: false, label: "No" }
];

const accountOptions = [
  { value: 1, label: 'EDER FERNANDO JIMENEZ GARCÍA'},
  { value: 2, label: 'JOSE CRUZ ROSALES FELIX'},
  { value: 3, label: 'CYNTHIA NALLELY ROSALES VEGA'},
  { value: 4, label: 'FERNANDO...'},
  { value: 5, label: 'CAJA CHICA'},
  { value: 6, label: 'MARIA DEL ROSARIO VEGA ESEBERRE'},
  { value: 7, label: 'FERNANDO JIMENEZ RAMIREZ'},
];

const expensesOptions = [
  { value: 1, label: "Fijo" },
  { value: 2, label: "Variable" },
  { value: 3, label: "Pago de nómina" },
  { value: 14, label: "Pago nómina admin." },
  { value: 4, label: "Insumos" },
  { value: 5, label: "Prestaciones" },
  { value: 9, label: "Comisiones" },
  { value: 10, label: "Faltante" },
  { value: 11, label: "General" },
  { value: 12, label: "Sobrante" },
  { value: 13, label: "Préstamos - Caja Chica" },
  { value: 15, label: "Gastos Eder" },
  { value: 16, label: "Gastos Cynthia" },
  { value: 17, label: "Gastos Idalia" },
  { value: 18, label: "Gastos Tarjetas" },
  { value: 19, label: "Transportes" },
  { value: 20, label: "Renta" },
  { value: 21, label: "Cobro a Cliente" },
];

const Component = ({ getData, ...props }) => {

	const [ values, setValues ] = useState([]);
	const [ accountId, setAccountId ] = useState(1);
  const [ dates, setDates ] = useState('');
  const [ isOpen, setIsOpen ] = useState([ false, null ]);
	
	useEffect(() => {
		getData && recordsServices.get(`expenses?account=${accountId}${dates}`)
			.then(data => {
				setValues(data);
        props.getDataFromServer(false);
			})
			.catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleFilter = ({ account, getDates, from, to }) => {
		if(account) {
			setAccountId(account);
      const tmpDates = getDates 
        ? `&from=${from}&to=${to}`
        : '';
      setDates(tmpDates);
			props.getDataFromServer(true);
		}
	}

  const handleAmount = (e, { id }) => {
    setIsOpen([true, id]);
  }

  const handleClose = () => {
    setIsOpen([false, null]);
  }

	return (
    <Fragment>
  		<Module 
  			route='expenses'
        title='Flujo de Efectivo'
        singular='flujo de efectivo'
        Rows={Rows}
        noEdit
        records={values}
        actions={[{
          label: 'Actualizar Importe',
          icon: <EditIcon fontSize='small' />,
          onClick: handleAmount
        }]}
        filter={[handleFilter, [
          {
        		name: 'account',
        		label: 'Cuenta Bancaria',
        		field: 'select',
        		options: [ { value: -1, label: 'TODOS'}, ...accountOptions ]
        	},
          {
            name: 'getDates',
            label: 'Filtro por fechas',
            field: 'select',
            options: getDatesOptions
          },
          {
            name: 'from',
            label: 'Del',
            field: 'date'
          },
          {
            name: 'to',
            label: 'Al',
            field: 'date'
          }
        ]]}
        fields={[
        	{
        		name: 'action',
        		label: 'Acción',
        		field: 'select',
        		options: actionOptions
        	},
        	{
        		name: 'account',
        		label: 'Cuenta Bancaria',
        		field: 'select',
        		options: accountOptions
        	},
        	{
        		name: 'amount',
        		label: 'Importe',
        		type: 'number'
        	},
        	{
        		name: 'type',
        		label: 'Tipo de Movimiento',
        		field: 'select',
        		options: expensesOptions
        	},
        	{
        		name: 'description',
        		label: 'Descripción'
        	},
          {
            name: 'password',
            label: 'Contraseña',
            type: 'password'
          }
        ]}
      />
      <AmountDialog isOpen={isOpen[0]} expenseId={isOpen[1]} handleClose={handleClose} />
    </Fragment>
	);
};

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: value => dispatch(getDataFromServer(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component); 