import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

import formatDate from 'date-fns/format';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormDialog from '../../../_presentationals.v2/FormDialog';
import TextField from '../../../_presentationals.v3/FormComponents/TextField';
import DateField from '../../../_presentationals.v3/FormComponents/DateField';
import format from '../../../_presentationals.v2/Table/helpers/Format';

import { recordsServices } from '../../../_services.v2';

import { alertActions, getDataFromServer } from '../../../_actions.v2';
import { newDate } from '../../../_helpers.v2';

const defaultValues = {
  trackingDate: new Date(),
  trackingCommentary: '',
};

const FormComponent = props => {
  const [trackings, setTrackings] = useState([]);
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (props.id && props.isOpen) {
      recordsServices.get(`crm/tracking/customer/${props.id}`).then(data => {
        const tmpData = data.map(el => ({
          ...el,
          createdAt: format(el.createdAt, 'dateTime'),
          date: format(el.date, 'date'),
          commentary: el.commentary,
          status: format(el.status, 'customerStatus'),
        }));
        setTrackings(tmpData);
      }).catch(e => console.log(e));
    }
  }, [props.id, props.isOpen]);
  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }
  const handleCreate = async () => {
    try {
      setErrors({});
      let error;
      if (!values.trackingDate) {
        error = new Error('Proporciona una fecha para el seguimiento.')
        error.name = 'trackingDate';
        throw error;
      }
      if (values.trackingCommentary === '') {
        error = new Error('Proporciona un comentario para el seguimiento.')
        error.name = 'trackingCommentary';
        throw error;
      }
      const { response } = await recordsServices.create(`crm/tracking/customer/${props.id}`, {
        ...values,
        trackingDate: formatDate(newDate(values.trackingDate), 'yyyy/MM/dd'),
      });
      setTrackings(trackings => [...trackings, {
        id: response.id,
        createdAt: format(response.createdAt, 'dateTime'),
        date: format(response.date, 'date'),
        commentary: response.commentary,
        status: format(response.status, 'customerStatus'),
      } ])
      props.getDataFromServer(true);
      props.showAlert({ variant: 'success' });
      setValues(defaultValues);
    } catch(e) {
      if (['trackingDate', 'trackingCommentary'].includes(e.name)){
        setErrors({[e.name]: e.message});
      }
    }
  }
  return (
    <FormDialog
      title='Seguimiento'
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      handleAction={handleCreate}
    >
      <DateField
        name='trackingDate'
        label='Fecha de seguimiento'
        value={values.trackingDate}
        onChange={handleChange}
        error={errors.trackingDate}
      />
      <TextField
        name='trackingCommentary'
        label='Comentario para el seguimiento'
        value={values.trackingCommentary}
        onChange={handleChange}
        error={errors.trackingCommentary}
      />
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Registro</TableCell>
              <TableCell>Seguimiento</TableCell>
              <TableCell>Comentario</TableCell>
              <TableCell>Estatus</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
           {
              trackings.map(el => (
                <TableRow key={el.id}>
                  <TableCell>{el.createdAt}</TableCell>
                  <TableCell>{el.date}</TableCell>
                  <TableCell>{el.commentary}</TableCell>
                  <TableCell>{el.status}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </FormDialog>
  );
};

const mapDispatchToProps = dispatch => ({
  showAlert: data => dispatch(alertActions.show(data)),
  getDataFromServer: value => dispatch(getDataFromServer(value)),
})

export default connect(null, mapDispatchToProps)(FormComponent);
