import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { recordsActions } from '../../_actions';

const DeleteDialogModule = ({_id, table, _delete}) => {

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  useEffect(() => {
    (_id > 0) && setIsOpenDialog(true);
  }, [_id]);

  const handleClose = () => {
    setIsOpenDialog(false);
  }

  const handleDelete = () => {
    _delete({ table, _id });
    setIsOpenDialog(false);
  }

  return (
    <Dialog
      open={isOpenDialog}
      fullWidth={true}
      maxWidth="xs"
      aria-labelledby="Eliminar registro"
      aria-describedby="Dialogo de confirmación para eliminar un registro."
    >
      <DialogTitle disableTypography={true}>¿Eliminar este registro?</DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleDelete} color="primary" autoFocus>
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapDispatchToProps = dispatch => ({ 
  _delete: data => dispatch(recordsActions._delete(data)),
});

export const DeleteDialog = connect(null,mapDispatchToProps)(DeleteDialogModule);