import React, { useState } from 'react';
import { connect } from 'react-redux';

import format from 'date-fns/format';

import FormDialog from './../../_presentationals.v2/FormDialog';
import DateField from './../../_presentationals.v2/Form/DateField';
import TextField from './../../_presentationals.v2/Form/TextField';

import { getDataFromServer, alertActions } from './../../_actions.v2';

import { recordsServices } from './../../_services.v2';

const ERROR_MESSAGE = 'Ha ocurrido un error, por favor inténtalo de nuevo más tarde.';

const Component = props => {

  const [ trackingDate, setTrackingDate ] = useState(null);
	const [ trackingCommentary, setTrackingCommentary ] = useState('');
  const [ errors, setErrors ] = useState({});

	const handleAction = () => {
    try {
      let newError;
      if (!trackingDate) {
        newError = new Error('Selecciona una fecha de seguimiento.');
        newError.name = 'trackingDate';
        throw newError;
      }
      if (trackingCommentary === '') {
        newError = new Error('Escribre un comentario para el seguimiento.');
        newError.name = 'trackingCommentary';
        throw newError;
      }
      recordsServices.update(`v2/customers/${props.values.id}/prospectedDate`, { trackingDate, trackingCommentary })
      .then(data => {
        if(data.errorCode) { throw new Error(data.errorMessage); }
        props.showAlert({ variant: 'success' });
        setTrackingCommentary('');
				setTrackingDate(null);
        setErrors({});
        props.getDataFromServer(true);
        props.handleClose();
      })
      .catch(e => { 
        console.log(e.message);
        props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
      });
    } catch(e) {
      console.log('Error: ', e.message);
			if (['trackingDate', 'trackingCommentary'].includes(e.name)) {
				setErrors({ [e.name]: e.message });
			}
    }
	}

  const handleTrackingChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd')
		setTrackingDate(tmpValue);
	}
  const handleTrackingCommentary = e => {
		const value = e.target.value;
		setTrackingCommentary(value);
	}

	return (
		<FormDialog
      title='Actualización de fecha de prospección'
      isOpen={props.isOpen || false}
      handleClose={props.handleClose}
      handleAction={handleAction}
    >
      La fecha de prospección solo se podrá actualizar una vez.
      <DateField
					name='trackingDate'
					label='Fecha de seguimiento'
					value={trackingDate}
					onChange={handleTrackingChange}
					error={errors.trackingDate}
					required noFormat
				/>
				<TextField
					name='trackingCommentary'
					label='Comentarios del seguimiento'
					value={trackingCommentary}
					onChange={handleTrackingCommentary}
					multiline={true}
					error={errors.trackingCommentary}
					required noFormat
				/>
    </FormDialog>
   )
}

const mapDispatchToProps = dispatch => ({
  getDataFromServer: value => dispatch(getDataFromServer(value)),
  showAlert: data => dispatch(alertActions.show(data))
});

export default connect(null, mapDispatchToProps)(Component);