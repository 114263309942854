import React from "react";

import { FormDialog } from './../../_presentationals/FormDialog';

import { DropzoneArea } from 'material-ui-dropzone';

const FileDialog = props => 
  <FormDialog
    table="customer"
    title="Adjuntar Contrato"
    singular="contratoAdjunto"
  >
    { (props.image && props.image.message) ?
      <DropzoneArea 
        onChange={props.handleChange}
        filesLimit={1}
        dropzoneText="Selecciona un formato de imagen o PDF."
      />
      : 
      <a href={props.image}>Descargar archivo</a>

    }
  </FormDialog>


export default FileDialog;