import dateFormat from 'date-fns/format';

import currencyFormat from './../../../_helpers.v2/moneyFormat';
import { newDate } from '../../../_helpers.v2';

const format = (value, type) => {
	switch(type){
		case 'dateTime':
			return value && value !== '-' ? dateFormat(newDate(value), 'dd/MM/yyyy HH:mm:ss') : '-';
		case 'date':
			return value && value !== '-' ? dateFormat(newDate(value), 'dd/MM/yyyy') : '-';
		case 'time':
			return value && value !== '-' ? dateFormat(newDate(value), 'HH:mm:ss') : '-';
		case 'currency':
			return currencyFormat(value);
		case 'percent':
			return !isNaN(parseInt(value)) ? `${currencyFormat(value)}%` : '-';
		case 'balance':
			return (parseFloat(value) === 0) ? '-' : currencyFormat(value);
		case 'yesNoType': 
			return yesNoType(value);
		case 'customerType':
			return customerType(value);
		case 'customerStatus':
			return customerStatus(value);
			case 'customerReceipt':
				return customerReceipt(value);
		default: return (typeof value === 'undefined' || value === '') ? '-' : value;
	}
}

export const yesNoType = value => value ? 'SI' : 'NO'; 
export const customerType = value => parseInt(value) === 1 ? 'Casa' : 'Oficina'; 
export const customerStatus = value => {
	switch(parseInt(value)){
		case 1:
			return 'Oportunidad';
		case 2:
			return 'Prospección';
		case 3:
			return 'Cotización';
		case 4:
			return 'Contratación';
		default: return 'Indefinido';
	}
}; 
export const customerReceipt = value => {
	switch(parseInt(value)){
		case 1:
			return 'Personal Interno';
		case 2:
			return 'En Proceso';
		case 3:
			return 'Reagendar';
		case 4:
			return 'Precio Alto';
		case 5:
			return 'Contrato Vigente';
		default: return '-';
	}
}; 

export default format;