import React from "react";

import { FormDialog } from './../../_presentationals/FormDialog';
import { TextField } from './../../_presentationals/Form';

const HealthInsuranceDialog = () => 
  <FormDialog
    table="maids"
    title="Configurar cuota de seguro e infonavit"
    singular="healthInsurance"
  >
    <TextField
      fields={[{
        id: "healthInsurance", 
        label: "Cuota (Seguro)"
      }]}
    />
    <TextField
      fields={[{
        id: "infonavit", 
        label: "Cuota (Infonavit)"
      }]}
    />
  </FormDialog>


export default HealthInsuranceDialog;