import React, { useState } from "react";
import { connect } from 'react-redux';

import format from 'date-fns/format';

import FormDialog from './../FormDialog';
import TextField from './../Form/TextField';
import SelectField from './../Form/SelectField';
import AutocompleteField from './../Form/AutocompleteField';
import DateField from './../Form/DateField';

import { closeFilterDialog } from './../../_actions.v2';

const Component = props => { 
	const [ values, setValues ] = useState({});
  
	const handleChange = e => {
		const name = e.target.name;
		const value = e.target.value;
		setValues(values => ({ ...values, [name]: value }));
	}

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleDateChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd');
    setValues(values => ({ ...values, [name]: tmpValue }));
  }

  const handleCloseForm = () => {
    props.handleCloseForm();
    setValues({});
  }

  const handleAction = () => {
    props.action(values);
    props.handleCloseForm();
  }
  
	return (
		<FormDialog
      title={`Filtrar ${props.title}`}
      actionLabel='Filtrar'
      isOpen={props.openForm[0]}
      handleClose={handleCloseForm}
      handleAction={handleAction}
    >
    	{ 
    		props.fields && props.fields.map(el => {
          
          if(Object.keys(values).find(key => key === el.noShow)) {
            return false;
          }

    			switch(el.field){
    				case 'select': return (
    					<SelectField 
    						key={el.name}
								name={el.name}
								label={el.label}
                icon={el.icon}
								value={values[el.name] || ''}
								onChange={handleChange}
								options={el.options}
							/>
    				)
            case 'autocomplete': return (
              <AutocompleteField 
                key={el.name}
                name={el.name}
                label={el.label}
                icon={el.icon}
                value={values[el.name] || { value: '', label: '' }}
                onChange={handleAutocompleteChange}
                options={el.options}
              />
            )
            case 'date': return (
              <DateField 
                key={el.name}
                name={el.name}
                icon={el.icon}
                label={el.label}
                value={values[el.name] || null}
                onChange={handleDateChange}
              />
            )
    				default: return (
  						<TextField 
  							type={el.type || 'text'}
  							key={el.name}
								icon={el.icon}
								name={el.name}
								label={el.label}
								value={values[el.name] || ''}
								onChange={handleChange}
							/>
  					)
    			}

    		})
    	}
    </FormDialog>
	)
}

const mapStateToProps = states => ({
  openForm: states.filterDialog
});

const mapDispatchToProps = dispatch => ({
  handleCloseForm: () => dispatch(closeFilterDialog())
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);