import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { format } from 'date-fns';

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import IconButton from './../../_presentationals.v2/Form/IconButton';
import Fab from '@material-ui/core/Fab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { recordsServices } from '../../_services.v2';
import FormDialog from './../../_presentationals.v2/FormDialog';
import TextField from './../../_presentationals.v2/Form/TextField';
import SelectField from './../../_presentationals.v2/Form/SelectField';
import DateField from './../../_presentationals.v2/Form/DateField';
import { alertActions, getDataFromServer } from './../../_actions.v2';
import { newDate } from "../../_helpers.v2";

const ERROR_MESSAGE = 'Ha ocurrido un error, por favor inténtalo de nuevo más tarde.';

const style = makeStyles((theme) => ({
  CheckBoxForm: {
    margin: 0,
    paddingTop: theme.spacing(2)
  }
}));

const awareOptions = [
  { value: false, label: 'NO'},
  { value: true, label: 'SI'},
]

const defaultValues = {
  commentary: "",
  aware: false,
  paymentDate: new Date()
}

const Component = props => {
  const [ values, setValues] = useState(defaultValues);
  const [ rows, setRows] = useState([]);

  const classes = style();

  useEffect(() => {
    props.customerId && recordsServices.get(`v2/customers/${props.customerId}/commentaries`)
    .then(data => {
      const tmp = data.Commentaries.map(el => ({ ...el, dateFormat: format(newDate(el.date),'dd/MM/yyyy HH:mm') }))
      setRows(tmp);
      setValues(values => ({ ...values, aware: data.aware || false, paymentDate: data.paymentDate }));
    });
  },[props.customerId]);
  
  const handleAction = () => {
    props.customerId && recordsServices.update(`v2/customers/${props.customerId}/commentaries`,{
      Commentaries: rows, aware: values.aware, paymentDate: values.paymentDate
    })
    .then(data => {
      if(data.errorCode) { throw new Error(JSON.stringify(data.errorMessage)); }
      props.showAlert({ variant: 'success' });
      props.getDataFromServer(true);
      handleClose();
    })
    .catch(e => {
      props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
    });
  }

  const handleClose = () => {
    setRows([]);
    setValues(defaultValues);
    props.handleClose();
  }

  const handleChange = e => {
    const { value = '', name } = e.target;
    setValues(values => ({ ...values, [name]: value }) );
  }

  const handleAdd = e => {
    const { commentary } = values;
    if(commentary === '') return;
    setRows(rows => [ { commentary }, ...rows, ]);
    setValues(values => ({ ...values, commentary: '' }));
  }

  const handleDelete = (e, {i}) => {
    const tmp = rows.map((el, index) => index !== i && el).filter(el => el);
    setRows(tmp);
  }

  const handleDateChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd')
    setValues(values => ({ ...values, [name]: tmpValue }));
  }

  return(
    <FormDialog 
      title="Comentarios"
      isOpen={props.isOpen}
      handleClose={handleClose}
      handleAction={handleAction}
    >
      <SelectField
        name="aware" label="Al corriente" value={values.aware} onChange={handleChange} noFormat options={awareOptions}
      />
      <DateField 
        name='paymentDate' label='Promesa Pago' value={values.paymentDate} onChange={handleDateChange} noFormat
      />
      <br/>
      <br/>
      <Grid container spacing={1}>
        <Grid item lg={11} xs={11}>
          <TextField
            name='commentary' label='Comentario' variant='outlined' value={values.commentary}
            onChange={handleChange} size='small' noFormat
          />   
        </Grid>
        <Grid item lg={1} xs={1} align='right'>
          <Fab color='secondary' size='small' onClick={ handleAdd } aria-label='Agregar Comentario'>
            <AddIcon />
          </Fab>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Comentario</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            { 
              rows.map((el, i) => {
                return (
                <TableRow hover key={i}>
                  <TableCell>{el.commentary}</TableCell>
                  <TableCell>{el.dateFormat || format(new Date(), 'dd/MM/yyyy HH:mm')}</TableCell>
                  <TableCell align='right'>
                    <IconButton
                      label='Eliminar' action={handleDelete} params={{i}}
                      className={classes.actionButton} icon={<DeleteIcon fontSize='small' />}
                    />
                  </TableCell>
                </TableRow>)
              })
            }
          </TableBody>
        </Table>
      </Grid>
    </FormDialog>
  );
}

const mapDispatchToProps = dispatch => ({
  showAlert: data => dispatch(alertActions.show(data)),
  getDataFromServer: value => dispatch(getDataFromServer(value)),
});


export default connect(null, mapDispatchToProps)(Component);
