import React, { useEffect, Fragment, useState } from "react";


import format from 'date-fns/format';

import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Divider from '@material-ui/core/Divider';

import SearchIcon from '@material-ui/icons/SearchOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';

import { recordsServices } from '../../_services.v2/records.services'; 
import { currencyFormat } from '../../_helpers';
import { JsonToXls } from '../../_presentationals.v2/XLS';
import TablePagination from '../../_presentationals.v2/Table/TablePagination';

import { start, finish, checkinFormat, headerLabelDaysOfTheWeek } from './../CheckIn/helpers';
import { HeaderCell, CustomerCell, Button, Table } from './../CheckIn/_components';

import SearchDialog from './../Assignment/search.dialog';
import { newDate } from "../../_helpers.v2";

const CheckIn = ({ openFormDialog, editValueAction, ...props }) => {

	const [ startDate, setStartDate ] = useState(start);
	const [ finishDate, setFinishDate ] = useState(finish);
	const [ count, setCount ] = useState(0);
	const [ checkIns, setCheckIns ] = useState([]);
	const [ limit, setLimit ] = useState(25);
	const [ offset, setOffset ] = useState(0);
	const [ search, setSearch ] = useState('');
	const [ isOpenSearch, setIsOpenSearch ] = useState(false);
	
	useEffect(() => {
		recordsServices.get(`checkIns/${startDate}/${finishDate}?limit=${limit}&offset=${offset}&search=${search}`).then(data => {
			try {
				const tmpData = checkinFormat(data.rows);
				setCount(data.count);
				setCheckIns(tmpData);
			} catch (error) {
				console.log(error)
			}
		});
	}, [limit, offset, search, startDate, finishDate]);

	const handleRows = (offset, limit) => {
		setLimit(limit);
		setOffset(offset);
	}

	const handleSearch = ({ search, start, finish }) => {
    setSearch(search);
    setStartDate(start);
    setFinishDate(finish);
  };

  const handleOpenSearch = () => {
    setIsOpenSearch(isOpenSearch => !isOpenSearch);
  }

  const handleDownload = () => {
  	const maid = { label: 'Mucama', key: 'maid' };
  	const keys = headerLabelDaysOfTheWeek.map((el, i) => ({ label: el.label, key: i + 1 }));
  	const more = [
  		{ label: 'Sueldo', key: 'salary' },
  		{ label: 'Cobro', key: 'payment' },
  		{ label: 'Insumo', key: 'supplies' },
  		{ label: 'IVA', key: 'iva' },
  		{ label: 'IMSS', key: 'healthInsurance' },
      { label: 'Utilidad', key: 'utility' },
      { label: 'Utilidad ER', key: 'utilityER' },
      { label: '% Utilidad', key: 'utilityPercent' },
  	]

  	const data  = checkIns.map((el, i) => {
  		let salary = 0;
			let payment = 0;
			let supplies = 0;
			let iva = 0;

  		return { 
  			1: el[6] 
  				? el[6].reduce((string, elCh, iiii) => {
  						salary += elCh.status.id !== 6 ? parseFloat(elCh.salary) : 0;
							payment += elCh.status.id !== 6 ? parseFloat(elCh.payment) : 0;
							supplies += elCh.status.id !== 6 ? parseFloat(elCh.supplies) : 0;
							iva += elCh.status.id !== 6 ? parseFloat(elCh.ivaPayment) + parseFloat(elCh.ivaSupplies) : 0;
  						const separator = (el[6].length - 1) === iiii ? '' : '//';
  						return `${format(newDate(elCh.date), 'd MMM')} ${elCh.Customer.name} ${elCh.status.label} ${currencyFormat(elCh.status.id !== 6 ? elCh.salary : 0)} ${separator}`;
  					}, '')
  				: 'X',
  			2: el[7] 
  				? el[7].reduce((string, elCh, iiii) => {
  						salary += elCh.status.id !== 6 ? parseFloat(elCh.salary) : 0;
							payment += elCh.status.id !== 6 ? parseFloat(elCh.payment) : 0;
							supplies += elCh.status.id !== 6 ? parseFloat(elCh.supplies) : 0;
							iva += elCh.status.id !== 6 ? parseFloat(elCh.ivaPayment) + parseFloat(elCh.ivaSupplies) : 0;
  						const separator = (el[7].length - 1) === iiii ? '' : '//';
  						return `${format(newDate(elCh.date), 'd MMM')} ${elCh.Customer.name} ${elCh.status.label} ${currencyFormat(elCh.status.id !== 6 ? elCh.salary : 0)} ${separator}`;
  					}, '') : 'X',
  			3: el[1] 
  				? el[1].reduce((string, elCh, iiii) => {
  						salary += elCh.status.id !== 6 ? parseFloat(elCh.salary) : 0;
							payment += elCh.status.id !== 6 ? parseFloat(elCh.payment) : 0;
							supplies += elCh.status.id !== 6 ? parseFloat(elCh.supplies) : 0;
							iva += elCh.status.id !== 6 ? parseFloat(elCh.ivaPayment) + parseFloat(elCh.ivaSupplies) : 0;
  						const separator = (el[1].length - 1) === iiii ? '' : '//';
  						return `${format(newDate(elCh.date), 'd MMM')} ${elCh.Customer.name} ${elCh.status.label} ${currencyFormat(elCh.status.id !== 6 ? elCh.salary : 0)} ${separator}`;
  					}, '') : 'X',
  			4: el[2] 
  				? el[2].reduce((string, elCh, iiii) => {
  						salary += elCh.status.id !== 6 ? parseFloat(elCh.salary) : 0;
							payment += elCh.status.id !== 6 ? parseFloat(elCh.payment) : 0;
							supplies += elCh.status.id !== 6 ? parseFloat(elCh.supplies) : 0;
							iva += elCh.status.id !== 6 ? parseFloat(elCh.ivaPayment) + parseFloat(elCh.ivaSupplies) : 0;
  						const separator = (el[2].length - 1) === iiii ? '' : '//';
  						return `${format(newDate(elCh.date), 'd MMM')} ${elCh.Customer.name} ${elCh.status.label} ${currencyFormat(elCh.status.id !== 6 ? elCh.salary : 0)} ${separator}`;
  					}, '') : 'X',
  			5: el[3] 
  				? el[3].reduce((string, elCh, iiii) => {
  						salary += elCh.status.id !== 6 ? parseFloat(elCh.salary) : 0;
							payment += elCh.status.id !== 6 ? parseFloat(elCh.payment) : 0;
							supplies += elCh.status.id !== 6 ? parseFloat(elCh.supplies) : 0;
							iva += elCh.status.id !== 6 ? parseFloat(elCh.ivaPayment) + parseFloat(elCh.ivaSupplies) : 0;
  						const separator = (el[3].length - 1) === iiii ? '' : '//';
  						return `${format(newDate(elCh.date), 'd MMM')} ${elCh.Customer.name} ${elCh.status.label} ${currencyFormat(elCh.status.id !== 6 ? elCh.salary : 0)} ${separator}`;
  					}, '') : 'X',
  			6: el[4] 
  				? el[4].reduce((string, elCh, iiii) => {
  						salary += elCh.status.id !== 6 ? parseFloat(elCh.salary) : 0;
							payment += elCh.status.id !== 6 ? parseFloat(elCh.payment) : 0;
							supplies += elCh.status.id !== 6 ? parseFloat(elCh.supplies) : 0;
							iva += elCh.status.id !== 6 ? parseFloat(elCh.ivaPayment) + parseFloat(elCh.ivaSupplies) : 0;
  						const separator = (el[4].length - 1) === iiii ? '' : '//';
  						return `${format(newDate(elCh.date), 'd MMM')} ${elCh.Customer.name} ${elCh.status.label} ${currencyFormat(elCh.status.id !== 6 ? elCh.salary : 0)} ${separator}`;
  					}, '') : 'X',
  			7: el[5] 
  				? el[5].reduce((string, elCh, iiii) => {
  						salary += elCh.status.id !== 6 ? parseFloat(elCh.salary) : 0;
							payment += elCh.status.id !== 6 ? parseFloat(elCh.payment) : 0;
							supplies += elCh.status.id !== 6 ? parseFloat(elCh.supplies) : 0;
							iva += elCh.status.id !== 6 ? parseFloat(elCh.ivaPayment) + parseFloat(elCh.ivaSupplies) : 0;
  						const separator = (el[5].length - 1) === iiii ? '' : '//';
  						return `${format(newDate(elCh.date), 'd MMM')} ${elCh.Customer.name} ${elCh.status.label} ${currencyFormat(elCh.status.id !== 6 ? elCh.salary : 0)} ${separator}`;
  					}, '') : 'X',
  			maid: el.name, 
  			salary, 
  			payment, 
  			supplies, 
  			iva, 
  			healthInsurance: el.healthInsurance,
        utility: (parseFloat(payment) - parseFloat(salary) - parseFloat(el.healthInsurance)).toFixed(2),
        utilityER: (parseFloat(payment) + parseFloat(supplies) + parseFloat(iva) - parseFloat(salary) - parseFloat(el.healthInsurance)).toFixed(2),
        utilityPercent: (((parseFloat(payment) + parseFloat(supplies) + parseFloat(iva) - parseFloat(salary) - parseFloat(el.healthInsurance)) / (parseFloat(payment) + parseFloat(supplies) + parseFloat(iva))) * 100).toFixed(2)
  		}

  	})
  	JsonToXls('utilidad', [ maid, ...keys, ...more ], data);
  }

	return (
		<Fragment>
		<Table>
    	<TableHead>
    		<TableRow>
    			<CustomerCell>
    				Mucama
    				<Button title="Buscador" onClick={handleOpenSearch} icon={<SearchIcon fontSize='small' />} />
    				<Button title="Descargar XLS" onClick={handleDownload} icon={<CloudDownloadIcon fontSize='small' />} />
    			</CustomerCell>
    			{ headerLabelDaysOfTheWeek.map(el => 
    				<CustomerCell key={el.label} align="center">{el.label}</CustomerCell> 
    			)}
    			<HeaderCell padding='none' align="right">General</HeaderCell>
    			<HeaderCell padding='none' align="right">IMSS</HeaderCell>
    			<HeaderCell padding='none' align="right">Utilidad</HeaderCell>
    			<HeaderCell padding='none' align="right">Utilidad ER</HeaderCell>
    			<HeaderCell padding='none' align="right">% Utilidad</HeaderCell>
    		</TableRow>
    	</TableHead>
    	<TableBody>
    		{checkIns.map((el, i) => {
    			let salary = 0;
					let payment = 0;
					let supplies = 0;
					let iva = 0;
					return (
						<TableRow key={el.id}>
							<TableCell>{`${el.id} ${el.name}`}</TableCell>
							{ 
								[6,7,1,2,3,4,5].map((ii, iii) => {
									return (
										<TableCell key={iii} align='center'>
											{ el[ii]
												? el[ii].map((elCh, iiii) => {
														salary += elCh.status.id !== 6 ? parseFloat(elCh.salary) : 0;
														payment += elCh.status.id !== 6 ? parseFloat(elCh.payment) : 0;
														supplies += elCh.status.id !== 6 ? parseFloat(elCh.supplies) : 0;
														iva += elCh.status.id !== 6 ? parseFloat(elCh.ivaPayment) + parseFloat(elCh.ivaSupplies) : 0;
														return (
															<div key={iiii}>
																<strong>{elCh.date ? format(newDate(elCh.date), 'd MMM') : ''}</strong>
																<br/>
																{elCh.Customer.name} 
																<br/>
																{elCh.status.label} {currencyFormat(elCh.status.id !== 6 ? elCh.salary : 0)}
																<br/>
																CO {currencyFormat(elCh.status.id !== 6 ? elCh.payment : 0)}
																 | 
																IN {currencyFormat(elCh.status.id !== 6 ? elCh.supplies : 0)}
																 | 
																IV {currencyFormat(elCh.status.id !== 6 ? elCh.ivaPayment : 0)}
																{ (iiii + 1) < el[ii].length && <Divider /> }
															</div>
														)
													})
												: 'X'
											}
										</TableCell>
									)
								})
							}
							<TableCell padding='none' align="right">
								<div style={{ display: 'block' }}>Sueldo ({currencyFormat(salary)})</div>
								<div style={{ display: 'block' }}>Cobro ({currencyFormat(payment)})</div>
								<div style={{ display: 'block' }}>Insum ({currencyFormat(supplies)})</div>
								<div style={{ display: 'block' }}>IVA ({currencyFormat(iva)})</div>
							</TableCell>
							<TableCell padding='none' align="right">{currencyFormat(el.healthInsurance)}MXN</TableCell>
							<TableCell padding='none' align="right">{currencyFormat(payment - salary - el.healthInsurance)}MXN</TableCell>
							<TableCell padding='none' align="right">{currencyFormat(payment + supplies + iva - salary - el.healthInsurance)}MXN</TableCell>
							<TableCell padding='none' align="right">{currencyFormat(((payment + supplies + iva - salary - el.healthInsurance) / (payment + supplies + iva)) * 100)}%</TableCell>
						</TableRow>
					)
		  	})}
    	</TableBody>
    	
    </Table>
    <TablePagination count={count} handleRows={handleRows} />
    <SearchDialog showDate isOpen={isOpenSearch} handleClose={handleOpenSearch} handleClick={handleSearch} />
   	</Fragment>
	);

}

export default CheckIn;