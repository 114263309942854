import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { FormDialog } from './../../_presentationals/FormDialog';
import { TextField, SelectField } from './../../_presentationals/Form';
import { recordsActions, editValueAction } from '../../_actions';

const RateDialog = ({ DaysOfRate, editValueAction, update, ...props }) => {

  const [state, setState] = useState({
    monday: DaysOfRate ? DaysOfRate.monday : false,
    tuesday: DaysOfRate ? DaysOfRate.tuesday : false,
    wednesday: DaysOfRate ? DaysOfRate.wednesday : false,
    thursday: DaysOfRate ? DaysOfRate.thursday : false,
    friday: DaysOfRate ? DaysOfRate.friday : false,
    saturday: DaysOfRate ? DaysOfRate.saturday : false,
    sunday: DaysOfRate ? DaysOfRate.sunday : false,
  });

  useEffect(() => { 
    if(props.isOpen && DaysOfRate) {
      if(Object.keys(DaysOfRate).length === 7) {
        setState({ ...DaysOfRate });
      }
    }
    else {
      setState({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      });
    }
    if(!props.isOpen) editValueAction({ DaysOfRate: null });
  }, [props.isOpen, DaysOfRate, editValueAction]);
  // }, [props.isOpen]);
  
  useEffect(() => {
    if(!isNaN(parseFloat(props.rate))) {
      const rate = !isNaN(parseFloat(props.rate)) ? parseFloat(props.rate) : 0;
      const suppliesRate = !isNaN(parseFloat(props.suppliesRate)) ? parseFloat(props.suppliesRate) : 0;
      const taxesRate = ((parseFloat(rate) + parseFloat(suppliesRate)) * 0.16).toFixed(2);
      editValueAction({ taxesRate });      
    }
  }, [props.rate, props.suppliesRate, editValueAction]);

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

  useEffect(() => {
    editValueAction({DaysOfRate: state})
  },[state, editValueAction]);

  const handleRate = () => {
    editValueAction({ isRate: true });
    update('customers');
  }

  return (
    <FormDialog
      title="Tarifa"
      singular="rate"
      handleCustomizedClick={handleRate}
      keepDataAfterSuccess={false}
      clearDataAfterClose={true} 
    >
      <SelectField
        fields={[{
          id: 'typeRate', 
          label: 'Tipo de Tarifa',
          options: [
            {key: 1, value: 'Diaria'},
            {key: 2, value: 'Mensual'}
          ]
        }]}
      />
      {
        parseInt(props.typeRate )=== 2 ? (
          <Grid container>
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={9}>
              Días
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox checked={state.monday} onChange={handleChange('monday')} value="monday" />
                  }
                  label="Lunes"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={state.tuesday} onChange={handleChange('tuesday')} value="tuesday" />
                  }
                  label="Martes"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={state.wednesday} onChange={handleChange('wednesday')} value="wednesday" />
                  }
                  label="Miércoles"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={state.thursday} onChange={handleChange('thursday')} value="thursday" />
                  }
                  label="Jueves"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={state.friday} onChange={handleChange('friday')} value="friday" />
                  }
                  label="Viernes"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={state.saturday} onChange={handleChange('saturday')} value="saturday" />
                  }
                  label="Sábado"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={state.sunday} onChange={handleChange('sunday')} value="sunday" />
                  }
                  label="Domingo"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={2}>
            </Grid>
          
            <TextField
              fields={[{
                id: 'quantityMaid',
                label: 'Cantidad de Mucamas'
              }]}
            />
            <TextField
              fields={[{
                id: 'rate',
                label: 'Cobro a Cliente (Servicio)'
              }]}
            />
            <TextField
              fields={[{
                id: 'suppliesRate',
                label: 'Cobro a Cliente (Insumos)'
              }]}
            />
            <TextField
              fields={[{
                id: 'taxesRate',
                label: 'Cobro a Cliente (IVA de Servicio)'
              }]}
            />
          </Grid>
        ) : ''
      }
    </FormDialog>
  )
}

const mapStateToProps = state => ({
  rate: state.field.value.rate || null,
  suppliesRate: state.field.value.suppliesRate || null,
  typeRate: state.field.value.typeRate || null,
  isOpen: state.formDialog
});


const mapDispatchToProps = dispatch => ({
  editValueAction: data => dispatch(editValueAction(data)),
  update: table => dispatch(recordsActions.update(table))
});

export default connect(mapStateToProps, mapDispatchToProps)(RateDialog);