import XLSX from 'xlsx';
import { format } from 'date-fns';
import rowFormat from './../Table/helpers/Format';

export const JsonToXls = (title, keys, data) => {
	const filename = title + '_' + format(new Date(), "dd-MMM-yyyy_HH-mm") + ".xlsx";
	const wb = XLSX.utils.book_new();

	const header = keys.map(el => el.label);
	
	let ws = XLSX.utils.json_to_sheet([], { header, skipHeader: true });

	const tmp = data.map(el => {
		const response = keys.reduce( (newObject = {},{ key, label, format: dataFormat }) => {
			let value = (key + '').includes(' ') 
				? key.split(' ').reduce((string, elCh) => {
					const tmpData = string[elCh] || el.ifNull || '-';
					return tmpData;
				}, el)
				: el[key];

			if(dataFormat === 'currency') {
				dataFormat = null;
				value = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
			};
			return { ...newObject, [label]: rowFormat(value,dataFormat) }
		}
		,{});
		return response;
	});

	XLSX.utils.sheet_add_json(ws, tmp, { skipHeader: false, origin: "A1"});

	const ws_name = title;

	XLSX.utils.book_append_sheet(wb, ws, ws_name);
	XLSX.writeFile(wb, filename);
}