import React from 'react';

import useStyles from './style';
import Header from './Header';

const ContainerComponent = (props) => {
  const classes = useStyles();
  return (
  <div className={classes.container}>
    <Header title={props.title} moreButtons={props.moreButtons} />
    {props.children}
  </div>
  );
}

export default ContainerComponent;
