import React, { useState } from 'react';

import { connect } from 'react-redux';

import format from 'date-fns/format';

import FormDialog from '../../../_presentationals.v2/FormDialog';
import TextField from '../../../_presentationals.v3/FormComponents/TextField';
import SelectField from '../../../_presentationals.v3/FormComponents/SelectField';

import { recordsServices } from '../../../_services.v2';

import { closeActionDialog, alertActions, getDataFromServer } from '../../../_actions.v2';
import { newDate } from '../../../_helpers.v2';

const quadrantOptions = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
];

const defaultValues = {
	type: 2, 
  quadrant: '',
	branchName: '', 
	name: '', 
	phone: '', 
	email: '', 
	addressLine1: '', 
	addressLine2: '',
	zipCode: '',
	city: '',
	quantity: 1,
	Days: {
		monday: false,
		tuesday: false,
		wednesday: false,
		thursday: false, 
		friday: false,
		saturday: false,
		sunday: false
	},
	startServiceTime: '08:00:00',
	finishServiceTime: '08:00:00',
	commentary: '',
	supplies: false,
	suppliesCommentary: '',
	typePrice: 1,
	price: '',
	vatRate: 16,
	supervisionDay: 15,
};

const FormComponent = props => {
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }
  const handleCreate = async () => {
    try {
      setErrors({});
      let error;
      if (values.branchName === '') {
        error = new Error('Proporciona el nombre de la empresa.')
        error.name = 'branchName';
        throw error;
      }
      if (values.addressLine2 === '') {
        error = new Error('Proporciona la colonia de la empresa.')
        error.name = 'addressLine2';
        throw error;
      }
      if (values.quadrant === '') {
        error = new Error('Selecciona el cuadrante.')
        error.name = 'quadrant';
        throw error;
      }
      await recordsServices.create('customers', {
        ...values,
        trackingDate: values.trackingDate && format(newDate(values.trackingDate), 'yyyy/MM/dd'),
        Schedule: { start: values.startServiceTime, finish: values.finishServiceTime },
      });
      props.getDataFromServer(true);
      props.showAlert({ variant: 'success' });
      props.closeForm();
      setValues(defaultValues);
    } catch(e) {
      console.log(e);
      if (['name', 'quadrant', 'branchName', 'addressLine2', 'trackingCommentary'].includes(e.name)){
        setErrors({[e.name]: e.message});
      }
    }
  }
  return (
    <FormDialog
      title='Nuevo prospecto'
      isOpen={props.actionDialog[0]}
      handleClose={props.closeForm}
      handleAction={handleCreate}
    >
      <TextField
        name='branchName'
        label='Nombre de la empresa'
        value={values.branchName}
        onChange={handleChange}
        error={errors.branchName}
      />
      <TextField
        name='addressLine2'
        label='Colonia'
        value={values.addressLine2}
        onChange={handleChange}
        error={errors.addressLine2}
      />
      <SelectField
        name='quadrant'
        label='Cuadrante'
        value={values.quadrant}
        onChange={handleChange}
        options={quadrantOptions}
        error={errors.quadrant}
      />
    </FormDialog>
  );
};

const mapStateToProps = state => ({
  actionDialog: state.actionDialog,
})

const mapDispatchToProps = dispatch => ({
  closeForm: () => dispatch(closeActionDialog({})),
  showAlert: data => dispatch(alertActions.show(data)),
  getDataFromServer: value => dispatch(getDataFromServer(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);
