import React, { PureComponent, Fragment } from "react";
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';

import RateReviewIcon from '@material-ui/icons/RateReview';
import AddIcon from '@material-ui/icons/Add';

import { openFormDialog } from './../../_actions';

import { styles } from './style';

class EmptyTableView extends PureComponent {

  openFormDialog = () => {
    const { openFormDialog, singular } = this.props;
    openFormDialog(singular);
  };

  render(){
    const { classes, singular, plural, create } = this.props;
    return (
      <main className={classes.layout}>
        <RateReviewIcon className={ classes.icon } />
        <br/>
        <Typography variant="h5" gutterBottom>
          Aún no hay { plural }
        </Typography>
        { create &&
          <Fragment>
            <br/>
            <Fab variant="extended" color="primary" aria-label="Crear" onClick={ this.openFormDialog } className={classes.margin}>
              <AddIcon className={classes.extendedIcon} />
              Crear { singular }
            </Fab>
          </Fragment>
        }
      </main>
    );
  }

}

EmptyTableView.propTypes = {
  singular: PropTypes.string.isRequired,
  plural: PropTypes.string.isRequired,
  create: PropTypes.bool.isRequired,
};

EmptyTableView.defaultProps = {
  singular: 'registro',
  plural: 'registros',
  create: true
};

const mapDispatchToProps = dispatch => ({ 
  openFormDialog: (data) => dispatch(openFormDialog(data))
});

export const EmptyTable =  withStyles(styles)(connect(null, mapDispatchToProps)(EmptyTableView));