import React, { Fragment, useState, useEffect } from "react";

import AddCircleIcon from '@material-ui/icons/Add';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonMUI from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCellMUI from '@material-ui/core/TableCell';

import QuotationDialog from './quotation.dialog';
import PulledApartDialog from './pulledApart.dialog';
import DescriptionDialog from './description.dialog';
import CustomerDialog from './customer.dialog';
import TrancingDialog from './tracing.dialog';
import BalanceDialog from './balance.dialog';

import { Button, TableCell } from './helpers';
import { useStyles } from './style';
import { ConfirmDialog } from '../../_presentationals/ConfirmDialog';
import { recordsServices } from '../../_services';

const defaultDialogValues = {
	quoted: false,
	pulledApart: false,
	description: false,
	customer: false
}

const FilipinasCustomer = props => {

	const classes = useStyles();

	const [ isChanging, setIsChanging ] = useState(1);
	const [ customers, setCustomers ] = useState(defaultTableValue);

	const [ customerId, setCustomerId ] = useState(null);
	const [ currentCustomerId, setCurrentCustomerId ] = useState(null);
	const [ currentStatus, setCurrentStatus ] = useState(null);

	const [ isOpen, setIsOpen ] = useState(defaultDialogValues);

	useEffect(() => {
		if(isChanging) {
			recordsServices.getAll({ table: 'filipinas' }).then(data => {
	      Object.keys(data).length > 0 ? setCustomers(data) : setCustomers(defaultTableValue);
	    });
	 		setIsChanging(0);
		}
  }, [isChanging]);

  const handleOpen = e => {
  	e.preventDefault();
  	const status = e.currentTarget.dataset.status;
  	setCustomerId(parseInt(e.currentTarget.dataset.customerid));
  	setIsOpen(isOpen => ({ ...isOpen, [status]: true }));
  }

  const handleClose = (dialog, config = {}) => {
  	setCustomerId(null);
  	setIsOpen(isOpen => ({ ...isOpen, [dialog]: false })); 
  	if(config.resetForm){ setIsChanging(1); }
  }

  const handleStatus = e => {
  	e.preventDefault();
  	const customerId = parseInt(e.currentTarget.dataset.customerid);
  	const status = e.currentTarget.dataset.status;
  	
  	setCurrentCustomerId(customerId);
  	setCurrentStatus(status);
  	
  }

  const storeSettings = async () => {
  	try {
  		await recordsServices.update({ table: 'filipinasStatus', id: currentCustomerId, [currentStatus]: true });
	  	await handleCancel();
	  	await setIsChanging(1);
  	} catch(e) {
  		console.log(e.message);
  	}
  }

  const handleCancel = () => {
  	setCurrentCustomerId(null);
  	setCurrentStatus(null);
  }

  const handleCreate = () => {
  	setIsOpen(isOpen => ({ ...isOpen, customer: true }));
  }
  
  const bodyRows = customers.rows.map(el => {

		const quoted = el.Status.find(el => el.name === "quoted");
		const accepted = el.Status.find(el => el.name === "accepted");
		const pulledApart = el.Status.find(el => el.name === "pulledApart");
		const ordered = el.Status.find(el => el.name === "ordered");
		const reception = el.Status.find(el => el.name === "reception");
		const embroidery = el.Status.find(el => el.name === "embroidery");
		const delivered = el.Status.find(el => el.name === "delivered");
		const supplement = el.Status.find(el => el.name === "supplement");
		
		return (<TableRow className={ el.description === "" ? classes.regular : classes.danger } key={ el.id }>
			<TableCellMUI className={classes.customerTableCell}><Link href="#" data-customerid={ el.id } data-status="customer" onClick={handleOpen}>{ el.name } { (el.contactName !== "") && <small><br/>{ el.contactName }</small>}</Link></TableCellMUI>
			<TableCell 
				date={ quoted && 
					<Link href="#" data-customerid={ el.id } data-status="quoted" onClick={ handleOpen }>{ quoted.date }</Link>
				} 
				label="Cotizar" 
				handleClick={ handleOpen } 
				status="quoted" 
				customerId={ el.id } 
			/>
			<TableCell date={ accepted && accepted.date } label="Aceptar" handleClick={ handleStatus } status="accepted" customerId={ el.id } />
			<TableCell date={ pulledApart && pulledApart.date } label="Apartar" handleClick={ handleOpen } status="pulledApart" customerId={ el.id } />
			<TableCell date={ ordered && ordered.date } label="Ordenar" handleClick={ handleStatus } status="ordered" customerId={ el.id } />
			<TableCell date={ reception && reception.date } label="Recibir" handleClick={ handleStatus } status="reception" customerId={ el.id } />
			<TableCell date={ embroidery && embroidery.date } label="Bordar" handleClick={ handleStatus } status="embroidery" customerId={ el.id } />
			<TableCell date={ delivered && delivered.date } label="Entregar" handleClick={ handleStatus } status="delivered" customerId={ el.id } />
			<TableCell date={ supplement && supplement.date } label="Cobrar" handleClick={ handleStatus } status="supplement" customerId={ el.id } />
			<TableCellMUI className={classes.descriptionTableCell}>
				{ el.description } 
				<Button 
					label={ el.description === "" ? "Crear" : "Actualizar" } 
					handleClick={ handleOpen } 
					color="secondary" 
					status="description" 
					customerId={ el.id } 
				/>
			</TableCellMUI>
			<TableCellMUI align="right"><Link href="#" data-customerid={ el.id } data-status="balance" onClick={handleOpen}>{ (el.Quotation && el.Quotation.Products) ? parseFloat(el.Quotation.Products.reduce((a, b) => a + parseFloat(b.total), 0)).toFixed(2) : "0.00" }</Link></TableCellMUI>
		</TableRow>)
	});
  
	return (
		<Fragment>
			<Grid container alignItems="center" className={ classes.header } spacing={0}>
				<Grid item md={8}>
	        <Typography variant="subtitle1" className={ classes.title }>
	          CRM | Seguimiento de Clientes
	        </Typography>
	      </Grid>
	      <Grid item className={ classes.textRight } md={4}>
	      	<ButtonMUI onClick={ handleCreate } color="secondary">
            <AddCircleIcon /> Crear Cliente
          </ButtonMUI>
	      </Grid>
			</Grid>
			<div className={classes.toolbar} />
			<TableContainer className={classes.container}>
				<Table stickyHeader className={classes.table}>
		    	<TableHead>
		    		<TableRow>
		    			<TableCellMUI>Cliente</TableCellMUI>
		    			<TableCellMUI align="center">Cotizado</TableCellMUI>
		    			<TableCellMUI align="center">Aceptado</TableCellMUI>
		    			<TableCellMUI align="center">Apartado</TableCellMUI>
		    			<TableCellMUI align="center">Ordenado</TableCellMUI>
		    			<TableCellMUI align="center">Recibido</TableCellMUI>
		    			<TableCellMUI align="center">Bordado</TableCellMUI>
		    			<TableCellMUI align="center">Entregado</TableCellMUI>
		    			<TableCellMUI align="center">Pago</TableCellMUI>
		    			<TableCellMUI>Comentario</TableCellMUI>
		    			<TableCellMUI align="right">Total</TableCellMUI>
		    		</TableRow>
		    	</TableHead>
		    	<TableBody>{ bodyRows }</TableBody>
	    	</Table>
    	</TableContainer>
    	<CustomerDialog isOpen={ isOpen.customer } handleClose={ handleClose } customerId={ customerId } />
    	<QuotationDialog isOpen={ isOpen.quoted } handleClose={ handleClose } customerId={ customerId } />
    	<PulledApartDialog isOpen={ isOpen.pulledApart } handleClose={ handleClose } customerId={ customerId } />
    	<DescriptionDialog isOpen={ isOpen.description } handleClose={ handleClose } customerId={ customerId } />
    	<TrancingDialog isOpen={ isOpen.tracing } handleClose={ handleClose } customerId={ customerId } />
    	<BalanceDialog isOpen={ isOpen.balance } handleClose={ handleClose } customerId={ customerId } />
    	<ConfirmDialog _id={ currentCustomerId } handleAction={ storeSettings } handleCancel={ handleCancel } />
		</Fragment>
	);
}

const defaultTableValue = { count: 0, rows: [] };

export default FilipinasCustomer;