import React from 'react';
import { connect } from 'react-redux';

import ConfirmDialog from './../ConfirmDialog';
import routes from './../Module/routes';

import { closeDeleteDialog, getDataFromServer, alertActions } from './../../_actions.v2';
import { recordsServices } from './../../_services.v2';

const ERROR_MESSAGE = "Ha ocurrido un error, por favor inténtalo de nuevo más tarde.";

const Component = props => {

	const handleCloseDelete = () => { props.closeDialog(); }
	const handleDelete = values => {
		handleCloseDelete();
		recordsServices._delete(routes(props.route, 'DELETE', { ...values }))
			.then(() => {
				props.showAlert({ variant: 'success' });
				props.getDataFromServer(true);
			})
			.catch(e => props.showAlert({ variant: 'warning', message: ERROR_MESSAGE }));
	}

	return (
		<ConfirmDialog 
      isOpen={props.openForm[0]} 
      params={props.openForm[1]} 
      handleAction={handleDelete} 
      handleClose={handleCloseDelete} 
    />
	);
}

const mapStateToProps = state => ({
	openForm: state.deleteDialog
});

const mapDispatchToProps = dispatch => ({
	closeDialog: () => dispatch(closeDeleteDialog()),
	getDataFromServer: value => dispatch(getDataFromServer(value)),
	showAlert: data => dispatch(alertActions.show(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);