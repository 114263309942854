import { actionDialogConstants } from '../_constants.v2';

const defaultActionValues = [ false, {} ];
const defaultFilterValues = [ false, {} ];
const defaultAlternativeValues = [ false, {} ];

const actionDialog = (state = defaultActionValues, action) => {
  switch (action.type) {
    case actionDialogConstants.OPEN_FORM_DIALOG:
      return [ true, action.payload ];
    case actionDialogConstants.CLOSE_FORM_DIALOG:
      return defaultActionValues;
    case 'OPEN_ALTERNATIVE_DIALOG':
      return [ true, action.payload ];
    case 'CLOSE_ALTERNATIVE_DIALOG':
      return defaultActionValues;
    default:
      return state;
  }
};

const filterDialog = (state = defaultFilterValues, action) => {
  switch (action.type) {
    case actionDialogConstants.OPEN_FILTER_DIALOG:
      return [ true, { ...state[1], ...action.payload } ];
    case actionDialogConstants.CLOSE_FILTER_DIALOG:
      return [ false, state[1]];
    case actionDialogConstants.SET_FILTER_DATA:
      return [ state[0], action.payload ];
    default:
      return state;
  }
};

const alternativeDialog = (state = defaultAlternativeValues, action) => {
  switch (action.type) {
    case 'OPEN_ALTERNATIVE_DIALOG':
      return [ true, action.payload ];
    case 'CLOSE_ALTERNATIVE_DIALOG':
      return defaultAlternativeValues;
    default:
      return state;
  }
};

export { actionDialog, filterDialog, alternativeDialog };