const assignments = ({method, ...props}) => {
  switch (method) {
    case 'POST':
    	return `maids/${props.maidId}/assignments`;
    case 'DELETE':
    	return `maids/${props._id}/assignments`;
    case 'PUT':
    	return `maids/${props.id}/assignments`;
    default:
      return 'assignments';
  }
}

export default assignments;