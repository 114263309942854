import React from 'react';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckboxMUI from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const Component = props => 
	props.noFormat 
		? <>
			<Typography variant='subtitle1'>
				{ props.label }
			</Typography>
			<FormGroup row>
				{ props.controls.map(el => 
						<FormControlLabel key={el.name} label={el.label}
							control={
								<Checkbox general={props.name} name={el.name} value={el.value} action={props.action} />
							}
						/>
				)}
			</FormGroup>
		</>
		: <Grid container spacing={0} alignItems='flex-end'>
				<Grid item xs={1}>
					{ props.icon || '' }
				</Grid>
				<Grid item xs={10}>
					<Typography variant='caption' display='block' color='textSecondary'>
						{ props.label }
					</Typography>
					<FormGroup row>
						{ props.controls.map(el => 
								<FormControlLabel key={el.name} label={el.label}
									control={
										<Checkbox general={props.name} name={el.name} value={el.value} action={props.action} />
									}
								/>
						)}
					</FormGroup>
				</Grid>
			</Grid>

const Checkbox = props => {
	const handleChange = () => { props.action(props.name, props.general); }
	return (
		<CheckboxMUI 
			checked={props.value || false} 
			onChange={handleChange} 
			value={props.name} 
			color="primary" 
		/>
	);
}

export default Component;