const api = [ 'name', 'contactName', 'phone', 'addressLine1', 'addressLine2', 'zipCode', 'city', 'state', 'email' ];

const expenses = (method, values) => {
  const tmp = {};
  switch (method) {
    case 'POST':
    case 'PUT':
      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      api.forEach(key =>{
        if(!Object.keys(tmp).includes(key)) tmp[key] = "";
      });
      return tmp;
    default:
      return tmp;
  }
}

export default expenses;
