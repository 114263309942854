import React, { useState } from "react";

import format from 'date-fns/format';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormDialog from '../../_presentationals.v2/FormDialog';
import SelectField from './../../_presentationals.v2/Form/SelectField';
import DateField from './../../_presentationals.v2/Form/DateField';

import { recordsServices } from './../../_services.v2';
import { errorFormat } from './../../_helpers.v2';

const date = format(new Date(), 'yyyy/MM/dd');

const defaultValues = {
  paymentPlan: 1,
  account: 1,
  start: date,
  finish: date
};

const paymentPlanOptions = [
  { value: 1, label: "Semanal"}, 
  { value: 3, label: "Decenal"},
  { value: 2, label: "Quincenal"},
  { value: 4, label: "Mensual"},
  { value: 5, label: "Cartorcenal"},
];

const accountOptions = [
  { value: 1, label: 'EDER FERNANDO JIMENEZ GARCÍA'},
  { value: 2, label: 'JOSE CRUZ ROSALES FELIX'},
  { value: 3, label: 'CYNTHIA NALLELY ROSALES VEGA'},
  { value: 4, label: 'FERNANDO...'},
  { value: 5, label: 'CAJA CHICA'},
  { value: 6, label: 'MARIA DEL ROSARIO VEGA ESEBERRE'},
  { value: 7, label: 'FERNANDO JIMENEZ RAMIREZ'},
];

const ConfigDialog = props => {

  const [ values, setValues ] = useState(defaultValues);
  const [ errors, setErrors ] = useState({});
  const [ maids, setMaids ] = useState({});
  
  const handleAction = () => {
    recordsServices.create('v2/payrolls', { maids, start: values.start, finish: values.finish, paymentPlan: values.paymentPlan })
      .then(data => {
        if(data.errorCode) { throw new Error(JSON.stringify(data.errorMessage)); }
        props.handleCancel()
      })
      .catch(e => { 
        const tmpErrors = errorFormat(e.message);
        setErrors(tmpErrors);
      });
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAccountChange = e => {
    const name = e.target.name;
    const account = e.target.value;
    const tmp = maids[name];
    setMaids(values => ({ ...values, [name]: { ...tmp, account } }));
  }

  const handleDateChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd')
    setValues(values => ({ ...values, [name]: tmpValue }));
  }

  const handleGetMaids = () => {
    recordsServices.get(`checkins/paymentPlan/${values.paymentPlan}?start=${values.start}&finish=${values.finish}`)
      .then(data => {
        setMaids(data);
      })
      .catch(e => console.log(e.message));
  }

  return(
    <FormDialog
      title='Emitir pago'
      isOpen={props.isOpen}
      width='md'
      handleClose={props.handleCancel}
      handleAction={handleAction}
    >
      <DateField 
        name='start'
        label='Fecha Inicial'
        value={values.start}
        onChange={handleDateChange}
        error={errors.start}
      />
      <DateField 
        name='finish'
        label='Fecha Final'
        value={values.finish}
        onChange={handleDateChange}
        error={errors.finish}
      />
      <SelectField 
        name='paymentPlan'
        label='Plan de Pago'
        value={values.paymentPlan}
        onChange={handleChange}
        options={paymentPlanOptions}
        error={errors.paymentPlan}
      />
      <Grid container>
        <Grid item xs={12} align='right'>
          <br />
          
        </Grid>
        <Grid item xs={12}>

          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Mucaama</TableCell>
                  <TableCell align="right">Sueldo</TableCell>
                  <TableCell align="right">Infonavit</TableCell>
                  <TableCell align="right">Pago</TableCell>
                  <TableCell align="right">
                    <Button onClick={handleGetMaids} variant='outlined' color="primary" size='small'>
                      Buscar
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  Object.keys(maids).map(key => {
                    return (
                      <TableRow key={key}>
                        <TableCell>{maids[key].name}</TableCell>
                        <TableCell align="right">{parseFloat(maids[key].salary).toFixed(2)}</TableCell>
                        <TableCell align="right">{parseFloat(maids[key].infonavit).toFixed(2)}</TableCell>
                        <TableCell align="right">{parseFloat(maids[key].preSalary).toFixed(2)}</TableCell>
                        <TableCell align="right">
                          <SelectField 
                            name={key}
                            label='Cuenta Bancaria'
                            value={maids[key].account || 1}
                            onChange={handleAccountChange}
                            options={accountOptions}
                            noFormat
                            variant='outlined'
                            size='small'
                            margin='dense'
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>

        </Grid>
      </Grid>
    </FormDialog>
  );
}

export default ConfigDialog;