import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

import format from 'date-fns/format';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Link from '@material-ui/core/Link';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';

import FormDialog from '../../../_presentationals.v2/FormDialog';
import TextField from '../../../_presentationals.v3/FormComponents/TextField';
import SelectField from '../../../_presentationals.v3/FormComponents/SelectField';
import DateField from '../../../_presentationals.v3/FormComponents/DateField';
import CheckBoxField from '../../../_presentationals.v2/Form/CheckBoxField';

import { Quotation } from '../../Customer/quotation';

import { recordsServices } from '../../../_services.v2';

import { alertActions, getDataFromServer } from '../../../_actions.v2';

import useStyles from './style';
import { Typography } from '@material-ui/core';
import { currencyFormat } from '../../../_helpers';
import { newDate } from '../../../_helpers.v2';

const scheduleOptions = [ { value: 2, label: '2'}, { value: 4, label: '4'}, { value: 6, label: '6'}, { value: 8, label: '8'} ];
const cityOptions = [
  { value: 'Culiacán', label: 'Culiacán'},
  { value: 'Mazatlán', label: 'Mazatlán'},
  { value: 'Los Mochis', label: 'Los Mochis'},
  { value: 'Guasave', label: 'Guasave'},
];
const receiptOptions = [
  { value: 1, label: 'Personal interno' },
  { value: 2, label: 'En proceso' },
  { value: 3, label: 'Reagendar' },
  { value: 4, label: 'Precio alto' },
  { value: 5, label: 'Contrato vigente' },
];

const defaultValues = {
	type: 2, 
	branchName: '', 
	businessActivity: '', 
	name: '', 
	phone: '', 
	email: '', 
	addressLine1: '', 
	addressLine2: '',
	zipCode: '',
	city: '',
  trackingDate: new Date(),
  trackingCommentary: '',
  maidQty: '',
  schedule: '', 
  receipt: '',
  commentary: '',
  suppliesPrice: '',
  suppliesCommentary: '',
  discount: '',
  customizedPrice: '',
  Days: {
		monday: false,
		tuesday: false,
		wednesday: false,
		thursday: false, 
		friday: false,
		saturday: false,
		sunday: false
	},
};

const FormComponent = props => {

  const classes = useStyles();

  const [showExtraInfo, setShowExtraInfo] = useState(false);
  const [values, setValues] = useState(defaultValues);
  const [total, setTotal] = useState(0);
  const [prices, setPrices] = useState([0,0,0,0]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    recordsServices.get(`branches/users`).then(data => {
      setPrices([data.price1, data.price2, data.price3, data.price4]);
    }).catch(e => console.log(e));
  }, []);

  useEffect(() => {
    if (props.id && props.isOpen) {
      recordsServices.get(`crm/2/customer/${props.id}`).then(data => {
        let customizedPrice = '';
        switch(parseInt(data.schedule)) {
          case 2: customizedPrice = prices[0]; break;
          case 4: customizedPrice =  prices[1]; break;
          case 6: customizedPrice =  prices[2]; break;
          case 8: customizedPrice =  prices[3]; break;
          default: customizedPrice =  ''; break;
        }
        setValues(values => ({
          ...values,
          ...data,
          customizedPrice,
          maidQty: data.maidQty ? parseInt(data.maidQty) : '',
          suppliesPrice: parseFloat(data.suppliesPrice, 2),
          discount: parseFloat(data.discount, 2),
        }))
      }).catch(e => console.log(e));
    }
  }, [props.id, props.isOpen, prices]);

  useEffect(() => {
    if (parseFloat(values.maidQty) > 0 && values.schedule !== '') {
      const numberOfDays = Object.keys(values.Days).reduce((days,day) => {
        return values.Days[day] ? days + 1 : days;
      }, 0);
      let hourPrice = 0;
      switch(parseInt(values.schedule)) {
        case 2: hourPrice = prices[0]; break;
        case 4: hourPrice = prices[1]; break;
        case 6: hourPrice = prices[2]; break;
        case 8: hourPrice = prices[3]; break;
        default: hourPrice = 0; break;
      }
      const suppliesPrice = values.suppliesPrice ? parseFloat(values.suppliesPrice) : 0;
      const discount = values.discount ? parseFloat(values.discount) : 0;
      const priceWithoutDiscount = (((numberOfDays * hourPrice * values.maidQty) / 7) * 30.5) + suppliesPrice;
      const price = priceWithoutDiscount - (priceWithoutDiscount * (parseFloat(discount) / 100));
      // const tax = (parseFloat(price) * (parseFloat(16) / 100));
      // const total = (parseFloat(price) + parseFloat(tax));
      setTotal(price);
    }
  }, [values.Days, values.discount, values.suppliesPrice, values.maidQty, values.schedule, prices]);
  const handleCreate = async (e, quoted) => {
    try {
      setErrors({});
      let error;
      if (values.type === 2 && values.branchName === '') {
        error = new Error('Proporciona el nombre de la empresa.')
        error.name = 'branchName';
        throw error;
      }
      if (values.name === '') {
        error = new Error('Proporciona el nombre del contacto.')
        error.name = 'name';
        throw error;
      }
      if (values.addressLine2 === '') {
        error = new Error('Proporciona la colonia de la empresa.')
        error.name = 'addressLine2';
        throw error;
      }
      if (values.maidQty === '') {
        error = new Error('Proporciona el número de operarios.')
        error.name = 'maidQty';
        throw error;
      }
      if (values.schedule === '') {
        error = new Error('Proporciona el horario solicitado.')
        error.name = 'schedule';
        throw error;
      }
      if (parseInt(values.discount) > 15) {
        error = new Error('El descuento no puede ser mayor a un 15%.')
        error.name = 'discount';
        throw error;
      }
      if (values.trackingCommentary === '') {
        error = new Error('Proporciona un comentario para el seguimiento.')
        error.name = 'trackingCommentary';
        throw error;
      }
      if (values.receipt === '') {
        error = new Error('Proporciona un justificante.')
        error.name = 'receipt';
        throw error;
      }
      await recordsServices.update(`crm/2/customer/${props.id}`, {
        ...values,
        trackingDate: values.trackingDate && format(newDate(values.trackingDate), 'yyyy/MM/dd'),
        maidQty: parseInt(values.maidQty),
        schedule: parseInt(values.schedule), 
        suppliesPrice: parseInt(values.suppliesPrice),
        discount: parseInt(values.discount),
        receipt: parseInt(values.receipt),
        status: 3,
      });

      if (quoted) { 
        const numberOfDays = Object.keys(values.Days).reduce((days,day) => {
          return values.Days[day] ? days + 1 : days;
        }, 0);
        const suppliesPrice = values.suppliesPrice ? parseFloat(values.suppliesPrice) : 0;
        const discount = values.discount ? parseFloat(values.discount) : 0;
        const priceWithoutDiscount = (((numberOfDays * parseFloat(values.customizedPrice) * values.maidQty) / 7) * 30.5) + suppliesPrice;
        const price = priceWithoutDiscount - (priceWithoutDiscount * (parseFloat(discount) / 100));
        Quotation({
          ...values,
          customerId: props.id,
          vatRate: 16,
          price,
          typePrice: 3,
          quantity: parseInt(values.maidQty),
          supplies: parseInt(values.suppliesPrice) > 0 && true,
          serviceTime: parseFloat(values.schedule),
          commentary: values.commentary,
        });
      }
      props.getDataFromServer(true);
      props.showAlert({ variant: 'success' });
      props.handleClose();
      setValues(defaultValues);
    } catch(e) {
      console.log(e.message);
      if (['name', 'receipt', 'branchName', 'addressLine2', 'trackingCommentary', 'schedule', 'trackingCommentary', 'discount', 'maidQty'].includes(e.name)){
        setErrors({[e.name]: e.message});
      }
    }
  }
  const handleExtraConfiguration = () => {
    setShowExtraInfo(showExtraInfo => !showExtraInfo);
  };
  const handleDays = name => {
  	setValues(values => ({ 
  		...values, Days: { ...values.Days, [name]: !values.Days[name] } 
  	}));
  };
  const handleChange = (name, value) => {
    if (name === 'schedule') {
      switch(parseInt(value)) {
        case 2: setValues(values => ({ ...values, customizedPrice: prices[0] })); break;
        case 4:setValues(values => ({ ...values, customizedPrice: prices[1] })); break;
        case 6: setValues(values => ({ ...values, customizedPrice: prices[2] })); break;
        case 8: setValues(values => ({ ...values, customizedPrice: prices[3] })); break;
        default: setValues(values => ({ ...values, customizedPrice: '' })); break;
      }
    }
    setValues(values => ({ ...values, [name]: value }));
  };
  const handleQuotation = () => {
    handleCreate(null, true);
  };
  return (
    <FormDialog
      title='Cotizado | Paso 2'
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      handleAction={handleCreate}
      actionLabel='Aceptar cotización y continuar al contrato'
    >
      <TextField
        name='branchName'
        label='Nombre de la empresa'
        value={values.branchName}
        onChange={handleChange}
        error={errors.branchName}
      />
      <TextField
        name='maidQty'
        label='Número de operarios'
        value={values.maidQty}
        type='number'
        onChange={handleChange}
        error={errors.maidQty}
      />
      <SelectField
        name='schedule'
        label='Horas de servicio'
        value={values.schedule}
        onChange={handleChange}
        error={errors.schedule}
        options={scheduleOptions}
      />
      <TextField
        name='customizedPrice'
        label='Precio de jornada laboral'
        value={values.customizedPrice}
        type='number'
        onChange={handleChange}
        error={errors.customizedPrice}
      />
      <TextField
        name='suppliesPrice'
        label='Precio de insumos'
        value={values.suppliesPrice}
        onChange={handleChange}
        error={errors.suppliesPrice}
      />
      {
        parseFloat(values.suppliesPrice) > 0 &&
        <TextField
          name='suppliesCommentary'
          label='Descripción de insumos'
          value={values.suppliesCommentary}
          onChange={handleChange}
          error={errors.suppliesCommentary}
        />
      }
      <CheckBoxField
				label='Días'
        noFormat
				action={handleDays}
				controls={[{
					name: 'monday',
					label: 'Lunes',
					value: values.Days.monday
				},{
					name: 'tuesday',
					label: 'Martes',
					value: values.Days.tuesday
				},{
					name: 'wednesday',
					label: 'Miércoles',
					value: values.Days.wednesday
				},{
					name: 'thursday',
					label: 'Jueves',
					value: values.Days.thursday
				},{
					name: 'friday',
					label: 'Viernes',
					value: values.Days.friday
				},{
					name: 'saturday',
					label: 'Sábado',
					value: values.Days.saturday
				},{
					name: 'sunday',
					label: 'Domingo',
					value: values.Days.sunday
				}]}
			/>
      <TextField
        name='discount'
        label='Descuento (%)'
        type='number'
        value={values.discount}
        onChange={handleChange}
        error={errors.discount}
      />
      <TextField
        name='commentary'
        label='Comentarios para cotización'
        value={values.commentary}
        onChange={handleChange}
        error={errors.commentary}
      />
      <SelectField
        name='receipt'
        label='Justificante'
        value={values.receipt}
        options={receiptOptions}
        onChange={handleChange}
        error={errors.receipt}
      />
      <DateField
        name='trackingDate'
        label='Fecha de seguimiento'
        value={values.trackingDate}
        onChange={handleChange}
        error={errors.trackingDate}
      />
      <TextField
        name='trackingCommentary'
        label='Comentario para el seguimiento'
        value={values.trackingCommentary}
        onChange={handleChange}
        error={errors.trackingCommentary}
      />
      <Link href='#' onClick={handleExtraConfiguration} underline='none' color='inherit' variant='subtitle1'>
          <div className={classes.flex}>
              { !props.show ? <KeyboardArrowDownIcon fontSize='large' /> : <KeyboardArrowUpIcon fontSize='large' /> }
              Información Extra
          </div>
      </Link>
      <Collapse in={showExtraInfo}>
        <TextField
          name='name'
          label='Nombre del contacto'
          value={values.name}
          onChange={handleChange}
          error={errors.name}
        />
        <TextField
          name='businessActivity'
          label='Giro de la empresa'
          value={values.businessActivity}
          onChange={handleChange}
          error={errors.businessActivity}
        />
        <TextField
          name='phone'
          label='Teléfono'
          value={values.phone}
          onChange={handleChange}
          error={errors.phone}
        />
        <TextField
          name='email'
          label='Correo Electrónico'
          value={values.email}
          onChange={handleChange}
          error={errors.email}
        />
        <TextField
          name='addressLine1'
          label='Calle y número'
          value={values.addressLine1}
          onChange={handleChange}
          error={errors.addressLine1}
        />
        <TextField
          name='addressLine2'
          label='Colonia'
          value={values.addressLine2}
          onChange={handleChange}
          error={errors.addressLine2}
        />
        <SelectField
          name='city'
          label='Ciudad'
          value={values.city}
          options={cityOptions}
          onChange={handleChange}
          error={errors.city}
        />
      </Collapse>
      <Button 
        variant="contained" 
        color="secondary" 
        onClick={handleQuotation} 
        style={{ marginTop: 10, marginBottom: 20 }}
      >
        Descargar Cotización
      </Button>
      <Typography variant='h4'>
        Total ${currencyFormat(total)} MXN
      </Typography>
    </FormDialog>
  );
};

const mapDispatchToProps = dispatch => ({
  showAlert: data => dispatch(alertActions.show(data)),
  getDataFromServer: value => dispatch(getDataFromServer(value)),
})

export default connect(null, mapDispatchToProps)(FormComponent);
