import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import format from 'date-fns/format';

import MessageIcon from '@material-ui/icons/MessageOutlined';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooksOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoneyOutlined';
import CreditCardIcon from '@material-ui/icons/CreditCardOutlined';
import EventIcon from '@material-ui/icons/EventOutlined';
import HailIcon from '@material-ui/icons/WorkOutline';

import { recordsServices } from './../../_services.v2';
import { getDataFromServer } from './../../_actions.v2';

import Module from './../../_presentationals.v2/Module';

import ActionForm from './action.form';
import DescriptionForm from './description.form';
import ContractForm from './contract.form';
import RatesForm from './rates.form';
import BalanceForm from './balance.form';
import TrackingDateForm from './trackingDate.form';
import ProspectedDateForm from './prospectedDate.form';
import { newDate } from '../../_helpers.v2';

const Rows = [
	{ label: 'ID', key: 'id' }, 
  { label: 'Nombre', key: 'name', sort: 'text', filter: true }, 
	{ label: 'Teléfono', key: 'phone' }, 
	{ label: 'Tipo', key: 'type', sort: 'text' }, 
  { label: 'Tasa', key: 'vatRate', align: 'right' }, 
  { label: 'Colonia', key: 'addressLine2', sort: 'text' }, 
  { label: 'PorProspectar', key: 'pendingDate' },
  { label: 'Prospección', key: 'prospectedDate' },
  { label: 'Cotizado', key: 'quoted' },
  { label: 'En Espera', key: 'waiting' },
  { label: 'Colocado', key: 'working' },
	{ label: 'Descripción', key: 'description', wrap: true },
  { label: 'Seguimiento', key: 'trackingDate' },
];

const statusOptions = [
  { value: '-1', label: 'Todos' },
  { value: '6', label: 'Por prospectar' },
  { value: '1', label: 'Prospecto' },
  { value: '2', label: 'Cotizado' },
  { value: '3', label: 'En espera' },
  { value: '4', label: 'Colocado' },
  { value: '5', label: 'Seguimiento' },
];

const defaultStatusValue = { date: '' };
const defaultCommentaryValue = [ false, { id: null, description: '' } ];
const defaultContractValue = [ false, { id: null } ];

const { id: userId } = localStorage.getItem('session') 
  ? JSON.parse(localStorage.getItem('session')).data
  : { id: null };

const Component = ({ getData, ...props }) => {
	const [ customers, setCustomers ] = useState([]);
  const [ openDescriptionForm, setOpenDescriptionForm ] = useState(defaultCommentaryValue);
  const [ openContractForm, setOpenContractForm ] = useState(defaultContractValue);
  const [ openRatesForm, setOpenRatesForm ] = useState(defaultContractValue);
  const [ openBalanceForm, setOpenBalanceForm ] = useState(defaultContractValue);
  const [ openTrackingDateForm, setOpenTackingDateForm ] = useState(defaultContractValue);
  const [ openProspectedDateForm, setopenProspectedDateForm ] = useState(defaultContractValue);
  const [userOptions, setUserOptions] = useState([]);

  useEffect(() => {
		recordsServices.get('users')
			.then(data => {
        const tmpData = data.rows.map(el => ({
          value: el.id + '',
          label: `${el.firstName} ${el.lastName}`
        }))
				setUserOptions([ { value: '-1', label: 'TODOS' }, ...tmpData]);
			})
			.catch(e => console.log(e.message));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	
	useEffect(() => {
		getData && recordsServices.get('v2/customers')
			.then(data => {
				const tmp = data.map(el => {
          const pendingDate = el.pendingDate ? format(newDate(el.pendingDate), 'dd/MM/yyyy') : '';
          const prospectedDate = el.prospectedDate ? format(newDate(el.prospectedDate), 'dd/MM/yyyy') : '';
          const quoted = el.Status.find(el => el.name === 'quoted') || defaultStatusValue;
          const waiting = el.Status.find(el => el.name === 'waiting') || defaultStatusValue;
          const working = el.Status.find(el => el.name === 'working') || defaultStatusValue;
          const trackingDate = el.trackingDate ? format(newDate(el.trackingDate), 'dd/MM/yyyy') : '';
          const background = (el.trackingDate && newDate(el.trackingDate) <= new Date());
          return {
            id: el.id,
            name: el.type.id === 1 ? el.name : el.name + ' - ' + el.branchName,
            phone: el.phone,
            type: el.type.label,
            vatRate: el.vatRate + ' %',
            addressLine2: el.addressLine2,
            pendingDate,
            prospectedDate,
            quoted: quoted.date,
            waiting: waiting.date,
            working: working.date,
            description: el.description,
            rowColor: el.description !== '' ? 'danger' : 'regular',
            background,
            trackingDate,
          }
        });
				setCustomers(tmp);
        props.getDataFromServer(false);
			})
			.catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCommentary = (e, values) => { 
    recordsServices.get(`v2/customers/${values.id}/description`)
      .then(({ description }) => {
        setOpenDescriptionForm([ true, { id: values.id, description } ]); 
      })
      .catch(e => console.log(e.message));
  };
  const closeCommentary = () => { setOpenDescriptionForm(defaultCommentaryValue); };

  const handleContract = (e, values) => { 
    recordsServices.get(`v2/customers/${values.id}/contract`)
      .then(({ customerId, ...data}) => {
        setOpenContractForm([ true, { id: values.id, ...data } ]); 
      })
      .catch(e => console.log(e.message));
  };
  const closeContract = () => { setOpenContractForm(defaultContractValue); };

  const handleRates = (e, values) => { 
    recordsServices.get(`v2/customers/${values.id}/rates`)
      .then(({ customerId, ...data}) => {
        setOpenRatesForm([ true, { id: values.id, ...data } ]); 
      })
      .catch(e => console.log(e.message));
  };
  const closeRates = () => { setOpenRatesForm(defaultContractValue); };

  const handleBalance = (e, values) => { setOpenBalanceForm([ true, { id: values.id } ]); };
  const closeBalance = () => { setOpenBalanceForm(defaultContractValue); };

  const handleFilter = (data) => {
    const userId = data.userId ? data.userId !== '-1' ? `userId=${data.userId}` : '' : '';
    const status = data.status ? data.status !== '-1' ? parseInt(data.status) : null : null;
    recordsServices.get(`v2/customers?${userId}`)
			.then(data => {
				const tmp = data.map(el => {
          const pendingDate = el.pendingDate ? format(newDate(el.pendingDate), 'dd/MM/yyyy') : '';
          const prospectedDate = el.prospectedDate ? format(newDate(el.prospectedDate), 'dd/MM/yyyy') : '';
          const quoted = el.Status.find(el => el.name === 'quoted') || defaultStatusValue;
          const waiting = el.Status.find(el => el.name === 'waiting') || defaultStatusValue;
          const working = el.Status.find(el => el.name === 'working') || defaultStatusValue;
          const trackingDate = el.trackingDate ? format(newDate(el.trackingDate), 'dd/MM/yyyy') : '';
          const background = (el.trackingDate && newDate(el.trackingDate) <= new Date());
          if (status) {
            if (status === 6 && pendingDate === '') return null;
            if (status === 1 && prospectedDate === '') return null;
            if (status === 2 && quoted.date === '') return null;
            if (status === 3 && waiting.date === '') return null;
            if (status === 4 && working.date === '') return null;
            if (status === 5 && trackingDate === '') return null;
          }
          return { 
            id: el.id,
            name: el.type.id === 1 ? el.name : el.name + ' - ' + el.branchName,
            phone: el.phone,
            type: el.type.label,
            vatRate: el.vatRate + ' %',
            addressLine2: el.addressLine2,
            pendingDate,
            prospectedDate,
            quoted: quoted.date,
            waiting: waiting.date,
            working: working.date,
            description: el.description,
            trackingDate,
            background,
            rowColor: el.description !== '' ? 'danger' : 'regular'
          }
        }).filter(el => el);
				setCustomers(tmp);
        props.getDataFromServer(false);
			})
			.catch(e => console.log(e.message));
  };

  const handleTrackingDate = (e, values) => {
    recordsServices.get(`v2/customers/${values.id}/trackingDate`)
      .then(({ trackingDate }) => {
        setOpenTackingDateForm([ true, { id: values.id, trackingDate } ]); 
      })
      .catch(e => console.log(e.message));
  };
  const closeTackingDate = () => { setOpenTackingDateForm(defaultContractValue); };

  const handlePendingDate = (e, values) => {
    setopenProspectedDateForm([true, {id: values.id}]); 
  }

  const closePendingDate = () => { setopenProspectedDateForm(defaultContractValue); };

	return (
    <Fragment>
  		<Module 
  			route='v2/customers'
        title='Clientes'
        singular='Cliente'
        Rows={Rows}
        records={customers}
        filter={[handleFilter, [{
          field: 'select',
          name: 'userId',
          label: 'Vendedor',
          options: userOptions,
        },{
          field: 'select',
          name: 'status',
          label: 'Estatus',
          options: statusOptions,
        }]]}
        actions={[{
          label: 'Prospectado',
          icon: <HailIcon fontSize='small' />,
          onClick: handlePendingDate
        },{
          label: 'Fecha de Seguimiento',
          icon: <EventIcon fontSize='small' />,
          onClick: handleTrackingDate
        },{
          label: 'Descripción',
          icon: <MessageIcon fontSize='small' />,
          onClick: handleCommentary
        },{
          label: 'Contrato',
          icon: <LibraryBooksIcon fontSize="small" />,
          onClick: handleContract
        },{
          label: 'Tarifas',
          icon: <AttachMoneyIcon fontSize="small" />,
          onClick: handleRates
        },
          parseInt(userId) === 1 && {
            label: 'Saldo',
            icon: <CreditCardIcon fontSize="small" />,
            onClick: handleBalance
          }
        ]}
      />
      <ActionForm />
      <DescriptionForm 
        isOpen={openDescriptionForm[0]} 
        values={openDescriptionForm[1]} 
        handleClose={closeCommentary}
      />
      <ContractForm 
        isOpen={openContractForm[0]} 
        values={openContractForm[1]} 
        handleClose={closeContract}
      />
      <RatesForm 
        isOpen={openRatesForm[0]} 
        values={openRatesForm[1]} 
        handleClose={closeRates}
      />
      <BalanceForm 
        isOpen={openBalanceForm[0]} 
        values={openBalanceForm[1]} 
        handleClose={closeBalance}
      />
      <TrackingDateForm 
        isOpen={openTrackingDateForm[0]} 
        values={openTrackingDateForm[1]} 
        handleClose={closeTackingDate}
      />
      <ProspectedDateForm 
        isOpen={openProspectedDateForm[0]} 
        values={openProspectedDateForm[1]} 
        handleClose={closePendingDate}
      />
    </Fragment>
	);
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: value => dispatch(getDataFromServer(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);