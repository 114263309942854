import 'date-fns';
import React from 'react';

import DateFnsUtils from '@date-io/date-fns';
import localeES from 'date-fns/locale/es';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  marginVertical: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
}));

const DateFieldComponent = ({ label, error, onChange, helperText, ...props }) => {
  const classes = useStyles();
  const handleChange = date => {
    onChange(props.name, date);
  };
  return (
    <div className={classes.marginVertical}>
      <Typography variant='subtitle1'>{label}</Typography>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeES}>
        <DatePicker
          value={props.value}
          onChange={handleChange}
          error={!!error}
          helperText={error || helperText}
          format='dd MMMM yyyy'
          fullWidth={props.fullWidth || true}
          autoOk={true}
          autoComplete='off'
          cancelLabel='Cerrar'
          size={props.size || 'small'}
          inputVariant={props.variant || 'outlined'}
          {...props}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DateFieldComponent;
