import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

import format from 'date-fns/format';
import getDay from 'date-fns/getDay';

import FormDialog from './../../_presentationals.v2/FormDialog';
import TextField from './../../_presentationals.v2/Form/TextField';
import AutocompleteField from './../../_presentationals.v2/Form/AutocompleteField';
import DateField from './../../_presentationals.v2/Form/DateField';

import { closeActionDialog, getDataFromServer, alertActions } from './../../_actions.v2';
import { recordsServices } from './../../_services.v2';
import { errorFormat, newDate } from './../../_helpers.v2';

import routes from './../../_presentationals.v2/Module/routes';

const ERROR_MESSAGE = 'Ha ocurrido un error, por favor inténtalo de nuevo más tarde.';

const defaultValues = {
	customerId: { value: '', label: '' },
	maidId: { value: '', label: '' },
	salary: '',
	payment: '',
	supplies: '',
	date: format(new Date(), 'yyyy/MM/dd'),
};

const Component = props => { 
	
	const [ values, setValues ] = useState(defaultValues);
	const [ errors, setErrors ] = useState({});
	const [ maids, setMaids ] = useState([]);
	const [ customers, setCustomers ] = useState([]);

	useEffect(() => {
		recordsServices.get('v2/customers')
			.then(data => {
				const tmp = data.map(el => ({ value: el.id, label: el.name }));
				setCustomers(tmp);
			})
			.catch(e => console.log(e.message));
		recordsServices.get('maids')
			.then(data => {
				const tmp = data.rows.map(el => ({ value: el.id, label: el.name }));
				setMaids(tmp);
			})
			.catch(e => console.log(e.message));
	}, []);

	useEffect(() => {
		const customerId = parseInt(values.customerId.value);
		const numberDay = getDay(newDate(values.date));
		const day = numberDay === 0 ? 7 : numberDay;
		
		!isNaN(customerId) && recordsServices.get(`assignments/customers/${customerId}?day=${day}`)
			.then(data => {
				setValues({
					...values,
					salary: data.salary,
					payment: data.payment,
					// supplies: 0,
					supplies: data.supplies,
				});
			})
			.catch(e => console.log(e.message));		
	}, [values.customerId.value, values.date]); // eslint-disable-line react-hooks/exhaustive-deps
  
	const handleChange = e => {
		const name = e.target.name;
		const value = e.target.value;
		setValues(values => ({ ...values, [name]: value }));
	}

	const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleDateChange = (name, value) => {
	  const tmpValue = format(value, 'yyyy/MM/dd')
	  setValues(values => ({ ...values, [name]: tmpValue }));
	}

  const handleCloseForm = () => {
    props.handleCloseForm();
    Object.keys(errors).length > 0 && setErrors({});
    setValues(defaultValues);
  }

  const handleAction = () => {
  	const requestMethod = 'create';
    const url = routes('substitutions', requestMethod.toUpperCase(), {});
  	const body = {
  		customerId: values.customerId.value,
    	maidId: values.maidId.value,
    	salary: values.salary === '' ? 0 : values.salary,
    	payment: values.payment === '' ? 0 : values.payment,
    	supplies: values.supplies === '' ? 0 : values.supplies,
    	date: format(newDate(values.date), 'yyyy-MM-dd')
  	};

    recordsServices[requestMethod](url, body)
      .then(data => {
      	if(data.errorCode) { throw new Error(JSON.stringify(data.errorMessage)); }
      	props.showAlert({ variant: 'success' });
        props.getDataFromServer(true);
        handleCloseForm();
      })
      .catch(e => { 
      	const tmpErrors = errorFormat(e.message);
        setErrors(tmpErrors);
      	props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
      });
  }
  
	return (
		<FormDialog
      title='Crear Suplencia'
      isOpen={props.openForm[0]}
      handleClose={handleCloseForm}
      handleAction={handleAction}
    >
    	<DateField 
        name='date'
        label='Fecha'
        value={values.date}
        onChange={handleDateChange}
        error={errors.date}
      />
    	<AutocompleteField 
        name='customerId'
        label='Cliente'
        value={values.customerId}
        onChange={handleAutocompleteChange}
        options={customers}
        error={errors.customerId}
      />
      <AutocompleteField 
        name='maidId'
        label='Mucama'
        value={values.maidId}
        onChange={handleAutocompleteChange}
        options={maids}
        error={errors.maidId}
      />
			<TextField
				name='salary'
				label='Sueldo'
				value={values.salary}
				onChange={handleChange}
				error={errors.salary}
				type='number'
				required
			/>
			<TextField
				name='payment'
				label='Cobro'
				value={values.payment}
				onChange={handleChange}
				error={errors.payment}
				type='number'
				required
			/>
			<TextField
				name='supplies'
				label='Insumos'
				value={values.supplies}
				onChange={handleChange}
				error={errors.supplies}
				type='number'
				required
			/>
    </FormDialog>
	)
}

const mapStateToProps = states => ({
  openForm: states.actionDialog
});

const mapDispatchToProps = dispatch => ({
  handleCloseForm: () => dispatch(closeActionDialog()),
  getDataFromServer: value => dispatch(getDataFromServer(value)),
  showAlert: data => dispatch(alertActions.show(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);