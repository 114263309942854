import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import AccountBalanceIcon from '@material-ui/icons/AccountBalanceOutlined';
import RoomIcon from '@material-ui/icons/RoomOutlined';
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';

import Button from '@material-ui/core/Button';

import FormDialog from './../../_presentationals.v2/FormDialog';
import TextField from './../../_presentationals.v2/Form/TextField';
import SelectField from './../../_presentationals.v2/Form/SelectField';
import DateField from './../../_presentationals.v2/Form/DateField';

import { alertActions, getDataFromServer } from './../../_actions.v2';

import { recordsServices } from './../../_services.v2';
import { countriesSelectField, statesSelectField, errorFormat, newDate } from './../../_helpers.v2';

import Contract from './contract.helper';

const ERROR_MESSAGE = 'Ha ocurrido un error, por favor inténtalo de nuevo más tarde.';
const chooseCompanyOptions = [
  { value: 1, label: 'EDER FERNANDO JIMENEZ GARCÍA'},
  { value: 2, label: 'JOSE CRUZ ROSALES FELIX'},
  { value: 3, label: 'CYNTHIA NALLELY ROSALES VEGA'},
  { value: 6, label: 'MARIA DEL ROSARIO VEGA ESEBERRE'},
  { value: 7, label: 'FERNANDO JIMENEZ RAMIREZ'},
];
const chooseContractTimeOptions = [
  { value: 'TIEMPO INDETERMINADO', label: 'TIEMPO INDETERMINADO'},
  { value: '1 MES', label: '1 MES'},
  { value: '2 MESES', label: '2 MESES'},
  { value: '3 MESES', label: '3 MESES'},
  { value: '4 MESES', label: '4 MESES'},
  { value: '5 MESES', label: '5 MESES'},
  { value: '6 MESES', label: '6 MESES'},
  { value: '7 MESES', label: '7 MESES'},
  { value: '8 MESES', label: '8 MESES'},
  { value: '9 MESES', label: '9 MESES'},
  { value: '10 MESES', label: '10 MESES'},
  { value: '11 MESES', label: '11 MESES'},
  { value: '12 MESES', label: '12 MESES'}
]

const defaultValues = {
  identifier: '',
  businessName: '',
  legalRepresentative: '',
  addressLine1: '',
  addressLine2: '',
  zipCode: '', 
  city: '',
  stateId: 1,
  countryId: 1,
  daysToText: '',
  chooseCompany: 1,
  quantity: '1',
  chooseContractTime: 'TIEMPO INDETERMINADO',
  contractDate: new Date(),
  initialDate: new Date(),
  endDate: new Date(),
};

const Component = props => {

	const [ values, setValues ] = useState(defaultValues);
  const [ errors, setErrors ] = useState({});

	useEffect(() => {
    if(props.values.id){ 
      let tmp = [];
      Object.keys(props.values).forEach(el => {
        if(props.values[el]){ tmp = { ...tmp, [el]: props.values[el] }; }
      });
      const startTime = props.values.Schedule ? props.values.Schedule.start : '';
      const finishTime = props.values.Schedule ? props.values.Schedule.finish : '';
      const daysObject = props.values && props.values.Days ? props.values.Days : {};
      const tmpDays = Object.keys(daysObject)
        .map(key => props.values.Days[key] && translateDayToSpanish(key))
        .filter(el => el);
      const days = props.values.Days ? tmpDays.join(', ') : '';
      const daysToText = `LOS DÍAS ${days} DE LAS ${startTime} HORAS A LAS ${finishTime} HORAS.`;
		  setValues(values => ({
        ...values,
        contractDate: values.contractDate ? newDate(values.contractDate) : new Date(),
        initialDate: values.initialDate ? newDate(values.initialDate) : new Date(),
        endDate: values.endDate ? newDate(values.endDate) : new Date(),
        ...tmp,
        daysToText
      }));
    }
	}, [props.values]);

  const states = useMemo(() => statesSelectField(values.countryId), [values.countryId]);

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

	const serverRequest = printContract => {
    const url = `customers/${values.id}/contracts`;
    const requestMethod = props.values.id ? 'update' : 'create';
    
    recordsServices[requestMethod](url, values)
      .then(data => {
        if(data.errorCode) { throw new Error(data.errorMessage); }
        printContract && Contract(values);
        props.showAlert({ variant: 'success' });
        props.getDataFromServer(true);
        handleClose();
      })
      .catch(e => { 
        const tmpErrors = errorFormat(e.message);
        setErrors(tmpErrors);
        props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
      });
  }

  const handleContract = () => { serverRequest(true); }
  const handleAction = () => { serverRequest(false); }

  const handleClose = () => { 
    setValues(defaultValues); 
    setErrors({});
    props.handleClose();
  }
  
	return (
		<FormDialog
      title='Contrato'
      isOpen={props.isOpen || false}
      handleClose={handleClose}
      handleAction={handleAction}
    >
    	<TextField
        icon={<AccountBalanceIcon />}
				name='identifier'
				label='RFC'
				value={values.identifier}
				onChange={handleChange}
        error={errors.identifier}
        required
			/>
      <TextField
        name='businessName'
        label='Razón Social'
        value={values.businessName}
        onChange={handleChange}
        error={errors.businessName}
        required
      />
      <TextField
        name='legalRepresentative'
        label='Representante Legal'
        value={values.legalRepresentative}
        onChange={handleChange}
        error={errors.legalRepresentative}
        required
      />
      <TextField
        icon={<RoomIcon />}
        name='addressLine1'
        label='Calle y Número'
        value={values.addressLine1}
        onChange={handleChange}
        error={errors.addressLine1}
        required
      />
      <TextField
        name='addressLine2'
        label='Colonia'
        value={values.addressLine2}
        onChange={handleChange}
        error={errors.addressLine2}
        required
      />
      <TextField
        name='zipCode'
        label='Código Postal'
        value={values.zipCode}
        onChange={handleChange}
        error={errors.zipCode}
        required
      />
      <TextField
        name='city'
        label='Ciudad'
        value={values.city}
        onChange={handleChange}
        error={errors.city}
        required
      />
      <SelectField 
        name='countryId'
        label='País'
        value={values.countryId}
        onChange={handleChange}
        options={countriesSelectField}
        error={errors.countryId}
        required
      />
      <SelectField 
        name='stateId'
        label='Estado'
        value={values.stateId}
        onChange={handleChange}
        options={states}
        error={errors.stateId}
        required
      />
      <TextField
        icon={<ExtensionIcon />}
        name='daysToText'
        label='Configuración de Horario'
        value={values.daysToText}
        onChange={handleChange}
        error={errors.daysToText}
        required
      />
      <SelectField 
        name='chooseCompany'
        label='Emisor del Contrato'
        value={values.chooseCompany}
        onChange={handleChange}
        options={chooseCompanyOptions}
        error={errors.chooseCompany}
        required
      />
      <SelectField 
        name='chooseContractTime'
        label='Duración del Contrato'
        value={values.chooseContractTime}
        onChange={handleChange}
        options={chooseContractTimeOptions}
        error={errors.chooseContractTime}
        required
      />
      <DateField 
        name='contractDate'
        label='Fecha de Contrato'
        value={values.contractDate}
        onChange={handleDateChange}
        error={errors.contractDate}
      />
      <DateField 
        name='initialDate'
        label='Fecha de Inicial'
        value={values.initialDate}
        onChange={handleDateChange}
        error={errors.initialDate}
      />
      <DateField 
        name='endDate'
        label='Fecha de Final'
        value={values.endDate}
        onChange={handleDateChange}
        error={errors.endDate}
      />
      <TextField
        name='quantity'
        label='Número de mucamas'
        value={values.quantity}
        onChange={handleChange}
        error={errors.quantity}
        required
      />
      <Button variant="contained" color="secondary" onClick={handleContract} style={{ marginTop: 10, marginLeft: 50, marginBottom: 20 }}>
        Descargar Contrato
      </Button>
    </FormDialog>
   )
}

function translateDayToSpanish(day) {
  switch(day) {
    case 'monday':
      return 'LUNES';
    case 'tuesday':
      return 'MARTES';
    case 'wednesday':
      return 'MIÉRCOLES';
    case 'thursday':
      return 'JUEVES';
    case 'friday':
      return 'VIERNES';
    case 'saturday':
      return 'SÁBADO';
    case 'sunday':
      return 'DOMINGO';
    default:
      return '-';
  }
}

const mapDispatchToProps = dispatch => ({
  getDataFromServer: value => dispatch(getDataFromServer(value)),
  showAlert: data => dispatch(alertActions.show(data))
});

export default connect(null, mapDispatchToProps)(Component);