const sortArrayByDate = (array, key) => {
    return [...array].sort(function(a, b) {
        const dateA = new Date(a[key]);
        const dateB = new Date(b[key]);
        if (dateA < dateB ) return -1;        
        if (dateA > dateB ) return 1;
        return 0;
      });
}

export default sortArrayByDate;