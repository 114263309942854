import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import FormDialog from './../../_presentationals.v2/FormDialog';
import SelectField from './../../_presentationals.v2/Form/SelectField';

import { getDataFromServer, alertActions } from './../../_actions.v2';

import { recordsServices } from './../../_services.v2';

const ERROR_MESSAGE = 'Ha ocurrido un error, por favor inténtalo de nuevo más tarde.';

const supervisionDayOptions = [
  { value: 0, label: 'NA' }, 
  { value: 1, label: '1 día' }, 
  { value: 2, label: '2 días' }, 
  { value: 3, label: '3 días' }, 
  { value: 4, label: '4 días' }, 
  { value: 5, label: '5 días' }, 
  { value: 6, label: '6 días' }, 
  { value: 7, label: '7 días' }, 
  { value: 8, label: '8 días' }, 
  { value: 9, label: '9 días' }, 
  { value: 10, label: '10 días' }, 
  { value: 15, label: '15 días' }, 
  { value: 30, label: '30 días' }
]

const Component = props => {

	const [ supervisionDay, setSupervisionDay ] = useState(1);

	useEffect(() => {
		setSupervisionDay(props.values.supervisionDay);
	}, [props.values.supervisionDay]);

	const handleChange = e => {
		const value = e.target.value;
		setSupervisionDay(value);
	}

	const handleAction = () => {
		const body = { supervisionDay };
    recordsServices.update(`supervisions/customers/${props.values.id}/cuttingDay`, body)
      .then(data => {
      	if(data.errorCode) { throw new Error(data.errorMessage); }
      	props.showAlert({ variant: 'success' });
        props.getDataFromServer(true);
        props.handleClose();
        setSupervisionDay(1);
      })
      .catch(e => { 
      	console.log(e.message);
      	props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
      });
	}

	return (
		<FormDialog
      title='Comentarios'
      isOpen={props.isOpen || false}
      handleClose={props.handleClose}
      handleAction={handleAction}
    >
    	<SelectField
				name='supervisionDay'
				label='Frecuencia de Días para Visita'
				value={supervisionDay}
				onChange={handleChange}
        options={supervisionDayOptions}
				required
			/>
    </FormDialog>
   )
}

const mapDispatchToProps = dispatch => ({
  getDataFromServer: value => dispatch(getDataFromServer(value)),
  showAlert: data => dispatch(alertActions.show(data))
});

export default connect(null, mapDispatchToProps)(Component);