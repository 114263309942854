import React from "react";
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { editValueAction } from '../../_actions';

const SelectFieldUI = ({editValueAction, fields, icon, fieldValue, fieldValidation, dialog, ...props}) => {

  const handleChange = e => {
    const { name, value } = e.target;
    editValueAction({ [name]: value });
  }
  
  return (
    <Grid container alignItems={ dialog && "flex-end"} >
      { dialog && <Grid item xs={1}><Icon className="material-icons-outlined">{icon}</Icon></Grid> }
      <Grid item xs={ dialog ? 9 : 12 }>
        <Grid container spacing={2}>
          {fields.map(el => {
            const { id, label, options, type, required, fullWidth, ...props } = el; 
            return (
              <Grid key={id} item sm={ dialog ? (12 / parseInt(fields.length)) : 12} xs={12}>
                <FormControl 
                  fullWidth={ fullWidth || true } 
                  required={ typeof required === 'boolean' ? required : true } 
                  error={ fieldValidation[id] && fieldValidation[id].isError }
                >
                  <InputLabel htmlFor={ id }>{ label }</InputLabel>
                  <Select 
                    value={ (['boolean', 'number', 'string'].includes(typeof fieldValue[id]) && options.length > 0) ? fieldValue[id] : '' }
                    onChange={ handleChange }
                    input={ <Input name={ id } id={ id } /> }
                    { ...props }
                  >
                    { options.length > 0 ? 
                      options.map(({key, value}) => (
                        <MenuItem key={ key } value={ key } disabled={ key === "" ? true : false} >
                          { value }
                        </MenuItem>
                      ))
                      : <MenuItem value='' disabled={true} >No encontramos valores para mostrar</MenuItem>
                    }
                  </Select>
                  { (fieldValidation[id] && fieldValidation[id].isError) && 
                    <FormHelperText component="div">
                      <Grid container item spacing={0} alignItems="center" >
                        <ErrorIcon fontSize="small" /> { fieldValidation[id].message }
                      </Grid>
                    </FormHelperText> }
                </FormControl>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};


SelectFieldUI.propTypes = {
  fields: PropTypes.array.isRequired,
  dialog: PropTypes.bool.isRequired,
};

SelectFieldUI.defaultProps = {
  fields: [],
  dialog: true
};

const mapStateToProps = state => ({
  fieldValue: state.field.value,
  fieldValidation: state.field.validation, 
});

const mapDispatchToProps = dispatch => ({ 
  editValueAction: data => dispatch(editValueAction(data))
});

const SelectField = connect(mapStateToProps,mapDispatchToProps)(SelectFieldUI);
export { SelectField };