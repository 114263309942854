import React, { useState, useEffect } from 'react';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Dialog from './dialog';
import { recordsServices } from '../../_services';

const defaultValues = {
	qty: "",
	color: "",
	style: "",
	design: "",
	size: "",
	total: "",
	description: ""
};

export default function QuotationDialog(props) {

	const [ values, setValues ] = useState(defaultValues);
	const [ products, setProducts ] = useState([]);

	useEffect(() => {
		props.customerId && recordsServices.getAll({ table: 'filipinas', _id: props.customerId }).then(data => {
			if(data.Quotation) setProducts(data.Quotation.Products);
			else setProducts([]);
		})
	}, [props.customerId]);

	const handleChange = e => {
  	const { name, value } = e.target;
  	setValues(values => ({ ...values, [name]: value }));
  }

  const handleAccept = async () => {
  	try{
  		await recordsServices.update({ table: 'filipinasStatus', id: props.customerId, quoted: true });
  		await recordsServices.update({ table: "filipinasConfig", id: props.customerId, Quotation: products });
  		await handleClose();
  		await setValues(defaultValues);	
  	}catch (e){
  		console.log(e.message);
  	}
  }

  const handleClose = () => { props.handleClose('quoted', { resetForm: true }); }

  const addProduct = () => {
  	try {
  		if(isNaN(parseFloat(values.qty))) { throw new Error("Cantidad debe ser un número."); }
  		if(isNaN(parseFloat(values.total))) { throw new Error("Precio debe ser un número."); }
  		values.id = parseFloat(Math.random()).toFixed(4);
  		setProducts(products => ([ ...products, { ...values } ]));
  		setValues(defaultValues);
  	}catch(e) {
  		//Debe existir un snackbar
  		console.log(e.message);
  	}
  }

  const deleteProduct = productId => {
  	setProducts(products => products.filter(el => el.id !== productId));
  }

	return (
		<Dialog
			isOpen={ props.isOpen }
			handleClose={ handleClose }
			handleAccept={ handleAccept }
			maxWidth="lg"
			title="Módulo de Cotización"
			contentText="Proporciona la descripción y el presupuesto que deseas 
									 que aparezca en el formato PDF de la cotización, podrás 
									 modificar estos datos en cualquier ocasión."
		>
			<Grid container spacing={2}>
				<Grid item md={1} xs={12}>
					<TextField name="qty" label="Cant." onChange={handleChange} value={values.qty} fullWidth variant="outlined" autoComplete="off" />
				</Grid>
				<Grid item md={1} xs={12}>
					<TextField name="color" label="Color" onChange={handleChange} value={values.color} fullWidth variant="outlined" autoComplete="off" />
				</Grid>
				<Grid item md={1} xs={12}>
					<TextField name="style" label="Estilo" onChange={handleChange} value={values.style} fullWidth variant="outlined" autoComplete="off" />
				</Grid>
				<Grid item md={2} xs={12}>
					<TextField name="design" label="Diseño" onChange={handleChange} value={values.design} fullWidth variant="outlined" autoComplete="off" />
				</Grid>
				<Grid item md={1} xs={12}>
					<TextField name="size" label="Talla" onChange={handleChange} value={values.size} fullWidth variant="outlined" autoComplete="off" />
				</Grid>
				<Grid item md={3} xs={12}>
					<TextField name="description" label="Concepto (Producto)" onChange={handleChange} value={values.description} fullWidth variant="outlined" autoComplete="off" />
				</Grid>
				<Grid item md={2} xs={12}>
					<TextField name="total" label="Precio" onChange={handleChange} value={values.total} fullWidth variant="outlined" autoComplete="off" />
				</Grid>
				<Grid item md={1} xs={12}>
					<Fab color="secondary" onClick={addProduct} aria-label="Agregar">
		        <AddIcon />
		      </Fab>
				</Grid>
			</Grid>
			<br/>
			<Table size="small">
				<TableHead>
					<TableRow>
            <TableCell>Cant.</TableCell>
            <TableCell>Color</TableCell>
            <TableCell>Estilo</TableCell>
            <TableCell>Diseño</TableCell>
            <TableCell>Talla</TableCell>
            <TableCell>Concepto</TableCell>
            <TableCell align="right">Precio</TableCell>
            <TableCell></TableCell>
          </TableRow>
				</TableHead>
				<TableBody>
					{ products.map(el => 
							<TableRow key={el.id}>
								<TableCell>{el.qty}</TableCell>
								<TableCell>{el.color}</TableCell>
								<TableCell>{el.style}</TableCell>
								<TableCell>{el.design}</TableCell>
								<TableCell>{el.size}</TableCell>
								<TableCell>{el.description}</TableCell>
								<TableCell align="right">{el.total}</TableCell>
								<TableCell align="right">
									<DeleteQuotationButton onClick={deleteProduct} productId={el.id} />
								</TableCell>
							</TableRow>
					) }
				</TableBody>
			</Table>
		</Dialog>
	);
}

const DeleteQuotationButton = props => {
	const handleDelete = () => { props.onClick(props.productId); };
	return <IconButton onClick={handleDelete} aria-label="Eliminar">
	   <DeleteIcon fontSize="small" />
	</IconButton>
}