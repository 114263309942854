import React, { useEffect, Fragment, useState } from "react";
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import CreditCardIcon from '@material-ui/icons/CreditCardOutlined';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import PayrollsPDF from '../CheckIn15/payrolls.pdf';
import PayrollsXLS from '../CheckIn15/payrolls.xls';
import ConfirmDialog from './paymentDialog.js';
import { openFormDialog, editValueAction } from '../../_actions';
import { recordsServices } from '../../_services.v2/records.services'; 
import { currencyFormat } from '../../_helpers';
import TablePagination from '../../_presentationals.v2/Table/TablePagination';
import { start, finish, headerDaysOfTheWeek1, checkinFormat } from './helpers';
import { HeaderCell, CustomerCell, Button, Table } from './_components';
import CheckinButton from '../CheckIn15/helpers/CheckInButton';
import SearchDialog from './../Assignment/search.dialog';
import useStyles from './style';
import useQuery from "../../hooks/useQuery.js";

const CheckIn = ({ openFormDialog, editValueAction, ...props }) => {
	const query = useQuery();
	const startParam = query.get('start') ? query.get('start') : start
	const endParam = query.get('end') ? query.get('end') : finish
		
	const classes = useStyles();

	const [ count, setCount ] = useState(0);
	const [ checkIns, setCheckIns ] = useState([]);
	const [ confirmId, setConfirmId ] = useState(false);
	const [ limit, setLimit ] = useState(25);
	const [ offset, setOffset ] = useState(0);
	const [ search, setSearch ] = useState('');
	const [ isOpenSearch, setIsOpenSearch ] = useState(false);
	
	useEffect(() => {
		recordsServices.get(`checkIns/${startParam}/${endParam}?limit=${limit}&offset=${offset}&search=${search}&paymentPlan=1`).then(data => {
			const tmpData = checkinFormat(data.rows);
			setCount(data.count);
			setCheckIns(tmpData);
		});
	}, [limit, offset, search, startParam, endParam]);

	const handleCancelPayment = e => setConfirmId(false);
	const handleConfirmPayment = e => setConfirmId(true);
	const handlePayrollPdfReport = e => PayrollsPDF(startParam, endParam, 1);
	const handlePayrollXlsReport = e => PayrollsXLS(startParam, endParam, 1);

	const handleRows = (offset, limit) => {
		setLimit(limit);
		setOffset(offset);
	}

	const handleClick = data => {
		const tmpCheckIns = checkIns.map((el, i) => {
			if(i !== data.i) return el;
			const statusLabel = parseInt(data.status) === 1 ? 'Asistencia' : parseInt(data.status) === 3 ? 'Falta Justificada' : 'Falta Injustificada';
			const array = el[data.date];
			array[data.position] = {
				...array[data.position],  
				status: { id: parseInt(data.status), label: statusLabel },
				supplies: parseFloat(data.supplies),
				salary: parseFloat(data.salary),
				payment: parseFloat(data.payment),
				ivaSupplies: parseFloat(data.ivaSupplies),
				ivaPayment: parseFloat(data.ivaPayment),
				id: parseInt(data.checkInId)
			}
			return { ...el, [data.day]: array }
		});		
		setCheckIns(tmpCheckIns);
	}
	const handleSearch = value => {
    setSearch(value);
  };
  const handleOpenSearch = () => {
    setIsOpenSearch(isOpenSearch => !isOpenSearch);
  }
  return (
	<Fragment>
		<TableContainer className={classes.tableContainer}>
		<Table>
    	<TableHead>
    		<TableRow>
    			<CustomerCell>
    				Mucama
  					<Button title="Emitir Pago" onClick={handleConfirmPayment} icon={<CreditCardIcon fontSize='small' />} />
  					<Button title="Reporte de Nómina(PDF)" onClick={handlePayrollPdfReport} icon={<PictureAsPdfIcon fontSize='small' />} />
  					<Button title="Reporte de Nómina(XLS)" onClick={handlePayrollXlsReport} icon={<CloudDownloadIcon fontSize='small' />} />
  					<Button title="Buscador" onClick={handleOpenSearch} icon={<SearchIcon fontSize='small' />} />
    			</CustomerCell>
    			{ headerDaysOfTheWeek1(startParam, endParam).map(el => 
    				<CustomerCell key={el.label} align="center">{el.label}</CustomerCell> 
    			)}
    			<HeaderCell align="right">General</HeaderCell>
    			<HeaderCell align="right">IMSS</HeaderCell>
    			<HeaderCell align="right">Utilidad</HeaderCell>
    			<HeaderCell align="right">Utilidad ER</HeaderCell>
    			<HeaderCell align="right">% Utilidad</HeaderCell>
    		</TableRow>
    	</TableHead>
    	<TableBody>
    		{checkIns.map((el, i) => {
    			let salary = 0;
					let payment = 0;
					let supplies = 0;
					let iva = 0;
					return (
						<TableRow key={el.id}>
							<TableCell>{`${el.id} ${el.name}`}</TableCell>
							{ 
								headerDaysOfTheWeek1(startParam, endParam).map((ii, iii) => {
									return (
										<TableCell key={iii} align='center'>
											{ el[ii.value]
												? el[ii.value].map((elCh, iiii) => {
														salary += elCh.status.id !== 6 ? parseFloat(elCh.salary) : 0;
														payment += elCh.status.id !== 6 ? parseFloat(elCh.payment) : 0;
														supplies += elCh.status.id !== 6 ? parseFloat(elCh.supplies) : 0;
														iva += elCh.status.id !== 6 ? parseFloat(elCh.ivaPayment) + parseFloat(elCh.ivaSupplies) : 0;
														return (
															<CheckinButton
																key={iiii}
																id={elCh.id && elCh.id}
																handleClick={handleClick}
																status={elCh.status}
																maidId={el.id}
																day={elCh.date}
																Customer={elCh.Customer}
																date={elCh.day}
																i={i}
																position={iiii}
																salary={elCh.status.id !== 6 ? parseFloat(elCh.salary) : 0}
																payment={elCh.status.id !== 6 ? parseFloat(elCh.payment) : 0}
																supplies={elCh.status.id !== 6 ? parseFloat(elCh.supplies) : 0}
																iva={elCh.status.id !== 6 ? parseFloat(elCh.ivaPayment) + parseFloat(elCh.ivaSupplies) : 0}
															/> 
														)
													})
												: 'X'
											}
										</TableCell>
									)
								})
							}
							<TableCell align="right">
								<div style={{ display: 'block' }}>Sueldo ({currencyFormat(salary)})</div>
								<div style={{ display: 'block' }}>Cobro ({currencyFormat(payment)})</div>
								<div style={{ display: 'block' }}>Insum ({currencyFormat(supplies)})</div>
								<div style={{ display: 'block' }}>IVA ({currencyFormat(iva)})</div>
							</TableCell>
							<TableCell align="right">{currencyFormat(el.healthInsurance)}MXN</TableCell>
							<TableCell align="right">{currencyFormat(payment - salary - el.healthInsurance)}MXN</TableCell>
							<TableCell align="right">{currencyFormat(payment + supplies + iva - salary - el.healthInsurance)}MXN</TableCell>
							<TableCell align="right">{currencyFormat(((payment + supplies + iva - salary - el.healthInsurance) / (payment + supplies + iva)) * 100)}%</TableCell>
						</TableRow>
					)
		  	})}
    	</TableBody>
    </Table>
		</TableContainer>
    <TablePagination count={count} handleRows={handleRows} />
   	<ConfirmDialog isOpen={confirmId} handleCancel={handleCancelPayment} />
   	<SearchDialog isOpen={isOpenSearch} handleClose={handleOpenSearch} handleClick={handleSearch} />
    </Fragment>
	);

}


const mapDispatchToProps = dispatch => ({
  openFormDialog: data => dispatch(openFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data))
});

export default connect(null, mapDispatchToProps)(CheckIn);