import React, { useMemo } from "react";

import { isMobile } from "react-device-detect";

import withStyles from '@material-ui/core/styles/withStyles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { styles } from './style';

const HeaderComponent = ({classes, ...props}) => {

	const headerRows = useMemo(() => {
  	const { headerTitle, tooltip } = classes;
  	return props.records.length > 0 && Object.keys(props.records[0]).map(key => 
  		props.records[0][key].show !== false &&
  		<TableCell 
				key={key}
				padding='default'
				className={headerTitle}
				align={ props.records[0][key].align || 'left' }
				onClick={ props.records[0][key]._sort && props.handleSort}
				data-key={key}
			>
				{ 
					props.records[0][key]._sort ? 
						<Tooltip
              title={`Ordenar por ${(props.direction[key] + '').toUpperCase()}`}
              enterDelay={300}
              classes={{ tooltip }}
            >
            	<TableSortLabel
                active={true}
                direction={props.direction[key]}
                onClick={() => {}}
              >
    						{ key }
    					</TableSortLabel>
    				</Tooltip>
					: key 
				}
			</TableCell>
	  )
  }, [props.records, props.direction, props.handleSort, classes]);

	return (
		<TableHead>
  		<TableRow>
  			{ headerRows }
  			<TableCell align="right" className={ classes.filterCell }>
					{
						(!isMobile && props.records.length > 0) &&
						<Grid container alignItems="center" spacing={0}>
		          <Grid item zeroMinWidth={true} xs="auto">
		            <TextField
		              id="filtro"
		              variant="outlined"
		              label="Búsqueda"
		              value={props.textFilter}
		              onChange={props.handleChangeFilter}
		              className={ classes.mobileInput }
		              InputLabelProps={{ shrink: true }}
		              InputProps={{
		                classes: {
		                  input: classes.mobileInputProps,
		                  notchedOutline: classes.mobileInput
		                },
		              }}
		            />
		         	</Grid>
		        </Grid>
					}
		    </TableCell>
  		</TableRow>
  	</TableHead>
	)
}

export default withStyles(styles)(HeaderComponent);