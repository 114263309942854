let api = ['name', 'branchName', 'type', 'phone', 'email', 'addressLine1', 'addressLine2', 'zipCode', 'city', 'quantity', 'Days', 'supplies', 'typePrice', 'price', 'suppliesCommentary', 'trackingDate', 'trackingCommentary'];

const customers = (method, values) => {
  const tmp = {};
  switch (method) {
    case 'POST':
    case 'PUT':
      if(values.typeRate) {
        return { typeRate: values.typeRate, quantityMaid: values.quantityMaid, rate: values.rate, suppliesRate: values.suppliesRate, taxesRate: values.taxesRate, DaysOfRate: values.DaysOfRate };
      }
      if(values.updateBalance){
        if(values.payment)
          return { payment: values.payment };
        if(values.increase) 
          return { increase: values.increase, password: values.password };
        if(values.decrease)
          return { decrease: values.decrease, password: values.password };

        return {};
      }
      if(values.isCommenting){
        const { description } = values;
        const isAllowed = (description === "") ? true : false;
        return { isAllowed, description };
      }
      if(values.isHiring) {
        return { identifier: values.identifier, businessName: values.businessName, legalRepresentative: values.legalRepresentative, addressLine1: values.addressLine1, addressLine2: values.addressLine2, zipCode: values.zipCode, city: values.city, countryId: values.countryId, stateId: values.stateId };
        // api = ['identifier', 'businessName', 'legalRepresentative', 'addressLine1', 'addressLine2', 'zipCode', 'city', 'countryId', 'stateId'];
      }

      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      api.forEach(key =>{
        if(!Object.keys(tmp).includes(key)) tmp[key] = "";
      });

      if(!values.isHiring) {
        tmp['Schedule'] = {
          start: values.startServiceTime,
          finish: values.finishServiceTime
        }
      }

      if (values.suppliesCommentary) tmp['suppliesCommentary'] = values.suppliesCommentary;
      
      console.log(tmp);
      return tmp;
    default:
      return tmp;
  }
}

export default customers;