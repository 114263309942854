import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import CreditCardIcon from '@material-ui/icons/CreditCardOutlined';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import Module from './../../_presentationals.v2/Module';

import { recordsServices } from './../../_services.v2';
import { getDataFromServer } from './../../_actions.v2';
import { newDate } from '../../_helpers.v2';

const Rows = [
  { label: 'FechaCreación', key: 'createdAt', sort: 'date', format: 'dateTime' },
  { label: 'FechaAjuste', key: 'date', sort: 'date', format: 'date' },
	{ label: 'Cliente', key: 'customer', sort: 'text', filter: true }, 
  { label: 'Total', key: 'total', sort: 'number', format: 'balance' },
  { label: 'Justificación', key: 'justification', sort: 'text' }
]; 

const Reckoning = ({ getData, ...props }) => {

	const [ values, setValues ] = useState([]);
	const [ customers, setCustomers ] = useState([]);

	useEffect(() => {
		recordsServices.get('customers')
			.then(data => {
				const tmp = data.rows.map(el => ({ value: el.id, label: el.name }));
				setCustomers(tmp);
			})
			.catch(e => console.log(e.message));
	}, []);

	useEffect(() => {
		getData && recordsServices.get('reckonings')
			.then(data => {
				setValues(data.map(el => ({...el, date: newDate(el.date)})));
        props.getDataFromServer(false);
			})
			.catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Module 
			route='reckonings'
      title='Ajuste de Saldos'
      singular='ajuste de saldo'
      Rows={Rows}
      noEdit
      records={values}
      fields={[
      	{
          icon: <CalendarTodayIcon />,
          name: 'date',
          label: 'Fecha del Ajuste',
          field: 'date'
        },{
      		name: 'customerId',
      		icon: <PersonOutlineIcon />,
      		label: 'Cliente',
      		options: customers,
      		field: 'autocomplete'
      	},{
          icon: <CreditCardIcon />,
      		name: 'total',
      		label: 'Total'
      	},{
          name: 'justification',
          label: 'Justificación'
        }
      ]}
    />
	);
};

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: value => dispatch(getDataFromServer(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Reckoning);