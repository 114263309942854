import React, { Component } from 'react';
import { connect } from 'react-redux';

import DataBranch from './preferences';
import { openFormDialog } from '../../_actions';

import EditName from './editName';
import EditIva from './editIVA';
import EditAddress from './editAddress';
import EditPhone2 from './editPhone2';
import EditPhone1 from './editPhone1';

class Preferences extends Component {

	handleOpenName = () => {
		const { openFormDialog } = this.props;
		openFormDialog('Name');
	}

	handleOpenIVA = () => {
		const { openFormDialog } = this.props;
		openFormDialog('ConfigPayment');
	}

	handleOpenAddress = () => {
		const { openFormDialog } = this.props;
		openFormDialog('Address');
	}

	handleOpenPhone1 = () => {
		const { openFormDialog } = this.props;
		openFormDialog('phone1');
	}

	handleOpenPhone2 = () => {
		const { openFormDialog } = this.props;
		openFormDialog('phone2');
	}


	render() {
		return (
			<div>
				<DataBranch
				 handleOpenName={this.handleOpenName}
				 handleOpenIVA={this.handleOpenIVA}
				 handleOpenAddress={this.handleOpenAddress}
				 handleOpenPhone2={this.handleOpenPhone2}
				 handleOpenPhone1={this.handleOpenPhone1}
				/>
				<EditName />
				<EditIva />
				<EditAddress />
				<EditPhone2 />
				<EditPhone1 />
			</div>
		);
	}
}

const mapStateToProps = state => ({
	alertType : state.alert.type
});

const mapDispatchToProps = dispatch => ({
	openFormDialog: (data) => dispatch(openFormDialog(data)),
});

export default (connect(mapStateToProps, mapDispatchToProps)(Preferences));
