import React, { Fragment, useMemo, useState } from "react";
import { connect } from 'react-redux';

import AttachFileIcon from '@material-ui/icons/AttachFileOutlined';
import MessageIcon from '@material-ui/icons/MessageOutlined';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooksOutlined';
import AttachMoney from '@material-ui/icons/AttachMoney';

import { openFormDialog, editValueAction, recordsActions, profileActions } from './../../_actions';
import Module from '../../_presentationals/Module';
import Form from './_form';
import { Quotation } from './quotation';
import { config, authHeader } from '../../_helpers';
import { handleFetchResponse } from './../../_services';

import DescriptionDialog from './descriptionDialog';
import ContractDialog from './contractDialog';
import FileDialog from './fileDialog';
import RateDialog from './rateDialog';

const Customers = ({ openFormDialog, editValueAction, ...props }) => {
  const [contractImage, setContractImage] = useState(null);

  const customers = useMemo(() => 
    props.customers.count > 0 ? props.customers.rows.map(el => ({
      ID: { 
        value: el.id,
        show: true,
        data: {
          ...el,
          type: el.type.id,
          startServiceTime: el.Schedule.start,
          finishServiceTime: el.Schedule.finish,
          typePrice: el.typePrice.id
        }
      }, 
      Nombre: { value: el.name, filter: true, _sort: 'text' },
      Teléfono: { value: el.phone },
      Tipo: { value: el.type.label, _sort: 'text' },
      Colonia: { value: el.addressLine2, _sort: 'text' },
      Prospección:{ value: el.Status.prospecting },
      Cotizado:{ value: el.Status.quoted },
      "En Espera":{ value: el.Status.waiting },
      Colocada:{ value: el.Status.working },
      Comentarios: { value: `${el.description} ${el.descriptionDate ? el.descriptionDate : ''}` },
      background: {
        value: el.background,
        show: false
      }
    })) : []
  , [props.customers]);

  const handleMessage = e => {
    const data = JSON.parse(e.currentTarget.dataset.row);
    const { id, description } = data;
    editValueAction({ isCommenting: true, id, description });
    openFormDialog('comentario');
  }

  const handleContract = e => {
    const data = JSON.parse(e.currentTarget.dataset.row);
    const { id, price, quantity, supplies, CustomerContract } = data;
    editValueAction({ 
      isHiring: true,
      ...CustomerContract,
      id, price, quantity, supplies
    });

    openFormDialog('contrato');
  }

  const handleRate = e => {
    const data = JSON.parse(e.currentTarget.dataset.row);
    const { id, DaysOfRate, rate, suppliesRate, taxesRate, typeRate, quantityMaid } = data;
    editValueAction({ id, DaysOfRate, quantityMaid, rate, suppliesRate, taxesRate, typeRate, customTypeRate: typeRate });
    openFormDialog('rate');
  }

  const handleQuotation = e => {
    const quotationData = {
      customerId: props.id,
      name: props.name,
      branchName: props.branchName,
      phone: props.phone,
      type: props.type,
      addressLine1: props.addressLine1,
      addressLine2: props.addressLine2,
      city: props.city,
      zipCode: props.zipCode,
      quantity: props.quantity,
      typePrice: props.typePrice,
      price: props.price,
      supplies: props.supplies,
      suppliesCommentary: props.suppliesCommentary,
      startServiceTime: props.startServiceTime,
      finishServiceTime: props.finishServiceTime,
      Days: props.Days
    };
    editValueAction({ changeQuotationStatus: true, id: props.id });
    props.update('customers');
    Quotation(quotationData);
  }

  const handleContractFile = async e => {
    const data = JSON.parse(e.currentTarget.dataset.row);
    const { id } = data;
    editValueAction({ id });
    const image = await getDataFromServer(id);
    console.log(image.image);
    if(image) setContractImage(image.image);
    openFormDialog('contratoAdjunto');
  }

  const handleChange = files => {
    props.createFile({ id: props.id, type: 'customerContract', profile: files[0] });
  }

  return (
    <Fragment>
      <Module
        table="customers"
        name="Clientes"
        singularName="cliente"
        filter={false}
        title="Crear Cliente"
        actions={[{
          icon: <AttachFileIcon fontSize="small" />,
          label: "Adjuntar Contrato",
          handle: handleContractFile
        },
        {
          icon: <MessageIcon fontSize="small" />,
          label: "Comentario",
          handle: handleMessage
        },{
          icon: <LibraryBooksIcon fontSize="small" />,
          label: "Contrato",
          handle: handleContract
        },{
          icon: <AttachMoney fontSize="small" />,
          label: "Tarifas",
          handle: handleRate
        }]}
        records={customers}
      >
        <Form 
          customerId={ props.customerId } 
          Days={ props.Days } 
          type={ props.type } 
          supplies={ props.supplies } 
          handleQuotation={ handleQuotation } 
          editValueAction={editValueAction} />
      </Module>
      <DescriptionDialog />
      <ContractDialog />
      <FileDialog handleChange={handleChange} image={contractImage} />
      <RateDialog DaysOfRate={ props.DaysOfRate } />
    </Fragment>
  );
}

const getDataFromServer = id => {
  const method = 'GET';
  const url = `${config.apiUrl}/customers/${id}/contracts/file`;
  const myRequest = new Request(
    url, { method, headers: { ...authHeader() } }
  );
  return fetch(myRequest)
    .then(handleFetchResponse({ url, method, headers: {} }))
    .then(data => data);
}


const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  customerId: state.field.value.id || null,
  name: state.field.value.name || null,
  branchName: state.field.value.branchName || null,
  phone: state.field.value.phone || null,
  type: state.field.value.type || null,
  addressLine1: state.field.value.addressLine1 || '',
  addressLine2: state.field.value.addressLine2 || '',
  city: state.field.value.city || '',
  zipCode: state.field.value.zipCode || '',
  quantity: state.field.value.quantity || null,
  typePrice: state.field.value.typePrice || null,
  price: state.field.value.price || null,
  supplies: state.field.value.supplies || false,
  suppliesCommentary: state.field.value.suppliesCommentary || '',
  startServiceTime: state.field.value.startServiceTime || '',
  finishServiceTime: state.field.value.finishServiceTime || '',
  Days: state.field.value.Days || {},
  DaysOfRate: state.field.value.Days || {},

  customers: state.records.customers || defaultTableValue,
  isOpen: state.formDialog,
});

const mapDispatchToProps = dispatch => ({ 
  openFormDialog: data => dispatch(openFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data)),
  update: value => dispatch(recordsActions.update(value)),
  createFile: data => dispatch(profileActions.createFile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Customers);