import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

import format from 'date-fns/format';

import AssignmentIndIcon from '@material-ui/icons/AssignmentIndOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import RoomIcon from '@material-ui/icons/RoomOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import FormDialog from './../../_presentationals.v2/FormDialog';
import TextField from './../../_presentationals.v2/Form/TextField';
import SelectField from './../../_presentationals.v2/Form/SelectField';
import CheckBoxField from './../../_presentationals.v2/Form/CheckBoxField';
import DateField from './../../_presentationals.v2/Form/DateField';

import { closeActionDialog, getDataFromServer, alertActions } from './../../_actions.v2';
import { recordsServices } from './../../_services.v2';

import routes from './../../_presentationals.v2/Module/routes';
import { Quotation } from './../Customer/quotation';

const ERROR_MESSAGE = 'Ha ocurrido un error, por favor inténtalo de nuevo más tarde.';
const typeOptions = [ { value: 1, label: 'Casa'}, { value: 2, label: 'Empresa'} ];
const serviceTimeOptions = ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24'].map(el => ({  value: `${el}:00:00`, label: `${el}:00` }));
const suppliesOptions = [ { value: true, label: 'Si' }, { value: false, label: 'No' } ];
const typePriceOptions = [ { value: 1, label: 'Día'}, { value: 2, label: 'Semana'}, { value: 3, label: 'Mes'} ];
const vatRateOptions = [ { value: 0, label: 'Sin IVA'}, { value: 10, label: '10%'}, { value: 16, label: '16%'} ];
const supervisionDayOptions = [
  { value: 0, label: 'NA' }, 
  { value: 1, label: '1 día' }, 
  { value: 2, label: '2 días' }, 
  { value: 3, label: '3 días' }, 
  { value: 4, label: '4 días' }, 
  { value: 5, label: '5 días' }, 
  { value: 6, label: '6 días' }, 
  { value: 7, label: '7 días' }, 
  { value: 8, label: '8 días' }, 
  { value: 9, label: '9 días' }, 
  { value: 10, label: '10 días' }, 
  { value: 15, label: '15 días' }, 
  { value: 30, label: '30 días' }
]

const defaultValues = {
	type: 1, 
	branchName: '', 
	name: '', 
	phone: '', 
	email: '', 
	addressLine1: '', 
	addressLine2: '',
	zipCode: '',
	city: '',
	quantity: 1,
	Days: {
		monday: false,
		tuesday: false,
		wednesday: false,
		thursday: false, 
		friday: false,
		saturday: false,
		sunday: false
	},
	startServiceTime: '08:00:00',
	finishServiceTime: '08:00:00',
	commentary: '',
	supplies: false,
	suppliesCommentary: '',
	typePrice: 1,
	price: '',
	vatRate: 16,
	supervisionDay: 15
};

const Component = props => { 
	
	const [ values, setValues ] = useState(defaultValues);
	const [ trackingDate, setTrackingDate ] = useState(null);
	const [ trackingCommentary, setTrackingCommentary ] = useState('');
	const [ errors, setErrors ] = useState({});

  useEffect(() => {
    Object.keys(props.openForm[1]).length > 0 && 
    	setValues(values => ({ 
    		...values, ...props.openForm[1], 
    		type: props.openForm[1].type.id,
    		startServiceTime: props.openForm[1].Schedule.start,
    		finishServiceTime: props.openForm[1].Schedule.finish
    	}));
  }, [props.openForm]);
  
	const handleChange = e => {
		const name = e.target.name;
		const value = e.target.value;
		setValues(values => ({ ...values, [name]: value }));
	}
	const handleTrackingCommentary = e => {
		const value = e.target.value;
		setTrackingCommentary(value);
	}

	const handleDays = name => {
  	setValues(values => ({ 
  		...values, Days: { ...values.Days, [name]: !values.Days[name] } 
  	}));
  };

  const handleCloseForm = () => {
    props.handleCloseForm();
    Object.keys(errors).length > 0 && setErrors({});
    setValues(defaultValues);
  }

  const handleAction = () => { serverRequest(false); }
  const handleQuotation = () => { serverRequest(true); }

 	const serverRequest = printQuotation => {
		try {
			const requestMethod = !values.id ? 'create' : 'update';
			if (requestMethod === 'create' || (values.id && props.openForm[1] && props.openForm[1].Status && !props.openForm[1].Status.quoted)) {
				let newError;
				if (!trackingDate) {
				 newError = new Error('Selecciona una fecha de seguimiento.');
				 newError.name = 'trackingDate';
				 throw newError;
				}
				if (trackingCommentary === '') {
				 newError = new Error('Escribre un comentario para el seguimiento.');
				 newError.name = 'trackingCommentary';
				 throw newError;
				}
			}
			const url = !printQuotation ? routes('customers', requestMethod.toUpperCase(), { id: values.id }) : `customers/${values.id}/quotations`;
			// const { startServiceTime, finishServiceTime, id, uuid } = { 
			// 	...values, 
			// 	Schedule: { start: values.startServiceTime, finish: values.finishServiceTime } 
			// };
			const newBody = requestMethod === 'create' ? { 
				...values, 
				Schedule: { start: values.startServiceTime, finish: values.finishServiceTime },
				trackingDate, trackingCommentary
			} : { 
				...values, 
				Schedule: { start: values.startServiceTime, finish: values.finishServiceTime }
			}
			recordsServices[requestMethod](url, newBody)
				.then(data => {
					if(data.errorCode) { throw new Error(data.errorMessage); }
					printQuotation && Quotation({ ...values, customerId: values.id });
					setTrackingCommentary('');
					setTrackingDate(null);
					props.showAlert({ variant: 'success' });
					props.getDataFromServer(true);
					props.handleCloseForm();
				})
				.catch(e => { 
					let tmpErrors = {};
					JSON.parse(e.message).forEach(el => 
						tmpErrors = { ...tmpErrors, [el.name]: el.message }
					);
					setErrors(tmpErrors);
					props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
				});
		} catch (e) {
			console.log('Error: ', e.message);
			if (['trackingDate', 'trackingCommentary'].includes(e.name)) {
				setErrors({ [e.name]: e.message });
			}
		}
 		
 	}

	 const handleTrackingChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd')
		setTrackingDate(tmpValue);
	}
 
	return (
		<FormDialog
      title={`${!values.id ? 'Crear' : 'Actualizar'} Cliente`}
      actionLabel={values.id && 'Actualizar'}
      isOpen={props.openForm[0]}
      handleClose={handleCloseForm}
      handleAction={handleAction}
    >
			{
				!values.id && <Paper variant="outlined" style={{ marginBottom: 10, padding: 10, paddingTop: 5 }}>
				<DateField
					name='trackingDate'
					label='Fecha de seguimiento'
					value={trackingDate}
					onChange={handleTrackingChange}
					error={errors.trackingDate}
					required noFormat
				/>
				<TextField
					name='trackingCommentary'
					label='Comentarios del seguimiento'
					value={trackingCommentary}
					onChange={handleTrackingCommentary}
					multiline={true}
					error={errors.trackingCommentary}
					required noFormat
				/>
			</Paper>	
			}
    	<SelectField 
    		icon={<AssignmentIndIcon />}
				name='type'
				label='Tipo de Cliente'
				value={values.type}
				onChange={handleChange}
				options={typeOptions}
				error={errors.type}
				required
			/>
			{ values.type === 2 &&
				<TextField
					name='branchName'
					label='Nombre de la Empresa'
					value={values.branchName}
					onChange={handleChange}
					error={errors.branchName}
				/>
			}
			<TextField
				icon={<AccountCircleIcon />}
				name='name'
				label='Contacto'
				value={values.name}
				onChange={handleChange}
				error={errors.name}
				required
			/>
			<TextField
				icon={<PhoneIcon />}
				name='phone'
				label='Teléfono'
				value={values.phone}
				onChange={handleChange}
				error={errors.phone}
			/>
			<TextField
				icon={<EmailIcon />}
				name='email'
				label='Correo Electrónico'
				value={values.email}
				onChange={handleChange}
				error={errors.email}
			/>
			<TextField
				icon={<RoomIcon />}
				name='addressLine1'
				label='Calle y Número'
				value={values.addressLine1}
				onChange={handleChange}
				error={errors.addressLine1}
			/>
			<TextField
				name='addressLine2'
				label='Colonia'
				value={values.addressLine2}
				onChange={handleChange}
				error={errors.addressLine2}
			/>
			<TextField
				name='zipCode'
				label='Código Postal'
				value={values.zipCode}
				onChange={handleChange}
				error={errors.zipCode}
			/>
			<TextField
				name='city'
				label='Ciudad'
				value={values.city}
				onChange={handleChange}
				error={errors.city}
			/>
			<TextField
				icon={<SettingsIcon />}
				name='quantity'
				label='Número de Mucamas'
				value={values.quantity}
				onChange={handleChange}
				error={errors.quantity}
			/>
			<CheckBoxField
				label='Días'
				action={handleDays}
				controls={[{
					name: 'monday',
					label: 'Lunes',
					value: values.Days.monday
				},{
					name: 'tuesday',
					label: 'Martes',
					value: values.Days.tuesday
				},{
					name: 'wednesday',
					label: 'Miércoles',
					value: values.Days.wednesday
				},{
					name: 'thursday',
					label: 'Jueves',
					value: values.Days.thursday
				},{
					name: 'friday',
					label: 'Viernes',
					value: values.Days.friday
				},{
					name: 'saturday',
					label: 'Sábado',
					value: values.Days.saturday
				},{
					name: 'sunday',
					label: 'Domingo',
					value: values.Days.sunday
				}]}
			/>
			<SelectField 
    		name='startServiceTime'
				label='Inicio de Servicio'
				value={values.startServiceTime}
				onChange={handleChange}
				options={serviceTimeOptions}
				error={errors.startServiceTime}
			/>
			<SelectField 
    		name='finishServiceTime'
				label='Fin de Servicio'
				value={values.finishServiceTime}
				onChange={handleChange}
				options={serviceTimeOptions}
				error={errors.finishServiceTime}
			/>
			<TextField
				name='commentary'
				label='Comentarios'
				value={values.commentary}
				onChange={handleChange}
				multiline={true}
				error={errors.commentary}
			/>
			<SelectField 
    		name='supplies'
				label='Insumos'
				value={values.supplies}
				onChange={handleChange}
				options={suppliesOptions}
				error={errors.supplies}
			/>
			{	values.supplies &&
				<TextField
					name='suppliesCommentary'
					label='Comentario para Insumos'
					value={values.suppliesCommentary}
					onChange={handleChange}
					multiline={true}
					error={errors.suppliesCommentary}
				/>
			}
			<SelectField 
    		name='typePrice'
				label='Modalidad de Precio'
				value={values.typePrice}
				onChange={handleChange}
				options={typePriceOptions}
				error={errors.typePrice}
			/>
			<TextField
				name='price'
				label='Precio'
				value={values.price}
				onChange={handleChange}
				error={errors.price}
			/>
			<SelectField 
    		name='vatRate'
				label='Tasa de IVA'
				value={values.vatRate}
				onChange={handleChange}
				options={vatRateOptions}
				error={errors.vatRate}
			/>
			<SelectField
				name='supervisionDay'
				label='Frecuencia de Días para Visita'
				value={values.supervisionDay}
				onChange={handleChange}
        options={supervisionDayOptions}
        error={errors.supervisionDay}
			/>
			{ values.id &&
					<Button 
						variant="contained" 
						color="secondary" 
						onClick={handleQuotation} 
						style={{ marginTop: 10, marginLeft: 50, marginBottom: 20 }}
					>
						Descargar Cotización
					</Button>
			}
			{
				values.id && props.openForm[1] && props.openForm[1].Status && !props.openForm[1].Status.quoted &&
				<Paper variant="outlined" style={{ marginBottom: 10, padding: 10, paddingTop: 5 }}>
					<DateField
						name='trackingDate'
						label='Fecha de seguimiento'
						value={trackingDate}
						onChange={handleTrackingChange}
						error={errors.trackingDate}
						required noFormat
					/>
					<TextField
						name='trackingCommentary'
						label='Comentarios del seguimiento'
						value={trackingCommentary}
						onChange={handleTrackingCommentary}
						multiline={true}
						error={errors.trackingCommentary}
						required noFormat
					/>
				</Paper>
			}
    </FormDialog>
	)
}

const mapStateToProps = states => ({
  openForm: states.actionDialog
});

const mapDispatchToProps = dispatch => ({
  handleCloseForm: () => dispatch(closeActionDialog()),
  getDataFromServer: value => dispatch(getDataFromServer(value)),
  showAlert: data => dispatch(alertActions.show(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);