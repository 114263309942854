const filipinas = ({method, ...props}) => {
  switch (method) {
    case 'PUT':
    case 'DELETE':
    	return `filipinas/customers/${props._id}`;
    case 'GET':
    	if(props._id) return `filipinas/customers/${props._id}`;
    	return 'filipinas/customers';
    default:
      return 'filipinas/customers';
  }
}

export default filipinas;
