import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import format from 'date-fns/format';

import FormDialog from './../../_presentationals.v2/FormDialog';
import DateField from './../../_presentationals.v2/Form/DateField';

import { getDataFromServer, alertActions } from './../../_actions.v2';

import { recordsServices } from './../../_services.v2';

const ERROR_MESSAGE = 'Ha ocurrido un error, por favor inténtalo de nuevo más tarde.';

const Component = props => {

	const [ trackingDate, setTrackingDate ] = useState(null);

	useEffect(() => {
		setTrackingDate(props.values.trackingDate);
	}, [props.values.trackingDate]);

	const handleChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd')
		setTrackingDate(tmpValue);
	}

	const handleAction = () => {
		const body = { trackingDate };
    recordsServices.update(`v2/customers/${props.values.id}/trackingDate`, body)
      .then(data => {
      	if(data.errorCode) { throw new Error(data.errorMessage); }
      	props.showAlert({ variant: 'success' });
        props.getDataFromServer(true);
        props.handleClose();
      })
      .catch(e => { 
      	console.log(e.message);
      	props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
      });
	}

	return (
		<FormDialog
      title='Actualización de fecha de seguimiento'
      isOpen={props.isOpen || false}
      handleClose={props.handleClose}
      handleAction={handleAction}
    >
    	<DateField
				name='trackingDate'
				label='Nueva fecha de seguimiento'
				value={trackingDate}
				onChange={handleChange}
				required noFormat
			/>
    </FormDialog>
   )
}

const mapDispatchToProps = dispatch => ({
  getDataFromServer: value => dispatch(getDataFromServer(value)),
  showAlert: data => dispatch(alertActions.show(data))
});

export default connect(null, mapDispatchToProps)(Component);