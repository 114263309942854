import React, { PureComponent } from "react";
import { connect } from 'react-redux';

import StoreIcon from '@material-ui/icons/StoreOutlined';

import { FormDialog } from './../../_presentationals/FormDialog';
import { TextField } from './../../_presentationals/Form';

class EditName extends PureComponent {

  render() {
  	return (
      <FormDialog
        title="Editar nombre de sucursal"
        name="editName"
        singular="Name"
      >
        <TextField
          icon={<StoreIcon />}
          fields={[
            {
              id: "name",
              label: "Nombre",
            }
          ]}
        />
      </FormDialog>
  	);
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(EditName);
