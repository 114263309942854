import React, { useState } from 'react';
import { getMonth } from 'date-fns';

import SelectField from './../../_presentationals.v2/Form/SelectField';
import FormDialog from './../../_presentationals.v2/FormDialog';

const monthsOption = [
  { value: 1, label: 'Enero'},
  { value: 2, label: 'Febrero'},
  { value: 3, label: 'Marzo'},
  { value: 4, label: 'Abril'},
  { value: 5, label: 'Mayo'},
  { value: 6, label: 'Junio'},
  { value: 7, label: 'Julio'},
  { value: 8, label: 'Agosto'},
  { value: 9, label: 'Septiembre'},
  { value: 10, label: 'Octubre'},
  { value: 11, label: 'Noviembre'},
  { value: 12, label: 'Diciembre'}
];

const defaultValues = {
  month: getMonth(new Date()) + 1
}

const Component = props => {
  const [values,setValues] = useState(defaultValues);

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }
  
  const handleAction = () => {
    props.handleFilter(values);
    props.handleClose();
  }

  const handleClose = () => { 
    setValues(defaultValues);
    props.handleClose();
  }
  
  return (
    <FormDialog 
      title='Filtrar'
      width='sm'
      isOpen={props.isOpen || false}
      handleClose={handleClose}
      handleAction={handleAction}
      actionLabel='Filtrar'
    >
      <SelectField 
        name='month'
        label='Mes'
        value={values.month}
        onChange={handleChange}
        options={monthsOption}
        required
      />
    </FormDialog>
   )
}

export default Component;