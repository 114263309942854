import { combineReducers } from 'redux';

import { alert } from './alert.reducer';
import { alertv2 } from './alert.reducer.v2';
import { auth } from './auth.reducer';
import { field } from './field.reducer';
import { formDialog } from './formDialog.reducer';
import { userNoAuth, user } from './user.reducer';
import { profile } from './profile.reducer';
import { progress } from './progress.reducer';
import { records } from './records.reducer';
import { redirect } from './redirect.reducer';

import { actionDialog, filterDialog, alternativeDialog } from './actionDialog.reducer';
import { deleteDialog } from './deleteDialog.reducer';
import { searchField } from './searchField.reducer';
import { getData } from './getData.reducer';
import { xlsDoc } from './xlsDoc.reducer';
import { pdfDoc } from './pdfDoc.reducer';
import { header } from './header.reducer';

const rootReducer = combineReducers ({
  alert,
  alertv2,
  auth,
  field, 
  formDialog,
  user,
  userNoAuth,
  profile,
  progress,
  records,
  redirect,
  actionDialog,
  filterDialog,
  deleteDialog,
  searchField,
  getData,
  xlsDoc,
  pdfDoc,
  header,
  alternativeDialog,
});
export default rootReducer;
