import React from "react";
import { FormDialog } from "../../../_presentationals/FormDialog";
import { TextField } from "../../../_presentationals/Form";

const GeneralBenefitsDialogComponent = () => {
    return <FormDialog
    table="maids"
    title="Configuración general de prestaciones"
    singular="generalBenefits"
  >
    <TextField
      fields={[{
        id: "healthInsurance", 
        label: "Cuota (Seguro)",
        required: false
      }]}
    />
    <TextField
      fields={[{
        id: "additionalBenefits", 
        label: "Prestaciones con altas",
        required: false
      }]}
    />
    <TextField
      fields={[{
        id: "taxBenefits", 
        label: "Prestaciones con bajas",
        required: false
      }]}
    />
    <TextField
      fields={[{
        id: "infonavit", 
        label: "Cuota (Infonavit)",
        required: false
      }]}
    />
    </FormDialog>
}

export default GeneralBenefitsDialogComponent