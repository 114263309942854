import React, { useMemo, useEffect } from "react";
import { connect } from 'react-redux';

import format from 'date-fns/format';


import Module from '../../_presentationals/Module';
import { TextField, SelectField } from './../../_presentationals/Form';

import { recordsActions, editValueAction } from '../../_actions';
import { newDate } from "../../_helpers.v2";

const expensesOptions = [
  { key: 1, value: "Fijo" },
  { key: 2, value: "Variable" },
  { key: 3, value: "Pago de nómina" },
  { key: 4, value: "Insumos" },
  { key: 5, value: "Prestaciones" },
  { key: 6, value: "Impuestos" },
  { key: 8, value: "Uniformes" },
  { key: 9, value: "Comisiones" },
  { key: 10, value: "Faltante" },
  { key: 11, value: "General" },
  { key: 12, value: "Sobrante" },
];

const inputsOptions = [
  { key: 1, value: "General" },
  { key: 2, value: "Sobrante" }
];

const actionOptions = [
  { key: 1, value: "Salida" },
  { key: 2, value: "Entrada" }
];

const accountOptions = [
  { key: 1, value: 'EDER FERNANDO JIMENEZ GARCÍA'},
  { key: 2, value: 'JOSE CRUZ ROSALES FELIX'},
  { key: 3, value: 'CYNTHIA NALLELY ROSALES VEGA'},
  { key: 4, value: 'FERNANDO...'},
  { key: 4, value: 'MARIA DEL ROSARIO VEGA ESEBERRE'},
  { key: 4, value: 'FERNANDO JIMENEZ RAMIREZ'},
];

const Expenses = ({getRecords, clearRecords, editValueAction, field, ...props}) => {
  
  useEffect(() => {
    editValueAction({ action: 1, type: 1 });
  }, [editValueAction]);

  useEffect(() => {
    editValueAction({ type: 1 });
  }, [field.action, editValueAction]);

  
  const expenses = useMemo(() => 
    props.expenses.count > 0 ? props.expenses.rows.map(el => ({
      ID: { 
        value: el.id,
        show: true,
        data: {
          ...el
        }
      }, 
      Fecha: {
        value: format(newDate(el.createdAt), 'dd/MM/yyyy HH:mm:ss')
      },
      Descripción: {
        value: el.description
      },
      Clasificación: {
        value: typeToLabel(parseFloat(el.action),parseFloat(el.type))
      }, 
      "Monto de Entrada": {
        value: parseFloat(el.action) === 2 ? `${parseFloat(el.amount).toFixed(2)}MXN` : '-',
        align: 'center'
      }, 
      "Monto de Salida": {
        value: parseFloat(el.action) === 1 ? `${parseFloat(el.amount).toFixed(2)}MXN` : '-',
        align: 'center'
      },
      Saldo: {
        value: `${parseFloat(el.balance).toFixed(2)}MXN`
      },
    })) : []
  , [props.expenses]);

  return (
    <Module
      table="expenses"
      name="movimientos"
      singularName="movimiento"
      filter={false}
      _delete={false}
      edit={false}
      title="Crear Movimiento"
      records={expenses}
    >
      <SelectField
        fields={[{
          id: 'action', 
          label: 'Acción',
          options: actionOptions
        }]}
      />
      <SelectField
        fields={[{
          id: 'account', 
          label: 'Cuenta Bancaria',
          options: accountOptions
        }]}
      />
      <TextField
        icon="monetization_on"
        fields={[{
          id: "amount", 
          label: "Cantidad",
          autoFocus: true
        }]}
      />

      <SelectField
        fields={[{
          id: "type", 
          label: `Tipo`,
          options: (field.action === 2) ? inputsOptions : expensesOptions
        }]}
      />
      <TextField
        fields={[{
          id: "description", 
          label: "Descripción"
        }]}
      />
      <TextField
        fields={[{
          id: 'password', 
          label: 'Contraseña',
          type: 'password'
        }]}
      />
      
    </Module>
  );
}

const typeToLabel = (action, value) => {
  if(action === 1) {
    switch(value){
      case 1: return "Fijo";
      case 2: return "Variable";
      case 3: return "Pago de nómina";
      case 4: return "Insumos";
      case 5: return "Prestaciones";
      case 6: return "Impuestos";
      case 7: return "Uniformes";
      case 8: return "Comisiones";
      default: return "Indefinido";
    }
  }else {
    switch(value){
      case 1: return "General";
      case 2: return "Sobrante";
      case 3: return "Cobro a Cliente";
      default: return "Indefinido";
    }
  }
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  field: state.field.value,
  expenses: state.records.expenses || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  clearRecords: value => dispatch(recordsActions.clearRecords(value)),
  editValueAction: data => dispatch(editValueAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Expenses);