const styles = theme => ({
  background: {
    background: "rgb(20,91,230)",
    minHeight: "100vh",
    margin: 0,
    padding: 0,
    fontFamily: "'Heebo', sans-serif"
  },
  header: {
    textAlign: "center"
  },
  body: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  footer: {
    color: "#fff"
  },
  logo: {
    width: theme.spacing(45),
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.5)
    }
  },
  navigation: {
    borderTop: "1px solid #092968",
    background: "#0f46b0",
    color: "#fff",
    width: "100%",
    position: "fixed",
    bottom: 0
  },
  navigationAction: {
    color: "#fff"
  }
});

export { styles };
