import { format } from "date-fns";
import localeES from 'date-fns/locale/es';
import { newDate } from "../../_helpers.v2";

const checkIns = ({method, ...props}) => {
  switch (method) {
    case 'DELETE':
      return `checkIns/${props._id}`;
    default:
    	const startFormat = props.start ? newDate(props.start) : new Date();
      const finishFormat = props.finish ? newDate(props.finish) : new Date();

      const start = format(startFormat, 'yyyy/MM/dd', {locale: localeES});
      const finish = format(finishFormat, 'yyyy/MM/dd', {locale: localeES});

      const query = { 
        type: props.type, 
        status: props.status, 
        customerId: props.customerId, 
        maidId: props.maidId 
      };
      
      let querys = '';
      Object.keys(query).forEach(key => {
        querys += `&${key}=${query[key]}`;
      });

      return `checkIns/reports?start=${start}&finish=${finish}${querys}`
  }
}

export default checkIns;