const api = ['start', 'finish', 'day', 'salary', 'payment', 'supplies', 'customerId'];

const countries = (method, values) => {
  const tmp = {};
  switch (method) {
    case 'POST': 
    case 'PUT':
      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      api.forEach(key =>{
        if(!Object.keys(tmp).includes(key)) tmp[key] = "";
      });
      return tmp;
    case 'DELETE':
      return { day: values.day, customerId: values.customerId, reason: values.reason, descriptionDelete: values.descriptionDelete };
    default:
      return tmp;
  }
}

export default countries;