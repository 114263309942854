import React from 'react';

import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import EventIcon from '@material-ui/icons/EventOutlined';

import IconButton from './../../../_presentationals.v2/Form/IconButton';

import { openFilterDialog } from '../../../_actions.v2';

const useStyles = makeStyles(theme => ({
  paddingX: {
		paddingLeft: theme.spacing(1.6),
		paddingRight: theme.spacing(1.6),
	},
}));

const FilterButtonComponent = (props) => {

  const classes = useStyles();
  
  const handleOpenFilter = () => {
    props.openFilter();
  };

  return (
    <IconButton 
      label="Filtrar" 
      action={ handleOpenFilter } 
      className={ classes.paddingX }
      icon={<EventIcon />}
    />
  )
};

const mapDispatchToProps = dispatch => ({
  openFilter: () => dispatch(openFilterDialog({})),
});

export default connect(null, mapDispatchToProps)(FilterButtonComponent);
