import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

import { isMobile } from "react-device-detect";

import PropTypes from 'prop-types';

import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import { closeFormDialog, resetFormAction, recordsActions } from './../../_actions';

import { styles, DialogTitle, DialogContent, DialogActions } from './style';

const FormDialogComponent = ({ children, classes, title, primaryButtonLabel, showActionButton, showClose, disableEscape, disableClick, isOpen, singular, closeFormDialog, alert, field, resetFormAction, handleCustomizedClick, clearDataAfterClose, keepDataAfterSuccess, ...props}) => {
  const [buttonLabel, setButtonLabel] = useState('Guardar');
  useEffect(() => {
    if(primaryButtonLabel) {
      setButtonLabel(primaryButtonLabel);
      return;
    }
    if(Object.keys(field).includes('id')) {
      setButtonLabel('Actualizar');
      return;
    }

    setButtonLabel('Guardar');

  }, [field, primaryButtonLabel]);

  useEffect(() => {
    if(alert.type === 'success'){ 
      closeFormDialog(singular);
      if(!keepDataAfterSuccess){ resetFormAction(); }
    }
  }, [alert, singular, closeFormDialog, resetFormAction, keepDataAfterSuccess]);

  const handleCloseDialog = e => {
    e.preventDefault();
    closeFormDialog(singular);
    if(clearDataAfterClose) resetFormAction();
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    const { table, create, update } = props;
    if(Object.keys(field).includes('id')){
      update(table);
      return;
    }
    create(table);
  }

  return (
    <Dialog
     fullScreen={ isMobile }
     open={ isOpen[singular] || false }
     onClose={ handleCloseDialog }
     fullWidth={true}
     maxWidth = {'sm'}
     disableEscapeKeyDown={disableEscape}
     classes={{ paperScrollPaper: classes.root }}     
    >
      { title && <DialogTitle children={title} /> }
      <DialogContent className={classes.root}>
        { children }
      </DialogContent>
      <DialogActions>
        { showClose && <Button onClick={ handleCloseDialog }>Cerrar</Button> }
        { showActionButton && (
          <Button onClick={ handleCustomizedClick || handleFormSubmit } color="primary">
            { buttonLabel }
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

FormDialogComponent.propTypes = {
  isOpen: PropTypes.object.isRequired,
  showActionButton: PropTypes.bool,
  showClose: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool
  ]),
  closeAfterSave: PropTypes.bool.isRequired,
  action: PropTypes.bool,
  primaryButtonLabel: PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool
  ])
};

FormDialogComponent.defaultProps = {
  title: false,
  closeAfterSave: false,
  primaryButtonLabel: false,
  showActionButton: true,
  showClose: true,
  disableEscape: false,
  disableClick: false,
  clearDataAfterClose: true
};

const mapStateToProps = state => ({ 
  isOpen: state.formDialog, 
  field: state.field.value,
  alert: state.alert
});
const mapDispatchToProps = dispatch => ({ 
  resetFormAction: () => dispatch(resetFormAction()),
  closeFormDialog: data => dispatch(closeFormDialog(data)),
  create: table => dispatch(recordsActions.create(table)),
  update: table => dispatch(recordsActions.update(table))
});

export const FormDialog =  withWidth()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FormDialogComponent)));