import { config, authHeader, chooseFields, chooseUrl } from '../_helpers';
import { handleFetchResponse } from './';

const create = ({ table, ...props }) => {
  const headers = { 'Content-Type': 'application/json', ...authHeader() };
  const method = 'POST';
  const url = `${config.apiUrl}/${chooseUrl[table]({method, ...props})}`;
  const body = JSON.stringify(chooseFields[table](method, props));
  const myRequest = new Request(url, { method, headers, body });
  return fetch(myRequest).then(handleFetchResponse({ url, method, headers, body }));
}

const update = ({ table, ...props }) => {
  const headers = { 'Content-Type': 'application/json', ...authHeader() };
  const method = 'PUT';
  const url = `${config.apiUrl}/${chooseUrl[table]({ _id: props.id, method, ...props})}`;
  const body = JSON.stringify(chooseFields[table](method, props));
  const myRequest = new Request(url, { method, headers, body });
  return fetch(myRequest).then(handleFetchResponse({ url, method, headers, body }));
}

const _delete = ({ table, _id, ...props }) => {
  const headers = { 'Content-Type': 'application/json', ...authHeader() };
  const method = 'DELETE';
  const url = `${config.apiUrl}/${chooseUrl[table]({ _id, method, ...props})}`;
  const body = JSON.stringify(chooseFields[table](method, props));  const myRequest = new Request(url, { method, headers, body });
  return fetch(myRequest).then(handleFetchResponse({ url, method, headers, body }));
}

const getAll = ({ table, ...props }) => {
  const method = 'GET';
	const url = `${config.apiUrl}/${chooseUrl[table]({method, ...props})}`;
  const myRequest = new Request(url, { method, headers: { ...authHeader() } });
	return fetch(myRequest).then(handleFetchResponse({ url, method, headers: {} }));
}

export const recordsServices = {
 create, getAll, update, _delete
};
