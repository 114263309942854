import React, { Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';

import { styles } from './style';
import { history } from './../../_helpers';
import { JsonToXls } from './../../_presentationals/XLS';
import { openFormDialog, editValueAction, editIdentifierAction, redirectActions } from '../../_actions';
import { DeleteDialog } from '../DeleteDialog';
import HeaderComponent from './header';
import BodyComponent from './body';


const TableComponent = ({ classes, name, filter, create, singularName, table, structure, records, edit, _delete, updateStructure, openFormDialog, editValueAction, editIdentifierAction, actions, redirect, print, download, clearRedirect, additionalText }) => {

  const [keys, setKeys] = useState([]); 
  const [count, setCount] = useState(0); 
  const [rows, setRows] = useState([]);
  const [textFilter, setTextFilter] = useState('');
  const [direction, setDirection] = useState([]);
  const [idToDelete, setIdToDelete] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  useEffect(() => { 
    setRows(records);
    setCount(records.length || 0); 
    const tmpKeys = (records.length > 0) ? Object.keys(records[0]).filter(el => records[0][el]._sort && el) : [];
    setKeys(tmpKeys);
  }, [records]);
 
  useEffect(() => {
    const directions = [];
    keys.forEach(data => directions[data] = 'asc');
    setDirection(directions);
  }, [keys]);

  const handleSort = e => {
    const keyToSort = e.currentTarget.dataset.key;
    const newRows = sortByType(rows, keyToSort, records[0][keyToSort]._sort, direction[keyToSort]);
    setDirection({ ...direction, [keyToSort]: direction[keyToSort] === 'asc' ? 'desc' : 'asc' })
    setRows(newRows);
  }

  const handleChangeFilter = e => {
    setTextFilter(e.target.value);
    const newRows = filterRows(e.target.value, records || []);
    setCount(newRows.length);
    setRows(newRows);
  }

  const handleCreateXLS = e => {
    const data = rows.map(el => { 
      const children = {};
      Object.keys(el).forEach(key => { 
        return children[key] = el[key].arrayValue ? el[key].arrayValue.join(' ') : (typeof el[key].alt !== 'undefined' ? el[key].alt : el[key].value) 
      });
      return children;
    });
    const array = { title: name, data };
    JsonToXls(array);
  }

  const handleCreate = e => {
    openFormDialog(singularName);
  }

  const handleEdit = e => {
    const data = JSON.parse(e.currentTarget.dataset.row);
    Object.keys(data).map(el => ['boolean','string','number'].includes(typeof data[el]) && editValueAction({ [el]: data[el] }));
    openFormDialog(singularName);
  }

  const handleDelete = e => {
    const currentId = JSON.parse(e.currentTarget.dataset.id);
    setIdToDelete(currentId);
  } 

  const handleClickMenu = e => {
    setAnchorEl(e.currentTarget);
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  }

  const handleReturn = () => {
    const { returnTo } = redirect;
    history.push(returnTo);
    clearRedirect();
  }

  return(
    <Fragment>
      <DeleteDialog _id={idToDelete} table={table} />
      <HeaderComponent  
        name={name}
        count={count}
        filter={filter}
        create={create}
        handleCreate={handleCreate}
        singularName={singularName}
        print={print}
        download={download}
        redirect={redirect}
        table={table}
        direction={direction}
        handleClickMenu={handleClickMenu}
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
        handleReturn={handleReturn}
        handleCreateXLS={handleCreateXLS}
        additionalText={additionalText}
      />
      <BodyComponent 
        records={records}
        rows={rows}
        table={table}
        handleSort={handleSort}
        direction={direction}
        textFilter={textFilter}
        handleChangeFilter={handleChangeFilter}
        actions={actions}
        edit={edit}
        _delete={_delete}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
    </Fragment>
  );
}

const filterRows = (value, rows) => {
  const keys = (rows.length > 0) ? Object.keys(rows[0]).filter(el => rows[0][el].filter && el) : [];
  const tmp = rows.filter(data => {
    let response = false;
    keys.forEach(key => {
      if((data[key]).value.toLowerCase().includes(value.toLowerCase())){
        response = true;
      }
    });
    return response && data;
  });
  return tmp;
}

const sortByType = (rows, key, type, direction) => {
  switch(type){
    case 'text':
      rows.sort(function (a, b) {
        if (a[key].value > b[key].value) { return 1; }
        if (a[key].value < b[key].value) { return -1; }
        return 0;
      });
      break;
    case 'alt':
      rows.sort(function (a,b) {
        return b[key].alt - a[key].alt;
      });
      break;
    case 'number':
      rows.sort(function (a, b) {
        return b[key].value - a[key].value;
      });
      break;
    default:
      break;
  }
  if(direction === 'desc') rows.reverse();
  return rows;
}

TableComponent.defaultProps = {
  structure: {},
  filter: true,
  create: true,
  edit: true,
  _delete: true,
  print: false,
  download: false,
  additionalText: ""
}

const mapStateToProps = state => ({
  redirect: state.redirect
});

const mapDispatchToProps = dispatch => ({ 
  openFormDialog: data => dispatch(openFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data)),
  editIdentifierAction: data => dispatch(editIdentifierAction(data)),
  clearRedirect: () => dispatch(redirectActions.clearRedirect())
});

export const TableModule = withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(TableComponent));