import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(Theme => ({
	container: {
		width: '35%',
		[Theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	title: {
		padding: Theme.spacing(5, 2, 1, 2),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	closeButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			color: '#141414',
			transition: 'all 0.5s ease-in-out',
		},
	},
	actionButton: {
		padding: Theme.spacing(2, 2, 2, 2),
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	content: {
		flex: 1,
		padding: Theme.spacing(2),
		overflow: 'auto',
	},
	dateContent: {
		padding: Theme.spacing(2, 2, 3, 2),
	},
	footer: {
		padding: Theme.spacing(2, 2, 3, 2),
	},
}));

export default useStyles;
