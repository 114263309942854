const api = ['quoted', 'accepted', 'pulledApart', 'ordered', 'reception', 'embroidery', 'delivered', 'supplement', 'tracing'];

const filipinas = (method, values) => {
  const tmp = {};
  switch (method) {
    case 'PUT':
      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      return tmp;
    default:
      return tmp;
  }
}

export default filipinas;