import React from 'react';
import { Router } from "react-router-dom";

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import blue from '@material-ui/core/colors/blue';
import { history } from './_helpers';
import Navigation from "./_containers/Navigation";

import Routes from "./Routes";
import 'typeface-roboto';

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Roboto',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: blue,
  },
});

const useStyles = makeStyles((theme) => ({
  root: { width: '100%', height: '100vh' }
}));

const session = JSON.parse(localStorage.getItem('session'));
const isAuth = session ? session.isAuth : false;

const App = () => {

  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router history={history}>
  	    <div className={classes.root}>
          { isAuth ? <Navigation /> : <Routes /> }
        </div>
      </Router>
    </MuiThemeProvider>
  );
}
 

export default App;
