import React from "react";

import { FormDialog } from './../../_presentationals/FormDialog';
import { TextField } from './../../_presentationals/Form';

const DescriptionDialog = () => 
  <FormDialog
    table="customers"
    title="Crear un Comentario"
    singular="comentario"
  >
    <TextField
      dialog={false}
      fields={[{
        id: "description", 
        label: "¿Por qué no se atendió el servicio?",
        multiline: true,
        rows: 2,
        variant: "outlined",
        required: false
      }]}
    />
  </FormDialog>


export default DescriptionDialog;