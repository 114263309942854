import React, { Fragment } from "react";

import withStyles from '@material-ui/core/styles/withStyles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import CalendarIcon from '@material-ui/icons/CalendarToday';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';

import { styles } from './style';

const Header = withStyles(styles)(({ classes, handleFilter, handleSettings }) => (
  <Fragment>
    <Grid container alignContent="center" alignItems="center" className={ classes.header } spacing={0}>
      <Grid item md={6} xs={12}>
        <Typography variant="subtitle2" className={ classes.headerTitle }>
         ESTADO DE RESULTADOS
        </Typography>
      </Grid>
      <Grid item className={ classes.headerButtons } md={6} xs={false}>
        <Button onClick={ handleFilter } color="secondary" data-id={1}>
          <CalendarIcon fontSize="small" /> Filtrar
        </Button>
        <Button onClick={ handleSettings } color="secondary" data-id={1}>
          <SettingsIcon fontSize="small" /> Configración
        </Button>
      </Grid>
    </Grid>
    
    <Divider />
  </Fragment>
));

export { Header };