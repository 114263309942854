const routes = (route, method, params) => {
	switch(route){
		case 'receivable':
			const customerId = params.id ? params.id.value : 0;
			return `customers/${customerId}/balances`;
		default: 
			if(['GET_BY_PK', 'DELETE', 'UPDATE'].includes(method)) return `${route}/${params.id}`;
			return route;
	}
}

export default routes;