import jsPDF from 'jspdf';
import format from 'date-fns/format';

import logoF from './quitationImage';

const b = [139, 208, 246];

export const Quotation = data => {

 	const name = (parseInt(data.type) === 2) ? data.branchName : data.name;
 	const addressLine1 = data.addressLine1;
 	const addressLine2 = data.addressLine2;
 	const city = data.city;
 	const zipCode = data.zipCode;
 	const phone = data.phone;
 	const commentary = data.commentary;

 	const startServiceTime = data.startServiceTime && hour(data.startServiceTime);
 	const finishServiceTime = data.finishServiceTime && hour(data.finishServiceTime);
 	const serviceTime = data.serviceTime;

 	const price = data.price;
 	const tax = (parseFloat(price) * (parseFloat(data.vatRate) / 100));
 	const total = (parseFloat(price) + parseFloat(tax));

 	const typePrice = typePriceLabel(data.typePrice);
 	const quantity = quantityLabel(data.quantity);
 	const Days = Object.keys(data.Days).map(el => data.Days[el]);

 	const date = format(
	  new Date(),
	  'dd/MM/yyyy HH:mm:ss'
	);

	const quotationId = `${Math.floor(1000 + Math.random() * 9000)}-${data.customerId}`;

 	const supplies = data.supplies ? `-> Incluye los siguientes insumos:` : "-> Insumos no están incluidos.";
 	data.suppliesCommentary = data.supplies ? data.suppliesCommentary : "";
 	
 	// const supplies = data.supplies ? " Todos los insumos incluidos.  Prestaciones" : " Insumos no están incluidos.  Prestaciones";
	
	const doc = new jsPDF();

	doc.setFontStyle("bold");
	doc.text('COTIZACIÓN-PROPUESTA', 105, 10, { align: 'center' });
	
	const logo = logoF();
	doc.addImage(logo, 'PNG', 150, 15, 45, 55);
	
	doc.text('Emisor', 10, 20);
	doc.setFontStyle("normal");
	doc.setFontSize(12);
	doc.setFontStyle("bold");
	doc.text('EDER FENANDO JIMENEZ GARCÍA', 10, 26);
	doc.setFontStyle("normal");
	doc.text('JIGE830303G90', 10, 31);
	doc.text('AV. ALVARO OBREGON NORTE 623, LOCAL 125', 10, 36);
	doc.text('EDIFICIO CLOUTHIER, COL. CENTRO', 10, 41);
	doc.text('CULIACÁN, SINALOA, MÉXICO CP 80000', 10, 46);
  
	doc.setFontSize(16);
	doc.setFontStyle("bold");
	doc.text('Receptor', 10, 56);
	doc.setFontSize(12);
	doc.setFontStyle("bold");
	doc.text(name, 10, 62);
	doc.setFontStyle("normal");
	doc.text(addressLine1, 10, 67);
	doc.text(addressLine2, 10, 72);
	doc.text(`${city}, SINALOA, MÉXICO CP ${zipCode}`, 10, 77);
	doc.text(`TEL. ${phone}`, 10, 82);

	doc.setDrawColor(b[0], b[1], b[2]);
	doc.setFillColor(b[0], b[1], b[2]);
	doc.rect(10, 87, 190, 7, 'F');
	doc.rect(10, 95, 190, 7, 'F');

	doc.setDrawColor(0);
	doc.setFillColor(246,246,246);
	doc.rect(10, 102, 190, 70, 'F');

	doc.setFontSize(14);
	doc.setFontStyle("bold");
	doc.setTextColor(255, 255, 255);
	doc.text('Conceptos', 105, 92, { align: 'center' });
	doc.setFontSize(12);
	doc.text('Descripción', 12, 100);
	doc.text('Importe', 198, 100, { align: 'right' });

	doc.setTextColor(0, 0, 0);
	doc.text('Tarifa ' + typePrice, 12, 107);
	doc.text('$'+parseFloat(price).toFixed(2) + 'MXN', 198, 107, { align: 'right' });

	doc.setFontStyle("normal");

	const tipo_servicio = `-> Servicio ${daysToLabel(Days)}.`;
	doc.text(tipo_servicio, 12, 116, { align: 'justify' });
	const operadores = `-> ${quantity} de limpieza`;
	doc.text(operadores, 12, 121, { align: 'justify' });
	const tipo_servicio_1 = startServiceTime ? `-> Horario preestablecido por el cliente de ${startServiceTime} a ${finishServiceTime} horas.` : serviceTime ? `-> Servicio de ${serviceTime} horas.` : '';
	doc.text(tipo_servicio_1, 12, 126, { align: 'justify' });

	doc.setFontSize(12);
	doc.setFontStyle("italic");
	// const line = doc.splitTextToSize(supplies, 180);
	let breakLine = 131;
	if(commentary !== "") {
		doc.text(doc.splitTextToSize(`-> ${commentary}`, 220), 12, breakLine);
		breakLine += 5;
	}

	const suppliesJustify = doc.splitTextToSize(`${data.suppliesCommentary}`, 220);

	doc.text(supplies, 12, breakLine);
	breakLine += 5;
	doc.setFontSize(10);
	// doc.text(12, 136, `${data.suppliesCommentary}`);
	doc.text(12, breakLine, suppliesJustify, 'left');
	doc.setFontSize(12);
	doc.setFontStyle("normal");
	// const tipo_servicio_2 = 'que la ley indique, servicio a domicilio, excelente presentación.';
	// doc.text(tipo_servicio_2, 12, 129, { align: 'justify' });

	doc.setDrawColor(0);
	doc.setFillColor(b[0],b[1],b[2]);
	doc.rect(10, 174, 125, 8, 'F');

	doc.rect(137, 174, 29, 24, 'F');

	doc.setDrawColor(0);
	doc.setFillColor(246,246,246);
	doc.rect(10, 181, 125, 17, 'F');
	doc.rect(162, 174, 38, 24, 'F');

	doc.setTextColor(255, 255, 255);
	doc.setFontStyle("bold");
	doc.text('Garantía del mejor precio', 12, 179);

	doc.text('Subtotal', 142, 179);
	doc.text('IVA', 152, 186);
	doc.text('Total', 149, 194);

	doc.setTextColor(0, 0, 0);
	doc.setFontStyle("normal");
	doc.text('En Smart Clean nos esforzamos para brindarle el mejor precio del', 12, 186);
	doc.text('mercado, por lo cual si encuentras una mejor cotización con', 12, 191);
	doc.text('las mismas características hazlo saber y te mejoramos el precio.', 12, 196);

	// doc.text('Trabajamos todos los días para ayudar a mejorar la economía', 12, 186);
	// doc.text('y calidad de tu empresa. Te garantizamos que encontrarás las', 12, 191);
	// doc.text('mejores tarifas disponibles en el mercado.', 12, 196);

	doc.text('$'+parseFloat(price).toFixed(2) + 'MXN', 197, 179, { align: 'right' });
	doc.text('$'+parseFloat(tax).toFixed(2) + 'MXN', 197, 186, { align: 'right' });
	doc.text('$'+parseFloat(total).toFixed(2) + 'MXN', 197, 194, { align: 'right' });

	doc.setDrawColor(b[0],b[1],b[2]);
	doc.setFillColor(b[0],b[1],b[2]);
	doc.rect(10, 200, 190, 7, 'F');

	doc.setTextColor(255, 255, 255);
	doc.setFontStyle("bold");

	doc.text('Contáctanos', 12, 205, { align: 'left' });

	doc.setDrawColor(0);
	doc.setFillColor(246,246,246);
	doc.rect(10, 207, 190, 30, 'F');

	doc.setTextColor(0, 0, 0);
	// doc.setFontStyle("bold");
	// doc.text('Ventas', 12, 216, { align: 'left' });
	// doc.setFontStyle("normal");
	// doc.text('Eder Fernando Jimenez García', 12, 221, { align: 'left' });
	// doc.text('Tel. (667) 390 0948', 12, 226, { align: 'left' });
	// doc.text('efernandojg@yahoo.com.mx', 12, 231, { align: 'left' });

	doc.setFontStyle("bold");
	doc.text('Atención a Clientes', 12, 216, { align: 'left' });
	doc.setFontStyle("normal");
	doc.text('Oficinas de Lunes a Viernes de 8:00 am a 3:00 pm, sábado de 8:00 am a 1:00 pm', 12, 221, { align: 'left' });
	doc.text('Tel. (667) 455 3968', 12, 226, { align: 'left' });
	doc.text('limpiezamucaama@gmail.com', 12, 231, { align: 'left' });

	doc.setDrawColor(b[0],b[1],b[2]);
	doc.setFillColor(b[0],b[1],b[2]);
	doc.rect(10, 239, 190, 12, 'F');

	doc.setFontSize(22);
	doc.setFontStyle("bold");
	doc.setTextColor(255, 255, 255);

	doc.text('FOLIO: CLN' + quotationId, 12, 248, { align: 'left' });
	doc.text('FECHA: ' + date, 197, 248, { align: 'right' });

	doc.setDrawColor(b[0],b[1],b[2]);
	doc.setFillColor(b[0],b[1],b[2]);
	doc.rect(10, 273, 190, 10, 'F');

	doc.setFontSize(18);
	doc.setFontStyle("bold");
	
	doc.text('¡Mejoramos cualquier cotización!', 105, 280, { align: 'center' });
	doc.setFontSize(12);
	doc.setFontStyle("normal");
	doc.setTextColor(0, 0, 0);
	doc.text('Este documento no tiene validez fiscal.', 10, 290, { align: 'left' });
	doc.text('Página 1/1', 200, 290, { align: 'right' });

	doc.save('COTIZACIÓN SMART CLEAN CLN' + quotationId);


}

function typePriceLabel(value){
	switch(value){
		case 1:
			return 'Diaria';
		case 2:
			return 'Semanal';
		case 3:
			return 'Mensual';
		default:
			return "Indefinido"
	}
}

function hour(value){
	const hour = value.split(':');
	return `${hour[0]}:${hour[1]}`;
}

function quantityLabel(value){
	return (parseInt(value) === 1) ? 'Un operador' : `${value} operadores`;
}

function daysToLabel(array) {
	const daysOfTheWeek = ["lunes", "martes", "miércoles", "jueves", "viernes", "sábado", "domingo"];
	let daysTolabel = "";
	if(array[0] && array[1] && array[2] && array[3] && array[4]){
		daysTolabel = "lunes a viernes";
		if(array[5]){ daysTolabel = "lunes a sábado"; }
		if(array[5] && array[6]){ daysTolabel = "lunes a domingo"; }
		daysTolabel = "de " + daysTolabel
	}else{
		daysTolabel = array.map((el, i) => { return el && daysOfTheWeek[i];}).filter(el => el && el);
		daysTolabel = "días " + daysTolabel.join(", ");
	}
	return daysTolabel;
}